import TrixEditor from "components/EditorField/Editor";
import ErrorText from "components/ErrorText/ErrorText";
import HelperText from "components/HelperText/HelperText";
import IconContainer from "components/IconContainer";

export const EditorInput = ({
  label: labelProp,
  inputProps,
  name,
  icon,
  iconStyle,
  error,
  helpText,
  type,
  language,
  required,
}) => {
  const label = required ? `${labelProp}*` : labelProp;

  return (
    <div className="form-floating">
      <div className="editor-section">
        <div className="my-4">{label}</div>
        <TrixEditor name={name} {...inputProps} />
      </div>

      <IconContainer
        icon={icon?.url}
        className={`${icon?.className} base-position color-icon`}
        style={iconStyle}
      />
      <ErrorText error={error} />
      <HelperText
        enabled={helpText?.enabled && !error.length}
        helpText={helpText}
        language={language}
        type={type}
      />
    </div>
  );
};
