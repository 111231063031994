import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import JobList from "components/Job/JobList";
import { Spinner } from "components/Shared/Spinner";
import { useTranslation } from "react-i18next";

import { UserContext } from "contextAPI/UserContext";
import { AuthContext } from "contextAPI/AuthContext";
import { CandidateJobContext } from "contextAPI/CandidateJobContext";

import { DASHBOARD_ROUTE, HOME } from "constants/routes";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

const getJobs = (candidateData) => {
  return candidateData?.map(({ id, status, job, company }) => ({
    candidateId: id,
    candidateStatus: status,
    ...job,
    company,
  }));
};

const SelectJob = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { isLoadingUserInfo, jobsInfo } = useContext(AuthContext);
  const { userCandidatesData, userSelectedData, setUserSelectedData } =
    useContext(CandidateJobContext);
  const { handleCandidateSelected, setSelectedCandidate } =
    useContext(UserContext);

  const [isSingleDataLoading, setIsSingleDataLoading] = useState(false);

  const onJobSelected = (job) => {
    handleCandidateSelected(job.candidateId);
    LocalStorageManager.set(CONSTANTS.SELECTED_JOB, job);

    navigate(HOME.INDEX);
  };

  //auto select jobs when there is only one job

  useEffect(() => {
    if (userCandidatesData?.length > 0) {
      const jobData = getJobs(userCandidatesData);

      if (jobData?.length === 1) {
        setIsSingleDataLoading(true);
        onJobSelected(jobData?.[0]);
      }

      setUserSelectedData(jobData?.[0]);
      setSelectedCandidate(jobData[0]?.candidateId);
    }
  }, [userCandidatesData]);

  useEffect(() => {
    if (LocalStorageManager.get(CONSTANTS.SELECTED_JOB)) {
      onJobSelected(LocalStorageManager.get(CONSTANTS.SELECTED_JOB));
      setUserSelectedData(LocalStorageManager.get(CONSTANTS.SELECTED_JOB));
      setSelectedCandidate(
        LocalStorageManager.get(CONSTANTS.SELECTED_JOB.candidateId)
      );
    }
  }, [userCandidatesData]);

  if (
    isLoadingUserInfo ||
    userCandidatesData.length == 0 ||
    isSingleDataLoading
  ) {
    return <Spinner />;
  }

  return (
    <div
      className="modal fade show d-block"
      id="myModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered job-modal">
        <div className="modal-content">
          <div className="modal-body">
            <h4 className="mb-2">{t("message.jobList.selectJob")}</h4>
            <p className="mb-4">{t("message.jobList.chooseOneJob")}</p>
            <JobList
              onJobSelected={onJobSelected}
              jobs={getJobs(userCandidatesData)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectJob;
