import React from "react";

const CustomCheckbox = ({
  name,
  value,
  className,
  onClick,
  onBlur,
  id,
  templateName,
}) => {
  return (
    <input
      name={name}
      component="input"
      type="checkbox"
      value={value}
      className={className}
      id={id}
      onClick={onClick}
      checked={value}
      onBlur={onBlur}
      data-template={templateName}
    />
  );
};

export default CustomCheckbox;
