import React from "react";

import { IntIcon } from "components/Shared/Icon";

const KeyLayout = ({ data }) => {
  const { rawData, accuracyData, netData } = data;

  return (
    <div className="key-layout d-flex mx-auto justify-content-between py-4 px-4 mt-3">
      <div className="d-flex w-50">
        <div className="w-50 raw-kpm">
          <h1>{rawData?.score}</h1>
          <p>{rawData?.title}</p>
        </div>
        <div className="w-50">
          <h1>{accuracyData}</h1>
          <p>Accuracy</p>
        </div>
      </div>

      <div className="d-flex align-items-center big-arrow">
        <IntIcon name="bigArrow" className="h-100" />
      </div>

      <div className="w-50">
        <h1>{netData?.score}</h1>
        <p>{netData?.title}</p>
      </div>
    </div>
  );
};

export default KeyLayout;
