import React, { useState, useEffect } from "react";

import Spinner from "./Spinner";

import ReactTooltip from "react-tooltip";

import ProgressBar from "components/Dashboard/Accordion/ProgressBar";

import CourseInfo from "components/Dashboard/Accordion/CourseInfo";

import CoursesApi from "api/CoursesApi/CoursesApi";

import TalentLms from "assets/images/talentLms.svg";
import { IntIcon } from "components/Shared/Icon";

const CourseCard = ({
  name,
  big_avatar,
  code,
  completion_percentage,
  category_name,
  description,
  id,
  enrolled,
  course,
  label,
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loadSpinner, setLoadSpinner] = useState();

  const showMask = isHovering ? "mask" : "";
  const showClass = isHovering ? "d-flex" : "d-none";

  const [courseInfo, setCourseInfo] = useState([]);

  const infoList = async () => {
    const response = await CoursesApi.getSingleCourse(id);

    setCourseInfo(response?.data);
  };

  useEffect(() => {
    infoList();
  }, [id]);

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const codeDisplayClassName = !code ? "d-none" : "";

  const course_percentage = course?.filter((el) => el.id === id);

  const getLoginId = async () => {
    setLoadSpinner(true);

    const gotoLink = await CoursesApi.getGotoCourseLink(id);

    if (gotoLink?.data) {
      setLoadSpinner(false);
    }

    window.open(gotoLink?.data?.goto_url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      <div className="col-md-3 mb-3 align-items-stretch">
        <div
          className="wrapper text-center d-flex flex-column align-items-between justify-content-between min-h-full"
          onMouseEnter={() => setIsHovering(!isHovering)}
          onMouseLeave={() => setIsHovering(!isHovering)}
          onClick={() => getLoginId()}
          onKeyDown={() => getLoginId()}
          role="button"
          tabIndex={0}
        >
          <div className="image position-relative">
            <figure className="m-0">
              <img
                className={`${showMask}`}
                src={big_avatar ?? TalentLms}
                alt={name}
                title={name}
              />
            </figure>
            <div
              className={`action position-absolute d-flex align-items-center gap-5 ${showClass}`}
            >
              <Spinner loadSpinner={loadSpinner}>
                <IntIcon name="playBtn" className="play-btn" />
              </Spinner>
              <span
                className="cursor-pointer ms-2 info-icon"
                onClick={toggleModal}
                aria-hidden
              >
                <IntIcon name="infoIcon" />
              </span>
            </div>
          </div>
          <div className="desc h-100 d-flex flex-column align-items-between justify-content-between">
            <div className="title mb-3">
              <div
                className="px-1"
                data-tip={`${name} ${code}`}
                data-place="top"
              >
                <h4>
                  {name}{" "}
                  <span className={`${codeDisplayClassName} text-success`}>
                    ({code})
                  </span>
                </h4>
              </div>
              <ReactTooltip />
            </div>
            <ProgressBar
              enrolled={enrolled}
              completion_percentage={completion_percentage}
              course_percentage={course_percentage}
              label={label}
              id={id}
            />
          </div>
        </div>
      </div>
      <CourseInfo
        image={big_avatar}
        title={name}
        code={code}
        showModal={showModal}
        toggleModal={toggleModal}
        category_name={category_name}
        summary={description}
        id={id}
        codeDisplayClassName={codeDisplayClassName}
        courseInfo={courseInfo}
      />
    </>
  );
};

export default CourseCard;
