import AuthenticatedApi from "../BaseApi";

const URL = {
  INDEX: `/candidates`,
  DYNAMIC_FIELD_VALUES: `/candidates/:candidateId/dynamic_field_values?page=:page`,
  COMPANY: "/candidates/:candidateId/company",
  JOB: `/candidates/:candidateId/job`,
  FETCH_INFO: "/candidates/info",
};

class CandidateApi {
  /**
   *
   * @param {int|string} dynamicFormId
   * @returns Promise
   */
  static get(dynamicFormId) {
    const FETCH_URL = URL.INDEX.replace(":dynamicFormId", dynamicFormId);

    return AuthenticatedApi.get(FETCH_URL);
  }

  /**
   *
   * @param {int|string} dynamicFormId
   * @returns Promise
   */
  static getDynamicFieldValues(candidateId, currentPage) {
    const FETCH_URL = URL.DYNAMIC_FIELD_VALUES.replace(
      ":candidateId",
      candidateId
    ).replace(":page", currentPage);

    return AuthenticatedApi.get(FETCH_URL);
  }

  /**
   *
   * @param {Obj} data
   * @returns Promise
   */
  static updateFieldValues(data) {
    const UPDATE_URL = URL.FIELD_UPDATE;

    const postData = {
      dynamic_field_values: data,
    };

    return AuthenticatedApi.post(UPDATE_URL, postData);
  }

  /**
   *
   * @param {string} uuid - Candidate UUID
   */
  static getCompanyDetails(uuid) {
    const FETCH_URL = URL.INDEX.replace(":candidateId", uuid);

    return AuthenticatedApi.get(FETCH_URL);
  }

  /**
   *
   * @param {string} uuid - Candidate UUID
   */
  static getJobDetails(uuid) {
    const FETCH_URL = URL.INDEX.replace(":candidateId", uuid);

    return AuthenticatedApi.get(FETCH_URL);
  }

  /**
   *
   * @param {string} uuid
   */
  static fetchCandidateByUId(uuid) {
    return AuthenticatedApi.post(URL.FETCH_INFO, {
      uuid,
    });
  }
}

export default CandidateApi;
