import { useState } from "react";
import { useTranslation } from "react-i18next";

import DeleteModal from "components/Modals/DeleteModal";
import { EDUCATION } from "constants/constant";
import { DeleteIcon, EditIcon } from "constants/icons/icon";
import { useGetLabel } from "hooks/useGetLabel";

export const EducationListItem = ({
  education,
  onEdit,
  onDelete: onDeleteProp,
  index,
  isEditing,
  section,
}) => {
  const { t } = useTranslation();

  const isEnrolled = education.end_month
    ? `${education?.end_month} ${education?.end_year}`
    : EDUCATION.PRESENT;

  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const getLabel = useGetLabel(section);

  function deleteEducation() {
    onDeleteProp(index);

    setOpenDeleteModal(false);
  }

  const deletePrompt = education?.school_name
    ? t("common.confirmEducationDelete").replace(
        ":school_name",
        education?.school_name
      )
    : t("modalValues.deleteEmployment");

  const buttons = isEditing ? (
    <div className="buttons-holder d-flex justify-content-end my-4">
      <div className="px-2 py-1 editing-btn">{t("attributes.editing")}</div>
    </div>
  ) : (
    <div className="buttons-holder d-flex my-4 justify-content-center">
      <button
        type="button"
        className="bg-transparent border-0 btn-icon"
        onClick={() => onEdit(index)}
      >
        <EditIcon className="color-icon edit-icon me-2" />
      </button>

      <button
        type="button"
        className="bg-transparent border-0 btn-icon"
        onClick={() => setOpenDeleteModal(true)}
      >
        <DeleteIcon className="del-icon" />
      </button>
    </div>
  );

  return (
    <div className="">
      <div className="bg-secondary-dark px-4 py-2 rounded mb-2 ">
        <div className={`row  justify-content-between align-items-center`}>
          <div className="col-lg-4">
            <strong
              className="head fw-medium text-light "
              data-tip={education?.school_name}
              data-place="top"
            >
              {education?.school_name}
            </strong>

            <p className="sub-head pt-1 text-capitalize">
              {education?.start_month} {education?.start_year} - {isEnrolled}
            </p>
          </div>
          <div className="col-lg-3">
            <p className="head text-light" data-place="top">
              {getLabel(EDUCATION.LEVEL, education?.level)}
            </p>
          </div>
          <div className="col-lg-4">
            <strong className="head fw-medium text-light" data-place="top">
              {getLabel(EDUCATION.DEGREE, education?.degree)}
            </strong>

            <p className="sub-head pt-1" data-place="top">
              {education?.certification}
            </p>
          </div>

          <div className="col-lg-1">
            <div className="position-relative">
              {buttons}
              <div className="delete-holder position-absolute bg-dark">
                <DeleteModal
                  idx={0}
                  deleteId={0}
                  del={openDeleteModal}
                  setDel={setOpenDeleteModal}
                  deleteFunction={deleteEducation}
                  message={deletePrompt}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
