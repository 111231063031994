import { useEffect, useRef, useState } from "react";
import { useField } from "react-final-form";
import { FieldArray, useFieldArray } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";

import NoEmploymentSection from "components/LayoutComponent/EmploymentComponent/NoEmploymentSection";
import Modal from "components/Shared/Modal";
import { EMPLOYMENT } from "constants/constant";
import { validateAtleastOneArray } from "utils/validation";

import { DynamicFormElement } from "./DynamicFormElement";
import { EmploymentForm } from "./Employment/EmploymentForm";
import { EmploymentListItem } from "./Employment/EmploymentListItem";
import { ErrorText } from "./ErrorText";
import { Portal } from "./Portal";
import { SectionContainer } from "./Section";
import { NoEducation } from "./Education/NoEducation";

//Employment Section
export const EmploymentSection = ({ section }) => {
  const ref = useRef();
  const noExperienceField = useField(EMPLOYMENT.NO_WORK_EXPERIENCE);
  const [refresh, setRefresh] = useState(0);
  const [element, setElement] = useState(null);
  const { fields: input } = useFieldArray(section.name);
  const [selectedEmploymentIndex, setSelectedEmploymentIndex] = useState(
    input.value?.length - 1 || 0
  );

  const [showFormModal, setShowFormModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setElement(ref.current);
  }, []);

  useEffect(() => {
    //This is a hack to rerender the component as useFieldArray does not rerender the component
    setRefresh(Math.random());
  }, [noExperienceField.input.value]);

  useEffect(() => {
    setSelectedEmploymentIndex(input.value.length - 1);
  }, [input.value.length]);

  function onEdit(index) {
    setSelectedEmploymentIndex(index);
    setShowFormModal(true);
  }

  function onDelete(index) {
    input.remove(index);
  }

  function onSubmit(values, index) {
    input.update(index, values[section.name]);
    if (selectedEmploymentIndex === input.value?.length - 1) {
      input.push(undefined);
      // this hack is because the input.value length is not immediately updated
    } else {
      setSelectedEmploymentIndex(input.value.length - 1);
    }
    setShowFormModal(false);
  }

  function cancelEdit() {
    setSelectedEmploymentIndex(input.value?.length - 1);
    setShowFormModal(false);
  }

  const noExperienceCheckComponent = section.components.find(
    (i) => i.name === EMPLOYMENT.NO_WORK_EXPERIENCE
  );

  //For key , check comment in Education section

  return (
    <SectionContainer
      section={section}
      left={() => {
        if (input.value.length > 1)
          return <AddEmploymentButton onClick={() => setShowFormModal(true)} />;

        return (
          noExperienceCheckComponent && (
            <DynamicFormElement component={noExperienceCheckComponent} />
          )
        );
      }}
    >
      <div ref={ref}>
        {noExperienceField.input.value ? (
          <NoEmploymentSection />
        ) : (
          <Portal el={element}>
            <div className="portal-content">
              <FieldArray
                name={section.name}
                validate={(v) =>
                  validateAtleastOneArray(
                    v,
                    t("message.errors.missingEmploymentDetails")
                  )
                }
              >
                {({ fields }) =>
                  fields.value.length <= 1 ? (
                    <NoEducation>
                      <AddEmploymentButton
                        onClick={() => setShowFormModal(true)}
                      />
                    </NoEducation>
                  ) : (
                    <div>
                      {fields.map((name, index) => {
                        if (index === fields.value?.length - 1) return null;

                        return (
                          <EmploymentListItem
                            employment={fields.value[index]}
                            name={name}
                            index={index}
                            key={Date.now() + "" + index}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            isEditing={selectedEmploymentIndex === index}
                            section={section}
                          />
                        );
                      })}
                    </div>
                  )
                }
              </FieldArray>
              <Modal
                isVisible={showFormModal}
                onClose={cancelEdit}
                className=""
                overlayClassName="form-modal-overlay"
              >
                <EmploymentForm
                  section={section}
                  initialValue={input.value?.[selectedEmploymentIndex]}
                  index={selectedEmploymentIndex}
                  onSubmit={onSubmit}
                  key={JSON.stringify(input.value?.[selectedEmploymentIndex])}
                  isEditing={
                    selectedEmploymentIndex !== input.value?.length - 1
                  }
                  cancelEdit={cancelEdit}
                />
              </Modal>

              <ErrorText name={section.name} />
            </div>
          </Portal>
        )}
      </div>
    </SectionContainer>
  );
};

function AddEmploymentButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <button
      className="btn btn-primary "
      onClick={() => onClick()}
      type="button"
    >
      {t("common.addEmployment")}
    </button>
  );
}
