import React from "react";

export const Spinner = () => {
  return (
    <div className="text-center d-flex h-100 justify-content-center align-items-center">
      <div className="spinner-border" role="status"></div>
      <span className="px-4">Loading...</span>
    </div>
  );
};
