import React, { useState, useEffect } from "react";

import { IntIcon } from "components/Shared/Icon";

const PerformanceLayout = ({ title, children }) => {
  const [requiredIcon, setRequiredIcon] = useState("ArmIconAlt");
  const [requiredClassName, setRequiredClassName] = useState("arm-icon");
  const watchOut = title === "Watch Out";
  const mostApplicant = title === "Most Applicant";
  const [layoutColorClassName, setLayoutColorClasName] = useState("");
  const [bulletPointColorClassName, setBulletPointColorClasName] =
    useState("listBulletPoint");

  useEffect(() => {
    if (watchOut) {
      setRequiredIcon("starIconAlt");
      setRequiredClassName("star-icon");
      setLayoutColorClasName("performance-layout-watchout");
      setBulletPointColorClasName("listBulletPointwatchout");
    } else if (mostApplicant) {
      setRequiredIcon("usersIcon");
      setRequiredClassName("users-icon");
    }
  }, [title]);

  return (
    <div
      className={`p-4 performance-layout position-relative ${layoutColorClassName}`}
    >
      <div className="d-flex gap-3 px-4 position-absolute title-icon">
        <div>
          <IntIcon
            name={requiredIcon}
            className={`icon ${requiredClassName}`}
          />
        </div>
        <div className="title">{title}</div>
      </div>
      {React.cloneElement(children, {
        layoutColor: bulletPointColorClassName,
      })}
    </div>
  );
};

export default PerformanceLayout;
