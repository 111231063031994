import AuthenticatedApi from "api/AuthenticatedApi";

import { WithId } from "utils/helpers";

const URL = {
  COURSES: "/lms/courses",
  CATEGORIES: "lms/categories",
  SINGLE_CATEGORY: "/lms/categories",
  SINGLE_COURSE: "/lms/courses",

  CANDIDATES: "/candidates",
  APPLICANT_LMS_COURSES: "applicants/lms/explore_courses",
  COURSE_LINK: "/applicants/lms/courses/:id/go",
  ENROLLED_COURSE: "/applicants/lms/courses/enrolled",
};

class CoursesApi {
  static getCourse() {
    return AuthenticatedApi.get(URL.APPLICANT_LMS_COURSES);
  }

  static getExplore() {
    return AuthenticatedApi.get(URL.APPLICANT_LMS_COURSES);
  }

  static getEnrolled(user_id) {
    return AuthenticatedApi.get(URL.ENROLLED_COURSE);
  }

  static enrollToCourse(user_id, course_id) {
    const ENROLL_TO = `${URL.CANDIDATES}/${user_id}/enroll_to_course?course_id=${course_id}`;

    return AuthenticatedApi.get(ENROLL_TO);
  }

  static getSingleCourse(course_id) {
    const FETCH_SINGLE_COURSE = `${URL.SINGLE_COURSE}/${course_id}`;

    return AuthenticatedApi.get(FETCH_SINGLE_COURSE);
  }

  static getCourses() {
    const FETCH_COURSE = URL.COURSES;

    return AuthenticatedApi.get(FETCH_COURSE);
  }

  static getCategories() {
    const FETCH_CATEGORIES = URL.CATEGORIES;

    return AuthenticatedApi.get(FETCH_CATEGORIES);
  }

  static getSingleCategory(category_id) {
    const FETCH_SINGLE_CATEGORY = `${URL.SINGLE_CATEGORY}/${category_id}`;

    return AuthenticatedApi.get(FETCH_SINGLE_CATEGORY);
  }

  static getGotoCourseLink(id) {
    return AuthenticatedApi.get(WithId(URL.COURSE_LINK, id));
  }
}

export default CoursesApi;
