import React from "react";

const EyeOnIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "24"}`}
        height={`${height ? height : "24"}`}
        viewBox="0 0 25 25"
        className={classNames.join(" ")}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_28)">
          <path
            d="M1.75 12.25C1.75 12.25 5.75 4.25 12.75 4.25C19.75 4.25 23.75 12.25 23.75 12.25C23.75 12.25 19.75 20.25 12.75 20.25C5.75 20.25 1.75 12.25 1.75 12.25Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.75 15.25C14.4069 15.25 15.75 13.9069 15.75 12.25C15.75 10.5931 14.4069 9.25 12.75 9.25C11.0931 9.25 9.75 10.5931 9.75 12.25C9.75 13.9069 11.0931 15.25 12.75 15.25Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_28">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.75 0.25)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default EyeOnIcon;
