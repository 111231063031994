import arrayMutators from "final-form-arrays";
import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  createContext,
} from "react";
import { Form, useForm } from "react-final-form";
import { useTranslation } from "react-i18next";
import { createForm } from "final-form";

import { BackIcon, NextIcon } from "assets/icons/icons";
import { WORKFLOW_PAGE_TYPES } from "constants/constant";
import { onboardingContext } from "pages/Onboarding/OnBoarding";

import { pick } from "./utils";

const WizardContext = createContext();

export const useWizardContext = () => useContext(WizardContext);

export const Wizard = ({
  appState,
  initialValues,
  initialPage,
  children,
  onSubmit,
  onNextPage,
}) => {
  const { t } = useTranslation();
  const { workflow, testMode } = useContext(onboardingContext);
  const [loading, setLoading] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);

  const [page, setPage] = useState(initialPage);
  const [values, setValues] = useState(initialValues || {});

  useEffect(() => {
    setPage(initialPage);
  }, [initialPage]);

  function next(formData, isLastPage) {
    const pageData = workflow[page];
    const sectionNames = pageData.components
      .filter(
        (i) =>
          i.type === WORKFLOW_PAGE_TYPES.SECTION ||
          i.type === WORKFLOW_PAGE_TYPES.DEMOGRAPHY
      )
      .map((i) => i.name);

    //check if values are same as initial values and dont send request if same
    //also dont send request if testMode is true
    if (
      JSON.stringify(pick(sectionNames, formData)) ===
        JSON.stringify(pick(sectionNames, values)) ||
      testMode
    ) {
      setPage(Math.min(page + 1, React.Children.count(children) - 1));
      if (isLastPage) {
        onSubmit(formData);
      }

      return;
    }

    setLoading(true);

    return onNextPage(formData, page)
      .then((res) => {
        setPage(Math.min(page + 1, React.Children.count(children) - 1));
        setValues((v) => ({ ...v, ...formData }));
        setLoading(false);
        if (isLastPage) {
          onSubmit(formData);
        }
      })
      .catch((e) => {
        console.error(e);
        alert("Failed to submit data! Please try again.");
        setLoading(false);

        return {
          [e.name]: e.response?.data?.errors,
        };
      });
  }

  function previous() {
    setPage(Math.max(page - 1, 0));
  }

  const validate = (values) => {
    const activePage = React.Children.toArray(children)[page];

    return activePage.props.validate ? activePage.props.validate(values) : {};
  };

  const handleSubmit = (values) => {
    const isLastPage = page === React.Children.count(children) - 1;

    return next(values, isLastPage);
  };

  useEffect(() => {
    const assesmentPages = React.Children.toArray(children).filter(
      (page) =>
        page.props?.pageData?.subType === WORKFLOW_PAGE_TYPES.ASSESSMENT_PAGE
    );

    if (assesmentPages.length > 0) {
      const container = document.createElement("div");

      container.id = "assessment-container";
      document.body.appendChild(container);

      assesmentPages.forEach((page) => {
        let props = page.props?.pageData;

        if (!props) return;

        const iframe = document.createElement("iframe");

        iframe.id = "iframe" + props.b_id;
        iframe.src = props.assessment_url;
        iframe.title = "Assessment";
        container.appendChild(iframe);
      });
    }
  }, [children]);

  const activePage = React.Children.toArray(children)[page];
  const isLastPage = page === React.Children.count(children) - 1;
  const isAssessmentPage =
    activePage.props?.pageData?.subType === WORKFLOW_PAGE_TYPES.ASSESSMENT_PAGE;

  useEffect(() => {
    if (isAssessmentPage) {
      const currentPageStateDetails = Object.values(appState).find(
        (i) => i?.id === activePage.props?.pageData?.b_id
      );

      setIsButtonVisible(currentPageStateDetails?.completed);
    } else {
      setIsButtonVisible(true);
    }
  }, [activePage.props?.pageData?.b_id, isAssessmentPage, appState]);

  const customForm = useMemo(() => {
    const form = createForm({
      onSubmit: handleSubmit,
      mutators: { ...arrayMutators },
      initialValues: values,
    });

    if (testMode) {
      form.pauseValidation();
      form.resumeValidation = () => {};
    }

    return form;
  }, []);

  return (
    <WizardContext.Provider value={{ setIsButtonVisible }}>
      <Form
        initialValues={values}
        mutators={{
          // potentially other mutators could be merged here
          ...arrayMutators,
        }}
        validate={validate}
        onSubmit={handleSubmit}
        form={customForm}
      >
        {({ handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div
                className={isAssessmentPage ? "" : "container-fluid max-w-1970"}
              >
                <section>
                  {activePage}
                  {!isButtonVisible ? null : (
                    <div className="d-flex align-items-center">
                      {page !== 0 && (
                        <button
                          onClick={previous}
                          type="button"
                          className="btn bg-transparent border-0 back ps-0"
                        >
                          <BackIcon className="back-icon me-3" />
                          <span className="ms-3">{t("attributes.back")}</span>
                        </button>
                      )}
                      <button
                        disabled={loading}
                        type="submit"
                        className="btn ms-auto bg-transparent border-0 next pe-0"
                      >
                        <span className="me-3">
                          {isLastPage
                            ? t("attributes.submit")
                            : t("attributes.next")}
                        </span>
                        {loading ? (
                          <span className="position-relative">
                            <NextIcon className="next-icon" />
                            <span className=" spinner-border loading-button"></span>
                          </span>
                        ) : (
                          <NextIcon className="next-icon" />
                        )}
                      </button>
                    </div>
                  )}
                </section>
              </div>
            </form>
          );
        }}
      </Form>
    </WizardContext.Provider>
  );
};
