import React, { useEffect, useState } from "react";

import camera from "lib/intelliante-ui/camera/camera";
import { CAMERA_SHOT } from "constants/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancelIcon } from "constants/icons/icon";

const CandidateIdentification = ({ data, language, color }) => {
  const [startShot, setStartShot] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [imagesPlaceHolder, setImagesPlaceHolder] = useState([]);

  const cameraPermission = async () => {
    await camera?.cameraView("camera-shot", "no-cam-image");
    setStartShot(true);
  };

  const cameraShot = async () => {
    if (imageUrls?.length == 5) return;
    await camera.takePhoto();
    const url = camera?.imageUrl;

    setImageUrls((prev) => {
      return [...prev, url];
    });

    const p = [...imagesPlaceHolder];

    p.shift();
    setImagesPlaceHolder(p);
  };

  const imageList = () => {
    let placeholder = [];

    for (let i = 0; i < 5; i++) {
      placeholder.push(CAMERA_SHOT.SOURCE);
    }
    setImagesPlaceHolder(placeholder);
  };

  useEffect(() => {
    imageList();
    cameraPermission();
  }, []);

  useEffect(() => {
    if (startShot) {
      camera.cameraShotTimeInterval(5, cameraShot);
    }
  }, [startShot]);

  useEffect(() => {
    if (imageUrls?.length <= data?.imageUrlLimit) {
      camera.stopCameraShotInteval();
    }
  }, [imageUrls]);

  const handleRemoveImage = (index) => {
    setImageUrls((prev) => {
      prev.splice(index, 1);

      return prev;
    });

    const tempPhoto = [...imagesPlaceHolder];

    tempPhoto.push(CAMERA_SHOT.SOURCE);
    setImagesPlaceHolder(tempPhoto);
  };

  return (
    <div className="candidate-identification">
      <p>{data?.title?.locales?.[language]}</p>
      <div className="mb-4">
        <video
          id="camera-shot"
          className="camera-view"
          autoPlay
          playsInline
          controls={false}
        ></video>
        <span id="no-cam-image" className={"no-cam-image icon"} />
      </div>
      <div className="d-flex justify-content-between">
        <p>{data?.imageListTitle?.locales?.[language]}</p>
        <p>
          <span style={{ color: color?.primaryColor }}>
            {imageUrls?.length}
          </span>
          /5
        </p>
      </div>
      <div className="d-flex justify-content-center justify-content-md-start flex-wrap">
        {imageUrls?.map((item, index) => (
          <div className="position-relative">
            <img
              src={item}
              key={index}
              className="camera-shot"
              alt="camera icon"
            />
            <span
              role="button"
              onKeyDown={() => handleRemoveImage(index)}
              style={data?.close.style}
              className="rounded-circle position-absolute close d-flex justify-content-center align-items-center"
              tabIndex={0}
              onClick={() => handleRemoveImage(index)}
            >
              <FontAwesomeIcon icon={faCancelIcon} size="lg" />
            </span>
          </div>
        ))}
        {imagesPlaceHolder?.map((item, index) => (
          <img src={item} key={index} className="m-2" alt="placeholder" />
        ))}
      </div>
    </div>
  );
};

export default CandidateIdentification;
