import React, { useState, useEffect } from "react";

import { t } from "i18next";

import PerformanceLayout from "components/Report/HelperComponents/PerformanceLayout";
import PerformanceListWithoutTitle from "components/Report/HelperComponents/PerformanceListWithoutTitle";
import Subtitle from "components/Report/HelperComponents/Subtitle";
import TitleAlt from "components/Report/HelperComponents/TitleAlt";
import Result from "components/Report/HelperComponents/Result";
import { TEST_NAME } from "constants/applicantReport";
import LineBreaker from "components/Report/HelperComponents/LineBreaker";

const OverComingAdversity = ({ assessments }) => {
  const [standOutList, setStandOutList] = useState([]);
  const [watchOutList, setWatchOutList] = useState([]);
  const [suggestedStatus, setSuggestedStatus] = useState("Stand Out");

  const overcomingAdversityData = assessments.find(
    (assessment) => assessment?.name === TEST_NAME.OVERCOMING_ADVERSITY_SKILLS
  );

  const scoreBetween0and33 = {
    stand_out: [
      t("applicantReport.overcomeAdversityScale.wantToperformWell"),
      t("applicantReport.overcomeAdversityScale.seekFeedbackAnd"),
      t("applicantReport.overcomeAdversityScale.spendTimeThinking"),
    ],
    watch_out: [
      t("applicantReport.overcomeAdversityScale.avoidDwellingOn"),
      t("applicantReport.overcomeAdversityScale.recognizeWhatCan"),
      t("applicantReport.overcomeAdversityScale.strengthenTheSocial"),
    ],
  };

  const scoreBetween33and65 = {
    stand_out: [
      t("applicantReport.overcomeAdversityScale.bePositiveAnd"),
      t("applicantReport.overcomeAdversityScale.distinguishBetweenIssues"),
      t("applicantReport.overcomeAdversityScale.haveARelatively"),
    ],
    watch_out: [
      t("applicantReport.overcomeAdversityScale.dwellOnNegative"),
      t("applicantReport.overcomeAdversityScale.imprveTheAbility"),
      t("applicantReport.overcomeAdversityScale.expandTheirSocial"),
    ],
  };

  const scoreBetween65and100 = {
    stand_out: [
      t("applicantReport.overcomeAdversityScale.haveaPositive"),
      t("applicantReport.overcomeAdversityScale.identifyIssuesThat"),
      t("applicantReport.overcomeAdversityScale.careForOthers"),
    ],
    watch_out: [
      t("applicantReport.overcomeAdversityScale.neverStopBuilding"),
      t("applicantReport.overcomeAdversityScale.takeCareOf"),
      t("applicantReport.overcomeAdversityScale.makeEveryDay"),
    ],
  };

  useEffect(() => {
    const isStatusMostApplicant =
      overcomingAdversityData?.score?.suggested_status === "most_applicants";
    const isStatusWatchOut =
      overcomingAdversityData?.score?.suggested_status === "watch_out";

    if (isStatusMostApplicant) {
      setSuggestedStatus("Most Applicants");
    } else if (isStatusWatchOut) {
      setSuggestedStatus("Watch Out");
    }
  }, [overcomingAdversityData]);

  useEffect(() => {
    if (overcomingAdversityData?.score?.overall_percentile * 100 < 33.4) {
      setStandOutList(scoreBetween0and33.stand_out);
      setWatchOutList(scoreBetween0and33.watch_out);
    } else if (
      overcomingAdversityData?.score?.overall_percentile * 100 <
      65.4
    ) {
      setStandOutList(scoreBetween33and65.stand_out);
      setWatchOutList(scoreBetween33and65.watch_out);
    } else {
      setStandOutList(scoreBetween65and100.stand_out);
      setWatchOutList(scoreBetween65and100.watch_out);
    }
  }, [overcomingAdversityData]);

  if (overcomingAdversityData?.status === "incomplete") {
    return null;
  }

  return (
    <>
      <LineBreaker />
      <TitleAlt>{t("applicantReport.overcomingAdversity")}</TitleAlt>
      <Subtitle>{t("applicantReport.measuresAperson")}</Subtitle>

      <Result suggestedStatus={suggestedStatus}>{suggestedStatus}</Result>

      <div className="d-flex gap-4 mt-4">
        <PerformanceLayout title={t("applicantReport.standOut")}>
          <PerformanceListWithoutTitle listData={standOutList} />
        </PerformanceLayout>
        <PerformanceLayout
          title={t("applicantReport.watchOut")}
          listData={watchOutList}
        >
          <PerformanceListWithoutTitle listData={watchOutList} />
        </PerformanceLayout>
      </div>
    </>
  );
};

export default OverComingAdversity;
