import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";

import { DynamicFormElement } from "components/Onboarding/DynamicFormElement";
import { sectionContext } from "components/Onboarding/Section";

export const EducationForm = ({
  section,
  initialValue,
  index,
  isEditing,
  cancelEdit,
  onSubmit: onSubmitProp,
  values,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue || { currently_enrolled: true });
  }, [initialValue]);

  function onSubmit(values) {
    if (values[section.name]["currently_enrolled"]) {
      values[section.name]["end_month"] = null;
      values[section.name]["end_year"] = null;
      delete values[section.name]["end_month"];
      delete values[section.name]["end_year"];
    }
    onSubmitProp(values, index);
  }

  const title = isEditing
    ? t("common.editEducation")
    : t("common.addEducation");

  const buttonText = isEditing ? t("common.edit") : t("common.add");

  return (
    <sectionContext.Provider
      value={{
        name: section.name,
      }}
    >
      <div>
        <h5 className="fw-medium mb-4 mt-2">{title}</h5>

        <Form onSubmit={onSubmit} initialValues={{ [section.name]: value }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {section.components.map((component) => (
                <DynamicFormElement component={component} key={component.id} />
              ))}
              <div className="text-end">
                <button
                  className="btn btn-outline-secondary mt-2"
                  onClick={() => cancelEdit(false)}
                >
                  {t("common.cancel")}
                </button>
                <button className="btn btn-secondary mt-2  ms-2" type="submit">
                  {buttonText}
                </button>
              </div>
            </form>
          )}
        </Form>
      </div>
    </sectionContext.Provider>
  );
};
