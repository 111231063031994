import React, { useState, useEffect, useContext } from "react";

import RenderEnrolled from "components/Dashboard/RenderEnrolled";

import CoursesApi from "api/CoursesApi/CoursesApi";

import { UserContext } from "contextAPI/UserContext";

const ExploreList = () => {
  const { candidateInfo } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [explore, setExplore] = useState([]);
  const [course, setCourse] = useState([]);

  const browseCategory = [];

  const categoriesList = async () => {
    const response = await CoursesApi.getExplore(candidateInfo?.id);
    const courseResponse = await CoursesApi.getCourse(candidateInfo?.id);

    if (response || courseResponse) {
      setLoading(false);
      setExplore(response.data);
      setCourse(courseResponse?.data);
    }
  };

  useEffect(() => {
    categoriesList();
  }, [candidateInfo?.id]);

  for (let i = 0; i <= explore?.length; i++) {
    browseCategory.push({
      id: explore?.[i]?.category?.id,
      name: explore?.[i]?.category?.name,
      parent_category_id: explore?.[i]?.category?.parent_category_id,
      price: explore?.[i]?.category?.price,
    });
  }

  const filteredCategory = browseCategory.filter(
    (el, idx) => browseCategory.findIndex((obj) => obj.id === el.id) === idx
  );

  return (
    <div className="accordion" id="courses-accordion">
      <RenderEnrolled
        filteredCategory={filteredCategory}
        enrolled={explore}
        loading={loading}
        course={course}
        label="browse"
      />
    </div>
  );
};

export default ExploreList;
