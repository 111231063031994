import React from "react";

import { t } from "i18next";

import MccsLayout from "components/Report/HelperComponents/MCCSLayout";
import { TEST_NAME } from "constants/applicantReport";

const MCCSVoiceTechnicalSupport = ({ assessments }) => {
  const title = t("applicantReport.mccsVoiceTechnicalSupport");
  const subtitle = t("applicantReport.measuresAperson");

  const MCCSVoiceTechnicalSupportData = assessments.find(
    (assessment) => assessment.name === TEST_NAME.MCCS_VOICE_TECHNICAL_SUPPORT
  );

  return (
    <MccsLayout
      data={MCCSVoiceTechnicalSupportData}
      title={title}
      subtitle={subtitle}
    />
  );
};

export default MCCSVoiceTechnicalSupport;
