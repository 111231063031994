import React from "react";

import { t } from "i18next";

import MccsLayout from "components/Report/HelperComponents/MCCSLayout";
import { TEST_NAME } from "constants/applicantReport";

const MCCSVoiceSales = ({ assessments }) => {
  const title = t("applicantReport.mccsVoiceSales");
  const subtitle = t("applicantReport.measuresAperson");
  const MCCSVoiceSalesData = assessments.find(
    (assessment) => assessment.name === TEST_NAME.MCCS_VOICE_SALES
  );

  return (
    <MccsLayout data={MCCSVoiceSalesData} title={title} subtitle={subtitle} />
  );
};

export default MCCSVoiceSales;
