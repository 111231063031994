import React from "react";

const AssessmentIcon = ({ className, width, height, color }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "32"}`}
        height={`${height ? height : "31"}`}
        viewBox="0 0 32 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={classNames.join(" ")}
      >
        <path
          d="M27.1017 23.2704V7.94403C27.1017 6.5123 25.979 5.38965 24.5473 5.38965C23.1156 5.38965 21.9929 6.5123 21.9929 7.94403V23.2704L24.5473 25.8247L27.1017 23.2704Z"
          stroke={`${color}`}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.9929 10.4976H27.1017"
          stroke={`${color}`}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24.5474 25.8245H7.94391C7.26645 25.8245 6.61673 25.5554 6.13769 25.0763C5.65865 24.5973 5.38953 23.9476 5.38953 23.2701C5.38953 22.5926 5.65865 21.9429 6.13769 21.4639C6.61673 20.9848 7.26645 20.7157 7.94391 20.7157H13.0527C13.7302 20.7157 14.3799 20.4466 14.8589 19.9675C15.3379 19.4885 15.6071 18.8388 15.6071 18.1613C15.6071 17.4839 15.3379 16.8341 14.8589 16.3551C14.3799 15.8761 13.7302 15.6069 13.0527 15.6069H9.22111"
          stroke={`${color}`}
          strokeWidth="1"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default AssessmentIcon;
