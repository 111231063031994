import React from "react";

const ListeningIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "72"}`}
        height={`${height ? height : "72"}`}
        className={classNames.join(" ")}
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M18 29.9991C17.9974 26.7617 18.7434 23.5676 20.1796 20.6662C21.6158 17.7649 23.7033 15.2348 26.2791 13.2738C28.8549 11.3127 31.8492 9.97376 35.0281 9.36145C38.2071 8.74915 41.4845 8.88011 44.6045 9.74409C47.7244 10.6081 50.6023 12.1817 53.0135 14.342C55.4246 16.5023 57.3036 19.1908 58.5037 22.1975C59.7039 25.2042 60.1926 28.4477 59.9317 31.6745C59.6709 34.9014 58.6675 38.0242 57 40.7991C55.2944 43.0732 53.2742 45.0934 51 46.7991C48.3412 49.3034 46.2891 52.3816 45 55.7991C44.0691 57.5583 42.7606 59.09 41.1684 60.2843C39.5761 61.4787 37.7395 62.3062 35.79 62.7075C33.8405 63.1089 31.8263 63.0742 29.8918 62.606C27.9573 62.1377 26.1502 61.2475 24.6 59.9991"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.0002 30.0002C29.9821 28.4945 30.3422 27.0084 31.0473 25.678C31.7525 24.3475 32.7802 23.2152 34.0363 22.3848C35.2924 21.5544 36.7368 21.0525 38.2371 20.9249C39.7375 20.7974 41.2459 21.0483 42.6242 21.6547C44.0024 22.2612 45.2065 23.2037 46.1261 24.3961C47.0457 25.5884 47.6515 26.9924 47.8879 28.4795C48.1243 29.9666 47.9838 31.4893 47.4793 32.908C46.9748 34.3268 46.1224 35.5962 45.0002 36.6002"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ListeningIcon;
