import React from "react";

const DesktopPattern = ({ className }) => {
  return (
    <svg
      width="393"
      height="355"
      className={className}
      viewBox="0 0 393 355"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="422.5"
        cy="424.5"
        r="342"
        strokeOpacity="0.2"
        strokeWidth="3"
      />
      <circle cx="423.5" cy="423.5" r="423" strokeOpacity="0.1" />
      <circle
        cx="423.5"
        cy="423.5"
        r="264"
        strokeOpacity="0.3"
        strokeWidth="5"
      />
    </svg>
  );
};

export default DesktopPattern;
