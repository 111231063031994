import React, { useContext, useState, useEffect } from "react";

import "bootstrap/js/dist/tab";

import { CircleCloseIcon } from "assets/icons/icons";

import { DashboardContext } from "contextAPI/dashboardContext";
import { useWindowDimensions } from "utils/useWindowDimension";

import Navigation from "layout/DashboardLayout/Navigation";

const Sidebar = () => {
  const { sidebarToggle, mobileToggle, setMobileToggle } =
    useContext(DashboardContext);

  const [checkSidebar, setCheckSidebar] = useState(sidebarToggle);

  const { width } = useWindowDimensions();

  const onNavSelected = () => {
    setMobileToggle(false);
  };

  useEffect(() => {
    if (sidebarToggle) {
      setTimeout(() => {
        setCheckSidebar(true);
      }, 300);
    } else {
      setTimeout(() => {
        setCheckSidebar(false);
      }, 300);
    }
  }, [sidebarToggle]);

  const checkToggleAction = () => {
    if (sidebarToggle || checkSidebar || (width <= 991 && !mobileToggle)) {
      return "d-none";
    }

    return "text-muted";
  };

  if (!sidebarToggle) {
    return null;
  }

  return (
    <div className={`dashboard-sidebar position-fixed z-index-3 px-4 bg-dark`}>
      <Navigation />
    </div>
  );
};

export default Sidebar;
