import React from "react";

const CircleCloseIcon = ({ width, height, className, color }) => {
  return (
    <>
      <svg
        width={width ? width : "25"}
        height={height ? height : "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M12.0815 22C17.6418 22 22.1493 17.5228 22.1493 12C22.1493 6.47715 17.6418 2 12.0815 2C6.52118 2 2.01367 6.47715 2.01367 12C2.01367 17.5228 6.52118 22 12.0815 22Z"
          stroke={color ? color : "#778583"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.1012 9L9.06055 15"
          stroke={color ? color : "#778583"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.06055 9L15.1012 15"
          stroke={color ? color : "#778583"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default CircleCloseIcon;
