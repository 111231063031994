const LOGO_SIZE = "70";

const lOGO_LIST_HEIGHT = "40";
const lOGO_CARD_HEIGHT = "20";
const lOGO_LIST_WIDTH = "40";

const GENDER = {
  male: "male",
  female: "female",
  non_binary: "non_binary",
  not_any: "undisclosed",
};

const RACE = {
  hispanic: "hispanic_or_latino",
  asia: "asian",
  native_american: "native_american_or_alaska_native",
  native_hawaiian: "native_hawaiin_or_pacific_islander",
  african_american: "black_or_african_american",
  white: "white",
  two_or_more_races: "two_or_more_races",
  not_any: "undisclosed",
};

const DISABILITIES_lIST = [
  "Blindness",
  "Autism",
  "Bipolar Disorder",
  "Post Traumatic Stress Disorder",
  "Deafness",
  "Cerebral Palsy",
  "Major Depression",
  "Obsessive Compulsive Disorder",
  "Cancer",
  "HIV/AIDS",
  "Multiple Sclerosis",
  "Impairments Requiring a Wheelchair",
  "Diabetes",
  "Schizophrenia",
  "Missing Limbs",
  "Intellectual Disability",
  "Epilepsy",
  "Muscular Dystrophy",
  "Partially Missing Limbs",
];

const DISABLE_OPION_VALUE = {
  first: "yes",
  second: "no",
  third: "undisclosed",
};

const EMPLOYMENT_DETAILS = {
  currently_working: "I am currently working in this role.",
  no_experience: "No work experience",
  employment_history: "Employment History",
  end_year: "endYearEmployment",
  end_month: "endMonthEmployment",
  start_year: "startYearEmployment",
  start_month: "startMonthEmployment",
  current_employer: "current_employer",
  current_enrolled: "currently_enrolled",
};

export const LINK = {
  ROUTE: "https://intelliante.talentlms.com/learner/course/id",
  EXPLORE: "/explore",
};

export const educationHeader = [
  "School Name / Year",
  "Type of school",
  "Degree / Certification",
  "Action",
];

export const BUTTON = {
  NEXT: "next",
  BACK: "skip",
};

export const SECTION_TYPE = {
  EDUCATION_LIST: "education-list",
  EMPLOYMENT_LIST: "employment-list",
};

export const EDUCATION = {
  PRESENT: "Present",
  YES: "yes",
  NO: "no",
  LEVEL: "level",
  SCHOOL_NAME: "school_name",
  START_MONTH_EDUCATION: "start_month",
  START_YEAR_EDUCATION: "start_year",
  END_MONTH_EDUCATION: "end_month",
  END_YEAR_EDUCATION: "end_year",
  DEGREE: "degree",
  CURRENTLY_ENROLLED: "currently_enrolled",
};

export const EMPLOYMENT = {
  DESCRIPTION: "description",
  CURRENT_EMPLOYER: "current_employer",
  LOCATION: "location",
  EMPLOYMENT_TYPE: "employment_type",
  JOB_TITLE: "job_title",
  COMPANY_TITLE: "company_title",
  NO_WORK_EXPERIENCE: "no_work_experience",
  END_MONTH: "end_month",
  END_YEAR: "end_year",
};

export const SUB_FORM_FIELDS = [
  EDUCATION.PRESENT,
  EDUCATION.YES,
  EDUCATION.NO,
  EDUCATION.LEVEL,
  EDUCATION.SCHOOL_NAME,
  EDUCATION.START_MONTH_EDUCATION,
  EDUCATION.START_YEAR_EDUCATION,
  EDUCATION.END_MONTH_EDUCATION,
  EDUCATION.END_YEAR_EDUCATION,
  EDUCATION.DEGREE,
  EDUCATION.CURRENTLY_ENROLLED,
  EMPLOYMENT.DESCRIPTION,
  EMPLOYMENT.CURRENT_EMPLOYER,
  EMPLOYMENT.LOCATION,
  EMPLOYMENT.EMPLOYMENT_TYPE,
  EMPLOYMENT.JOB_TITLE,
  EMPLOYMENT.COMPANY_TITLE,
  EMPLOYMENT.NO_WORK_EXPERIENCE,
];

export const WORKFLOW_PAGE_TYPES = {
  ASSESSMENT_PAGE: "assessment-page",
  FORM_PAGE: "form-page",
  TRANSITION_PAGE: "transition_page",
  DEMOGRAPHY: "demography",
  SECTION: "section",
};

export const PAYLOAD_TYPES = {
  EDUCATION: "user.applicant_educations_attributes",
  EMPLOYMENT: "user.applicant_employments_attributes",
  VOLUNTARY: "VoluntarySelfIdentification",
  DISABILITY: "DisabilityDisclosureForm",
};

export const ELEMENT_TYPES = {
  FULL_NAME: "FullNameTemplate",
  EDUCATION: "EducationListTemplate",
  EMPLOYMENT: "EmploymentListTemplate",
  CONTACT: "ContactDetailTemplate",
  ADDRESS: "AddressDetailTemplate",
};

export {
  LOGO_SIZE,
  lOGO_LIST_HEIGHT,
  lOGO_CARD_HEIGHT,
  lOGO_LIST_WIDTH,
  GENDER,
  RACE,
  EMPLOYMENT_DETAILS,
  DISABILITIES_lIST,
  DISABLE_OPION_VALUE,
};

export const END_TEST = {
  DASHBOARD: "Dashboard",
  NEXT: "Next",
};
