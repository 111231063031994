import React, { useContext, useState } from "react";

import { OnBoardingContext } from "contextAPI/onBoardingContext";
import { educationHeader } from "constants/constant";

import TableRow from "./TableRow";

const Table = () => {
  const [deleteEducation, setDeleteEducation] = useState(false);

  const [deleteId, setDeleteId] = useState(null);

  const {
    educationData,
    setEducationData,
    editEducationId,
    setEditEducationId,
    setEditEducationData,
    setShowFormClass,
  } = useContext(OnBoardingContext);

  const displayClassName = educationData?.length ? "" : "d-none";
  const overflowClassName = educationData?.length > 3 ? "overflow-auto" : "";

  return (
    <ul className="responsive-table p-0">
      <li
        className={`table-header bg-dark py-0 d-flex justify-content-between align-items-center ${displayClassName}`}
      >
        <div className="col-4 header-text">{educationHeader?.[0]}</div>
        <div className="col-3 header-text">{educationHeader?.[1]}</div>
        <div className="col-4 header-text">{educationHeader?.[2]}</div>
        <div className="col-1  d-flex justify-content-end header-text">
          {educationHeader?.[3]}
        </div>
      </li>
      <li className={`edu-list list-unstyled p-0 ${overflowClassName}`}>
        <ul className="p-0">
          {educationData?.map((education) => (
            <TableRow
              key={education.id}
              education={education}
              idx={education.id}
              deleteEducation={deleteEducation}
              educationData={educationData}
              setEducationData={setEducationData}
              setDeleteEducation={setDeleteEducation}
              deleteId={deleteId}
              setDeleteId={setDeleteId}
              editId={editEducationId}
              setEditId={setEditEducationId}
              setEditData={setEditEducationData}
              setShowFormClass={setShowFormClass}
            />
          ))}
        </ul>
      </li>
    </ul>
  );
};

export default Table;
