import { LOGO } from "constants/images";

export const brandingJson = {
  company: {
    name: "intelliante",
    address: "USA",
    website: "www.intelliante.com",
    logo: {
      url: {
        light: LOGO.LIGHT.SOURCE,
        dark: LOGO.DARK.SOURCE,
      },
      className: "",
      customClass: "",
      style: {},
    },
  },
  languages: [
    {
      id: 1,
      code: "en",
      label: {
        locales: {
          en: "english",
          es: "spanish english",
        },
      },
    },
    {
      id: 2,
      code: "es",
      label: {
        locales: {
          en: "spanish",
          es: "spanish",
        },
      },
    },
  ],
  footer: [
    {
      id: 1,
      icon: {
        url: "copyright",
        className: "color-icon",
        customClass: "",
        style: {},
      },
      label: {
        locales: {
          en: "Copyright @ Intelliante CE",
          es: "Copyright @ Intelliante CE",
        },
      },
      date: "",
    },
    {
      id: 2,
      icon: {
        url: "version",
        className: "color-icon",
        customClass: "",
        style: {},
      },
      label: {
        locales: {
          en: "App Version",
          es: "App Version",
        },
      },
      date: "1.2.1",
    },
    {
      id: 3,
      icon: {
        url: "calendar",
        className: "",
        customClass: "",
        style: {},
      },
      label: {
        locales: {
          en: "Release date 2014",
          es: "Spanish Release date 2014",
        },
      },
      date: "02/03/2021",
    },
    {
      id: 4,
      icon: {
        url: "shieldExclamation", 
        className: "color-icon",
        customClass: "",
        style: {},
      },
      label: {
        locales: {
          en: "Privacy policy and terms of use",
          es: "Privacy policy and terms of use",
        },
      },
      date: "",
    },
  ],
  color: {
    primaryColor: "#1db27e",
    secondaryColor: "#eef4f3",
    warningColor: "#d9bc3f",
    dangerColor: " #EB5757",
    dark: {
      background: "#222b29",
      label: "#778583",
      secondaryLabel: "#b8c1bf",
      gray: "#778583",
      primaryBackgroundColor: "#45504D",
    },
    light: {
      background: "#eef4f3",
      label: "#45504d",
      secondaryLabel: "#727272",
      gray: "#778583",
      primaryBackgroundColor: "#778583",
    },
  },
};
