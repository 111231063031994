import React from "react";

import { createStore, StateMachineProvider } from "little-state-machine";

import AuthProvider from "./AuthContext";
import CandidateGamesProvider from "./CandidateGamesContext";
import CandidateJobProvider from "./CandidateJobContext";
import DashBoardProvider from "./dashboardContext";
import LayoutProvider from "./LayoutContext";

import OnBoardingContextProvider from "./onBoardingContext";
import UserProvider from "./UserContext";

createStore({});

const ContextProviderContainer = ({ children }) => {
  return (
    <AuthProvider>
      <StateMachineProvider>
        <CandidateJobProvider>
          <UserProvider>
            <LayoutProvider>
              <CandidateGamesProvider>
                <DashBoardProvider>
                  <OnBoardingContextProvider>
                    {children}
                  </OnBoardingContextProvider>
                </DashBoardProvider>
              </CandidateGamesProvider>
            </LayoutProvider>
          </UserProvider>
        </CandidateJobProvider>
      </StateMachineProvider>
    </AuthProvider>
  );
};

export default ContextProviderContainer;
