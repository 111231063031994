import React from "react";
import { t } from "i18next";

import TrainingCourse from "components/ApplicantDashboard/TalentLMS/TrainingCourse";

const NoCourse = () => {
  return (
    <div className="no-course text-center mt-5 rounded overflow-hidden">
      <div className="no-course-title-container pt-5 pb-3">
        <div className="no-course-title mb-3">
          <h3>{t("message.noCourse.title")}</h3>
        </div>
        <div className="no-course-subtitle">
          {t("message.noCourse.subtitle")}
        </div>
      </div>
      <div className="divider my-3 mx-auto w-50"></div>
      <TrainingCourse showTab={false} />
    </div>
  );
};

export default NoCourse;
