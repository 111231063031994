const getStatus = (status, t) => {
  switch (status) {
    case "in_progress":
      return t("jobsFilter.progress");
    case "disabled":
      return t("jobsFilter.disable");
    case "completed":
      return t("attributes.completed");
    case "expired":
      return t("attributes.expired");
    default:
      return t("attributes.all");
  }
};

export default getStatus;
