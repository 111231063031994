import PhoneInput from "react-phone-input-2";

import ErrorText from "components/ErrorText/ErrorText";
import HelperText from "components/HelperText/HelperText";
import IconContainer from "components/IconContainer";

import "react-phone-input-2/lib/bootstrap.css";

export const TextInput = ({
  id,
  inputProps,
  icon,
  iconStyle,
  name,
  helpText,
  error,
  language,
  type,
  label: labelProp,
  required,
  phoneInput,
}) => {
  const iconClassName = icon?.enabled || phoneInput ? "icon-added" : "";

  const label = required ? `${labelProp}*` : labelProp;

  return (
    <div className={`form-floating ${phoneInput ? "phone" : ""}`}>
      {phoneInput ? (
        <PhoneInput {...inputProps} specialLabel="" />
      ) : (
        <input
          type="text"
          className="form-control w-100"
          id={id}
          placeholder={label}
          {...inputProps}
        />
      )}

      <IconContainer
        icon={icon?.url}
        className={`${icon?.className} base-position color-icon`}
        style={iconStyle}
      />
      <ErrorText error={error} className="text-danger" />
      <HelperText
        enabled={helpText?.enabled && !error.length}
        helpText={helpText}
        language={language}
        type={type}
      />
      <label
        className={`form-check-label position-absolute custom-paragraph-text ${iconClassName}`}
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};
