import { useContext } from "react";
import { Field } from "react-final-form";

import { EditorInput } from "components/Form/EditorInput";
import { VALIDATION_ON } from "utils/validation";
import { onboardingContext } from "pages/Onboarding/OnBoarding";
import { composeValidation, isRequired } from "components/Onboarding/utils";

export const Editor = ({
  name,
  label,
  icon,
  iconStyle,
  helpText,
  type,
  validations,
}) => {
  const { language } = useContext(onboardingContext);

  return (
    <Field
      name={name}
      validate={composeValidation(validations, VALIDATION_ON.CHANGE, language)}
    >
      {({ input, meta: { error, touched } }) => (
        <EditorInput
          label={label}
          inputProps={input}
          name={name}
          icon={icon}
          iconStyle={iconStyle}
          error={touched && error}
          helpText={helpText}
          type={type}
          language={language}
          required={isRequired(validations)}
        />
      )}
    </Field>
  );
};
