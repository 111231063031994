import React from "react";
import DashboardLayout from "layout/DashboardLayout/DashboardLayout";
import JobDetail from "components/Dashboard/JobDetail";

export default function JobDetailPage() {
  return (
    <DashboardLayout>
      <JobDetail />
    </DashboardLayout>
  );
}
