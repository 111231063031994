import React from "react";

const TechSupportIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "78"}`}
        height={`${height ? height : "73"}`}
        className={classNames.join(" ")}
        viewBox="0 0 78 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M58.5 12.1328H19.5C15.9101 12.1328 13 14.8489 13 18.1993V54.5981C13 57.9485 15.9101 60.6646 19.5 60.6646H58.5C62.0899 60.6646 65 57.9485 65 54.5981V18.1993C65 14.8489 62.0899 12.1328 58.5 12.1328Z"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.75 27.2988H29.25V45.4982H48.75V27.2988Z"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.25 3.0332V12.1329"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.75 3.0332V12.1329"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.25 60.6641V69.7638"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.75 60.6641V69.7638"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65 27.2988H74.75"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M65 42.4648H74.75"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.25 27.2988H13"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.25 42.4648H13"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default TechSupportIcon;
