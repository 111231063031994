import logo from "assets/images/logo.png";
import lightVersionLogo from "assets/images/centerville-light-logo.svg";
import calender from "assets/images/calender.png";
import copyRight from "assets/images/copy-right.png";
import appVersion from "assets/images/app-version.png";
import correct from "assets/images/correct.svg";
import cameraShot from "assets/images/camera-shot-background.svg";
import cameraSample from "assets/images/camera-sample.png";
import facebook from "assets/images/facebook.svg";
import loader from "assets/images/loader.svg";
import hamBurger from "assets/images/hamburger.svg";
import user from "assets/images/user.png";
import intellianteLogo from "assets/images/intelliante-logo.png";
import companyLogo from "assets/images/airbnb.png";
import leftArr from "assets/images/Left.svg";
import intellianteLogoWhite from "assets/images/logo-intelilante.svg";
import topLeft from "assets/images/top-left.svg";
import bottomRight from "assets/images/bottom-right.svg";
import mail from "assets/images/mail.svg";
import recoveryMail from "assets/images/recovery-mail.svg";
import Eye from "assets/images/eye.svg";
import EyeOn from "assets/images/eye-on.svg";
import JobLogo from "assets/images/job-logo.svg";
import SessionTimeout from "assets/images/session-timeout.svg";

const LEFT_ARROW = {
  SOURCE: leftArr,
};

const INTELLIANTEWHITE = {
  SOURCE: intellianteLogoWhite,
};

const TOP_LEFT_PATTERN = {
  SOURCE: topLeft,
};

const BOTTOM_RIGHT_PATTERN = {
  SOURCE: bottomRight,
};

const MAIL_ICON = {
  SOURCE: mail,
};

const RECOVERY_EMAIL = {
  SOURCE: recoveryMail,
};

const COVER = {
  SOURCE: Eye,
};

const UNCOVER = {
  SOURCE: EyeOn,
};

const JOB_LOGO = {
  SOURCE: JobLogo,
};

const LOGO = {
  DARK: {
    SOURCE: logo,
  },
  LIGHT: {
    SOURCE: lightVersionLogo,
  },
};

const CALENDER = {
  SOURCE: calender,
};

const COPY_RIGHT = {
  SOURCE: copyRight,
};

const APP_VERSION = {
  SOURCE: appVersion,
};

const CORRECT = {
  SOURCE: correct,
};

const CAMERA_SHOT = {
  SOURCE: cameraShot,
};

const CAMERA_SAMPLE = {
  SOURCE: cameraSample,
};

const FACEBOOK = {
  SOURCE: facebook,
};

const LOADER = {
  SOURCE: loader,
};

const HAM_BURGER = {
  SOURCE: hamBurger,
};

const USER = {
  SOURCE: user,
};

const COMPANY = {
  SOURCE: companyLogo,
};

const COMPANY_LOGO = {
  SOURCE: intellianteLogo,
};

const SESSION_TIMEOUT = {
  SOURCE: SessionTimeout,
};

export {
  LOGO,
  JOB_LOGO,
  CALENDER,
  COPY_RIGHT,
  APP_VERSION,
  CORRECT,
  CAMERA_SHOT,
  CAMERA_SAMPLE,
  FACEBOOK,
  LOADER,
  HAM_BURGER,
  USER,
  COMPANY_LOGO,
  COMPANY,
  LEFT_ARROW,
  INTELLIANTEWHITE,
  TOP_LEFT_PATTERN,
  BOTTOM_RIGHT_PATTERN,
  MAIL_ICON,
  RECOVERY_EMAIL,
  COVER,
  UNCOVER,
  SESSION_TIMEOUT,
};
