import React, { useState } from "react";
import { Route, Link, Routes, useParams } from "react-router-dom";

import WelcomePage from "./WelcomePage/WelcomePage";

const WelcomePreview = () => {
  const params = useParams();

  return (
    <>
      <header className="bg-dark bg-opacity-85 py-2">
        <div className="container">
          <span>Preview Mode</span>
        </div>
      </header>
      <WelcomePage id={params?.id} />
    </>
  );
};

export default WelcomePreview;
