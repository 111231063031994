import React from "react";

const SalesIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "88"}`}
        height={`${height ? height : "67"}`}
        className={classNames.join(" ")}
        viewBox="0 0 88 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.75 27C27.9297 27 33.75 21.1797 33.75 14C33.75 6.8203 27.9297 1 20.75 1C13.5703 1 7.75 6.8203 7.75 14C7.75 21.1797 13.5703 27 20.75 27Z"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59 27C66.1797 27 72 21.1797 72 14C72 6.8203 66.1797 1 59 1C51.8203 1 46 6.8203 46 14C46 21.1797 51.8203 27 59 27Z"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 53.5V47.6667C1 44.5725 2.42232 41.605 4.95406 39.4171C7.4858 37.2292 10.9196 36 14.5 36H28C31.5804 36 35.0142 37.2292 37.5459 39.4171C40.0777 41.605 41.5 44.5725 41.5 47.6667V53.5"
          stroke="#B8C1BF"
          strokeOpacity="0.7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M56.8333 37.667L49.5 45.0003L56.8333 52.3337"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M75.1667 37.667L82.5001 45.0003L75.1667 52.3337"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M49.5 45H82.5"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2.75"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default SalesIcon;
