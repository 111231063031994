import React, { useContext, useState, useEffect } from "react";
import { t } from "i18next";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

import { PAYLOAD_TYPES, SECTION_TYPE } from "constants/constant";

export const FormCancelButton = ({
  showButton,
  hideForm,
  addAnotherButton,
  editId,
  allData,
}) => {
  const handleOnCancel = () => {
    hideForm(false);
    addAnotherButton(true);
  };

  if (!showButton || editId || allData.length === 0) {
    return <div />;
  }

  return (
    <button
      className="btn btn-outline-secondary  mt-2  "
      onKeyDown={handleOnCancel}
      onClick={handleOnCancel}
    >
      {t("attributes.cancel")}
    </button>
  );
};

export const SubmitContainer = ({
  editEmploymentId,
  handleOnCancel,
  handleOnUpdate,
  handleAddNew,
}) => {
  if (editEmploymentId) {
    return (
      <div className="d-flex">
        <button
          className="btn px-4 border-secondary text-muted back-step mt-2 shadow-none text-uppercase"
          onKeyDown={handleOnCancel}
          onClick={handleOnCancel}
        >
          {t("attributes.cancel")}
        </button>
        <button
          className="btn ms-4 btn-secondary back-step mt-2 shadow-none text-uppercase"
          aria-hidden="true"
          onClick={handleOnUpdate}
        >
          {t("attributes.update")}
        </button>
      </div>
    );
  }

  return (
    <div className="d-flex">
      <button
        className="btn text-secondary back-step mt-2 shadow-none"
        type="button"
        onKeyDown={handleAddNew}
        onClick={(e) => handleAddNew(e)}
      >
        {t("message.employmemtDetails.addAnotherJob")}
      </button>
    </div>
  );
};

const ButtonsEmployment = ({ onSubmit, stepValues, setSubmit }) => {
  const {
    employmentFormData,
    setEmploymentData,
    employmentData,
    setAnotherJobButton,
    setShowEmploymentForm,
    editEmploymentId,
    setEditEmploymentId,
    setEditEmploymentData,
    setEditorData,
    employmentFormCancelButton,
    formValue,
    setFormValue,
  } = useContext(OnBoardingContext);

  const [isUpdate, setUpdate] = useState(false);

  const employmentFields = stepValues.filter(
    ({ sectionName }) => sectionName === SECTION_TYPE.EMPLOYMENT_LIST
  );

  const handleSave = (e) => {
    e.preventDefault();
    setSubmit(true);
  };

  const handleAddNew = () => {
    // New way to save data V2

    const formData = [];

    let formFields = document.querySelectorAll("[data-template]");

    formFields.forEach((element) => {
      const {
        name,
        value,
        dataset: { template, fieldType },
        innerHTML,
      } = element;

      if (template != PAYLOAD_TYPES.EMPLOYMENT) {
        return null;
      }

      if (fieldType == "single-dropdown") {
        formData.push({
          keyName: name,
          value: innerHTML,
          templateName: template,
        });
      } else {
        formData.push({
          keyName: name,
          value: value,
          templateName: template,
        });
      }
      element.value = "";
      setFormValue({});
    });

    let employmentFormData = {};

    formData.forEach((item) => {
      const { keyName, value } = item;

      employmentFormData[keyName] = value;
      employmentFormData["description"] = formValue["description"];
      employmentFormData["id"] = employmentData[employmentData?.length - 1]?.id
        ? employmentData[employmentData?.length - 1]?.id + 1
        : employmentData?.length + 1;
    });

    setEmploymentData((prev) => {
      return [...prev, employmentFormData];
    });
  };

  const handleOnCancel = (e) => {
    e.preventDefault();
    setEditEmploymentId(null);
    setEditEmploymentData(null);

    const itemsToRemove = Object.keys(employmentFormData);
    const formValueKeys = Object.keys(formValue);

    const newValues = formValueKeys.filter((item) => {
      return itemsToRemove.indexOf(item) !== -1;
    });

    let newForm = { ...formValue };

    newValues.map((item) => {
      delete newForm[item];
    });

    setEditorData(" ");
    setFormValue(newForm);
  };

  //education -- edit
  const handleOnUpdate = () => {
    const filteredData = employmentData?.filter(
      (e) => e?.id !== editEmploymentId
    );

    setEmploymentData(filteredData);
    setEditEmploymentData(null);
    setEditEmploymentId(null);
    handleAddNew();
  };

  useEffect(() => {
    if (!onSubmit) return;

    const formWithoutError = employmentFields.every(
      ({ error }) => error === null
    );

    if (formWithoutError) {
      if (isUpdate) {
        handleOnUpdate();
      } else {
        handleAddNew();
      }
    }
    setSubmit(false);
  }, [employmentFields, onSubmit]);

  return (
    <div className="d-flex justify-content-between mt-5">
      <FormCancelButton
        showButton={employmentFormCancelButton}
        hideForm={setShowEmploymentForm}
        addAnotherButton={setAnotherJobButton}
        editId={editEmploymentId}
        allData={employmentData}
      />
      <div className="button d-flex  d-block">
        <div className="button d-flex justify-content-end d-block">
          <SubmitContainer
            editEmploymentId={editEmploymentId}
            handleOnCancel={handleOnCancel}
            handleOnUpdate={(e) => {
              e.preventDefault();
              setUpdate(true);
              setSubmit(true);
            }}
            handleAddNew={handleSave}
          />
        </div>
      </div>
    </div>
  );
};

export default ButtonsEmployment;
