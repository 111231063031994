const SIGN_IN = {
  INDEX: "/sign_in",
};

const JOBCARD = {
  INDEX: "/job_card",
};

const SIGN_UP = {
  INDEX: "/sign_up",
};

const RESET_PASSWORD = {
  INDEX: "/forgot_password",
};

const NEW_PASSWORD = {
  INDEX: "/new_password",
};

const VERIFICATION = {
  INDEX: "/verification",
};

const ON_BOARDING = {
  INDEX: "/onboarding",
};

const WELCOME = {
  INDEX: "/welcome",
};

const HOME = {
  INDEX: "/",
  LIST: "/applicant-dashboard",
};

const DASHBOARD_ROUTE = {
  INDEX: "/",
};

const WORKFLOW_PREVIEW = {
  INDEX: "/preview/:id",
};

const WELCOME_PREVIEW = {
  INDEX: "welcome/preview/:id",
};

const GAMELIST = {
  INDEX: "/games",
};

const MYREPORT = {
  INDEX: "/report",
};

const SETTINGS = {
  INDEX: "/settings",
};

const TRAINING = {
  INDEX: "/training",
};

const JOBDETAIL = {
  INDEX: "/jobdetail",
};

const ACHIEVEMENTS = {
  INDEX: "/achievements",
};

const AUTH = {
  FORGOT_PASSWORD: "/forgot_password",
};

const EXPLORE = {
  INDEX: "/explore",
};

const JOBS = {
  INDEX: "/jobs",
};

const WORKFLOW = {
  INDEX: "/workflow",
};

export {
  SIGN_IN,
  JOBCARD,
  SIGN_UP,
  RESET_PASSWORD,
  NEW_PASSWORD,
  ON_BOARDING,
  DASHBOARD_ROUTE,
  WORKFLOW_PREVIEW,
  WELCOME_PREVIEW,
  VERIFICATION,
  GAMELIST,
  JOBDETAIL,
  SETTINGS,
  TRAINING,
  ACHIEVEMENTS,
  AUTH,
  HOME,
  EXPLORE,
  MYREPORT,
  WELCOME,
  JOBS,
  WORKFLOW,
};
