import React from "react";

const IconApplicant = (props) => {
    return (
        <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.5292 8.94463C8.59086 8.94463 7.01953 7.34279 7.01953 5.36684C7.01953 3.39089 8.59086 1.78906 10.5292 1.78906C12.4675 1.78906 14.0388 3.39089 14.0388 5.36684C14.0388 7.34279 12.4675 8.94463 10.5292 8.94463Z" stroke="#FAFEFD" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.5283 19.6789L8.77344 17.4428L10.5283 11.6289L12.2831 17.4428L10.5283 19.6789Z" stroke="#FAFEFD" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5295 11.8747C6.31087 11.8747 2.88281 15.3624 2.88281 19.6747C2.88281 19.8128 2.77088 19.9247 2.63281 19.9247C2.49474 19.9247 2.38281 19.8128 2.38281 19.6747C2.38281 15.0953 6.02571 11.3747 10.5295 11.3747C15.0333 11.3747 18.6762 15.0953 18.6762 19.6747C18.6762 19.8128 18.5643 19.9247 18.4262 19.9247C18.2881 19.9247 18.1762 19.8128 18.1762 19.6747C18.1762 15.3624 14.7481 11.8747 10.5295 11.8747ZM16.3247 7.24863C16.5039 7.05417 16.7424 6.94922 16.9862 6.94922ZM15.957 6.9098C16.2262 6.61767 16.5959 6.44922 16.9862 6.44922H24.1598C24.5501 6.44922 24.9198 6.61768 25.189 6.9098L25.189 6.90981C25.4576 7.20132 25.6053 7.59258 25.6053 7.99665V16.1056C25.6053 16.1684 25.5817 16.2289 25.5391 16.275L22.2513 19.843C22.204 19.8944 22.1373 19.9236 22.0675 19.9236H16.9862C16.5959 19.9236 16.2262 19.7551 15.957 19.463L15.957 19.463C15.6884 19.1715 15.5406 18.7803 15.5406 18.3761V7.99665C15.5406 7.59253 15.6884 7.2013 15.957 6.90982L15.957 6.9098ZM16.9862 6.94922H24.1598ZM24.1598 6.94922C24.4036 6.94922 24.6421 7.05416 24.8213 7.24864ZM24.8213 7.24864C25.0012 7.44379 25.1053 7.71252 25.1053 7.99665V16.008L21.9579 19.4236H16.9862C16.7424 19.4236 16.5039 19.3186 16.3247 19.1242C16.1448 18.929 16.0406 18.6602 16.0406 18.3761V7.99665C16.0406 7.71258 16.1448 7.44383 16.3246 7.24865" fill="#FAFEFD"/>
            <path d="M21.5078 19.5447V15.6523H25.0946" stroke="#FAFEFD" stroke-width="0.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.9621 12.5391H18.1797" stroke="#FAFEFD" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22.9621 9.94141H18.1797" stroke="#FAFEFD" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M19.3753 15.1328H18.1797" stroke="#FAFEFD" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

    );
};

export default IconApplicant;
