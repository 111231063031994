import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { FileIcon } from "constants/icons/icon";
import { JOBDETAIL } from "constants/routes";

import ChevronIcon from "assets/icons/ChevronIcon";

const JobOverview = () => {
  const { t } = useTranslation();

  return (
    <div className="box flex-grow-1 w-50 detail-card job-overview py-4 px-3 me-3 position-relative">
      <div>
        <FileIcon className="color-icon" />
      </div>
      <span className="title my-2 d-block">
        {t("message.applicantDashboard.jobOverview")}
      </span>
      <div className="mw-100  ">
        <p className="details card-brief">
          {t("message.applicantDashboard.detailOfJob")}
        </p>
      </div>

      <Link to={JOBDETAIL.INDEX} className="job-overview-link rounded py-4 px-3">
          <span>
            <FileIcon className="color-icon" />
          </span>
          <span className="title my-2 d-block">
            {t("message.applicantDashboard.jobOverview")}
          </span>
          <span className="w-100 d-flex justify-content-between align-items-center">
            <span className="details card-brief">
              {t("message.applicantDashboard.detailOfJob")}
            </span>
            <span className="text-details">
                View Job Detail  <ChevronIcon />
            </span>
          </span>
        </Link>
    </div>
  );
};

export default JobOverview;
