import React, { useContext } from "react";
import ReactTooltip from "react-tooltip";

import ActionButtons from "components/EducationList/ActionButtons";

import { EDUCATION } from "constants/constant";
import { OnBoardingContext } from "contextAPI/onBoardingContext";

const TableRow = ({
  schoolName,
  startMonthEducation,
  startYearEducation,
  endMonthEducation,
  endYearEducation,
  level,
  degree,
  certification,
  enrolled,
  idx,
  deleteEducation,
  setDeleteEducation,
  deleteId,
  setDeleteId,
  educationData,
  setEducationData,
  editId,
  setEditId,
  setEditData,
  setShowFormClass,
  setAnotherEducationButton,
  start_month_education,
  education,
}) => {
  const { setIsEducationEditing } = useContext(OnBoardingContext);

  const handleOnClickEdit = () => {
    setIsEducationEditing(true);
    const editData = educationData?.filter((e) => e?.id == idx);

    setEditData(editData);
    setEditId(idx);
    setShowFormClass(true);
    setAnotherEducationButton(false);
  };

  const handleDelete = () => {
    setDeleteId(idx);
    setDeleteEducation(true);
  };

  const isEnrolled = education?.currently_enrolled
    ? EDUCATION.PRESENT
    : `${education?.end_month} ${education?.end_year}`;

  const editClassName = idx === editId ? "edit" : "";

  const DeleteConfirmEducation = () => {
    const filterId = idx;
    const filteredData = educationData?.filter((e) => e?.id !== filterId);

    setEducationData(filteredData);
    setDeleteEducation(false);
  };

  if (!education) {
    return null;
  }

  return (
    <li
      className={`table-row bg-dark d-flex justify-content-between align-items-center ${editClassName}`}
    >
      <div className="col-4">
        <p
          className="head text-light"
          data-tip={education?.school_name}
          data-place="top"
        >
          {education?.school_name}
        </p>
        <ReactTooltip />

        <p className="sub-head pt-1">
          {education?.start_month} {education?.start_year} - {isEnrolled}
        </p>
      </div>
      <div className="col-3">
        <p className="head text-light" data-tip={level} data-place="top">
          {education?.level}
        </p>
        <ReactTooltip />
      </div>
      <div className="col-3">
        <p className="head text-light" data-tip={degree} data-place="top">
          {education?.degree}
        </p>
        <ReactTooltip />
        <p className="sub-head pt-1" data-tip={certification} data-place="top">
          {education?.certification}
        </p>
        <ReactTooltip />
      </div>

      <div className="col-2">
        <ActionButtons
          idx={idx}
          deleteEducation={deleteEducation}
          educationData={educationData}
          setEducationData={setEducationData}
          setDeleteEducation={setDeleteEducation}
          deleteId={deleteId}
          setDeleteId={setDeleteId}
          handleOnClickEdit={handleOnClickEdit}
          handleDelete={handleDelete}
          editId={editId}
          DeleteConfirmEducation={DeleteConfirmEducation}
        />
      </div>
    </li>
  );
};

export default TableRow;
