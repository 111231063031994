import React, { useEffect, useRef } from "react";

const TalentPoolBar = ({ blockName, placement, currentPlacement, name }) => {
  const talentPoolBarRef = useRef();
  const barRef = useRef();
  const tooltipRef = useRef();

  useEffect(() => {
    const position =
      placement == currentPlacement
        ? "visible"
        : placement - currentPlacement > 0 && placement - currentPlacement < 10
        ? "visible"
        : "hidden";

    if (placement == currentPlacement) {
      barRef.current.style.left = `10%`;
    } else if (position === "visible") {
      const barPosition = `${placement - currentPlacement + 50}%`;

      barRef.current.style.left = `${barPosition}%`;
      tooltipRef.current.style.left = `${placement - currentPlacement + 50}%`;
    }

    talentPoolBarRef.current.style.visibility = position;
  }, [currentPlacement, placement]);

  return (
    <div className="talent-pool-bar-container py-4 my-3" ref={talentPoolBarRef}>
      <div
        className={`position-absolute talent-pool-bar ${blockName} rounded-pill`}
        ref={barRef}
      >
        <div
          className="bar-tooltip text-nowrap position-absolute  py-1 px-3 translate-middle"
          ref={tooltipRef}
        >
          <span className="bar-name w-auto ">{name}</span>
        </div>
      </div>
    </div>
  );
};

export default TalentPoolBar;
