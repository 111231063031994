import { Link } from "react-router-dom";

import Modal from "components/Shared/Modal";
import { ThankYou } from "constants/icons/icon";
import { useTranslation } from "react-i18next";

const ThankYouModal = ({ summary, link, linkText, onClick, isVisible }) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  return (
    <Modal
      className="d-flex flex-column align-items-center thank-you-modal text-center px-5 py-4 bg-section"
      isVisible={true}
    >
      <ThankYou className="mb-3" />
      <h4 className="mb-2">{t("message.modal.thankYou")}</h4>
      <p className="summary mb-4">{summary}</p>

      <Link onClick={onClick} to={link} className="btn btn-outline-heading">
        {linkText}
      </Link>
    </Modal>
  );
};

export default ThankYouModal;
