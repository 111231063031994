import { useContext, useState, useEffect } from "react";
import { Field, useForm, useField } from "react-final-form";

import { SelectInput } from "components/Form/SelectInput";
import { useAddressQuery } from "hooks/useAddressQuery";
import { onboardingContext } from "pages/Onboarding/OnBoarding";
import { VALIDATION_ON } from "utils/validation";
import { composeValidation, isRequired } from "components/Onboarding/utils";

//Select component using React Select
export const Select = ({ label, options, name, validations }) => {
  const { language } = useContext(onboardingContext);
  const [selectOptions] = useState(
    options.map((option) => ({
      label: option.label.locales[language],
      value: option.value,
    }))
  );
  const {
    meta: { error, touched },
  } = useField(name, { subscription: { error: true, touched: true } });

  const [_, fieldName] = name.split(".");
  const { data, isLoading } = useAddressQuery(fieldName);

  const isAsync = ["country", "city", "state"].includes(fieldName);

  function getOptions() {
    if (isAsync && data) return data;
    if (selectOptions.length) return selectOptions;
    if (fieldName === "country_code") return [{ label: "Test", value: "test" }];

    return [];
  }

  return (
    <Field
      name={name}
      key={JSON.stringify(getOptions()?.[0])}
      validate={composeValidation(validations, VALIDATION_ON.CHANGE, language)}
    >
      {({ input }) => (
        <SelectField
          isLoading={isAsync && isLoading}
          label={label}
          options={getOptions()}
          inputProps={input}
          error={touched && error}
          required={isRequired(validations)}
        />
      )}
    </Field>
  );
};

const SelectField = ({
  label,
  options,
  isLoading,
  error,
  inputProps,
  required,
}) => {
  const form = useForm();

  const optionsDependency = JSON.stringify(options);

  useEffect(() => {
    if (!isLoading) {
      if (
        inputProps.value &&
        !options.map((option) => option.value).includes(inputProps.value)
      ) {
        form.change(inputProps.name, undefined);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionsDependency, form, inputProps.name, isLoading]);

  return (
    <SelectInput
      inputProps={inputProps}
      label={label}
      options={options}
      isLoading={isLoading}
      error={error}
      required
    />
  );
};
