import DisabilityDisclosure from "components/CustomComponent/CustomerExtraForm/DisabilityDisclosure";
import VoluntarySelfIdentification from "components/CustomComponent/CustomerExtraForm/VoluntarySelfIdentification";
import { PAYLOAD_TYPES } from "constants/constant";

export const Demography = ({ component }) => {
  if (component?.name === PAYLOAD_TYPES.VOLUNTARY)
    return <VoluntarySelfIdentification templateName={component?.name} />;

  if (component?.name === PAYLOAD_TYPES.DISABILITY) {
    return <DisabilityDisclosure templateName={component?.name} />;
  }
};
