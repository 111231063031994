import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Form } from "react-final-form";

import { EMPLOYMENT } from "constants/constant";
import { sectionContext } from "components/Onboarding/Section";
import { DynamicFormElement } from "components/Onboarding/DynamicFormElement";

export const EmploymentForm = ({
  section,
  initialValue,
  index,
  isEditing,
  cancelEdit,
  onSubmit: onSubmitProp,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue || { current_employer: true });
  }, [initialValue]);

  function onSubmit(values) {
    if (values[section.name][EMPLOYMENT.CURRENT_EMPLOYER]) {
      values[section.name][EMPLOYMENT.END_MONTH] = null;
      values[section.name][EMPLOYMENT.END_YEAR] = null;
      delete values[section.name][EMPLOYMENT.END_MONTH];
      delete values[section.name][EMPLOYMENT.END_YEAR];
    }
    onSubmitProp(values, index);
  }

  const title = isEditing
    ? t("common.editEmployment")
    : t("common.addEmployment");

  const buttons = isEditing ? (
    <div className="d-flex justify-content-end mt-4">
      <button
        className="btn btn-outline-secondary mt-2 me-2"
        onClick={() => cancelEdit(false)}
      >
        {t("common.cancel")}
      </button>
      <button className="btn btn-primary mt-2" type="submit">
        {t("common.edit")}
      </button>
    </div>
  ) : (
    <div className="text-end mt-4">
      <button className="btn btn-secondary mt-2" type="submit">
        {t("common.save")}
      </button>
    </div>
  );

  return (
    <sectionContext.Provider
      value={{
        name: section.name,
      }}
    >
      <div>
        <h5 className="fw-medium mb-4 mt-2">{title}</h5>

        <Form onSubmit={onSubmit} initialValues={{ [section.name]: value }}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              {section.components.map((component) => {
                if (component.name === EMPLOYMENT.NO_WORK_EXPERIENCE)
                  return null;

                return (
                  <DynamicFormElement
                    component={component}
                    key={component.id}
                  />
                );
              })}
              {buttons}
            </form>
          )}
        </Form>
      </div>
    </sectionContext.Provider>
  );
};
