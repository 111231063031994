import React, { useContext } from "react";
import { t } from "i18next";

import Button from "pages/Partials/Button";

import CustomComponent from "components/CustomComponent/CustomComponent";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

const EducationFields = (props) => {
  const { data, language, setSubmit, stepValues, onSubmit } = props;

  const { isEducationEditing } = useContext(OnBoardingContext);

  const showClassName = isEducationEditing
    ? "d-flex justify-content-between align-items-center"
    : "";

  const displayClassName = isEducationEditing ? "" : "d-none";

  return (
    <>
      <div
        className={`row gx-2 align-items-center gy-3 ${data?.className} mb-4 mt-2`}
      >
        <div className={showClassName}>
          <h5
            style={data?.label?.style}
            className={`align-self-center  ${data?.label?.className} mb-3`}
          >
            {data?.label?.locales?.[language]}
          </h5>
          <span className={displayClassName}>{t("common.editDetail")}</span>
        </div>
        {data?.components?.map((item) => (
          <CustomComponent key={item.id} {...item} {...props} data={item} />
        ))}
        <Button
          setSubmit={setSubmit}
          stepValues={stepValues}
          onSubmit={onSubmit}
        />
      </div>
    </>
  );
};

export default EducationFields;
