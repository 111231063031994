import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "contextAPI/AuthContext";
import { LayoutContext } from "contextAPI/LayoutContext";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

import OnBoarding from "./Onboarding/OnBoarding";

import { useQuery } from "utils/useQuery";

const WorkflowPreview = () => {
  const { t } = useTranslation();

  const [disableValidation, setDisableValidation] = useState(false);

  const { getBranding } = useContext(AuthContext);

  const { applyBrandingToApp } = useContext(LayoutContext);

  useEffect(async () => {
    const branding = await getBranding();

    applyBrandingToApp(branding.data.company.branding_config);
  }, []);

  const { id } = useParams();

  const { token } = useQuery();

  LocalStorageManager.set(CONSTANTS.TOKEN_TYPE, "Bearer");
  LocalStorageManager.set(CONSTANTS.ACCESS_TOKEN, token);

  return (
    <>
      <header className="bg-dark bg-opacity-85 py-2">
        <div className="container">
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <span>{t("attributes.previewMode")}</span>
            </div>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={disableValidation}
                onChange={(e) => {
                  setDisableValidation(e.target.checked);
                }}
              />
              <label
                className="form-check-label"
                htmlFor="flexSwitchCheckChecked"
              >
                {t("attributes.disableValidations")}
              </label>
            </div>
          </div>
        </div>
      </header>
      <OnBoarding
        testMode
        disableValidation={disableValidation}
        workflowId={id}
        token={token}
      />
    </>
  );
};

export default WorkflowPreview;
