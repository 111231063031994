import React from "react";

const EyeIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "24"}`}
        height={`${height ? height : "24"}`}
        viewBox="0 0 25 25"
        fill="none"
        className={classNames.join(" ")}
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_1_25)">
          <path
            d="M14.87 14.37C14.5954 14.6647 14.2641 14.9012 13.8962 15.0651C13.5282 15.2291 13.1309 15.3173 12.7281 15.3244C12.3253 15.3315 11.9252 15.2574 11.5516 15.1065C11.1781 14.9556 10.8387 14.731 10.5538 14.4462C10.269 14.1613 10.0444 13.8219 9.89351 13.4484C9.74262 13.0748 9.66853 12.6747 9.67563 12.2719C9.68274 11.8691 9.77091 11.4718 9.93488 11.1038C10.0988 10.7359 10.3353 10.4047 10.63 10.13M18.69 18.19C16.9806 19.493 14.8991 20.2149 12.75 20.25C5.75 20.25 1.75 12.25 1.75 12.25C2.99389 9.9319 4.71914 7.90661 6.81 6.31L18.69 18.19ZM10.65 4.49C11.3383 4.32888 12.0431 4.24834 12.75 4.25C19.75 4.25 23.75 12.25 23.75 12.25C23.143 13.3856 22.4191 14.4547 21.59 15.44L10.65 4.49Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1.75 1.25L23.75 23.25"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_1_25">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(0.75 0.25)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default EyeIcon;
