import React, { useState } from "react";

import CandidateGamesApi from "api/Games/CandidateGamesApi";

export const CandidateGamesContext = React.createContext();

const CandidateGamesProvider = ({ children }) => {
  const [games, setGames] = useState([]);

  const fetchCandidateGames = async (candidateId) => {
    const { data } = await CandidateGamesApi.get(candidateId).catch(() => {
      return [];
    });

    setGames(data || []);
  };

  return (
    <CandidateGamesContext.Provider value={{ games, fetchCandidateGames }}>
      {children}
    </CandidateGamesContext.Provider>
  );
};

export default CandidateGamesProvider;
