import React from "react";

const ClockIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "25"}`}
        height={`${height ? height : "25"}`}
        className={classNames.join(" ")}
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.4999 22.9163C18.2529 22.9163 22.9166 18.2526 22.9166 12.4997C22.9166 6.74671 18.2529 2.08301 12.4999 2.08301C6.74695 2.08301 2.08325 6.74671 2.08325 12.4997C2.08325 18.2526 6.74695 22.9163 12.4999 22.9163Z"
          stroke="#FAFEFD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.5 6.25V12.5L16.6667 14.5833"
          stroke="#FAFEFD"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ClockIcon;
