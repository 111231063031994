import React from "react";

const CompanyImage = ({ className, logos, height, width }) => {
  if (!logos.length) return null;

  return (
    <figure className="me-3 left mb-0">
      <img
        src={logos[0].image_url}
        className={`main-logo img-fluid ${className && className}`}
        alt="logo"
        width={width}
        height={height}
      />
    </figure>
  );
};

export default CompanyImage;
