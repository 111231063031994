import React from "react";

const TrainingIcon = ({ className, color, width, height }) => {
  return (
    <svg
      width={32}
      height={32}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M16.988 11.035A5.061 5.061 0 1016 21.062a5.068 5.068 0 004.965-6.05L17.99 17.99a2.794 2.794 0 01-1.989.823 2.794 2.794 0 01-1.989-.823A2.794 2.794 0 0113.188 16c0-.751.292-1.457.823-1.989l2.977-2.976z"
        fill="#B8C1BF"
      />
      <path
        d="M30.568 9.387l-2.017 2.017c-.202.202-.43.37-.675.5.444 1.284.686 2.662.686 4.096 0 6.927-5.635 12.563-12.562 12.563S3.437 22.927 3.437 16C3.438 9.073 9.073 3.437 16 3.437c1.434 0 2.813.243 4.098.688.13-.246.297-.475.498-.676l2.017-2.017A15.906 15.906 0 0016 0C7.177 0 0 7.177 0 16s7.177 16 16 16 16-7.177 16-16c0-2.357-.513-4.596-1.432-6.613z"
        fill="#B8C1BF"
      />
      <path
        d="M21.65 5.517l.274 3.235-6.587 6.586a.937.937 0 101.326 1.326l6.586-6.587 3.235.273a.937.937 0 00.742-.271L31.202 6.1a.937.937 0 00-.584-1.597l-2.88-.243-.242-2.879a.938.938 0 00-1.598-.584l-3.977 3.978a.938.938 0 00-.271.741z"
        fill="#B8C1BF"
      />
    </svg>
  );
};

export default TrainingIcon;
