import React from "react";
import Carousel from "react-grid-carousel";

import JobCard from "./JobCard";

const JobList = ({ jobs, onJobSelected }) => {
  return (
    <Carousel
      cols={2}
      rows={2}
      gap={20}
      containerStyle={{ background: "transparent" }}
    >
      {jobs?.map((job, i) => (
        <Carousel.Item key={i}>
          <JobCard onJobSelected={onJobSelected} job={job} />
        </Carousel.Item>
      ))}
    </Carousel>
  );
};

export default JobList;
