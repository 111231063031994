import React from "react";
import { t } from "i18next";

import { IntIcon } from "components/Shared/Icon";

const Steps = () => {
  const stepsData = [
    {
      icon: <IntIcon name="userIconBig" className="steps-icon" />,
      timeIcon: <IntIcon name="timeIcon" className="time-icon" />,
      step: "Step 1",
      title: "Profile",
      time: "5 mins",
      descripitions: "To complete the profile setup",
    },
    {
      icon: <IntIcon name="gamepadIcon" className="steps-icon" />,
      timeIcon: <IntIcon name="timeIcon" className="time-icon" />,
      step: "Step 2",
      title: "Assessment",
      time: "10 mins",
      descripitions: "To complete the Assessment",
    },
    {
      icon: <IntIcon name="handshakeIcon" className="steps-icon" />,
      step: "Step 3",
      title: "Get Evaluated",
    },
  ];

  return (
    <div className="my-5 d-flex justify-content-center gap-5">
      {stepsData.map((data) => {
        return (
          <div className="d-flex gap-3 align-items-center justify-content-center steps-wrapper position-relative">
            <div className="step-num position-absolute px-3">{data.step}</div>
            <div>{data.icon}</div>
            <div>
              <div className="title text-left">{data.title}</div>
              <div className="d-flex gap-1">
                <div>{data.timeIcon}</div>
                <div className="time-description">
                  <span className="time">{data.time}</span> {data.descripitions}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
