import React from "react";
import { Link } from "react-scroll";
import ReactTooltip from "react-tooltip";

import { t } from "i18next";

const stickyNav = () => {
  const navItems = [
    {
      section: "1",
      name: t("applicantReport.stickyNav.yourDetail"),
      offSet: -110,
    },
    {
      section: "2",
      name: t("applicantReport.stickyNav.howItWorks"),
      offSet: -110,
    },
    {
      section: "3",
      name: t("applicantReport.stickyNav.testAssigned"),
      offSet: -110,
    },
    {
      section: "4",
      name: t("applicantReport.stickyNav.resultSummary"),
      offSet: -400,
    },
    {
      section: "5",
      name: t("applicantReport.stickyNav.overallCompetitiveness"),
      offSet: -400,
    },
  ];

  return (
    <>
      <ul className="m-0 py-3 d-flex flex-column gap-3 justify-content-between ">
        {navItems.map((item, i) => (
          <Link
            activeClass="active"
            to={item.section}
            spy={true}
            smooth={true}
            offset={item.offSet}
            duration={200}
          >
            <li data-tip={item.name} key={i} className="rounded-circle">
              <ReactTooltip
                border="true"
                borderColor="#fff"
                className="sticknav-tooltip"
              />
            </li>
          </Link>
        ))}
      </ul>
    </>
  );
};

export default stickyNav;
