import React, { useContext, useEffect, useState } from "react";

import { UserContext } from "./UserContext";
import { AuthContext } from "./AuthContext";

const mapBrandingValues = {
  primaryColor: "--bs-primary-rgb",
  secondaryColor: "--bs-secondary-rgb",

  textPrimaryColor: "--bs-text-primary-rgb",
  textSecondaryColor: "--bs-heading-color",
  borderColor: "--border-color-rgb",

  bodyBackgroundColor: "--bs-body-rgb-2",
  headingBackground: "--bs-body-rgb-1",
};

const setRgb = (colorValue) => {
  if (typeof colorValue === "string") {
    return colorValue;
  }

  const { r, g, b } = colorValue;

  return `${r},${g},${b}`;
};

const applyBrandingToApp = (_brandingDetails) => {
  var brandingDetails = {
    // buttonBg: "213, 205, 229",
    // iconColor: "213, 205, 229",
    themeName: "Endoplasm",
    paletteName: "palette3",

    primaryColor: "29, 178, 126",
    secondaryColor: "248, 220, 94",

    textPrimaryColor: "238, 244, 243", //gray scale
    textSecondaryColor: "238, 244, 243",
    borderColor: "87,87,87",

    headingBackground: "26, 29, 30", //siebar
    // textSecondaryColor: "238, 238, 238",
    bodyBackgroundColor: "6, 6, 6", // body
  };

  // var brandingDetails = {
  //   // buttonBg: "213, 205, 229",
  //   // iconColor: "213, 205, 229",
  //   themeName: "Endoplasm",
  //   paletteName: "palette3",

  //   primaryColor: "240, 71, 110",
  //   secondaryColor: "88, 43, 230",

  //   textPrimaryColor: "36, 36, 35", //gray scale
  //   textSecondaryColor: "152, 159, 178",
  //   borderColor: "217, 217, 217",

  //   headingBackground: "255, 255, 255", //siebar
  //   // textSecondaryColor: "238, 238, 238",
  //   bodyBackgroundColor: "245, 245, 245", // body
  // };

  // var brandingDetails = {
  //   // buttonBg: "213, 205, 229",
  //   // iconColor: "213, 205, 229",
  //   themeName: "Endoplasm",
  //   paletteName: "palette3",

  //   primaryColor: "228, 116, 107",
  //   secondaryColor: "169, 146, 247",

  //   textPrimaryColor: "238, 244, 243", //gray scale
  //   textSecondaryColor: "184, 193, 191",
  //   borderColor: "91, 95, 113",

  //   headingBackground: "39, 41, 54", //siebar
  //   // textSecondaryColor: "238, 238, 238",
  //   bodyBackgroundColor: "65, 69, 88", // body
  // };

  var brandingDetails = {
    // buttonBg: "213, 205, 229",
    // iconColor: "213, 205, 229",
    themeName: "Endoplasm",
    paletteName: "palette3",

    primaryColor: "2, 98, 137",
    secondaryColor: "254, 233, 153",

    textPrimaryColor: "238, 244, 243", //gray scale
    textSecondaryColor: "238, 244, 243",
    borderColor: "70, 108, 123",

    headingBackground: "1, 57, 80", //siebar
    // textSecondaryColor: "238, 238, 238",
    bodyBackgroundColor: "2, 74, 103", // body
  };

  brandingDetails = _brandingDetails;
  brandingDetails = _brandingDetails;

  Object.entries(mapBrandingValues).forEach(([key, value]) => {
    if (brandingDetails[key])
      document.documentElement.style.setProperty(
        value,
        setRgb(brandingDetails[key])
      );
  });
};

export const LayoutContext = React.createContext();

const LayoutProvider = ({ children }) => {
  const { candidateInfo } = useContext(UserContext);
  const { userInfo } = useContext(AuthContext);

  const [isUpdatingLayout, setIsUpdatingLayout] = useState(false);

  const fetchBrandingDetails = () => {
    setIsUpdatingLayout(true);
    const companyDetails = userInfo?.data?.company;

    applyBrandingToApp(companyDetails?.branding_config || {});
  };

  useEffect(() => {
    fetchBrandingDetails();
  }, [userInfo]);

  return (
    <LayoutContext.Provider value={{ isUpdatingLayout, applyBrandingToApp }}>
      {children}
    </LayoutContext.Provider>
  );
};

export default LayoutProvider;
