import React, { useEffect, useRef, useState } from "react";

import { t } from "i18next";

import Title from "components/Report/HelperComponents/Title";
import TalentPoolBar from "./TalentPoolBar";
import TalentPoolBlock, { BLOCK_NAME } from "./TalentPoolBlock";

import { talentPoolJSON } from "constants/talentPool";

const STATUS = {
  WATCH_OUT: "watch_out",
  MOST_APPLICANT: "most_applicants",
  STAND_OUT: "stand_out",
};

const TalentPoolGraph = ({ assessments }) => {
  const [watchOut, setWatchOut] = useState([]);
  const [applicant, seApplicant] = useState([]);
  const [standOut, setStandOut] = useState([]);

  useEffect(() => {
    const watchOutData = [];
    const applicantData = [];
    const standOutData = [];

    assessments?.forEach((assessment) => {
      const percent = assessment?.score?.overall_percentile_higher * 100;
      const suggestedStatus = assessment?.score?.suggested_status;
      const newData = {
        percent: percent % 1 !== 0 ? percent.toFixed(2) : percent,
        name: assessment?.name,
      };

      if (suggestedStatus === STATUS.WATCH_OUT) {
        watchOutData.push(newData);
      } else if (suggestedStatus === STATUS.MOST_APPLICANT) {
        applicantData.push(newData);
      } else if (suggestedStatus === STATUS.STAND_OUT) {
        standOutData.push(newData);
      }
    });
    setWatchOut(watchOutData);
    seApplicant(applicantData);
    setStandOut(standOutData);
  }, [assessments]);

  return (
    <div className="container max-w-1970">
      <div className="mb-5">
        <Title>
          {t("applicantReport.talentPool.howYou")}{" "}
          <span>{t("applicantReport.talentPool.compare")}</span>{" "}
          {t("applicantReport.talentPool.toApplicant")}
          <br />
        </Title>
        <div className="my-4">
          <Title>{t("applicantReport.talentPool.applyingFor")}</Title>
        </div>
        <article className="talent-pool-graph pt-0">
          <div className="talent-pool-graph-table">
            <table className=" mb-0 table-bordered table rounded">
              <thead className="table-header">
                <tr>
                  <th className="blank-block p-0 border-0" />
                  {Array.from({ length: 11 }, (_, k) => k).map((i) => (
                    <th
                      scope="col"
                      className="cell-header text-center fw-normal"
                      key={i}
                    >
                      {i * 10}%
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    // eslint-disable-next-line jsx-a11y/scope
                    scope="col"
                    className="cell-header block-container text-center fw-normal position-relative p-0"
                  >
                    <TalentPoolBlock blockName={BLOCK_NAME.WATCH_OUT} />
                  </td>
                  {Array.from({ length: 11 }, (_, k) => k).map((i) => (
                    <td
                      // eslint-disable-next-line jsx-a11y/scope
                      scope="col"
                      className="cell-header text-center fw-normal position-relative"
                      key={i}
                    >
                      {watchOut.map(({ name, percent }) => (
                        <TalentPoolBar
                          blockName={BLOCK_NAME.WATCH_OUT}
                          placement={percent}
                          currentPlacement={i * 10}
                          name={name}
                        />
                      ))}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    // eslint-disable-next-line jsx-a11y/scope
                    scope="col"
                    className="cell-header block-container text-center fw-normal position-relative p-0"
                  >
                    <TalentPoolBlock blockName={BLOCK_NAME.MOST_APPLICANT} />
                  </td>
                  {Array.from({ length: 11 }, (_, k) => k).map((i) => (
                    <td
                      // eslint-disable-next-line jsx-a11y/scope
                      scope="col"
                      className="cell-header text-center fw-normal position-relative"
                      key={i}
                    >
                      {applicant.map(({ name, percent }) => (
                        <TalentPoolBar
                          blockName={BLOCK_NAME.MOST_APPLICANT}
                          placement={percent}
                          name={name}
                          currentPlacement={i * 10}
                        />
                      ))}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    // eslint-disable-next-line jsx-a11y/scope
                    scope="col"
                    className="cell-header block-container text-center fw-normal position-relative p-0"
                  >
                    <TalentPoolBlock blockName={BLOCK_NAME.STAND_OUT} />
                  </td>
                  {Array.from({ length: 11 }, (_, k) => k).map((i) => (
                    <td
                      // eslint-disable-next-line jsx-a11y/scope
                      scope="col"
                      className="cell-header text-center fw-normal position-relative"
                      key={i}
                    >
                      {standOut.map(({ name, percent }) => (
                        <TalentPoolBar
                          blockName={BLOCK_NAME.STAND_OUT}
                          placement={percent}
                          currentPlacement={i * 10}
                          name={name}
                        />
                      ))}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </article>
      </div>
    </div>
  );
};

export default TalentPoolGraph;
