import { library, dom } from "@fortawesome/fontawesome-svg-core";

import {
  faTickIcon,
  faDotList,
  faCorrectIcon,
  faCancelIcon,
  faSearchIcon,
  faRightArrowIcon,
  faBackgroundPatternIcon,
  faLockIcon,
} from "constants/icons/icon";

// add new icons to lib
library.add(
  faTickIcon,
  faDotList,
  faCorrectIcon,
  faCancelIcon,
  faSearchIcon,
  faRightArrowIcon,
  faBackgroundPatternIcon,
  faLockIcon
);

// Look for changes of icons
dom.watch();
