import React from "react";

const ProgressPercentage = ({ progress }) => {
  const totalPercentage = {
    width: `${progress}%`,
  };

  return (
    <div className="percentage-holder roundedw-100">
      <div
        className={`total-percentage roundedh-100 ${
          progress == "100" ? "bg-success" : "bg-progress"
        }`}
        style={totalPercentage}
      ></div>
    </div>
  );
};

export default ProgressPercentage;
