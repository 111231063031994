import React from "react";

import AccordionItem from "components/Dashboard/Accordion/AccordionItem";
import CourseSkeleton from "components/Dashboard/Accordion/CourseSkeleton";
import NoCourse from "components/Dashboard/NoCourse";

const RenderEnrolled = ({
  filteredCategory,
  enrolled,
  loading,
  course,
  label,
}) => {
  const enrolledList = filteredCategory?.map((el) => (
    <AccordionItem
      key={el.id}
      {...el}
      enrolled={enrolled}
      course={course}
      label={label}
    />
  ));

  if (loading) return <CourseSkeleton />;

  if (!loading) {
    if (!enrolled?.length) {
      return <NoCourse />;
    } else return enrolledList;
  }
};

export default RenderEnrolled;
