import React, { useState } from "react";
import "bootstrap/js/dist/collapse";

import Container from "components/Dashboard/Container";
import DashboardLayout from "layout/DashboardLayout/DashboardLayout";

const Dashboard = () => {
  const [activeNav, setActiveNav] = useState("");

  return (
    <DashboardLayout activeNav={activeNav} setActiveNav={setActiveNav}>
      <Container active={activeNav} />
    </DashboardLayout>
  );
};

export default Dashboard;
