import { useContext, useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { HOME } from "constants/routes";
import { CandidateJobContext } from "contextAPI/CandidateJobContext";
import { UserContext } from "contextAPI/UserContext";

import JobsApi from "api/JobsApi/JobsApi";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

import { AuthContext } from "contextAPI/AuthContext";

const getJobs = (candidateData) => {
  return candidateData?.map(({ id, status, job }) => ({
    candidateId: id,
    candidateStatus: status,
    ...job,
  }));
};

const useAcceptInvitation = () => {
  const { jobsFetchValue, setUserSelectedData, userCandidatesData } =
    useContext(CandidateJobContext);
  const { handleCandidateSelected, setSelectedCandidate } =
    useContext(UserContext);
  const { authError } = useContext(AuthContext);

  const [acceptedInvitationData, setAcceptedInvitationData] = useState(null);
  const navigate = useNavigate();

  const handleAcceptInvitations = useCallback(async (invitationId) => {
    const acceptedData = await JobsApi.acceptInvitations(invitationId);

    setAcceptedInvitationData(acceptedData?.data);
  }, []);

  const onJobSelected = (job) => {
    if (job?.candidateId) {
      handleCandidateSelected(job?.candidateId);
      LocalStorageManager.set(CONSTANTS.SELECTED_JOB, job);

      navigate(HOME.INDEX);
    }
  };

  useEffect(async () => {
    if (acceptedInvitationData) {
      await jobsFetchValue();
    }
  }, [acceptedInvitationData]);

  useEffect(() => {
    if (acceptedInvitationData && !authError) {
      if (userCandidatesData?.length > 0) {
        const jobData = getJobs(userCandidatesData);

        const selectedJob = jobData.find(
          (job) => job?.id === acceptedInvitationData?.source_id
        );

        onJobSelected(selectedJob);

        setUserSelectedData(selectedJob);
        setSelectedCandidate(selectedJob?.candidateId);
      }
    }
  }, [userCandidatesData, acceptedInvitationData]);

  return { handleAcceptInvitations };
};

export default useAcceptInvitation;
