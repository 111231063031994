import { createContext, useContext } from "react";
import { useFormState } from "react-final-form";

import { LABEL } from "constants/formType";
import { DynamicFormElement } from "./DynamicFormElement";
import { EducationSection } from "./EducationSection";
import { EmploymentSection } from "./EmploymentSection";
import { onboardingContext } from "pages/Onboarding/OnBoarding";

export const sectionContext = createContext();

export function useSectionContext() {
  const context = useContext(sectionContext);

  if (!context) return {};

  return context;
}

export const Section = ({ section }) => {
  const formState = useFormState();

  if (section.value === LABEL.SECTION_TYPE) {
    return <EducationSection section={section} />;
  }

  if (section.value === LABEL.EMPLOYMENT_SECTION_TYPE) {
    return <EmploymentSection section={section} />;
  }

  return (
    <SectionContainer section={section}>
      <sectionContext.Provider
        value={{
          name: section.name,
        }}
      >
        {section.components.map((component) => (
          <DynamicFormElement component={component} key={component.id} />
        ))}
      </sectionContext.Provider>
    </SectionContainer>
  );
};

export const SectionContainer = ({ children, section, onClick, left }) => {
  const { language } = useContext(onboardingContext);

  return (
    <div className="form-block">
      <div className="d-flex justify-content-between">
        <h5
          style={section?.label?.style}
          className={`fw-medium mb-4`}
          onClick={onClick}
        >
          {section?.label?.locales?.[language]}
        </h5>
        {left && left()}
      </div>
      {children}
    </div>
  );
};
