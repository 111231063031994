import { useState } from "react";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DeleteModal from "components/Modals/DeleteModal";

import { TOOLTIP_COLOR } from "constants/formType";
import {
  DateIcon,
  DeleteIcon,
  DesignationIcon,
  EditIcon,
  EmployerIcon,
  LocationIcon,
  UserIcon,
} from "constants/icons/icon";

import { useGetLabel } from "hooks/useGetLabel";

export const EmploymentListItem = ({
  employment,
  onEdit,
  onDelete: onDeleteProp,
  index,
  isEditing,
  section,
}) => {
  const { t } = useTranslation();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const getLabel = useGetLabel(section);

  function onDelete() {
    onDeleteProp(index);
    setOpenDeleteModal(false);
  }

  const fullDate = employment
    ? employment.start_month +
      " " +
      employment.start_year +
      " - " +
      (employment.current_employer
        ? t("common.present")
        : employment.end_month + " " + employment.end_year)
    : "";

  const deletePrompt = employment?.company_title
    ? t("common.confirmEmploymentDelete").replace(
        ":employer",
        employment?.company_title
      )
    : t("modalValues.deleteEmployment");

  const actionButtons = isEditing ? (
    <div className="buttons-holder d-flex justify-content-end my-4">
      <div className="px-2 py-1 editing-btn">{t("attributes.editing")}</div>
    </div>
  ) : (
    <div className="buttons-holder d-flex my-4">
      <button
        type="button"
        className="bg-transparent border-0 btn-icon"
        onClick={() => onEdit(index)}
      >
        <EditIcon className="color-icon edit-icon me-2" />
      </button>

      <button
        type="button"
        className="bg-transparent border-0 btn-icon"
        onClick={() => setOpenDeleteModal(true)}
      >
        <DeleteIcon className="del-icon" />
      </button>
    </div>
  );

  return (
    <div>
      <div>
        <div className="box-styled p-0">
          <div className="list-block">
            <div className="row list-items">
              <div className=" row col-lg-11">
                <div
                  className="my-2 col-lg-4"
                  data-tip={employment.job_title}
                  data-border
                  data-background-color={TOOLTIP_COLOR.BACKGROUND_DARK}
                >
                  <DesignationIcon className="me-3 color-icon" />
                  <span className="custom-heading-text">
                    {employment.job_title}
                  </span>
                  <ReactTooltip />
                </div>
                <div
                  className="my-2 col-lg-4"
                  data-tip={employment.company_title}
                  data-border
                  data-scroll-hide
                  data-background-color={TOOLTIP_COLOR.BACKGROUND_DARK}
                >
                  <EmployerIcon className="me-3 color-icon fill" />
                  <span className="custom-heading-text">
                    {employment.company_title}
                  </span>
                  <ReactTooltip />
                </div>
                <div className="my-2 col-lg-4">
                  <DateIcon className="me-3 color-icon" />
                  <span className="custom-heading-text text-capitalize">
                    {fullDate}
                  </span>
                </div>
                <div className="my-2 col-lg-4">
                  <UserIcon className="me-3 color-icon" />
                  <span className="custom-heading-text">
                    {getLabel("employment_type", employment.employment_type)}
                  </span>
                </div>
                <div
                  className="my-2 location-values overflow-hidden col-lg-4"
                  data-tip={employment.location}
                  data-border
                  data-background-color={TOOLTIP_COLOR.BACKGROUND_DARK}
                >
                  <LocationIcon className="me-3 color-icon " />
                  <span className="custom-heading-text">
                    {employment.location}
                  </span>
                  <ReactTooltip />
                </div>
              </div>
              <div className="col-lg-1">{actionButtons}</div>
            </div>
            <button
              type="button"
              data-bs-toggle="collapse"
              data-bs-target={`#collapse${index}`}
              aria-expanded="false"
              aria-controls={`collapse${index}`}
              className="view-block"
            >
              <span className="view-more d-flex align-items-center">
                <span>{t("formValues.viewMore")}</span>
                <span>
                  <FontAwesomeIcon className="ms-1 arrow" icon={faAngleDown} />
                </span>
              </span>
              <span className="view-less d-flex align-items-center">
                <span>{t("formValues.viewLess")}</span>
                <span>
                  <FontAwesomeIcon className="ms-1 arrow" icon={faAngleUp} />
                </span>
              </span>
            </button>
            <div className="collapse content-holder" id={`collapse${index}`}>
              <strong className="d-block mb-2">
                {t("message.employmentDetails.briefDescription")}
              </strong>
              <div
                dangerouslySetInnerHTML={{
                  __html: employment?.description,
                }}
              ></div>
            </div>
            <div className="delete-holder position-absolute bg-dark">
              <DeleteModal
                idx={0}
                deleteId={0}
                del={openDeleteModal}
                setDel={setOpenDeleteModal}
                deleteFunction={onDelete}
                message={deletePrompt}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
