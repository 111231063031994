import React, { useContext, useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { t } from "i18next";

import { DashboardContext } from "contextAPI/dashboardContext";

import { useWindowDimensions } from "utils/useWindowDimension";

import {
  EXPLORE,
  GAMELIST,
  HOME,
  JOBDETAIL,
  TRAINING,
  MYREPORT,
} from "constants/routes";

import {
  HomeIcon,
  CodeSandBoxIcon,
  ReportIcon,
  JobIcon,
  BadgeIcon,
  TrainingIcon,
} from "assets/icons/icons";

const Navigation = () => {
  const { sidebarToggle, activeName, setActiveName, setSideBarToggle } =
    useContext(DashboardContext);

  const [checkSidebar, setCheckSidebar] = useState(sidebarToggle);
  const [mobileToggle, setMobileToggle] = useState(true);

  const { width } = useWindowDimensions();

  const onNavSelected = () => {
    setMobileToggle(false);
  };

  useEffect(() => {
    if (sidebarToggle) {
      setTimeout(() => {
        setCheckSidebar(true);
      }, 300);
    } else {
      setTimeout(() => {
        setCheckSidebar(false);
      }, 300);
    }
  }, [sidebarToggle]);

  const sideBarItems = [
    {
      logo: <HomeIcon className={`color-icon`} />,
      name: t("attributes.home"),
      link: HOME.INDEX,
    },
    // NOTE: Might need this later. So not removing currently.
    // {
    //   logo: <JobIcon className={`color-icon`} />,
    //   name: t("userMenu.myJobs"),
    //   link: JOBDETAIL.INDEX,
    // },
    {
      logo: <ReportIcon className={`color-icon`} />,
      name: t("userMenu.myReport"),
      link: MYREPORT.INDEX,
    },
  ];

  const accordionItems = [
    {
      name: t("attributes.explore"),
      link: EXPLORE.INDEX,
    },
    {
      name: t("attributes.enroll"),
      link: TRAINING.INDEX,
    },
  ];

  const myTrainingNavHandler = () => {
    setActiveName(t("userMenu.myTraining"));
  };

  return (
    <nav aria-label="Main Navigation">
      <ul className="nav flex-column">
        {sideBarItems.map((sideBarItem, i) => {
          return (
            <li
              role="presentation"
              onClick={() => setActiveName(sideBarItem.name)}
              key={i}
              className={`my-2 px-0`}
            >
              <Link to={sideBarItem.link} onClick={onNavSelected}>
                <div
                  className={`nav-list  ${
                    activeName === sideBarItem.name ? "activeLink" : ""
                  }`}
                >
                  <div>{sideBarItem.logo}</div>
                  <div className={` ms-2 `}>{sideBarItem.name}</div>
                </div>
              </Link>
            </li>
          );
        })}

        <li id="accordionExample">
          <div
            role="presentation"
            onClick={myTrainingNavHandler}
            className="accordion-item border-0"
          >
            <button
              className={`accordion-button fw-normal px-2 py-3 mt-2 mb-0 bg-transparent shadow-none ${
                activeName === "My Training" ? "activeLink" : null
              }`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              <TrainingIcon className={`color-icon`} />{" "}
              <span className={`ms-2 fw400 `}>{t("userMenu.myTraining")}</span>
            </button>

            <div
              id="collapseOne"
              className="accordion-collapse collapse show"
              aria-labelledby="headingOne"
              data-bs-parent="#sub-menu"
            >
              <div className="accordion-body position-relative bg-transparent pt-2 pb-0 ps-0">
                {accordionItems.map((accordionItem, i) => {
                  return (
                    <div
                      role="presentation"
                      key={i}
                      className="accordion-items-wrapper position-relative"
                    >
                      <NavLink to={accordionItem.link}>
                        <div className={` py-3 mb-1 ps-3 accordion-list-item`}>
                          {accordionItem.name}
                        </div>
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
