import { useContext } from "react";
import { Field } from "react-final-form";

import { VALIDATION_ON } from "utils/validation";
import { CheckboxInput } from "components/Form/CheckboxInput";
import { onboardingContext } from "pages/Onboarding/OnBoarding";
import { composeValidation, isRequired } from "components/Onboarding/utils";

//Checkbox component
export const Checkbox = ({ label, name, className, id, validations }) => {
  const { language } = useContext(onboardingContext);

  /* This hack is cause react-final-form is causing error without explicitly turning name to string */

  return (
    <Field
      name={`${name}`}
      className={className}
      id={id}
      type="checkbox"
      validate={composeValidation(validations, VALIDATION_ON.CHANGE, language)}
    >
      {({ input, meta: { error, touched } }) => (
        <CheckboxInput
          error={touched && error}
          inputProps={input}
          label={label}
          key={name}
          required={isRequired(validations)}
        />
      )}
    </Field>
  );
};
