/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ThemeSwitcher from "components/ThemeSwitcher/ThemeSwitcher";
import {
  SettingIcon,
  JobIcon,
  LogoutIcon,
  IconApplicant
} from "assets/icons/icons";

import { AuthContext } from "contextAPI/AuthContext";
import { UserContext } from "contextAPI/UserContext";
import { CandidateJobContext } from "contextAPI/CandidateJobContext";

import { WORKFLOW } from "constants/routes";
const UserProfile = ({ value, onChange, setSwitchJob }) => {
  const navigate = useNavigate();

  const { handleLogout } = useContext(AuthContext);
  const { userCandidatesData } = useContext(CandidateJobContext);
  const { candidateFullName } = useContext(UserContext);

  const str = candidateFullName;
  const matches = str.match(/\b(\w)/g);
  const nameAbbr = matches.join("");

  const { t } = useTranslation();

  const jobData = userCandidatesData;
  const jobMenuList = jobData?.length > 1 && (
    <li
      role="option"
      aria-selected="false"
      className="my-3 px-3 d-flex align-items-center "
    >
      <button
        className="d-flex align-items-center flex-grow-1 interactive-click"
        onClick={() => setSwitchJob(true)}
        onKeyDown={() => setSwitchJob(true)}
      >
        <JobIcon className="me-3 color-icon" /> {t("attributes.jobs")}
      </button>
    </li>
  );

  const setEditProfile = () => {
    navigate(WORKFLOW.INDEX);
  };

  return (
    <div className="user-profile my-1">
      <div className="d-flex align-items-center">
        <div className="dropdown">
          <div
            className=" dropdown-toggle user position-relative d-flex align-items-center border-none bg-transparent
            "
            type="button"
            id="profileDropDown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            aria-label="User related"
          >
            <span className="image-holder text-uppercase d-inline-block rounded-pill me-2 text-center px-1">
              <IconApplicant className="me-3 color-icon" />
            </span>
            <div className="pe-5">
              <span className="text-white fw-medium d-none d-lg-block lh-1">
                {candidateFullName}
              </span>
              <span className="fs-7 d-none d-lg-block">
                {/* {t("userMenu.applicant")} */}
              </span>
            </div>
          </div>
          <ul
            className="dropdown-menu profile-list bg-dark"
            aria-labelledby="profileDropDown"
            aria-label="listbox"
          >
            <li
              role="option"
              aria-selected="true"
              className="my-3 px-3 d-flex align-items-start flex-column "
            >
              <span className="name text-start col-12 text-truncate d-block d-lg-none">
                {t("userMenu.name")} {candidateFullName}
              </span>
              <span className="role d-block d-lg-none pb-3">
                {" "}
                {t("userMenu.role")} {t("userMenu.candidates")}
              </span>
            </li>
            <li
              role="option"
              aria-selected="false"
              className="my-3 px-3 d-flex align-items-center d-none "
            >
              <SettingIcon className="me-3 color-icon" />
              {t("userMenu.accountSetting")}
            </li>
            {/* TODO: JobMenuList will be needed later  */}
            {/* {jobMenuList} */}
            <li
              role="option"
              aria-selected="false"
              className="my-3 px-3 d-flex align-items-center "
            >
              <div
                role="button"
                tabIndex="-1"
                onClick={() => setEditProfile()}
                className=" p-0 inherit-reset"
              >
                <SettingIcon className="me-3 color-icon" />
                {t("userMenu.editProfile")}
              </div>
            </li>
            <li
              role="option"
              aria-selected="false"
              className="my-3 px-3 d-flex align-items-center "
            >
              <div
                onClick={handleLogout}
                role="button"
                tabIndex="-1"
                className=" p-0 inherit-reset"
              >
                <LogoutIcon className="me-3 color-icon" />
                {t("userMenu.logout")}
              </div>
            </li>
            {/* <li
              role="option"
              aria-selected="false"
              className="my-3 px-3 d-flex align-items-center  d-flex d-none"
            >
              <span className="align-self-center me-2 mode">
                {t("userMenu.modeSwitch")}
              </span>
              <ThemeSwitcher value={value} onChange={onChange} />
            </li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
