import React from "react";

const ComputerIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "82"}`}
        height={`${height ? height : "70"}`}
        className={classNames.join(" ")}
        viewBox="0 0 82 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M68.3333 11.666H13.6667C11.7797 11.666 10.25 12.9719 10.25 14.5827V43.7494C10.25 45.3602 11.7797 46.666 13.6667 46.666H68.3333C70.2203 46.666 71.75 45.3602 71.75 43.7494V14.5827C71.75 12.9719 70.2203 11.666 68.3333 11.666Z"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.9165 58.334H58.0832"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M30.75 46.666V58.3327"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M51.25 46.666V58.3327"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="1.83333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ComputerIcon;
