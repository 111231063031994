import React from "react";

const HamBuggerIcon = ({ className, color, width, height }) => {
  return (
    <>
      <svg
        width={width ? width : "24"}
        height={height ? height : "24"}
        viewBox="0 0 26 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M3 12H21"
          stroke={color ? color : "#778583"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 6H21"
          stroke={color ? color : "#778583"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3 18H21"
          stroke={color ? color : "#778583"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default HamBuggerIcon;
