import AuthenticatedApi from "api/AuthenticatedApi";
import BaseRequest from "api/BaseApi.js";

import { WithId } from "utils/helpers";

const urlWithFilters = (url, filters) => {
  let strArr = [];

  for (const key in filters) {
    if (filters[key]) strArr.push(`${key}=${filters[key]}`);
  }

  return `${url}?${strArr.join("&")}`;
};

const insertIdToUrl = (url, id) => {
  return url.replace(":jobId", id);
};

const URL = {
  INDEX: "/jobs",
  SHOW: "/companies/jobs/:jobId",
  CANDIDATE_JOBS: "/applicants/job_applications",
  DYNAMIC_FORM: "/jobs/:jobId/dynamic_forms?pages=:currentPage",
  INVITATION_ACCEPT: "/applicants/invitations/accept/:id",
};

class JobsApi {
  static getDetails(jobId) {
    return AuthenticatedApi.get(insertIdToUrl(URL.SHOW, jobId));
  }

  static fetchAll(filters) {
    let GET_URL = URL.CANDIDATE_JOBS;

    if (filters) {
      GET_URL = urlWithFilters(GET_URL, filters);
    }

    return BaseRequest.get(GET_URL);
  }

  static update(id, data) {
    const UPDATE_URL = `${URL.INDEX}/${id}`;

    return BaseRequest.put(UPDATE_URL, data);
  }

  static get() {
    let GET_URL = `${URL.CANDIDATE_JOBS}`;

    return AuthenticatedApi.get(GET_URL);
  }

  static fetchForm(jobId, currentPage) {
    const fetchURL = insertIdToUrl(URL.DYNAMIC_FORM, jobId).replace(
      ":page",
      currentPage
    );

    return AuthenticatedApi.get(fetchURL);
  }

  /**
   *
   * @param {init} invitationId - id of accepted invitation
   * @returns
   */

  static acceptInvitations(invitationId) {
    return AuthenticatedApi.patch(WithId(URL.INVITATION_ACCEPT, invitationId));
  }
}

export default JobsApi;
