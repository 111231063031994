import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import "bootstrap/js/dist/collapse";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

import EmploymentListComponent from "components/CustomComponent/CustomerExtraForm/EmploymentListComponent";

const EmploymentList = () => {
  const { t } = useTranslation();

  const [deleteEmployment, setDeleteEmployment] = useState(false);

  const {
    employmentData,
    setEmploymentData,
    editEmploymentId,
    setEditEmploymentId,
    editEmploymentData,
    setEditEmploymentData,
    setShowEmploymentForm,
    setAnotherJobButton,
    setFormValues,
  } = useContext(OnBoardingContext);
  const [deleteId, setDeleteId] = useState(null);

  return employmentData.map((data) => {
    return (
      <EmploymentListComponent
        data={data}
        t={t}
        setDeleteEmployment={setDeleteEmployment}
        idx={data?.id}
        employmentData={employmentData}
        setEmploymentData={setEmploymentData}
        deleteEmployment={deleteEmployment}
        deleteId={deleteId}
        setDeleteId={setDeleteId}
        editId={editEmploymentId}
        setEditId={setEditEmploymentId}
        setEditData={setEditEmploymentData}
        setShowEmploymentForm={setShowEmploymentForm}
        setAnotherJobButton={setAnotherJobButton}
        editEmploymentData={editEmploymentData}
        setFormValues={setFormValues}
      />
    );
  });
};

export default EmploymentList;
