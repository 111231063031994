export const VideoElement = ({ videoElement }) => {
  return (
    <div>
      <video
        key={videoElement.value}
        width={videoElement.style?.width || "100%"}
        height={videoElement.style?.height || "100%"}
        controls
      >
        <source src={videoElement.value} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
