import { ReactComponent as ArmIconAlt } from "assets/images/arm.svg";
import { ReactComponent as usersIcon } from "assets/images/users.svg";
import { ReactComponent as starIconAlt } from "assets/images/star.svg";
import { ReactComponent as noList } from "assets/images/no-list.svg";
import { ReactComponent as tickIcon } from "assets/images/tick.svg";
import { ReactComponent as tenkey } from "assets/images/10key.svg";
import { ReactComponent as businessWriting } from "assets/images/business-writing.svg";
import { ReactComponent as mcc } from "assets/images/mcc.svg";
import { ReactComponent as responsiblility } from "assets/images/responsibility.svg";
import { ReactComponent as typing } from "assets/images/typing.svg";
import { ReactComponent as overcommingAdversity } from "assets/images/overcoming-adversity.svg";
import { ReactComponent as workspace } from "assets/images/workspace.svg";
import { ReactComponent as basicComputerSkill } from "assets/images/basiccomputerskill.svg";
import { ReactComponent as bigArrow } from "assets/images/big-arrow.svg";
import { ReactComponent as playBtn } from "assets/images/play.svg";
import { ReactComponent as infoIcon } from "assets/images/info.svg";
import { ReactComponent as wavehand } from "assets/images/wavehand.svg";
import { ReactComponent as userIconBig } from "assets/images/user-icon-big.svg";
import { ReactComponent as gamepadIcon } from "assets/images/gamepad.svg";
import { ReactComponent as handshakeIcon } from "assets/images/handshake-icon.svg";
import { ReactComponent as timeIcon } from "assets/images/time-icon.svg";
import { ReactComponent as MCCS } from "assets/images/MCCS.svg";
import { ReactComponent as sessionTimeoutIcon } from "assets/images/session-timeout.svg";

const ICONS = {
  usersIcon,
  starIconAlt,
  "Ten Key": tenkey,
  "Basic Computer Skills": basicComputerSkill,
  "Business Writing": businessWriting,
  "Multi-Channel Customer Support": mcc,
  "Multi-Channel Customer Support Voice Service": mcc,
  "Multi-Channel Customer Support Voice Sales": mcc,
  "Multi-Channel Customer Support Voice Support": mcc,
  "Multi-Channel Customer Support Voice Collections": mcc,
  "Multi-Channel Customer Support Chat Service": mcc,
  "Multi-Channel Customer Support Chat Sales": mcc,
  "Multi-Channel Customer Support Chat Support": mcc,
  "Responsibility and Dependability Skills": responsiblility,
  "Typing Test": typing,
  "Overcoming Adversity Skills": overcommingAdversity,
  "Communication Skills": workspace,
  tickIcon,
  ArmIconAlt,
  noList,
  bigArrow,
  playBtn,
  infoIcon,
  wavehand,
  userIconBig,
  gamepadIcon,
  handshakeIcon,
  timeIcon,
  sessionTimeoutIcon,
};

export default ICONS;
