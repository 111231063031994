import React from "react";

const LogoutIcon = ({ className, color, width, height }) => {
  return (
    <>
      <svg
        width={width ? width : "24"}
        height={height ? height : "24"}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M9 21.4858H5C4.46957 21.4858 3.96086 21.2751 3.58579 20.9001C3.21071 20.525 3 20.0163 3 19.4858V5.48584C3 4.95541 3.21071 4.4467 3.58579 4.07163C3.96086 3.69655 4.46957 3.48584 5 3.48584H9"
          stroke={color ? color : "#B8C1BF"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 17.4858L21 12.4858L16 7.48584"
          stroke={color ? color : "#B8C1BF"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21 12.4858H9"
          stroke={color ? color : "#B8C1BF"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default LogoutIcon;
