import React from "react";
import { useTranslation } from "react-i18next";

import StatusTooltipIcon from "./StatusTooltipIcon";

const GameToolTipTitle = ({
  gameStatus,
  tutorialStatus,
  practiceStatus,
  assessmentStatus,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <h4 className="text-capitalize">{t("attributes.status")}</h4>
      <hr className="mt-0 mb-1" />
      <ul className="list-unstyled m-0">
        <li className="d-flex align-items-center justify-content-between">
          {t("gameType.tutorials")}
          <StatusTooltipIcon
            gameStatus={gameStatus}
            iconStatus={tutorialStatus}
          />
        </li>
        <li className="d-flex align-items-center justify-content-between">
          {t("gameType.practice")}
          <StatusTooltipIcon
            gameStatus={gameStatus}
            iconStatus={practiceStatus}
          />
        </li>
        <li className="d-flex align-items-center justify-content-between">
          {t("gameType.assessment")}
          <StatusTooltipIcon
            gameStatus={gameStatus}
            iconStatus={assessmentStatus}
          />
        </li>
      </ul>
    </>
  );
};

export default GameToolTipTitle;
