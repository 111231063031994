export const PERFORMANCE_PERCENTAGE = {
  TOTAL_PERCENTAGE: 100,
  STANDOUT_PERCENTAGE: 65.5,
  WATCHOUT_PERCENTAGE: 33.5,
};

export const TEST_NAME = {
  BASIC_COMPUTER_SKILLS: "Basic Computer Skills",
  BUSINESS_WRITING: "Business Writing",
  COMMUNICATION_SKILLS: "Communication Skills",
  MCCS_CHAT_CUSTOMER_SERVICE: "Multi-Channel Customer Support Chat Service",
  MCCS_CHAT_SALES: "Multi-Channel Customer Support Chat Sales",
  MCCS_CHAT_TECHNICAL_SUPPORT: "Multi-Channel Customer Support Chat Support",
  MCCS_VOICE_COLLECTION: "Multi-Channel Customer Support Voice Collections",
  MCCS_VOICE_CUSTOMER_SERVICE: "Multi-Channel Customer Support Voice Service",
  MCCS_VOICE_SALES: "Multi-Channel Customer Support Voice Sales",
  MCCS_VOICE_TECHNICAL_SUPPORT: "Multi-Channel Customer Support Voice Support",
  OVERCOMING_ADVERSITY_SKILLS: "Overcoming Adversity Skills",
  TEN_KEY: "Ten Key",
  TYPING_TEST: "Typing Test",
};
