import React from "react";
import { useTranslation } from "react-i18next";

const CandidateDetail = ({ applicantData, id }) => {
  const { t } = useTranslation();
  const companyName = applicantData?.company_name;
  const jobTitle = applicantData?.job_label;

  return (
    <div className="position-relative">
      <div className="container max-w-1970">
        <div className="d-flex justify-content-between mb-5" id={id}>
          <div className="candidate-detail-container">
            <h1 className="applicant-detail mb-3">
              {t("applicantReport.candidateDetail.your")}{" "}
              <span>
                {t("applicantReport.candidateDetail.competitiveness")}
              </span>{" "}
              {t("applicantReport.candidateDetail.andPerfessional")}
            </h1>
            <p className="summary-detail">
              {t("applicantReport.candidateDetail.ourGoalIsFor")}
              <br />
              <span>
                {t("applicantReport.candidateDetail.theseServicesAre")}
              </span>
            </p>
            <h3 className="mt-5">
              {t("applicantReport.candidateDetail.summaryReportFor")}{" "}
              <span className="company-name">{companyName}’s</span> {jobTitle}{" "}
              {t("applicantReport.candidateDetail.job")}.
            </h3>
          </div>
        </div>
      </div>
      <div className=" logo-img py-5 px-4 text-end position-absolute"></div>
    </div>
  );
};

export default CandidateDetail;
