import React, { useContext } from "react";

import { Spinner } from "components/Shared/Spinner";

import { AuthContext } from "contextAPI/AuthContext";

import SelectJob from "pages/Job/SelectJob";

const Jobs = () => {
  const { userInfo } = useContext(AuthContext);

  if (!userInfo?.data) {
    return <Spinner />;
  }

  return <SelectJob />;
};

export default Jobs;
