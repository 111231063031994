import React from "react";

import { CompletedCheckBox, SpinnerIcon, AlertIcon } from "assets/icons/icons";

const StatusTooltipIcon = ({ gameStatus, iconStatus }) => {
  if (
    gameStatus === "not_started" ||
    iconStatus === false ||
    iconStatus === null
  ) {
    return <AlertIcon className="color-icon" width="12px" height="12px" />;
  }

  if (iconStatus === true) {
    return (
      <CompletedCheckBox
        className="color-icon primary"
        width="12px"
        height="12px"
      />
    );
  }

  return (
    <SpinnerIcon
      className="color-icon color-progress"
      width="12px"
      height="12px"
    />
  );
};

export default StatusTooltipIcon;
