import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { EyeIcon, EyeOnIcon, MailIcon } from "assets/icons/icons";

import { useTranslation } from "react-i18next";

import { TOP_LEFT_PATTERN, BOTTOM_RIGHT_PATTERN } from "constants/images";
import { AUTH, JOBS } from "constants/routes";

import { LayoutContext } from "contextAPI/LayoutContext";
import { AuthContext } from "contextAPI/AuthContext";

import { Spinner } from "components/Shared/Spinner";

import useAcceptInvitation from "hooks/useAcceptInvitation";

const inputIsValid = (value) => {
  if (value.trim().length === 0) return false;

  return true;
};

const SignIn = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const {
    handleLogin,
    authError,
    setError,
    isLoggedIn,
    isLoadingUserInfo,
    getBranding,
    companyLogos,
  } = useContext(AuthContext);

  const { applyBrandingToApp } = useContext(LayoutContext);

  const [value, setValue] = useState({
    showPassword: false,
  });

  const location = useLocation();
  const invitationId = location?.state?.invitationId;

  const [email, setEmail] = useState(
    location?.state?.email ? location?.state?.email : ""
  );
  const [password, setPassword] = useState("");

  const [message, setMessage] = useState("");
  const [isShowing, setIsShowing] = useState(false);

  const [emailHasError, setEmailHasError] = useState(false);
  const [passwordHasError, setPasswordHasError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const { handleAcceptInvitations } = useAcceptInvitation();

  const handleShowPassword = () => {
    setValue({ ...value, showPassword: !value.showPassword });
    if (isShowing === false) {
      setIsShowing(true);
    } else {
      setIsShowing(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!inputIsValid(email)) {
      setEmailHasError(true);
    }

    if (!inputIsValid(password)) {
      setPasswordHasError(true);
    }

    if (!inputIsValid(email) || !inputIsValid(password)) {
      return;
    }

    setIsLoading(true);
    await handleLogin(email, password);

    if (invitationId) {
      setIsLoading(true);

      await handleAcceptInvitations(invitationId);

      return null;
    }

    setIsLoading(false);
  };

  const handleCaps = (e) => {
    if (e.getModifierState("CapsLock")) {
      setMessage(`Caps lock is on`);
    } else {
      setMessage("");
    }
  };

  useEffect(async () => {
    const branding = await getBranding();

    applyBrandingToApp(branding?.data?.company?.branding_config);
  }, []);

  if (isLoggedIn && !isLoadingUserInfo && !invitationId && !authError) {
    navigate(JOBS.INDEX);

    return null;
  }

  if (isLoading && !authError)
    return (
      <div className={isLoading && !authError && "overflow-hidden"}>
        {isLoading && !authError && (
          <div
            className={`position-absolute w-100 h-100 theme-customizer bg-dark`}
          >
            <Spinner />
          </div>
        )}
      </div>
    );

  return (
    <div className="container-fluid d-flex min-vh-100 w-100 justify-content-center align-items-center sign-in">
      <div className="col-12 my-5">
        <div className="position-absolute col-5 col-sm-4 col-lg-auto top-0 start-0">
          <img
            className="mw-100"
            src={TOP_LEFT_PATTERN.SOURCE}
            alt="circle pattern"
          />
        </div>
        <div className="position-absolute col-5 col-sm-4 col-lg-auto bottom-0 end-0">
          <img
            className="mw-100"
            src={BOTTOM_RIGHT_PATTERN.SOURCE}
            alt="circle pattern"
          />
        </div>
        <h1 className="text-center mb-5">
          <img
            className="mw-100"
            src={companyLogos?.[0]?.image_url}
            alt="logo"
            title="intelliante logo"
          />
        </h1>
        <div className="row justify-content-center">
          <div className="col-lg-5 col-xl-4 col-sm-8 col-11">
            <div className="login-form mt-4 m-0">
              <div className="form-container">
                <form className="form form-floating" onSubmit={handleSubmit}>
                  <h2 className="mb-4">{t("label.label.signIn")}</h2>
                  {authError && (
                    <div className="col-12 mb-3">
                      <div className="text-danger">
                        {`${authError.message[0]}${authError.message[1]}.`}
                      </div>
                    </div>
                  )}
                  <div className="col-12 email-input">
                    <div className="input-field form-floating">
                      <input
                        id="floatingInput"
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Work Email Address"
                        value={email}
                        onChange={(e) => {
                          setEmailHasError(!inputIsValid(e.target.value));
                          setEmail(e.target.value);
                        }}
                      />
                      <label htmlFor="floatingInput">
                        {t("label.label.workEmail")}
                      </label>
                      <figure className="icon">
                        <MailIcon className="color-icon" />
                      </figure>
                    </div>
                    {emailHasError && (
                      <p className="error-msg mt-1">
                        {t("message.auth.emailInvalidMessage")}
                      </p>
                    )}
                  </div>
                  <div className="col-12 mt-4 password-input">
                    <div className="input-field form-floating">
                      <input
                        id="floatingInput"
                        type={value.showPassword ? "text" : "password"}
                        name="password"
                        className={"form-control"}
                        placeholder="Password"
                        value={password}
                        onChange={(e) => {
                          setPasswordHasError(!inputIsValid(e.target.value));
                          setPassword(e.target.value);
                        }}
                        onKeyUp={handleCaps}
                        onClick={handleCaps}
                      />
                      <label htmlFor="floatingInput">
                        {t("label.label.password")}
                      </label>
                      <span className="icon">
                        {isShowing ? (
                          <button
                            className="interactive-click"
                            type="button"
                            onClick={handleShowPassword}
                            onKeyDown={handleShowPassword}
                          >
                            <EyeOnIcon className="color-icon" />
                          </button>
                        ) : (
                          <button
                            className="interactive-click"
                            type="button"
                            onClick={handleShowPassword}
                            onKeyDown={handleShowPassword}
                          >
                            <EyeIcon className="color-icon" />
                          </button>
                        )}
                      </span>
                      <span className="text-secondary small">{message}</span>
                    </div>
                    {passwordHasError && (
                      <p className="error-msg mt-1">
                        {t("message.auth.passwordInvalidMessage")}
                      </p>
                    )}
                  </div>
                  <div className="col-12 mt-3">
                    <Link
                      to={AUTH.FORGOT_PASSWORD}
                      className="btn-link text-secondary"
                    >
                      {t("label.label.forgotPassword")}
                    </Link>
                  </div>
                  <div className="col-12 mt-3">
                    <button
                      type="submit"
                      className="btn w-100 btn-primary text-uppercase"
                    >
                      {t("label.label.signIn")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
