import { useContext, useEffect, useRef, useState } from "react";
import { ReactTrixRTEInput, ReactTrixRTEToolbar } from "react-trix-rte";
import Trix from "trix";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

const TrixEditor = ({ onChange, name, templateName, onBlur, value }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    if (value) {
      editorRef.current.value = value;
    }
  }, []);

  useEffect(() => {
    Trix.config.textAttributes.underline = {
      style: { textDecoration: "underline" },
      inheritable: true,
      parser: function (element) {
        var style = window.getComputedStyle(element);

        return style.textDecoration === "underline";
      },
    };

    Trix.config.textAttributes.textcenter = {
      style: { "text-align": "center" },
      inheritable: true,
      parser: function (element) {
        var style = window.getComputedStyle(element);

        return style.textAlign === "center";
      },
    };

    // adding the underline button
    const button = document.createElement("button");

    button.type = "button";
    button.innerText = "U";
    button.setAttribute("data-trix-attribute", "underline");
    button.setAttribute(
      "class",
      "trix-button trix-button--icon trix-button--icon-underline"
    );

    const mainDiv = document.getElementsByClassName(
      "trix-button--icon-bullet-list "
    )[0];

    // adding the text-center button
    const buttonCenter = document.createElement("button");

    buttonCenter.type = "button";
    buttonCenter.innerText = "c";
    buttonCenter.setAttribute("data-trix-attribute", "justify-center");
    buttonCenter.setAttribute(
      "class",
      "trix-button trix-button--icon trix-button--icon-justify-center"
    );

    const main = document.getElementsByClassName(
      "trix-button--icon-increase-nesting-level"
    )[0];

    if (main) {
      if (mainDiv.previousElementSibling.innerText !== "U") {
        mainDiv.parentNode.insertBefore(button, mainDiv);
      }
    }
  }, []);

  const handleChange = (e) => {
    onChange?.(e);
  };

  return (
    <>
      <ReactTrixRTEToolbar
        toolbarId="react-trix-rte-editor"
        disableGroupingAction={true}
        toolbarActions={["bold", "italic", "bullet", "outdent", "indent"]}
      />
      <ReactTrixRTEInput
        // {...input}
        data-template={templateName}
        trixInputRef={editorRef}
        name={name}
        className={`input px-3 border-top-0`}
        toolbarId="react-trix-rte-editor"
        onChange={handleChange}
        onBlur={onBlur}
        defaultValue={value}
      />
    </>
  );
};

export default TrixEditor;
