import React from "react";

const HelperText = ({ enabled, helpText, language, type }) => {
  const styleHelper = (style) => {
    const custom = Object.create(style);

    // custom["color"] = color?.[mode]?.secondaryLabel;

    return custom;
  };

  return (
    <>
      {enabled ? (
        <div
          id={`${type}Help`}
          className={`form-text label-text text-truncate col-12 text-info opacity-50 ${helpText.className} ${helpText.customClass}`}
          style={styleHelper(helpText.style)}
        >
          {helpText?.locales?.[language]}
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default HelperText;
