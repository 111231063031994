import { t } from "i18next";

export const BLOCK_NAME = {
  WATCH_OUT: "watch-out",
  MOST_APPLICANT: "most-applicant",
  STAND_OUT: "stand-out",
};

const TalentPoolBlock = ({ blockName }) => {
  const title =
    blockName === BLOCK_NAME.WATCH_OUT
      ? t("applicantReport.watchOut")
      : blockName === BLOCK_NAME.MOST_APPLICANT
      ? t("applicantReport.mostApplicants")
      : t("applicantReport.standOut");

  const description =
    blockName === BLOCK_NAME.WATCH_OUT ||
    blockName === BLOCK_NAME.MOST_APPLICANT
      ? t("applicantReport.talentPool.skillsThatDont")
      : t("applicantReport.talentPool.standOutSkills");

  return (
    <div
      className={`talent-pool-block ${blockName} py-2 px-3 d-flex flex-column align-items-center justify-content-center`}
    >
      <p className="talent-pool-block-title text-center">{title}</p>
      <p className="talent-pool-block-description text-center mt-2">
        {description}
      </p>
    </div>
  );
};

export default TalentPoolBlock;
