import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useLayoutEffect,
} from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import LanguageDropDown from "components/DropDown/LanguageDropDown";

import UserProfile from "components/UserProfile/UserProfile";

import BackgroundPattern from "components/BackgroundPattern/BackgroundPattern";

import { NotifcationIcon, HamBuggerIcon, CloseIcon } from "assets/icons/icons";

import { useWindowDimensions } from "utils/useWindowDimension";

import { OnBoardingContext } from "contextAPI/onBoardingContext";
import { DashboardContext } from "contextAPI/dashboardContext";
import { UserContext } from "contextAPI/UserContext";

import CompanyImage from "components/Job/CompanyImage";

import { DesignationIcon,} from "constants/icons/icon";

import { DASHBOARD_ROUTE } from "constants/routes";

import { AuthContext } from "contextAPI/AuthContext";

import { lOGO_LIST_HEIGHT } from "constants/constant";

const Header = ({ languageList, setSwitchJob }) => {
  const { t } = useTranslation();
  const { companyLogos } = useContext(AuthContext);

  const { language, setLanguage, mode, setMode, color } =
    useContext(OnBoardingContext);
  const { setSideBarToggle, setMobileToggle, mobileToggle, sidebarToggle } =
    useContext(DashboardContext);
  const { candidateInfo } = useContext(UserContext);

  const [theme, setTheme] = useState(mode == "dark");

  const { width } = useWindowDimensions();

  const handleChangeActiveElement = (lang) => {
    setLanguage(lang);
  };

  const modeHandler = () => {
    if (theme) {
      setMode("light");
      setTheme(false);
    } else {
      setMode("dark");
      setTheme(true);
    }
  };

  const toggleHandler = () => {
    setSideBarToggle((prevState) => !prevState);
  };

  const spacerRef = useRef();
  const headerRef = useRef();

  useLayoutEffect(() => {
    if (spacerRef.current && headerRef.current) {
      spacerRef.current.style.height = `${headerRef.current.offsetHeight}px`;
    }
  }, []);

  return (
    <>
      <header
        className="header-container  position-fixed w-100 bg-dark "
        ref={headerRef}
      >
        <div className="max-w-1970 mx-auto container">
          {mobileToggle ? (
            <div
              type="button"
              role="button"
              className="overlay"
              tabIndex={0}
              onClick={toggleHandler}
              onKeyPress={(e) => {
                if (e.code == "Enter") toggleHandler();
              }}
            />
          ) : (
            ""
          )}
          <div className="d-flex justify-content-between align-items-center w-100 py-1">
            <div className="d-flex align-items-center">
              <div
                role="button"
                tabIndex={0}
                onClick={toggleHandler}
                onKeyPress={(e) => {
                  if (e.key == "Enter") toggleHandler();
                }}
                className="logo-container"
              >
                {sidebarToggle ? (
                  <CloseIcon className="color-icon" />
                ) : (
                  <HamBuggerIcon className="color-icon" />
                )}
              </div>

              <div className="ms-4 d-flex align-items-center">
                <Link to={DASHBOARD_ROUTE.INDEX}>
                  <CompanyImage
                    logos={companyLogos}
                    height={lOGO_LIST_HEIGHT}
                  />
                </Link>

                <div className="job-title-header d-flex align-items-center ps-3">
                  <DesignationIcon className="me-2 color-icon" />
                  <span className="text-white fw-semibold">
                    {candidateInfo?.job?.label}
                  </span>
                </div>
              </div>
            </div>

            <div className="d-flex">
              <UserProfile
                onChange={modeHandler}
                value={theme}
                setSwitchJob={setSwitchJob}
              />
            </div>
          </div>
          <BackgroundPattern color={color.primaryColor} mobile />
        </div>
      </header>
      <div id="spacer" ref={spacerRef} />
    </>
  );
};

export default Header;
