import React, { useState, useEffect } from "react";
import { t } from "i18next";

import PerformanceLayout from "components/Report/HelperComponents/PerformanceLayout";

import PerformanceListWithTitle from "components/Report/HelperComponents/PerformanceListWithTitle";

import Subtitle from "components/Report/HelperComponents/Subtitle";
import TitleAlt from "components/Report/HelperComponents/TitleAlt";
import Result from "components/Report/HelperComponents/Result";
import { TEST_NAME } from "constants/applicantReport";
import LineBreaker from "components/Report/HelperComponents/LineBreaker";

const BusinessWritingSkills = ({ assessments }) => {
  const [standOutList, setStandOutList] = useState([]);
  const [watchOutList, setWatchOutList] = useState([]);
  const [suggestedStatus, setSuggestedStatus] = useState("Stand Out");

  const businessWritingSkillsData = assessments.find(
    (assessment) => assessment?.name === TEST_NAME.BUSINESS_WRITING
  );

  const scaleData = [
    {
      title: t("applicantReport.businessesWritinScale.collocation"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status
          .collocation,
      description: t("applicantReport.businessesWritinScale.whenAWordGoes"),
    },
    {
      title: t("applicantReport.businessesWritinScale.confusedWords"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status
          .confused_words,
      description: t("applicantReport.businessesWritinScale.theErrorOccurs"),
    },
    {
      title: t("applicantReport.businessesWritinScale.compounding"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status
          .compounding,
      description: t("applicantReport.businessesWritinScale.aCompoundingWord"),
    },
    {
      title: t("applicantReport.businessesWritinScale.grammer"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status.grammer,
      description: t("applicantReport.businessesWritinScale.clarityRefersTo"),
    },
    {
      title: t("applicantReport.businessesWritinScale.typos"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status.typos,
      description: t("applicantReport.businessesWritinScale.thisTypeOf"),
    },
    {
      title: t("applicantReport.businessesWritinScale.punctuation"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status
          .punctuation,
      description: t("applicantReport.businessesWritinScale.punctuationMarks"),
    },
    {
      title: t("applicantReport.businessesWritinScale.redundancy"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status.redundancy,
      description: t("applicantReport.businessesWritinScale.thisErrorThat"),
    },
    {
      title: t("applicantReport.businessesWritinScale.semantics"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status.semantics,
      description: t("applicantReport.businessesWritinScale.anErrorthat"),
    },
    {
      title: t("applicantReport.businessesWritinScale.styles"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status.style,
      description: t(
        "applicantReport.businessesWritinScale.stylisticErrorsOccur"
      ),
    },
    {
      title: t("applicantReport.businessesWritinScale.textAnalysis"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status
          .text_analysis,
      description: t(
        "applicantReport.businessesWritinScale.readabilityDescribeHow"
      ),
    },
    {
      title: t("applicantReport.businessesWritinScale.plainEnglish"),
      status:
        businessWritingSkillsData?.score?.subscales_suggested_status
          .plain_english,
      description: t("applicantReport.businessesWritinScale.clarityRefersTo"),
    },
  ];

  useEffect(() => {
    const standOutData = scaleData.filter(
      (data) => data?.status === "stand_out"
    );
    const watchOutData = scaleData.filter(
      (data) => data?.status === "watch_out"
    );

    setStandOutList(standOutData);
    setWatchOutList(watchOutData);
  }, [businessWritingSkillsData]);

  useEffect(() => {
    const isStatusMostApplicant =
      businessWritingSkillsData?.score?.suggested_status === "most_applicants";
    const isStatusWatchOut =
      businessWritingSkillsData?.score?.suggested_status === "watch_out";

    if (isStatusMostApplicant) {
      setSuggestedStatus("Most Applicants");
    } else if (isStatusWatchOut) {
      setSuggestedStatus("Watch Out");
    }
  }, [businessWritingSkillsData]);

  if (businessWritingSkillsData?.status === "incomplete") {
    return null;
  }

  return (
    <>
      <LineBreaker />
      <div className="mt-5">
        <TitleAlt>{t("applicantReport.businessesWritinSkills")}</TitleAlt>
        <Subtitle>{t("applicantReport.measuresAperson")}</Subtitle>
        <Result suggestedStatus={suggestedStatus}>{suggestedStatus}</Result>
        <div className="d-flex gap-4 mt-4">
          <PerformanceLayout title={t("applicantReport.standOut")}>
            <PerformanceListWithTitle listData={standOutList} />
          </PerformanceLayout>
          <PerformanceLayout title={t("applicantReport.watchOut")}>
            <PerformanceListWithTitle listData={watchOutList} />
          </PerformanceLayout>
        </div>
      </div>
    </>
  );
};

export default BusinessWritingSkills;
