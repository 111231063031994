import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import "bootstrap/js/src/dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { BRAND_NAME } from "constants/icons/icon";
import HelperText from "components/HelperText/HelperText";
import ErrorText from "components/ErrorText/ErrorText";
import { OnBoardingContext } from "contextAPI/onBoardingContext";

import { composeValidation, VALIDATION_ON } from "utils/validation";
import IconContainer from "components/IconContainer";

const DropDown = ({
  label,
  options,
  language,
  defaultValue,
  htmlFor,
  color,
  mode,
  validations,
  handleUpdatedValue,
  helpText,
  style,
  icon,
  onChange,
  name,
  error,
  setError,
  templateName,
}) => {
  const { setDefaultValue } = useContext(OnBoardingContext);

  const [active, setActive] = useState("");
  const [updatedValue, setUpdatedValue] = useState("");
  const [selected, setSelected] = useState(false);
  const optionSelectionRef = useRef(null);

  useEffect(() => {
    setActive(defaultValue);
    setSelected(false);
  }, [defaultValue]);

  const handleChangeActiveElement = (label, value) => {
    if (active !== label) {
      setActive(label);
      setUpdatedValue(value);
      handleUpdatedValue(value, null);
    } else {
      setActive("");
    }
  };

  const handleOnClick = () => {
    clearTimeout(optionSelectionRef.current);
    setSelected(true);
  };

  useEffect(() => {
    if (defaultValue) {
      setActive(defaultValue);
      setDefaultValue(defaultValue);
    }
  }, [defaultValue, style]);

  const styleHelper = (style) => {
    let custom = { ...style };

    if (custom?.height) {
      custom["height"] = custom.height + "px";
    }

    return custom;
  };

  const onBlur = () => {
    optionSelectionRef.current = setTimeout(() => {
      const message = composeValidation(
        validations,
        VALIDATION_ON.BLUR,
        language,
        updatedValue
      );

      handleUpdatedValue(updatedValue, message);

      setError(message);
    }, 300);
  };

  const handleOptionSelection = (e, item) => {
    e.preventDefault();
    handleChangeActiveElement(item.label.locales[language], item.value);
  };

  const iconStyle = (iconStyle) => {
    const customStyle = { ...iconStyle };

    if (style?.height) {
      customStyle.bottom = 0.584 + Math.abs(Number(style.height) - 45) + "px";
    }

    return customStyle;
  };

  useEffect(() => {
    if (updatedValue) {
      clearTimeout(optionSelectionRef.current);
      onChange(updatedValue);
    }
  }, [updatedValue]);

  const activeClassName = active ? "dropdown-label" : "bg-body custom-text";
  const isClassNameEnabled = icon?.enabled ? "icon-added" : "";
  const dropdownUpdatedValue = defaultValue
    ? defaultValue
    : active == ""
    ? ""
    : active;

  return (
    <>
      <div className="dropdown p-0">
        <label
          className={`form-check-label position-absolute ${activeClassName} ${isClassNameEnabled}`}
          htmlFor={htmlFor}
        >
          {label}
        </label>
        <IconContainer
          icon={icon?.url}
          className={`base-position mx-2 ${icon?.position} ${icon?.className}`}
          style={iconStyle(icon?.style)}
        />
        <button
          className={` dropdown-toggle d-flex align-items-center w-100 text-truncate position-relative form-control ${isClassNameEnabled}`}
          type="button"
          id="dropdownMenuButton1"
          data-bs-toggle="dropdown"
          aria-expanded="false"
          onClick={handleOnClick}
          style={styleHelper(style)}
          onBlur={onBlur}
          name={name}
          data-template={templateName}
          data-field-type="single-dropdown"
        >
          {dropdownUpdatedValue}
        </button>
        <ul
          className="dropdown-menu bg-dark w-100 py-1 "
          aria-labelledby="dropdownMenuButton1"
        >
          {options.map((item, index) => (
            <li className="px-3" key={index}>
              <Link
                to={{
                  hash: "#",
                }}
                role="button"
                onClick={(e) => handleOptionSelection(e, item)}
                onKeyDown={(e) => handleOptionSelection(e, item)}
              >
                <div className="dropdown-item mb-0 px-0 py-3 d-flex justify-content-between">
                  <p className="mb-0 text-wrap">
                    {item.label.locales[language]}
                  </p>
                  {active === item.label.locales[language] && (
                    <div className="rounded-circle">
                      <FontAwesomeIcon
                        icon={[BRAND_NAME, "correct-icon"]}
                        color={item?.activeColor}
                      />
                    </div>
                  )}
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <ErrorText error={error} name={name} />
      <HelperText
        enabled={helpText?.enabled && selected && active == ""}
        helpText={helpText}
        language={language}
        type="help"
        mode={mode}
        color={color}
      />
    </>
  );
};

export default DropDown;
