import { useContext } from "react";
import { Field } from "react-final-form";

import { TextInput } from "components/Form/TextInput";
import { composeValidation, isRequired } from "components/Onboarding/utils";

import { onboardingContext } from "pages/Onboarding/OnBoarding";
import { VALIDATION_ON } from "utils/validation";

const PHONE_FIELDS = ["tel_number", "mobile_number"];

export const Input = ({
  name,
  id,
  icon,
  iconStyle,
  helpText,
  label,
  type,
  validations,
}) => {
  const { language } = useContext(onboardingContext);

  const [_, fieldName] = name.split(".");

  return (
    <>
      <Field
        name={name}
        validate={composeValidation(
          validations,
          VALIDATION_ON.CHANGE,
          language
        )}
      >
        {({ input, meta: { touched, error, submitError } }) => (
          <TextInput
            id={id}
            inputProps={input}
            icon={icon}
            iconStyle={iconStyle}
            name={name}
            helpText={helpText}
            error={touched && (error || submitError)}
            language={language}
            type={type}
            label={label}
            phoneInput={PHONE_FIELDS.includes(fieldName)}
            required={isRequired(validations)}
          />
        )}
      </Field>
    </>
  );
};
