import BaseRequest from "../BaseApi";

const URL = {
  INDEX: `candidates/:candidateId/candidate_games`,
};

const insertIdToUrl = (url, id) => {
  return url.replace(":candidateId", id);
};

class CandidateGamesApi {
  static get(id) {
    const GET_URL = insertIdToUrl(URL.INDEX, id);

    return BaseRequest.get(GET_URL);
  }

  static post(data) {
    return BaseRequest.post(URL.INDEX, data);
  }

  static update(id, data) {
    const UPDATE_URL = `${URL.INDEX}/${id}`;

    return BaseRequest.put(UPDATE_URL, data);
  }

  static delete(id) {
    const DELETE_URL = `${URL.INDEX}/${id}`;

    return BaseRequest.delete(DELETE_URL);
  }
}

export default CandidateGamesApi;
