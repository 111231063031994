import ErrorText from "components/ErrorText/ErrorText";

export const RadioGroupWrapper = ({
  id,
  label: labelProp,
  error,
  children,
  required,
}) => {
  const label = required ? `${labelProp}*` : labelProp;

  return (
    <div className="input-block">
      <div className="d-flex align-items-center mb-4">
        <strong
          className="form-check-label radio-label bg-transparent ps-0 "
          htmlFor={id}
        >
          {label}
        </strong>
        <div className="d-flex align-items-center ms-4">{children}</div>
      </div>
      <ErrorText error={error} />
    </div>
  );
};
