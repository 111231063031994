import ErrorAlert from "components/NotificationAlert/ErrorAlert";
import { Field, useForm } from "react-final-form";

export const ErrorToast = ({ name }) => {
  const form = useForm();

  const resetNotification = () => {
    form.resetFieldState(name);
  };

  return (
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? (
          <ErrorAlert show={error} resetNotification={resetNotification}>
            {error}
          </ErrorAlert>
        ) : null
      }
    />
  );
};
