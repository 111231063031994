import React from "react";

const RecoveryMailIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width="72"
        height="72"
        className={classNames.join(" ")}
        viewBox="0 0 72 72"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle opacity="0.3" cx="36" cy="36" r="36" fill="#A3CFBB" />
        <path
          d="M21.857 21.8572H50.1427C52.0874 21.8572 53.6784 23.4482 53.6784 25.3929V46.6072C53.6784 48.5518 52.0874 50.1429 50.1427 50.1429H21.857C19.9124 50.1429 18.3213 48.5518 18.3213 46.6072V25.3929C18.3213 23.4482 19.9124 21.8572 21.857 21.8572Z"
          stroke="#37B772"
          strokeWidth="3.21429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.6784 25.3928L35.9999 37.7678L18.3213 25.3928"
          stroke="#37B772"
          strokeWidth="3.21429"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default RecoveryMailIcon;
