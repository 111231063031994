import {
  TestListIcon,
  EmpathyIcon,
  TechSupportIcon,
  ComputerIcon,
  CSIcon,
  KeyTestIcon,
  ListeningIcon,
  SalesIcon,
  TypingIcon,
  AssessmentIcon,
} from "assets/icons/icons";

export const GAME_LIST = {
  COMPUTER_ICON: "ComputerIcon",
  CS_ICON: "CSIcon",
  KEY_TEST_ICON: "KeyTestIcon",
  LISTENING_ICON: "ListeningIcon",
  SALES_ICON: "SalesIcon",
  TYPING_ICON: "TypingIcon",
  ASSESSMENT_ICON: "AssessmentIcon",
  TECH_SUPPORT_ICON: "TechSupportIcon",
  EMPATHY_ICON: "EmpathyIcon",
  TEST_LIST_ICON: "TestListIcon",
};

const GameIcon = ({ className, name, width, height }) => {
  switch (name) {
    case GAME_LIST.COMPUTER_ICON:
      return (
        <ComputerIcon className={className} height={height} width={width} />
      );
    case GAME_LIST.CS_ICON:
      return <CSIcon className={className} height={height} width={width} />;
    case GAME_LIST.KEY_TEST_ICON:
      return (
        <KeyTestIcon className={className} height={height} width={width} />
      );
    case GAME_LIST.LISTENING_ICON:
      return (
        <ListeningIcon className={className} height={height} width={width} />
      );
    case GAME_LIST.SALES_ICON:
      return <SalesIcon className={className} height={height} width={width} />;
    case GAME_LIST.TYPING_ICON:
      return <TypingIcon className={className} height={height} width={width} />;
    case GAME_LIST.ASSESSMENT_ICON:
      return (
        <AssessmentIcon height={height} width={width} className={className} />
      );
    case GAME_LIST.TECH_SUPPORT_ICON:
      return (
        <TechSupportIcon height={height} width={width} className={className} />
      );
    case GAME_LIST.EMPATHY_ICON:
      return (
        <EmpathyIcon className={className} height={height} width={width} />
      );
    case GAME_LIST.TEST_LIST_ICON:
      return (
        <TestListIcon className={className} height={height} width={width} />
      );
    default:
      return (
        <TestListIcon className={className} height={height} width={width} />
      );
  }
};

export default GameIcon;
