import React from "react";

import DashboardLayout from "layout/DashboardLayout/DashboardLayout";
import ExploreList from "components/Dashboard/ExploreList";

export default function ExplorePage() {
  return (
    <DashboardLayout>
      <ExploreList />
    </DashboardLayout>
  );
}
