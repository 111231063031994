import React, { useEffect, useContext, useState } from "react";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

import EducationLists from "components/EducationList/EducationLists";
import EducationFields from "components/HistoryList/EducationFields";

const EducationForm = (props) => {
  const {
    isEducationList,
    isEducationFormShowing,
    educationData,
    setIsEducationList,
    setIsEducationFormShowing,
  } = useContext(OnBoardingContext);

  const [onSubmit, setSubmit] = useState(false);

  useEffect(() => {
    if (isEducationList && !isEducationFormShowing && !educationData?.length) {
      setIsEducationList(false);
      setIsEducationFormShowing(true);
    } else if (educationData?.length) {
      setIsEducationList(true);
    }
  }, [educationData.length]);

  const educationProps = { ...props, onSubmit, setSubmit };

  if (isEducationList) {
    return (
      <>
        <EducationFields {...educationProps} />
        <EducationLists {...props} />
      </>
    );
  }

  return <EducationFields {...educationProps} />;
};

export default EducationForm;
