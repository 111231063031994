import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Modal from "components/Shared/Modal";
import { IntIcon } from "components/Shared/Icon";

import { AuthContext } from "contextAPI/AuthContext";
import { SIGN_IN } from "constants/routes";

const SessionExpireModal = ({ isVisible, handleModalValue }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { handleLogout } = useContext(AuthContext);

  const handleModal = () => {
    handleModalValue();
    handleLogout();
    navigate(SIGN_IN.INDEX);
  };

  return (
    <Modal
      isVisible={isVisible}
      className="custom-modal session-modal d-flex justify-content-center flex-column align-items-center bg-section"
    >
      <div className="d-flex justify-content-center flex-column align-items-center p-2 z-2">
        <div className="session-timeout-icon mb-4 m-auto position-relative">
          <IntIcon
            className="sleeping-icon color-icon position-absolute fill mb-4"
            name="sessionTimeoutIcon"
          />

          <div className="m-auto icon-circle rounded-circle position-relative"></div>

          <div className="icon-dialog-box icon-dialog-box-1 position-absolute">
            <div className="menu-bar w-100 px-2">
              <div className="menu-icons d-flex align-items-center h-100">
                <span className="menu-icon rounded-circle"></span>
                <span className="menu-icon rounded-circle"></span>
                <span className="menu-icon rounded-circle"></span>
              </div>
            </div>
          </div>

          <div className="icon-dialog-box position-absolute"></div>
        </div>
        <h2 className="mb-3">{t("message.auth.sessionExpiryMessage")}</h2>
        <p className="mb-4">{t("message.auth.loginAgainMessage")}</p>
        <button
          type="button"
          className="p-4 btn shadow-none btn-primary d-flex align-items-center justify-content-center"
          onClick={handleModal}
        >
          {t("label.login")}
        </button>
      </div>
    </Modal>
  );
};

export default SessionExpireModal;
