import React from "react";
import CustomRadioField from "./CustomRadioField";

const CustomRadio = ({
  name,
  value,
  text,
  className,
  parentClassName,
  onChange,
  checked,
  custom,
  id,
  onBlur,
  templateName,
}) => {
  return (
    <label className={`${parentClassName} ps-0`}>
      <CustomRadioField
        name={name}
        value={value}
        className={className}
        onChange={onChange}
        checked={checked}
        custom={custom}
        id={id}
        onBlur={onBlur}
        templateName={templateName}
      />

      <span className="checkmark" />
      <span className="label-text fw-medium">{text}</span>
    </label>
  );
};

CustomRadio.defaultProps = {
  custom: false,
};

export default CustomRadio;
