import React, { useState } from "react";

import CustomComponent from "components/CustomComponent/CustomComponent";
import ButtonsEmployment from "components/LayoutComponent/EmploymentComponent/ButtonsEmployment";

import { EMPLOYMENT_DETAILS } from "constants/constant";

const EmploymentForm = ({
  data,
  language,
  stepValues,
  setStepValues,
  color,
  mode,
  setDetectError,
  setOnSubmit,
  templateName,
}) => {
  const [onSubmit, setSubmit] = useState(false);

  return (
    <div className="row gx-2 align-items-center gy-3 mb-4 mt-2">
      {data.components.map((item) => {
        if (item.label.locales[language] === EMPLOYMENT_DETAILS.no_experience)
          return null;

        return (
          <CustomComponent
            key={item.id}
            {...item}
            data={item}
            language={language}
            setStepValues={setStepValues}
            stepValues={stepValues}
            color={color}
            mode={mode}
            onSubmit={onSubmit}
            setDetectError={setDetectError}
            setOnSubmit={setOnSubmit}
            templateName={templateName}
          />
        );
      })}
      <ButtonsEmployment
        stepValues={stepValues}
        onSubmit={onSubmit}
        setSubmit={setSubmit}
      />
    </div>
  );
};

export default EmploymentForm;
