const buttons = {
  id: "140",
  type: "field",
  label: {
    enabled: false,
    locales: {
      en: "",
      es: "",
    },
    style: {},
    className: "col-12 group-label",
  },
  className: "flex-column-reverse flex-md-row",
  components: [
    {
      id: "141",
      type: "button",
      value: "skip",
      containerClass: "col-12 col-md-3",
      className: "back-btn",
      style: {
        background: "",
        fontSize: "",
      },
      label: {
        style: {},
        className: "",
        customClass: "",
        locales: {
          en: "Back",
          es: "Spanish Back",
        },
      },
      disableIf: {
        formInvalid: true,
      },
    },
    {
      id: "142",
      type: "button",
      value: "next",
      containerClass: "col-12 col-md-9",
      className: "btn-secondary",
      style: {
        background: "",
        fontSize: "",
      },
      customClass: "next-step",
      label: {
        enabled: true,
        style: {},
        className: "",
        customClass: "",
        locales: {
          en: "Next",
          es: "spanish Next",
        },
      },
      icon: {
        enabled: false,
        name: ["intelliante", "right-arrow-icon"],
        className: "fill color-icon text-secondary-default",
        style: {
          fontSize: "1.2em",
        },
        customClass: "",
        position: "left", // ["left", "middle", "right"]
      },
      disableIf: {
        formInvalid: true,
      },
    },
  ],
};

function capitalizeTxt(txt) {
  return txt.charAt(0).toUpperCase() + txt.slice(1); //or if you want lowercase the rest txt.slice(1).toLowerCase();
}

const humanize = (value) => {
  return value
    ?.split("_")
    ?.map((v) => capitalizeTxt(v))
    ?.join(" ");
};

export const formatOnboardingData = (data) => {
  if (!data) return [];

  const steps = [];

  const stepNames = [];

  data?.forEach((page) => {
    stepNames.push(page.title);
  });

  data.forEach((page, i) => {
    let obj = page;

    if (obj.components) obj.components = [...obj.components, buttons];
    else obj.components = [buttons];

    const metaData = {
      currentStage: {
        label: {
          enabled: true,
          locales: {
            en: humanize(page.title),
            es: humanize(page.title),
          },
        },
      },
      nextStage: stepNames[i + 1]
        ? {
            label: {
              enabled: true,
              locales: {
                en: humanize(stepNames[i + 1]),
                es: humanize(stepNames[i + 1]),
              },
            },
          }
        : null,
      prevStage: stepNames[i - 1]
        ? {
            label: {
              enabled: true,
              locales: {
                en: humanize(stepNames[i - 1]),
                es: humanize(stepNames[i - 1]),
              },
            },
          }
        : null,
      currentStep: i + 1,
      totalSteps: stepNames.length,
      cameraPermission: false,
    };

    obj.metaData = metaData;

    steps.push(obj);
  });

  // Object.keys(data).forEach((key, i) => {
  //   if (data[key].enabled) {
  //     enabledSteps.push(data[key]);
  //     stepNames.push(key);

  //
  //   }
  // });
  /*
  stepNames.forEach((key, i) => {
    if (data[key].enabled) {
      const obj = { ...data[key] };

      if (key == "computer_vision") {
        obj.components = identification.components;
      } else {
        if (obj.components) obj.components = [...obj.components, buttons];
        else obj.components = [buttons];
      }
      const metaData = {
        currentStage: {
          label: {
            enabled: true,
            locales: {
              en: humanize(key),
              es: humanize(key),
            },
          },
        },
        nextStage: stepNames[i + 1]
          ? {
              label: {
                enabled: true,
                locales: {
                  en: humanize(stepNames[i + 1]),
                  es: humanize(stepNames[i + 1]),
                },
              },
            }
          : null,
        prevStage: stepNames[i - 1]
          ? {
              label: {
                enabled: true,
                locales: {
                  en: humanize(stepNames[i - 1]),
                  es: humanize(stepNames[i - 1]),
                },
              },
            }
          : null,
        currentStep: i + 1,
        totalSteps: stepNames.length,
        cameraPermission: false,
      };

      obj.metaData = metaData;

      steps.push(obj);
    }
  });

  */

  return steps;
};
