import AuthenticatedApi from "api/AuthenticatedApi";

import { WithId } from "utils/helpers";

const URL = {
  INDEX: `/dynamic_forms/:dynamicFormId`,
  FIELD_UPDATE: `/dynamic_field_values`,
  FIELD_VALUE: `/candidates`,
  WORKFLOW: "applicants/job_applications/:job_application_id/workflow",
  WORKFLOW_DATA: "/companies/workflows/:id",
  STEPS:
    "applicants/job_applications/:job_application_id/steps/:step_position/complete",
};

class DynamicFormApi {
  static getFormValue(candidate_id) {
    const FETCH_VALUE = `${URL.FIELD_VALUE}/${candidate_id}/dynamic_field_values?page=0`;

    return AuthenticatedApi.get(FETCH_VALUE);
  }

  /**
   *
   * @param {int|string} dynamicFormId
   * @returns Promise
   */
  static get(dynamicFormId) {
    const FETCH_URL = URL.INDEX.replace(":dynamicFormId", dynamicFormId);

    return AuthenticatedApi.get(FETCH_URL);
  }

  static getDynamicFieldValue(dynamicFieldId) {
    const FETCH_URL = `${URL.FIELD_UPDATE}/${dynamicFieldId}`;

    return AuthenticatedApi.get(FETCH_URL);
  }

  static updateFieldValues(data) {
    const UPDATE_URL = URL.FIELD_UPDATE;

    const postData = {
      dynamic_field_values: data,
    };

    return AuthenticatedApi.post(UPDATE_URL, postData);
  }

  static getWorkflowValues(id) {
    return AuthenticatedApi.get(
      URL.WORKFLOW.replace(":job_application_id", id)
    );
  }

  static completeStep(id, step) {
    let url = URL.STEPS.replace(":job_application_id", id).replace(
      ":step_position",
      step
    );

    return AuthenticatedApi.patch(url);
  }

  static submitApplicantData(url, method = "PATCH", data, id) {
    let updatedUrl = url.replace("/api/v1", "");

    updatedUrl = updatedUrl.replace(":job_application_id", id);

    return AuthenticatedApi[method.toLowerCase()](updatedUrl, data);
  }

  static getWorkflow(id) {
    return AuthenticatedApi.get(WithId(URL.WORKFLOW_DATA, id));
  }
}

export default DynamicFormApi;
