import React from "react";

import Tooltip from "components/ToolTip/ToolTip";
import ProgressPercentage from "components/Progressbar/ProgressPercentage";
import GameIcon from "./GameIcon";

import {
  CompletedCheckBox,
  SpinnerIcon,
  AlertIcon,
  LockIcon,
} from "assets/icons/icons";
import GameToolTipTitle from "./GameToolTipTitle";

const STATUS = {
  COMPLETED: "completed",
  NOT_STARTED: "not_started",
};

const StatusIcons = ({ status, ...props }) => {
  if (status === STATUS.COMPLETED) return <CompletedCheckBox {...props} />;
  if (status === STATUS.NOT_STARTED) return <AlertIcon {...props} />;

  return <SpinnerIcon {...props} />;
};

const IndividualGame = ({
  gameName,
  gameIcon,
  gameLink,
  gameStatus,
  practiceStatus,
  assessmentStatus,
  tutorialStatus,
  isLock,
}) => {
  const checkLock = isLock && (
    <div className="lock-holder d-inline-block text-center position-absolute rounded-circle">
      <LockIcon className="color-icon" />
    </div>
  );

  const lockClassName = isLock ? "locked" : "";

  const gameIconClassName = gameStatus === "not_started" ? "" : "primary";

  const progressWidth =
    gameStatus === "not_started"
      ? "0"
      : gameStatus === "completed"
      ? "100"
      : "33";

  const statusContentClassName =
    gameStatus === "not_started"
      ? ""
      : gameStatus === "completed"
      ? "text-primary"
      : "btn-text";

  const addGameStatus =
    gameStatus === "not_started"
      ? "Not Started"
      : gameStatus === "completed"
      ? "Completed"
      : "In Progress";

  const iconStatusClassName =
    gameStatus === "not_started"
      ? ""
      : gameStatus === "completed"
      ? "primary"
      : "color-progress custom-spinner";

  return (
    <>
      <div className="col-12 col-sm-6 col-md-4 col-xl-3 pb-4">
        <article
          className={`pb-4 test-card bg-dark position-relative ${lockClassName}`}
        >
          <header className="position-relative">
            <p className="bg-body-500 px-3 position-relative">
              <GameIcon
                name={gameIcon}
                className={`color-icon position-absolute ${gameIconClassName}`}
              />
            </p>
            <h3 className="my-4 py-0 px-3 fs-6">{gameName}</h3>
            {checkLock}
          </header>
          <a
            className="position-absolute h-100 w-100 start-0 top-0"
            href={gameLink}
            target="_blank"
          ></a>
          <div className="test-content px-3">
            <span
              className={`status-holder mb-2 d-flex ${statusContentClassName} align-items-center`}
            >
              <StatusIcons
                status={gameStatus}
                className={`color-icon ${iconStatusClassName} me-2`}
              />{" "}
              {addGameStatus}
            </span>

            <Tooltip
              size="medium"
              title={
                <GameToolTipTitle
                  gameStatus={gameStatus}
                  tutorialStatus={tutorialStatus}
                  practiceStatus={practiceStatus}
                  assessmentStatus={assessmentStatus}
                />
              }
            >
              <ProgressPercentage progress={progressWidth} />
            </Tooltip>

            <p className="mt-2 small d-none">
              <span>Estimated Completion Time:</span>
              <strong className="ms-2">3 min</strong>
            </p>
          </div>
        </article>
      </div>
    </>
  );
};

export default IndividualGame;
