import axios from "axios";
import ApiUrlConfig from "utils/ApiUrlConfig";

// axios.defaults.baseURL = `${process.env.REACT_APP_API_BASE_URL}/api/v1/`;

axios.defaults.baseURL = `${ApiUrlConfig.endPoint()}/api/v1/`;

export const DEFAULT_HEADERS = {
  Accept: "application/json",
};
class BaseRequest {
  static headers() {
    return { headers: DEFAULT_HEADERS };
  }

  static get(url) {
    return axios.get(url, this.headers());
  }

  static post(url, data) {
    return axios.post(url, data, this.headers());
  }

  static patch(url, data) {
    return axios.patch(url, data, this.headers());
  }

  static put(url, data) {
    return axios.put(url, data, this.headers());
  }

  static delete(url) {
    return axios.delete(url, this.headers());
  }
}

export default BaseRequest;
