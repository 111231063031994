import React from "react";

import { Route, Routes, Outlet } from "react-router-dom";

import {
  NEW_PASSWORD,
  RESET_PASSWORD,
  SIGN_IN,
  SIGN_UP,
  VERIFICATION,
  WELCOME_PREVIEW,
  WORKFLOW_PREVIEW,
  WELCOME,
} from "constants/routes";

import WorkflowPreview from "pages/WorkflowPreview";
import Signin from "pages/Signin/Signin";
import PasswordReset from "pages/PasswordReset/PasswordReset";
import NewPasswords from "pages/PasswordReset/NewPasswords";
import Signup from "pages/Signup/Signup";
import Verify from "components/SignUp/Verify";
import WelcomePreview from "pages/WelcomePreview";
import Welcome from "pages/Welcome/Welcome";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path={WELCOME.INDEX} element={<Welcome />} />
      <Route path={SIGN_IN.INDEX} element={<Signin />} />
      <Route path={SIGN_UP.INDEX} element={<Signup />} />
      <Route path={RESET_PASSWORD.INDEX} element={<PasswordReset />} />
      <Route path={NEW_PASSWORD.INDEX} element={<NewPasswords />} />
      <Route path={VERIFICATION.INDEX} element={<Verify />} />
      <Route path={WORKFLOW_PREVIEW.INDEX} element={<WorkflowPreview />} />
      <Route path={WELCOME_PREVIEW.INDEX} element={<WelcomePreview />} />
    </Routes>
  );
};

export default PublicRoutes;
