import React from "react";

const TypingIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "70"}`}
        height={`${height ? height : "38"}`}
        className={classNames.join(" ")}
        viewBox="0 0 70 38"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="1"
          y="1"
          width="68"
          height="36"
          rx="3"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
        />
        <rect
          x="11.2905"
          y="7"
          width="7.90323"
          height="7"
          rx="3.5"
          fill="#B8C1BF"
          fillOpacity="0.6"
        />
        <rect
          x="20.3228"
          y="18"
          width="7.90323"
          height="7"
          rx="3.5"
          fill="#B8C1BF"
          fillOpacity="0.6"
        />
        <rect
          x="38.3872"
          y="7"
          width="7.90323"
          height="7"
          rx="3.5"
          fill="#B8C1BF"
          fillOpacity="0.6"
        />
        <rect
          x="47.4194"
          y="18"
          width="7.90323"
          height="7"
          rx="3.5"
          fill="#B8C1BF"
          fillOpacity="0.6"
        />
        <rect
          x="23.7095"
          y="7"
          width="7.90323"
          height="7"
          rx="3.5"
          fill="#B8C1BF"
          fillOpacity="0.6"
        />
        <rect
          x="32.7417"
          y="18"
          width="7.90323"
          height="7"
          rx="3.5"
          fill="#B8C1BF"
          fillOpacity="0.6"
        />
        <rect
          x="50.8066"
          y="7"
          width="7.90323"
          height="7"
          rx="3.5"
          fill="#B8C1BF"
          fillOpacity="0.6"
        />
        <rect
          x="16.9355"
          y="29"
          width="39.5161"
          height="2"
          rx="1"
          fill="#B8C1BF"
          fillOpacity="0.6"
        />
      </svg>
    </>
  );
};

export default TypingIcon;
