import React, { useState, useEffect, useContext } from "react";

import CandidateApi from "api/Candidate/CandidateApi";

import currentBrandingJson from "constants/brandingJson";
import { ELEMENT_TYPES } from "constants/constant";

import { getLayoutStyle } from "utils/getLayoutStyle";

import { UserContext } from "./UserContext";

export const OnBoardingContext = React.createContext();

const OnBoardingContextProvider = ({ children }) => {
  const { candidateInfo, setCandidateName, dynamicForm } =
    useContext(UserContext);

  const [mode, setMode] = useState("dark");
  const [language, setLanguage] = useState("en");
  const [color, setColor] = useState(currentBrandingJson.color);
  const [secondaryColor, secondaryChangeColor] = useState();
  const [list, setList] = useState([]);
  const [educationList, setEducationList] = useState([]);
  const [employmentList, setEmploymentList] = useState([]);
  const [formValues, setFormValues] = useState({});
  const [userName, setUserName] = useState({});
  const [stepQuery, setStepQuery] = useState(null);

  const [check, setCheck] = useState("no");
  const [defaultValue, setDefaultValue] = useState("");
  const [isNextPressed, setIsNextPressed] = useState(false);
  const [step, setStep] = useState(0);
  const [workflowData, setWorkflowData] = useState(null);

  // store all current form data
  const [formValue, setFormValue] = useState({});

  // currently enrolled employment
  const [currentlyEmployed, setCurrentlyEmployed] = useState(false);

  const [currentlyEnrolledEducation, setCurrentlyEnrolledEducation] =
    useState(true);

  // set submitted data
  const [submittedEducationData, setSubmittedEducationData] = useState([]);
  const [submittedEmploymentData, setSubmittedEmploymentData] = useState([]);

  const [editEducationId, setEditEducationId] = useState(null);
  const [editEducationData, setEditEducationData] = useState(null);
  const [educationFormData, setEducationFormData] = useState({});
  const [educationData, setEducationData] = useState([]);
  const [showAddButton, setShowAddButton] = useState(false);
  const [showEducationForm, setShowEducationForm] = useState(true);
  const [addAnotherEducationButton, setAnotherEducationButton] =
    useState(false);
  const [isEducationEditing, setIsEducationEditing] = useState(false);

  const [showEmploymentForm, setShowEmploymentForm] = useState(true);
  const [employmentFormData, setEmploymentFormData] = useState({});
  const [employmentData, setEmploymentData] = useState([]);
  const [addAnotherJobButton, setAnotherJobButton] = useState(false);
  const [editEmploymentId, setEditEmploymentId] = useState(null);
  const [editEmploymentData, setEditEmploymentData] = useState(null);
  const [employmentSaved, setEmploymentSaved] = useState(false);
  const [editorData, setEditorData] = useState(null);
  const [employmentFormCancelButton, setEmploymentFormCancelButton] =
    useState(false);

  const [errorMsg, setErrorMsg] = useState({});
  const [saved, setSaved] = useState(false);

  const [educationFormId, setEducationFormId] = useState("");
  const [isEducationList, setIsEducationList] = useState(false);

  const [isEducationFormShowing, setIsEducationFormShowing] = useState(false);
  const [showFormClass, setShowFormClass] = useState(false);

  const [currentlyEnrolled, setCurrentlyEnrolled] = useState(false);
  const [noWorkExperience, setNoWorkExperience] = useState(false);

  const [formFieldData, setFormFieldData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const [workflowCurrentPage, setWorkflowCurrentPage] = useState(0);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [linkFields, setLinkFields] = useState({});

  const fetchFormValues = () => {
    const dynamicId = dynamicForm?.id;

    CandidateApi.getDynamicFieldValues(dynamicId, currentPage)
      .then(({ data }) => {
        const defaultValues = {};
        const userInfo = {
          firstName: candidateInfo?.user?.first_name,
          lastName: candidateInfo?.user?.last_name,
        };

        data.forEach(({ value, dynamic_field }) => {
          defaultValues[dynamic_field.id] = value;
          if (
            dynamic_field?.field_key === "firstName" ||
            dynamic_field?.field_json?.name === "firstName"
          ) {
            userInfo.firstName = value;
          }
          if (
            dynamic_field?.field_key === "lastName" ||
            dynamic_field?.field_json?.name === "lastName"
          ) {
            userInfo.lastName = value;
          }
          if (
            dynamic_field.field_key === "selectedSkills" ||
            dynamic_field?.field_json?.name === "selectedSkills"
          ) {
            defaultValues[dynamic_field.id] = JSON.parse(value);
          }
        });

        setUserName(userInfo);
        setFormValues(defaultValues);
      })
      .catch((e) => console.log(e));
  };

  const handleListUpdate = (type, value) => {
    if (type === "education") {
      setEducationList((oldValue) => [...oldValue, value]);
    } else if (type === "employment") {
      setEmploymentList((oldValue) => [...oldValue, value]);
    }
  };

  const handleListRemove = (type, value) => {
    if (type === "education") {
      setEducationList((oldValue) =>
        oldValue.filter((v) => JSON.stringify(v) !== JSON.stringify(value))
      );
    } else if (type === "employment") {
      setEmploymentList((oldValues) =>
        oldValues.filter((v) => JSON.stringify(v) !== JSON.stringify(value))
      );
    }
  };

  useEffect(() => {
    getLayoutStyle(currentBrandingJson.color, mode);
  }, [mode]);

  useEffect(() => {
    setCurrentlyEmployed(formValue?.current_employer);
  }, [formValue]);

  // check data for education and employment

  useEffect(() => {
    workflowData?.map((item) => {
      item?.components?.map((component) => {
        if (component?.name == ELEMENT_TYPES.EDUCATION) {
          const educationList = component?.submitted_data?.data;
          let eduList = [];

          educationList.forEach((education) => {
            let educationLists = {};

            education?.map((it) => {
              educationLists[it?.key] = it?.value;
            });

            eduList.push(educationLists);
          });

          setEducationData(eduList);
          setSubmittedEducationData(eduList);
        } else if (component?.name == ELEMENT_TYPES.EMPLOYMENT) {
          const employmentList = component?.submitted_data?.data;
          let employments = [];

          employmentList.forEach((education) => {
            let employmentHistory = {};

            education?.map((it) => {
              employmentHistory[it?.key] = it?.value;
            });

            employments.push(employmentHistory);
          });

          setEmploymentData(employments);
          setSubmittedEmploymentData(employments);
        }
      });
    });
  }, [workflowData]);

  useEffect(() => {
    setStep(0)
  }, [candidateInfo, dynamicForm]);

  useEffect(() => {
    setCandidateName(userName);
  }, [userName]);

  return (
    <OnBoardingContext.Provider
      value={{
        mode,
        setMode,
        language,
        setLanguage,
        color,
        list,
        setList,
        formValues,
        setFormValues,
        educationFormData,
        setEducationFormData,
        educationList,
        employmentList,
        handleListUpdate,
        setColor,
        secondaryColor,
        secondaryChangeColor,
        handleListRemove,
        check,
        setCheck,
        defaultValue,
        setDefaultValue,
        educationData,
        setEducationData,
        currentlyEnrolled,
        setCurrentlyEnrolled,
        noWorkExperience,
        setNoWorkExperience,
        isEducationList,
        setIsEducationList,
        errorMsg,
        setErrorMsg,
        isEducationFormShowing,
        setIsEducationFormShowing,
        showFormClass,
        setShowFormClass,
        saved,
        setSaved,
        employmentData,
        setEmploymentData,
        employmentFormData,
        setEmploymentFormData,
        addAnotherJobButton,
        setAnotherJobButton,
        showEmploymentForm,
        setShowEmploymentForm,
        showAddButton,
        setShowAddButton,
        educationFormId,
        setEducationFormId,
        employmentSaved,
        setEmploymentSaved,
        editEmploymentId,
        setEditEmploymentId,
        editEmploymentData,
        setEditEmploymentData,
        editEducationId,
        setEditEducationId,
        editEducationData,
        setEditEducationData,
        editorData,
        setEditorData,
        employmentFormCancelButton,
        setEmploymentFormCancelButton,
        showEducationForm,
        setShowEducationForm,
        addAnotherEducationButton,
        setAnotherEducationButton,
        isEducationEditing,
        setIsEducationEditing,
        formFieldData,
        setFormFieldData,
        isNextPressed,
        setIsNextPressed,
        currentPage,
        setCurrentPage,
        step,
        setStep,
        workflowCurrentPage,
        setWorkflowCurrentPage,
        stepQuery,
        setStepQuery,
        workflowData,
        setWorkflowData,
        formValue,
        setFormValue,
        submittedEducationData,
        setSubmittedEducationData,
        submittedEmploymentData,
        setSubmittedEmploymentData,
        currentlyEmployed,
        setCurrentlyEmployed,
        isModalVisible,
        setIsModalVisible,
        currentlyEnrolledEducation,
        setCurrentlyEnrolledEducation,
        linkFields,
        setLinkFields,
      }}
    >
      {children}
    </OnBoardingContext.Provider>
  );
};

export default OnBoardingContextProvider;
