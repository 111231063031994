import DynamicFormApi from "api/DynamicForm/DynamicFormApi";
import { WORKFLOW_PAGE_TYPES } from "constants/constant";
import { validation } from "utils/validation";

export function toObject(array) {
  if (!array) return null;

  return array.reduce((acc, item) => {
    acc[item.key] = item.value;

    return acc;
  }, {});
}

export function handleBooleanString(str) {
  if (str && typeof str === "string") {
    if (str.toLowerCase() === "true") return true;
    if (str.toLowerCase() === "false") return false;
  }

  return str;
}

export function isRequired(validations) {
  return !!validations?.find((i) => i.name === "required" && i.enabled);
}

function getStep(app_state, bid) {
  return Object.keys(app_state).find((i) => app_state[i].id === bid);
}

export async function submitPageData(values, pageData, applicationState, id) {
  const { components } = pageData;

  const sections = components.filter(
    (i) =>
      i.type === WORKFLOW_PAGE_TYPES.SECTION ||
      i.type === WORKFLOW_PAGE_TYPES.DEMOGRAPHY
  );

  const promises = sections.map((section) => {
    const { name, submission, submitted_data } = section;

    if (!submission?.url) {
      return null;
    }

    const { url, method, payload_key: payloadKey } = submission;
    let payload = { form_step_id: pageData.b_id };

    if (name === "EmploymentListTemplate" || name === "EducationListTemplate") {
      const oldData = (submitted_data?.data || []).map((i) => toObject(i));

      if (payloadKey.split(".").length > 1) {
        let [key, subKey, ...rest] = payloadKey.split(".");

        payload[key] = {};

        if (values["no_work_experience"] && name === "EmploymentListTemplate") {
          payload[key]["no_work_experience"] = true;
          payload[key][subKey] = handleDeleteData(
            values[name]?.slice(0, -1),
            oldData
          );
        } else {
          payload[key][subKey] = handleDeleteData(
            values[name]?.slice(0, -1),
            oldData
          );
        }
      } else {
        payload[payloadKey] = handleDeleteData(
          values[name]?.slice(0, -1),
          oldData
        );
      }
    } else {
      payload[payloadKey] = values[name];
    }

    return DynamicFormApi.submitApplicantData(url, method, payload, id).catch(
      (e) => {
        throw {
          ...e,
          name,
        };
      }
    );
  });

  promises.push(
    DynamicFormApi.completeStep(id, getStep(applicationState, pageData.b_id))
  );

  return await Promise.all(promises);
}

function handleDeleteData(data, oldData) {
  const ids = data.map((i) => i.id);
  const dataToDelete = oldData
    .filter((i) => !ids.includes(i.id))
    .map((i) => ({ ...i, _destroy: true }));

  return [...data, ...dataToDelete];
}

export function composeValidation(validations, event, lang) {
  return (value = null, allValues = null, meta = null) => {
    let errorMessage = null;

    if (!validations) return null;

    for (let i = 0; i < validations.length; i++) {
      errorMessage = validation(
        validations[i],
        event,
        lang,
        value,
        allValues,
        meta
      );
      if (errorMessage) break;
    }

    return errorMessage;
  };
}

export function getInitialValues(workflow) {
  let initialValues = {};
  const sections = workflow.flatMap((i) =>
    i.components.filter(
      (j) =>
        j.type === WORKFLOW_PAGE_TYPES.SECTION ||
        j.type === WORKFLOW_PAGE_TYPES.DEMOGRAPHY
    )
  );

  sections.forEach((section) => {
    if (
      section.submitted_data?.multiple &&
      section.submitted_data?.data?.length > 0
    ) {
      initialValues[section.name] = section?.submitted_data?.data
        .map((i) => toObject(i))
        .map((i) => {
          if (i.currently_enrolled === true || i.current_employer === true) {
            delete i.end_month;
            delete i.end_year;
          }

          return i;
        });
      initialValues[section.name].push(undefined);
    } else {
      initialValues[section.name] = toObject(
        section?.submitted_data?.data?.[0]
      );
    }
  });

  if (!initialValues["EmploymentListTemplate"])
    initialValues["EmploymentListTemplate"] = [{ current_employer: true }];
  if (!initialValues["EducationListTemplate"])
    initialValues["EducationListTemplate"] = [{ currently_enrolled: true }];

  return initialValues;
}

export function getInitialPage(state) {
  if (!state) return 0;
  let page = Object.keys(state).find((i) => !state[i].completed);

  if (page) {
    if (isNaN(page)) {
      return 0;
    }

    if (Number(page) === Object.keys(state).length - 1) {
      return 0;
    }

    return Number(page);
  }

  return 0;
}

export function pick(keys, object) {
  return keys.reduce(
    (resultObj, key) => ({
      ...resultObj,
      [key]: object[key],
    }),
    {}
  );
}
