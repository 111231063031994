import React from "react";

const LockIcon = ({ className, color, width, height }) => {
  return (
    <>
      <svg
        width={width ? width : "23"}
        height={height ? height : "22"}
        viewBox="0 0 23 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M16.405 10.2666H6.59498C5.82099 10.2666 5.19354 10.8636 5.19354 11.5999V16.2666C5.19354 17.003 5.82099 17.5999 6.59498 17.5999H16.405C17.179 17.5999 17.8064 17.003 17.8064 16.2666V11.5999C17.8064 10.8636 17.179 10.2666 16.405 10.2666Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.16132 10.2666V7.65916C8.16132 6.79475 8.51307 5.96575 9.1392 5.35452C9.76533 4.74329 10.6145 4.3999 11.5 4.3999C12.3855 4.3999 13.2347 4.74329 13.8608 5.35452C14.487 5.96575 14.8387 6.79475 14.8387 7.65916V10.2666"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default LockIcon;
