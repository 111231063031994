import { onboardingContext } from "pages/Onboarding/OnBoarding";
import { useEffect, useRef } from "react";
import { useContext } from "react";
import { useField, useFormState } from "react-final-form";

import { useSectionContext } from "components/Onboarding/Section";
import { Checkbox } from "components/Onboarding/InputFields/Checkbox";
import { Editor } from "components/Onboarding/InputFields/Editor";
import { Input } from "components/Onboarding/InputFields/Input";
import { Radio } from "components/Onboarding/InputFields/Radio";
import { Select } from "components/Onboarding/InputFields/Select";
import { EDUCATION, ELEMENT_TYPES, EMPLOYMENT } from "constants/constant";

//FormInput component, renders different input fields based on type and also has the logic for hiding end date fields in education and employment sections
export const FormInput = ({ component, error = null }) => {
  const { name: sectionName } = useSectionContext();
  const {
    type,
    id,
    icon,
    name: fieldName,
    style,
    label,
    helpText,
    validations,
    options,
  } = component;

  const { values } = useFormState();
  const name = sectionName ? `${sectionName}.${fieldName}` : fieldName;

  const { language } = useContext(onboardingContext);

  const getIconStyle = (iconStyle) => {
    const customStyle = { ...iconStyle };

    if (style?.height) {
      customStyle.bottom = 0.584 + Math.abs(Number(style.height) - 45) + "px";
    }

    return customStyle;
  };

  const inputFieldID = `flexCheckDefault${type}${id}`;
  const labelText = label?.enabled ? label?.locales?.[language] : " ";

  //condition for hiding end date field in education section
  //TODO: move this logic to education section somehow
  if (sectionName === ELEMENT_TYPES.EDUCATION) {
    if (
      fieldName === EDUCATION.END_MONTH_EDUCATION ||
      fieldName === EDUCATION.END_YEAR_EDUCATION
    ) {
      if (
        values?.[ELEMENT_TYPES.EDUCATION]?.[EDUCATION.CURRENTLY_ENROLLED] ===
        true
      )
        return null;
    }
  }

  //condition for hiding end date field in employment section
  //TODO: move this logic to employment section somehow
  if (sectionName === ELEMENT_TYPES.EMPLOYMENT) {
    if (
      fieldName === EMPLOYMENT.END_MONTH ||
      fieldName === EMPLOYMENT.END_YEAR
    ) {
      if (
        values?.[ELEMENT_TYPES.EMPLOYMENT]?.[EMPLOYMENT.CURRENT_EMPLOYER] ===
        true
      )
        return null;
    }
  }

  if (!fieldName) return null;

  const renderInput = () => {
    switch (type) {
      case "radio":
        return (
          <Radio
            className={`${component?.className} ${component?.customClass}`}
            id={inputFieldID}
            label={labelText}
            error={error}
            name={name}
            options={component.options}
            validations={validations}
          />
        );
      case "checkbox":
        return (
          <Checkbox
            className={`${component?.className} ${component?.customClass}`}
            id={inputFieldID}
            label={labelText}
            error={error}
            name={name}
            validations={validations}
          />
        );
      case "button":
        return null;
      case "editor":
        return (
          <Editor
            name={name}
            label={labelText}
            icon={icon}
            error={error}
            iconStyle={getIconStyle(icon?.style)}
            helpText={helpText}
            type={type}
            validations={validations}
          />
        );
      case "select":
        if (!component.allowMultiple) {
          return (
            <Select
              label={labelText}
              options={component.options}
              name={name}
              validations={validations}
            />
          );
        }

        return null;
      default:
        return (
          <Input
            iconStyle={getIconStyle(icon?.style)}
            name={name}
            label={labelText}
            type={type}
            id={inputFieldID}
            icon={icon}
            error={error}
            helpText={helpText}
            validations={validations}
          />
        );
    }
  };

  return <div>{renderInput()}</div>;
};
