import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import ReactTooltip from "react-tooltip";

import DeleteModal from "components/Modals/DeleteModal";

import {
  DateIcon,
  DeleteIcon,
  DesignationIcon,
  EditIcon,
  EmployerIcon,
  LocationIcon,
  UserIcon,
} from "constants/icons/icon";

import { dateFormated } from "utils/getDate";
import { TOOLTIP_COLOR } from "constants/formType";

const EmploymentListComponent = ({
  data,
  t,
  setDeleteEmployment,
  idx,
  employmentData,
  setEmploymentData,
  deleteId,
  setDeleteId,
  deleteEmployment,
  editId,
  setEditId,
  setEditData,
  setShowEmploymentForm,
  setAnotherJobButton,
  setFormValues,
}) => {
  const {
    start_month: startMonthEmployment,
    start_year: startYearEmployment,
    end_month: endMonthEmployment,
    end_year: endYearEmployment,
    company_title: currentEmployer,
    job_title: currentJob,
    employment_type: employmentType,
  } = data;

  const startDateValue = startMonthEmployment + ", " + startYearEmployment;
  const endDateValue = endMonthEmployment + ", " + endYearEmployment;
  const startDateMonth = dateFormated(startDateValue, { month: "short" });
  const endDateMonth = dateFormated(endDateValue, { month: "short" });

  const displayStartDate = `${startDateMonth} ${startYearEmployment}`;
  const displayEndDate =
    endMonthEmployment && endYearEmployment !== undefined
      ? `${endDateMonth} ${endYearEmployment}`
      : t("attributes.present");

  const fullDate = `${displayStartDate} - ${displayEndDate}`;

  const DelEmployment = () => {
    setDeleteId(idx);
    setDeleteEmployment(true);
  };

  const DeleteConfirmEmployment = () => {
    const filterId = idx;
    const filteredData = employmentData?.filter((e) => e?.id !== filterId);

    setEmploymentData(filteredData);
    setDeleteEmployment(false);
  };

  const handleOnClickEdit = () => {
    const editData = employmentData?.filter((e) => e?.id == idx);

    setFormValues(editData);

    setEditData(editData);
    setEditId(idx);
    setShowEmploymentForm(true);
    setAnotherJobButton(false);
  };

  const editClassName = idx === editId ? "edit" : "";

  return (
    <div
      key={idx}
      className={`position-relative bg-dark d-flex align-items-start flex-wrap ${editClassName}`}
    >
      <ul className="list-unstyled d-grid px-2b py-3 flex-fill bg-body-500 m-0 position-relative">
        <li
          className="my-2 job-details-values overflow-hidden"
          data-tip={data.currentJob}
          data-border
          data-background-color={TOOLTIP_COLOR.BACKGROUND_DARK}
        >
          <DesignationIcon className="me-3 color-icon" />
          <span className="custom-heading-text">{currentJob}</span>
          <ReactTooltip />
        </li>
        <li
          className="my-2 location-values overflow-hidden"
          data-tip={currentEmployer}
          data-border
          data-scroll-hide
          data-background-color={TOOLTIP_COLOR.BACKGROUND_DARK}
        >
          <EmployerIcon className="me-3 color-icon fill" />
          <span className="custom-heading-text">{currentEmployer}</span>
          <ReactTooltip />
        </li>
        <li className="my-2">
          <DateIcon className="me-3 color-icon" />
          <span className="custom-heading-text">{fullDate}</span>
        </li>
        <li className="my-2">
          <UserIcon className="me-3 color-icon" />
          <span className="custom-heading-text">{employmentType}</span>
        </li>
        <li
          className="my-2 location-values overflow-hidden"
          data-tip={data.location}
          data-border
          data-background-color={TOOLTIP_COLOR.BACKGROUND_DARK}
        >
          <LocationIcon className="me-3 color-icon " />
          <span className="custom-heading-text">{data.location}</span>
          <ReactTooltip />
        </li>
      </ul>
      <div className="delete-holder position-absolute bottom-100 end-0">
        <DeleteModal
          idx={idx}
          deleteId={deleteId}
          del={deleteEmployment}
          setDel={setDeleteEmployment}
          deleteFunction={DeleteConfirmEmployment}
        />
      </div>
      <ActionButtons
        idx={idx}
        editId={editId}
        handleOnClickEdit={handleOnClickEdit}
        DelEmployment={DelEmployment}
      />
      <button
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#collapse${idx}`}
        aria-expanded="false"
        aria-controls={`collapse${idx}`}
        className="btn mb-n3 px-3 py-1 btn-outline-heading position-absolute start-50 translate-middle-x bottom-0"
      >
        <span className="view-more d-flex align-items-center">
          <span>{t("formValues.viewMore")}</span>
          <FontAwesomeIcon className="ms-1" icon={faAngleDown} size="lg" />
        </span>
        <span className="view-less d-flex align-items-center">
          <span>{t("formValues.viewLess")}</span>
          <FontAwesomeIcon className="ms-1" icon={faAngleUp} />
        </span>
      </button>
      <div className="collapse content-holder px-5 py-3" id={`collapse${idx}`}>
        <div
          dangerouslySetInnerHTML={{
            __html: data?.description,
          }}
        ></div>
      </div>
    </div>
  );
};

export default EmploymentListComponent;

export const ActionButtons = ({
  idx,
  editId,
  handleOnClickEdit,
  DelEmployment,
}) => {
  if (idx === editId) {
    return (
      <div className="buttons-holder d-flex my-4 pe-5">
        <div className="px-2 py-1 editing-btn">Editing...</div>
      </div>
    );
  }

  return (
    <div className="buttons-holder d-flex my-4 pe-5">
      <button
        type="button"
        className="bg-transparent border-0 btn-icon"
        onClick={handleOnClickEdit}
      >
        <EditIcon className="color-icon edit-icon me-2" />
      </button>

      <button
        type="button"
        className="bg-transparent border-0 btn-icon"
        onClick={DelEmployment}
      >
        <DeleteIcon className="del-icon" />
      </button>
    </div>
  );
};
