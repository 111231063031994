import { DynamicFormElement } from "./DynamicFormElement";

export const Row = ({ row }) => {
  return (
    <div className={`row `}>
      {row.components.map((component) => (
        <DynamicFormElement component={component} key={component.id} />
      ))}
    </div>
  );
};
