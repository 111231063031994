import React from "react";
import DashboardLayout from "layout/DashboardLayout/DashboardLayout";
import Report from "components/Report/Report";

const ApplicantReport = () => {
  return (
    <DashboardLayout>
      <Report />
    </DashboardLayout>
  );
};

export default ApplicantReport;
