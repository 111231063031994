import React, { useContext } from "react";

import { OnBoardingContext } from "contextAPI/onBoardingContext";
import {
  Copyright,
  ShieldExclamation,
  Calendar,
  Version,
} from "constants/icons/icon";

const getIcon = (iconName) => {
  const icons = {
    copyright: (
      <Copyright className={`color-icon pe-2`} width={22} height={20} />
    ),
    version: <Version className={`color-icon pe-2`} width={20} height={20} />,
    calendar: <Calendar className={`color-icon pe-2`} />,
    shieldExclamation: (
      <ShieldExclamation className={`color-icon pe-2`} width={20} height={22} />
    ),
  };

  return icons[iconName];
};

const FooterContent = ({
  prefix,
  secondText,
  icon,
  iconclassName,
  version,
}) => (
  <div className="my-1 mx-4 my-sm-2 mx-sm-5 my-lg-3  d-flex align-items-center text-center">
    {getIcon(icon, iconclassName)}
    <span className="mb-1 mb-sm-0">
      {prefix} {secondText} {version}
    </span>
  </div>
);

const Footer = ({ data, company }) => {
  const { language } = useContext(OnBoardingContext);

  return (
    <footer className="footer-container my-2">
      <div className="footer my-0 px-2 d-flex flex-wrap align-items-center justify-content-center">
        {data.map((item, index) => (
          <FooterContent
            key={index}
            icon={item.icon.url}
            prefix={item.label.locales?.[language]}
            secondText={item.date}
            version={item?.version}
            iconclassName={`${item.icon.className} ${item.icon.customClass}`}
            style={item.icon.style}
          />
        ))}
      </div>
    </footer>
  );
};

export default Footer;
