import React, { useEffect, useState, useContext } from "react";
import { validateEmail } from "components/SignUp/PasswordValidator/utils";
import { Link } from "react-router-dom";

import { AuthContext } from "contextAPI/AuthContext";

import {
  LEFT_ARROW,
  INTELLIANTEWHITE,
  TOP_LEFT_PATTERN,
  BOTTOM_RIGHT_PATTERN,
} from "constants/images";
import { SIGN_IN } from "constants/routes";
import AuthApi from "api/AuthApi/AuthApi";

import { MailIcon } from "assets/icons/icons";

import LoadingState from "pages/Partials/LoadingState";
import { t } from "i18next";
import PasswordRecoveryModal from "components/Modals/PasswordRecoveryModal";

const ResetPassword = () => {
  const { companyLogos } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [verified, setVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showPasswordRecoveryModal, setShowPasswordRecoveryModal] = useState(false);

  useEffect(() => {
    if (!email) {
      setEmailError("");
    } else {
      if (validateEmail(email)) {
        setEmailError("");
        setVerified(true);
      } else {
        setEmailError("Please enter a valid email.");
        setVerified(false);
      }
    }
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    let body = {
      user: {
        email: email,
      },
    };

    try {
      const { data } = await AuthApi.setPassword(body);
      
      if (data?.message) {
        setIsLoading(false);
        setShowPasswordRecoveryModal(true);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const hidePasswordRecoveryModal = () => {
    setShowPasswordRecoveryModal(false);
  }

  return (
    <div className="container-fluid d-flex min-vh-100 w-100 justify-content-center align-items-center sign-in sign-up">
      <div className="col-12 my-5">
        <div className="position-absolute col-5 col-sm-4 col-lg-auto top-0 start-0">
          <img
            className="mw-100"
            src={TOP_LEFT_PATTERN.SOURCE}
            alt="circle pattern"
          />
        </div>
        <div className="position-absolute col-5 col-sm-4 col-lg-auto bottom-0 end-0">
          <img
            className="mw-100"
            src={BOTTOM_RIGHT_PATTERN.SOURCE}
            alt="circle pattern"
          />
        </div>
        <h1 className="text-center mb-5 pb-5">
          <img
            className="mw-100"
            src={companyLogos?.[0]?.image_url}
            alt="logo"
            title="intelliante logo"
          />
        </h1>

        <div className="row justify-content-center justify-content-center">
          <div className="col-md-11 col-lg-9">
            <div className="login-form mt-4 row m-0 justify-content-center">
              <div className="form-container right col-md-8 col-lg-6 col-sm-12">
                <form className="form row m-0" onSubmit={handleSubmit}>
                  <div className="col-12">
                    <h2 className="mb-3">Reset your password</h2>
                    <p className="mb-4">
                      Enter the email accociated with your account and we will
                      send an email with instruction to reset your password
                    </p>
                    <div className="input-field form-floating">
                      <input
                        id="floatingInput"
                        type="email"
                        name="email"
                        className="form-control"
                        placeholder="Work Email Address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <label htmlFor="floatingInput">Work Email Address</label>
                      <span className="icon">
                        <MailIcon className="color-icon" />
                      </span>
                    </div>
                    <div className="small text-danger">{emailError}</div>
                  </div>

                  <div className="col-12 mt-4">
                    {verified ? (
                      <button
                        type="submit"
                        className="btn w-100 btn-primary"
                        onClick={handleSubmit}
                        data-bs-toggle="modal"
                        data-bs-target="#successModal"
                      >
                        <LoadingState
                          isLoading={isLoading}
                          loadingLabel={t("label.sendingRecoveryInstruction")}
                          defaultLabel={t("label.sendRecoveryInstruction")}
                        />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="btn w-100 btn-primary"
                        disabled
                      >
                        <LoadingState
                          isLoading={isLoading}
                          loadingLabel={t("label.sendingRecoveryInstruction")}
                          defaultLabel={t("label.sendRecoveryInstruction")}
                        />
                      </button>
                    )}
                  </div>
                  <Link
                    className="btn-link mt-4 text-primary"
                    to={SIGN_IN.INDEX}
                  >
                    <img src={LEFT_ARROW.SOURCE} alt="left arrow" /> {t("label.backToSignIn")}
                  </Link>
                </form>
              </div>
            </div>
          </div>
        </div>
        <PasswordRecoveryModal isVisible={showPasswordRecoveryModal} hidePasswordRecoveryModal={hidePasswordRecoveryModal}/>
      </div>
    </div>
  );
};

export default ResetPassword;
