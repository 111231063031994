import React, { useContext } from "react";

import InputField from "components/InputField/InputField";
import EmploymentList from "components/HistoryList/EmploymentList";
import Skills from "components/Skills/Skills";
import CandidateIdentification from "components/CandidateIdentification/CandidateIdentification";
import RowComponent from "components/LayoutComponent/RowComponent";
import SectionComponent from "components/LayoutComponent/SectionComponent";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

import { PAYLOAD_TYPES, WORKFLOW_PAGE_TYPES } from "constants/constant";

import VoluntarySelfIdentification from "./CustomerExtraForm/VoluntarySelfIdentification";
import DisabilityDisclosure from "./CustomerExtraForm/DisabilityDisclosure";

const CustomComponent = ({
  data,
  dynamicSectionName,
  language,
  color,
  mode,
  setStepValues,
  stepValues,
  onSubmit,
  detectError,
  setDetectError,
  list,
  setList,
  handleListUpdate,
  educationList,
  employmentList,
  templateName,
  submittedDataSection,
  setOnSubmit,
}) => {
  const { currentlyEmployed, currentlyEnrolledEducation } =
    useContext(OnBoardingContext);

  const handleClick = (value) => {
    if (value === "add") {
      const newList = {};

      stepValues.map((item) => {
        if (item.type !== "checkbox" && item.type !== "radio") {
          newList[item.name] = item.value;
        }
        item = "";
      });

      const isInvalidValue = !Object.values(newList).every(
        (x) => x !== null && x !== ""
      );

      if (!isInvalidValue) {
        if (data.buttonFor) {
          handleListUpdate(data.buttonFor, newList);
        } else
          setList((prev) => {
            return [...prev, newList];
          });
      }
    }
  };

  if (data.type === "field") return null;

  // console.log({ name: data.name, type: data.type, value: data.value });

  if (data.type === "textElement") {
    const Element = data.tag || "div";

    if (currentlyEmployed && data?.value === "End Date") {
      return null;
    }

    if (
      String(currentlyEnrolledEducation) === "true" &&
      data?.value === "End Date"
    ) {
      return null;
    }

    return <Element className="mb-3 text-white">{data.value}</Element>;
  }

  if (data.type === "videoElement") {
    return (
      <div>
        <video
          key={data.value}
          width={data.style?.width || "100%"}
          height={data.style?.height || "100%"}
          controls
        >
          <source src={data.value} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    );
  }

  if (data.type === "imageElement") {
    return (
      <div>
        <img
          width={data.style?.width || "100%"}
          height={data.style?.height || "100%"}
          src={data.value}
          alt="WebCam shots"
        />
      </div>
    );
  }

  if (data.type === WORKFLOW_PAGE_TYPES.DEMOGRAPHY) {
    if (data?.name === PAYLOAD_TYPES.VOLUNTARY)
      return <VoluntarySelfIdentification templateName={data?.name} />;

    if (data?.name === PAYLOAD_TYPES.DISABILITY) {
      return <DisabilityDisclosure templateName={data?.name} />;
    }
  }

  if (data.type === "skill") {
    return (
      <Skills
        data={data.components}
        language={language}
        color={color}
        stepValues={stepValues}
      />
    );
  }

  if (data.type === "identification") {
    return (
      <CandidateIdentification data={data} language={language} color={color} />
    );
  }

  if (data.type === "row" || data.type === "column") {
    return (
      <RowComponent
        data={data}
        language={language}
        color={color}
        mode={mode}
        setStepValues={setStepValues}
        stepValues={stepValues}
        onSubmit={onSubmit}
        detectError={detectError}
        setDetectError={setDetectError}
        educationList={educationList}
        employmentList={employmentList}
        handleListUpdate={handleListUpdate}
        list={list}
        setList={setList}
        templateName={templateName}
        submittedDataSection={submittedDataSection}
      />
    );
  }

  if (data.type === "section") {
    return (
      <SectionComponent
        data={data}
        language={language}
        color={color}
        mode={mode}
        setStepValues={setStepValues}
        stepValues={stepValues}
        onSubmit={onSubmit}
        detectError={detectError}
        setDetectError={setDetectError}
        list={list}
        setList={setList}
        educationList={educationList}
        employmentList={employmentList}
        handleListUpdate={handleListUpdate}
        dynamicSectionName={data?.name}
        templateName={templateName}
        submittedData={data?.submitted_data?.data}
        setOnSubmit={setOnSubmit}
      />
    );
  }

  return (
    <div className="form-floating">
      <InputField
        className={`${data?.className} ${data?.customClass}`}
        style={data?.style}
        type={data?.type}
        defaultValue={data?.defaultValue}
        onClick={() => handleClick(data?.value)}
        label={data.label}
        options={data?.options}
        language={language}
        icon={data?.icon}
        value={data.value}
        color={color}
        mode={mode}
        id={data?.id}
        dynamicFieldId={data?.dynamic_field_id}
        helpText={data?.helpText}
        name={templateName + "." + data?.name}
        validations={data.validations}
        setStepValues={setStepValues}
        stepValues={stepValues}
        onSubmit={onSubmit}
        setDetectError={setDetectError}
        allowMultiple={data?.allowMultiple}
        data={data}
        dynamicSectionName={dynamicSectionName}
        templateName={templateName}
        submittedDataSection={submittedDataSection}
      />
    </div>
  );
};

export default CustomComponent;
