import React from "react";

import Carousel from "components/ApplicantDashboard/TalentLMS/Carousel";
import CourseCard from "components/ApplicantDashboard/TalentLMS/CourseCard";

const AvailableCourses = ({ browse }) => {
  return (
    <div className="courses-wrapper py-3">
      <Carousel>
        {browse?.map((course) => (
          <CourseCard
            key={course.id}
            courses={course}
            progress={false}
            id={course.id}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default AvailableCourses;
