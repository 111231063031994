import React, { useEffect, useContext } from "react";

import TestCard from "./TestCard";

import { CandidateGamesContext } from "contextAPI/CandidateGamesContext";
import { UserContext } from "contextAPI/UserContext";

import Carousel from "components/ApplicantDashboard/TalentLMS/Carousel";

const getGameLink = (link, token) => `${link}?session_token=${token}`;

const TestsList = ({ isTestCardHovered, setIsTestCardHovered }) => {
  const { selectedCandidateId } = useContext(UserContext);
  const { games, fetchCandidateGames } = useContext(CandidateGamesContext);

  const testListVisibility = isTestCardHovered ? "d-block mb-0" : "d-none";

  return (
    <div
      onMouseEnter={() => setIsTestCardHovered(true)}
      onMouseLeave={() => setIsTestCardHovered(false)}
      className={testListVisibility}
    >
      <div className="px-4 tests-list-container position-relative">
        <Carousel>
          {games.map(({ id, game, game_token: gameToken }) => (
            <TestCard
              key={id}
              testName={game?.game_label}
              id={id}
              testIcon={game?.icon}
              gameLink={getGameLink(game?.game_link, gameToken)}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default TestsList;
