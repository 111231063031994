import { getBlobFromMediaStream, getVideoStream } from "../utils/CameraFns";

class camera {
  constructor() {
    this.stream = "No video stream/no web camera found";
    this.imageUrl = null;
    this.error = "";
    this.timeInterval = null;
  }

  //check the camera
  checkCam() {
    if (typeof this.stream === "string") {
      this.error = this.stream;
    } else if (!this.stream.active) {
      this.error = "Lost contact with camera";
    }
  }

  // Get video (camera) stream permission
  async videoStream() {
    this.stream = await getVideoStream();
    this.checkCam();
  }

  // get stream
  getStream() {
    return { stream: this.stream, error: this.error };
  }

  // take photo
  async takePhoto() {
    if (typeof this.stream !== "string" && this.stream.active) {
      try {
        const photoBlob = await getBlobFromMediaStream(this.stream);

        const url = window.URL.createObjectURL(photoBlob);

        this.imageUrl = url;
      } catch (e) {
        this.error = e;
      }
    } else {
      this.checkCam();
    }
  }

  // return camera shot url and time
  getImageUrl() {
    return this.imageUrl;
  }

  // feature to view the camera.
  async cameraView(videoId, imageId) {
    const camera = await getVideoStream();
    const videoElement = document.querySelector(`video#${videoId}`);

    if (videoElement) {
      if (typeof camera !== "string" && camera.active) {
        videoElement.srcObject = camera;
      } else {
        videoElement.style.display = "none";
        const imageElement = document.querySelector(`img#${imageId}`);

        if (imageElement) {
          imageElement.style.display = "block";
        }
      }
    }
  }

  //setTimeInterval for cameraShot.
  cameraShotTimeInterval(time, cameraShot) {
    this.timeInterval = setInterval(() => {
      cameraShot();
    }, time * 1000);
  }
  // clear timerInterval
  stopCameraShotInteval() {
    clearInterval(this.timeInterval);
  }
}

const cameraGallery = new camera();

export default cameraGallery;
