export const ImageElement = ({ imageElement }) => {
  return (
    <div>
      <img
        width={imageElement.style?.width || "100%"}
        height={imageElement.style?.height || "100%"}
        src={imageElement.value}
        alt="WebCam shots"
      />
    </div>
  );
};
