export const getStatusText = (status) => {
  switch (status) {
    case "in_progress":
      return "text-secondary";
    case "disabled":
      return "text-disable";
    case "completed":
      return "text-primary";
    case "expired":
      return "text-danger";
    default:
      return "";
  }
};
