import { useContext, useEffect, useState } from "react";

import { UserContext } from "contextAPI/UserContext";
import DynamicFormApi from "api/DynamicForm/DynamicFormApi";
import { formatOnboardingData } from "utils/apiFormatter";

//Loads workflow data for onboarding
function useWorkflowData({ workflowId, testMode }) {
  //TODO: Handle Error
  //TODO: Handle dynamicForm Change
  const [isLoading, setIsLoading] = useState(false);
  const [workflow, setWorkflow] = useState(null);
  const [language, setLanguage] = useState(null);
  const [applicationState, setApplicationState] = useState(null);

  const { candidateInfo, dynamicForm } = useContext(UserContext);

  useEffect(() => {
    const getWorkflowData = async () => {
      let data;

      if (testMode) {
        data = await DynamicFormApi.getWorkflow(workflowId);
      } else {
        data = await DynamicFormApi.getWorkflowValues(candidateInfo?.id);
      }

      if (data) {
        setLanguage(data.data.language);
        setWorkflow(
          formatOnboardingData(data?.data?.template_json?.data?.components)
        );
        setApplicationState(data?.data?.job_application_state?.state);
      }
      setIsLoading(false);
    };

    setIsLoading(true);

    if (candidateInfo?.id) {
      getWorkflowData();
    }
  }, [candidateInfo?.id, testMode, workflowId]);

  return {
    workflow,
    isLoading,
    language,
    id: candidateInfo?.id,
    applicationState,
  };
}

export default useWorkflowData;
