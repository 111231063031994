import React from "react";

import { t } from "i18next";

import MccsLayout from "components/Report/HelperComponents/MCCSLayout";
import { TEST_NAME } from "constants/applicantReport";

const MCCSChatCustomerService = ({ assessments }) => {
  const title = t("applicantReport.mccsChatCustomerService");
  const subtitle = t("applicantReport.measuresAperson");
  const MCCSVoiceCustomerServiceData = assessments.find(
    (assessment) => assessment.name === TEST_NAME.MCCS_CHAT_CUSTOMER_SERVICE
  );

  return (
    <MccsLayout
      data={MCCSVoiceCustomerServiceData}
      title={title}
      subtitle={subtitle}
    />
  );
};

export default MCCSChatCustomerService;
