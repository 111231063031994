import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { WORKFLOW } from "constants/routes";

import { UserContext } from "contextAPI/UserContext";

const Header = () => {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const { candidateInfo } = useContext(UserContext);

  const handleWorkflow = () => {
    navigate(WORKFLOW.INDEX);
  };

  const disabled = !candidateInfo?.id;

  return (
    <div className="header d-flex justify-content-between align-items-center">
      <h4 className="mb-0">
        {t("message.applicantDashboard.applicationAdventures")}
      </h4>
      <button
        disabled={disabled}
        onClick={handleWorkflow}
        className="btn btn-primary"
      >
        {disabled ? "Loading..." : t("common.continueWorkflow")}
      </button>
    </div>
  );
};

export default Header;
