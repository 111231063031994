import React, { useState } from "react";
import { t } from "i18next";

import Box from "assets/images/box.svg";
import Flag from "assets/images/flag.svg";

const CourseInfo = ({
  courseInfo,
  showModal,
  toggleModal,
  image,
  title,
  category_name,
  code,
  summary,
  codeDisplayClassName,
}) => {
  const [isReadMore, setIsReadMore] = useState(true);

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  const toggleText = isReadMore ? summary.slice(0, 300) : summary;
  const toggleMore = isReadMore ? " ...See More" : "";
  const toggleLess = !isReadMore ? " Show Less" : "";

  const showModalClassName = showModal ? "show" : "";
  const showModalStyle = showModal ? "block" : "none";

  return (
    <div
      className={`modal fade ${showModalClassName}`}
      style={{ display: showModalStyle }}
      id="descModal"
      tabIndex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title p-2" id="exampleModalLabel">
              {t("modal.courseTitle")}
            </h5>
          </div>
          <div className="modal-body py-4 px-5">
            <div className="description d-flex">
              <figure className="">
                <img src={image} alt={title} className="rounded-1" />
              </figure>
              <div className="desc pt-0 ps-4 pe-0">
                <h4 className="text-secondary">
                  {title}{" "}
                  <span
                    className={`${codeDisplayClassName} text-success code fs-6 `}
                  >
                    ({code})
                  </span>
                </h4>
                <div className="category mb-3">
                  {t("label.courseCategory")} :{" "}
                  <span className="text-light">{category_name}</span>
                </div>
                <div className="summary">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: toggleText,
                    }}
                  />
                  <span
                    onClick={toggleReadMore}
                    onKeyPress={toggleReadMore}
                    role="button"
                    tabIndex="0"
                    className="read-or-hide"
                  >
                    <span className="text-secondary">{toggleMore}</span>
                  </span>
                </div>
                <div className="mt-2 see-less">
                  <span
                    onClick={toggleReadMore}
                    onKeyPress={toggleReadMore}
                    role="button"
                    tabIndex="0"
                    className="read-or-hide"
                  >
                    <span className="text-secondary">{toggleLess}</span>
                  </span>
                </div>
              </div>
            </div>
            <div className="content mb-3 mt-2">
              <h3 className="py-2 px-3 mb-3">{t("label.content")}</h3>
              {courseInfo?.units?.map((el) => (
                <div className="image d-flex mb-2" key={el.id}>
                  <img src={Box} alt="box" />{" "}
                  <span className="ms-2">
                    <a
                      href={el.url}
                      className=" content-link text-underline"
                      target="_blank"
                    >
                      {el.name}
                    </a>
                  </span>
                </div>
              ))}
            </div>
            <div className="rules mt-2">
              <h3 className="py-2 px-3 mb-3">{t("label.rules")}</h3>
              {courseInfo?.rules?.map((el) => (
                <div className="image d-flex mt-3">
                  <img src={Flag} alt="flag" />{" "}
                  <span className="ms-2">{el}</span>
                </div>
              ))}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-light btn-sm px-5"
              onClick={toggleModal}
            >
              {t("attributes.close")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseInfo;
