import { useTranslation } from "react-i18next";

import { NewFileImage } from "assets/icons/icons";

export function NoEducation({ children }) {
  const { t } = useTranslation();

  return (
    <div className="noJob-wrapper bg-dark p-5 d-flex flex-column align-items-center justify-content-center">
      <div>
        <NewFileImage />
      </div>
      <div className="title my-3"></div>
      <div className="desc text-center mt-3">
        {t("message.educationDetails.noEducationHistory")}
        <br />
        {t("message.educationDetails.addEducation")}
      </div>
      <div className="mt-3">{children}</div>
    </div>
  );
}
