import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Field } from "react-final-form";

import { RadioInput } from "components/Form/RadioInput";
import { DISABILITIES_lIST, DISABLE_OPION_VALUE } from "constants/constant";
import { onboardingContext } from "pages/Onboarding/OnBoarding";

const DisabilityDisclosure = ({ templateName }) => {
  const { language } = useContext(onboardingContext);

  const { t } = useTranslation();
  const disabilityOptions = [
    {
      value: DISABLE_OPION_VALUE.first,
      label: {
        locales: {
          en: t("extraForm.disableOption.first"),
        },
      },
    },
    {
      value: DISABLE_OPION_VALUE.second,
      label: {
        locales: {
          en: t("extraForm.disableOption.second"),
        },
      },
    },
    {
      value: DISABLE_OPION_VALUE.third,
      label: {
        locales: {
          en: t("extraForm.disableOption.third"),
        },
      },
    },
  ];

  const DisableList = () => {
    const listValue = DISABILITIES_lIST.map((e, i) => {
      return (
        <li key={i} className="pseudo-contained position-relative mb-3">
          {e}
        </li>
      );
    });

    return listValue;
  };

  return (
    <div className="mt-4 extra-form-wrapper">
      <div className="">
        <h2 className="custom-heading-text">{t("extraForm.disableTitle")}</h2>
        <div className="extra-form position-relative voluntary mt-4 p-0">
          <div className="mb-4 bg-dark common-wrapper rounded-2">
            <h3 className="custom-heading-text">
              {t("extraForm.disableReasonTitle")}
            </h3>
            <p className="mb-0">{t("extraForm.disableReasonText")}</p>
          </div>

          <div className="bg-dark common-wrapper rounded-2 ">
            <header className="mb-3">
              <h3 className="custom-heading-text">
                {t("extraForm.disableConfirmTitle")}
              </h3>
            </header>
            <p>{t("extraForm.disableConfirmText")}</p>
            <p>{t("extraForm.disableList")}</p>

            <ul className="list-unstyled d-grid disability-list">
              <DisableList />
            </ul>

            <div className="mt-3">
              <div className="form-group">
                <div className="mb-3">
                  <h3>
                    <span className="my-4 d-block">
                      {t("extraForm.disableOptionChoose")}
                    </span>
                  </h3>
                </div>
                <div className="radio-groups d-flex flex-wrap">
                  {disabilityOptions.map((option, idx) => (
                    <div
                      className="custom-control me-5 mb-4 custom-radio w-100 d-flex"
                      key={JSON.stringify(option)}
                    >
                      <Field
                        name={`${templateName}.disability`}
                        type="radio"
                        value={option.value}
                      >
                        {({ input }) => (
                          <RadioInput
                            id={"disability_options"}
                            idx={idx}
                            inputProps={{
                              ...input,
                              className: "custom-control-input me-2",
                            }}
                            language={language}
                            option={option}
                          />
                        )}
                      </Field>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisabilityDisclosure;
