import React, { useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import { DashboardContext } from "contextAPI/dashboardContext";
import { LINK } from "constants/constant";
import CoursesApi from "api/CoursesApi/CoursesApi";

const CourseCard = ({ courses, progress, idx, id }) => {
  const { course } = useContext(DashboardContext);

  const progressPercent =
    course?.[idx]?.id === courses.id
      ? course?.[idx]?.completion_percentage
      : "";

  const progressRef = useRef(null);

  useEffect(() => {
    progressRef.current.style.width = `${progressPercent}%`;
  }, []);

  const goToCourse = async () => {
    const gotoLink = await CoursesApi.getGotoCourseLink(id);

    window.open(gotoLink?.data?.goto_url, "_blank", "noopener,noreferrer");
  };

  const displayProgress = progress ? "d-block mb-3" : "d-none";

  return (
    <div
      onClick={goToCourse}
      role="presentation"
      className="course-card-wrapper cursor-pointer"
    >
      <div className="box box-sm">
        <div className="d-flex align-items-center mb-4">
          <figure className="box-image-sm rounded-1 flex flex-column justify-content-center">
            <img
              className="w-100 h-100"
              src={courses.big_avatar}
              alt={courses.name}
            />
          </figure>
          <div className="course-card-detail ps-2">
            <h6 className="">{courses.name}</h6>
            <p className="fs-7 truncate-one-line">{courses?.category?.name}</p>
          </div>
        </div>
        <div className="status text-center position-relative progress-height">
          <div
            className={`status text-center position-relative progress-height ${displayProgress}`}
          >
            <div className="progress-bar" ref={progressRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
