import React from "react";

const EmpathyIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "100"}`}
        height={`${height ? height : "93"}`}
        className={classNames.join(" ")}
        viewBox="0 0 100 93"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M50 76.7311C67.9493 76.7311 82.5 63.1508 82.5 46.3988C82.5 29.6467 67.9493 16.0664 50 16.0664C32.0507 16.0664 17.5 29.6467 17.5 46.3988C17.5 63.1508 32.0507 76.7311 50 76.7311Z"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M37 52.4648C37 52.4648 41.875 58.5313 50 58.5313C58.125 58.5313 63 52.4648 63 52.4648"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M59.75 37.2988H59.7825"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M40.25 37.2988H40.2825"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default EmpathyIcon;
