import { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { IntIcon } from "components/Shared/Icon";
import { SIGN_IN, SIGN_UP } from "constants/routes";
import AuthApi from "api/AuthApi/AuthApi";

import welcomeImg from "assets/images/welcomepage.png";

import { LayoutContext } from "contextAPI/LayoutContext";
import { AuthContext } from "contextAPI/AuthContext";

import Steps from "./Steps";

import queryString from "query-string";
import jwtDecode from "jwt-decode";
import { Spinner } from "components/Shared/Spinner";

const getQueryParams = (url) => {
  const formattedUrl = decodeURIComponent(url);

  return queryString.parse(formattedUrl);
};

const Welcome = () => {
  const [userRoute, setUserRoute] = useState("");
  const [isDataLoading, setDataLoading] = useState(false);

  const { t } = useTranslation();

  const { getBranding } = useContext(AuthContext);

  const { applyBrandingToApp } = useContext(LayoutContext);

  const parsed = getQueryParams(window.location.search);
  const decoded = jwtDecode(parsed?.invitation_token);
  const email = decoded?.email;
  const invitationId = decoded?.invitation_id;

  useEffect(async () => {
    const branding = await getBranding();

    applyBrandingToApp(branding?.data?.company?.branding_config);
  }, []);

  useEffect(async () => {
    try {
      setDataLoading(true);
      const response = await AuthApi.checkUser(email);

      if (response?.data?.message === "User is exists") {
        setUserRoute(SIGN_IN.INDEX);
      } else {
        setUserRoute(SIGN_UP.INDEX);
      }

      setDataLoading(false);
    } catch (err) {
      setDataLoading(false);
    }
  }, [email]);

  if (isDataLoading) {
    return <Spinner />;
  }

  return (
    <div className="welcomepage-container ">
      <div className="welcomepage-content-wrapper m-4 p-5">
        <div className="text-center">
          <div>
            <IntIcon name="wavehand" className="hand-logo" />
          </div>
          <h1 className="welcome-text">
            {t("welcomePage.welcome")} <br />
            {t("welcomePage.weAreGlad")}
          </h1>

          <div className="my-4">
            <img src={welcomeImg} alt="welcome" />
          </div>

          <p>{t("welcomePage.youAreTwoSteps")} </p>
        </div>

        <Steps />

        <div className="text-center aaa">
          <Link to={userRoute} state={{ email, invitationId }}>
            <button className="get-started-btn">
              {t("welcomePage.getStarted")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
