import React from "react";

const completedCheckbox = ({ height, width, color, className }) => (
  <svg
    width={`${width ? width : "16"}`}
    height={`${height ? height : "16"}`}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g clipPath="url(#clip0)">
      <path
        d="M14.6291 7.38674V8.00007C14.6283 9.43769 14.164 10.8365 13.3054 11.988C12.4468 13.1394 11.24 13.9817 9.86492 14.3893C8.48984 14.797 7.02017 14.748 5.6751 14.2498C4.33003 13.7516 3.18163 12.8308 2.40118 11.6248C1.62072 10.4188 1.25002 8.99212 1.34437 7.55762C1.43872 6.12312 1.99306 4.75762 2.92471 3.66479C3.85636 2.57195 5.11542 1.81033 6.51409 1.4935C7.91276 1.17668 9.37612 1.32163 10.6859 1.90674"
        stroke={`${color}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6291 2.6665L7.97949 9.33984L5.98462 7.33984"
        stroke={`${color}`}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="15.959" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default completedCheckbox;
