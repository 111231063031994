import React, { useState, useEffect } from "react";

import { t } from "i18next";

import PerformanceLayout from "components/Report/HelperComponents/PerformanceLayout";
import PerformanceListWithoutTitle from "components/Report/HelperComponents/PerformanceListWithoutTitle";
import Subtitle from "components/Report/HelperComponents/Subtitle";
import Result from "components/Report/HelperComponents/Result";
import TitleAlt from "components/Report/HelperComponents/TitleAlt";
import { TEST_NAME } from "constants/applicantReport";
import LineBreaker from "components/Report/HelperComponents/LineBreaker";

const Communication = ({ assessments }) => {
  const [standOutList, setStandOutList] = useState([]);
  const [watchOutList, setWatchOutList] = useState([]);
  const [suggestedStatus, setSuggestedStatus] = useState("Stand Out");

  const communicationData = assessments.find(
    (assessment) => assessment?.name === TEST_NAME.COMMUNICATION_SKILLS
  );

  const scoreBetween0and33 = {
    stand_out: [
      t("applicantReport.communicationScale.avoidconfrontation"),
      t("applicantReport.communicationScale.minimizeOpposition"),
      t("applicantReport.communicationScale.changeTheirMind"),
    ],
    watch_out: [
      t("applicantReport.communicationScale.takeTimeTo"),
      t("applicantReport.communicationScale.challengeAssumption"),
      t("applicantReport.communicationScale.treatOthersWith"),
    ],
  };

  const scoreBetween33and65 = {
    stand_out: [
      t("applicantReport.communicationScale.listenWellwhen"),
      t("applicantReport.communicationScale.expressOpinions"),
      t("applicantReport.communicationScale.communicatePositionsClearly"),
    ],
    watch_out: [
      t("applicantReport.communicationScale.practiseActiveListening"),
      t("applicantReport.communicationScale.manageEmotions"),
      t("applicantReport.communicationScale.treatOthersWith"),
    ],
  };

  const scoreBetween65and100 = {
    stand_out: [
      t("applicantReport.communicationScale.listenWellwhen"),
      t("applicantReport.communicationScale.expressThoughts"),
      t("applicantReport.communicationScale.askQuestionsThat"),
    ],
    watch_out: [
      t("applicantReport.communicationScale.focusOnNon"),
      t("applicantReport.communicationScale.manageEmotionsWhile"),
      t("applicantReport.communicationScale.askCoworksAnd"),
    ],
  };

  useEffect(() => {
    if (communicationData?.score?.overall_percentile * 100 < 33.4) {
      setStandOutList(scoreBetween0and33.stand_out);
      setWatchOutList(scoreBetween0and33.watch_out);
    } else if (communicationData?.score?.overall_percentile * 100 < 65.4) {
      setStandOutList(scoreBetween33and65.stand_out);
      setWatchOutList(scoreBetween33and65.watch_out);
    } else {
      setStandOutList(scoreBetween65and100.stand_out);
      setWatchOutList(scoreBetween65and100.watch_out);
    }
  }, [communicationData]);

  useEffect(() => {
    if (communicationData?.score?.suggested_status === "most_applicants") {
      setSuggestedStatus("Most Applicants");
    } else if (communicationData?.score?.suggested_status === "watch_out") {
      setSuggestedStatus("Watch Out");
    }
  }, [communicationData]);

  if (communicationData?.status === "incomplete") {
    return null;
  }

  return (
    <>
      <LineBreaker />
      <div className="my-5">
        <TitleAlt>{t("applicantReport.communication")}</TitleAlt>
        <Subtitle>{t("applicantReport.measuresAperson")}</Subtitle>

        <Result suggestedStatus={suggestedStatus}>{suggestedStatus}</Result>

        <div className="d-flex gap-4 mt-4">
          <PerformanceLayout title={t("applicantReport.standOut")}>
            <PerformanceListWithoutTitle listData={standOutList} />
          </PerformanceLayout>
          <PerformanceLayout
            title={t("applicantReport.watchOut")}
            listData={watchOutList}
          >
            <PerformanceListWithoutTitle listData={watchOutList} />
          </PerformanceLayout>
        </div>
      </div>
    </>
  );
};

export default Communication;
