import React from "react";

import { t } from "i18next";

import KeyLayout from "components/Report/HelperComponents/KeyLayout";
import Subtitle from "components/Report/HelperComponents/Subtitle";
import TitleAlt from "components/Report/HelperComponents/TitleAlt";
import { TEST_NAME } from "constants/applicantReport";
import LineBreaker from "components/Report/HelperComponents/LineBreaker";

const TenKey = ({ assessments }) => {
  const tenKeyData = assessments.find(
    (assessment) => assessment?.name === TEST_NAME.TEN_KEY
  );

  const data = {
    rawData: {
      score: tenKeyData?.score?.raw_kpm,
      title: t("applicantReport.keyTest.rawKpm"),
    },
    accuracyData: tenKeyData?.score?.accuracy_percentile,
    netData: {
      score: tenKeyData?.score?.net_kpm,
      title: t("applicantReport.keyTest.netkpm"),
    },
  };

  if (tenKeyData?.status === "incomplete") {
    return null;
  }

  return (
    <>
      <LineBreaker />
      <TitleAlt>{t("applicantReport.tenKey")}</TitleAlt>
      <Subtitle>{t("applicantReport.measuresAperson")}</Subtitle>
      <KeyLayout data={data} />
    </>
  );
};

export default TenKey;
