import React from "react";

const HomeIcon = (props) => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className}
    >
      <path
        d="M4 12.0013L16 2.66797L28 12.0013V26.668C28 27.3752 27.719 28.0535 27.219 28.5536C26.7189 29.0537 26.0406 29.3346 25.3333 29.3346H6.66667C5.95942 29.3346 5.28115 29.0537 4.78105 28.5536C4.28095 28.0535 4 27.3752 4 26.668V12.0013Z"
        stroke="#B8C1BF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12 29.3333V16H20V29.3333"
        stroke="#B8C1BF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
