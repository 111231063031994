import React, { useContext, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import "bootstrap/js/dist/tab";
import { t } from "i18next";

import { DashboardContext } from "contextAPI/dashboardContext";
import JobsApi from "api/JobsApi/JobsApi";

import { AuthContext } from "contextAPI/AuthContext";
import { UserContext } from "contextAPI/UserContext";

import SelectField from "components/InputField/SelectField";
import CandidateJobList from "components/IndividualGame/CandidateJobList";

import { CircleCloseIcon, SearchIcon, SpinnerIcon } from "assets/icons/icons";

import getStatus from "utils/getStatus";
import { getStatusText } from "utils/getStatusText";
import { getStatusIcon } from "utils/getStatusIcon";

import { CandidateJobContext } from "contextAPI/CandidateJobContext";
import CompanyImage from "components/Job/CompanyImage";

import { lOGO_LIST_HEIGHT, lOGO_LIST_WIDTH } from "constants/constant";

const getJobCompanies = (jobs) => {
  const jobOptions = jobs
    .map((candidate) => {
      const { id, name } = candidate?.job?.companies[0] ?? {};

      if (!id) return null;

      return { value: id, label: name };
    })
    .filter(Boolean);

  const key = "value";

  const jobOptionsUnique = [
    ...new Map(jobOptions.map((item) => [item[key], item])).values(),
  ];

  return jobOptionsUnique;
};

const Jobs = ({ setSwitchJob, switchJob }) => {
  const { mobileToggle } = useContext(DashboardContext);
  const { userInfo, companyLogos } = useContext(AuthContext);
  const { userCandidatesData } = useContext(CandidateJobContext);
  const { candidateInfo, handleCandidateSelected } = useContext(UserContext);
  const [jobs, setJobs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeFilter, setActiveFilter] = useState("in_progress");
  const [activeCompany, setActiveCompany] = useState(null);
  const [activeJob, setActiveJob] = useState("");

  const onCompanySelect = (value) => {
    setActiveCompany(value);
  };

  const ChangeActiveFilter = (status) => {
    return activeFilter === status ? "btn-primary" : null;
  };

  useEffect(() => {
    fetchFilter({
      user_id: userInfo?.id,
      "filter[status]": activeFilter === "All" ? null : activeFilter,
      "filter[company_id]": activeCompany?.value,
      search_query: searchQuery,
    });
  }, [activeCompany, activeFilter, searchQuery]);

  const fetchAll = async () => {
    setLoading(true);
    await JobsApi.get(userInfo?.id).then((res) => setJobs(res.data));
    setLoading(false);
  };

  const fetchFilter = async (filters) => {
    setLoading(true);
    if (filters === "All") {
      await fetchAll();
    } else {
      await JobsApi.fetchAll(filters).then((res) => setJobs(res.data));
    }
    setLoading(false);
  };

  const filter = (e) => {
    setActiveFilter(e.target.id);
  };

  const resetFilter = () => {
    setActiveCompany(null);
    setActiveFilter("in_progress");
    setSearchQuery("");
  };

  const searchJobs = (e) => {
    setSearchQuery(e.target.value);
    fetchFilter({
      user_id: userInfo?.id,
      "filter[status]": activeFilter === "All" ? null : activeFilter,
      "filter[company_id]": activeCompany?.value,
      search_query: searchQuery,
    });
  };

  const jobOptions = useMemo(
    () => getJobCompanies(userCandidatesData),
    [userCandidatesData]
  );

  const hideSwitchToggleClass = !switchJob ? "hide-job" : "";
  const mobileToggleClass = mobileToggle ? "mobile-toggle" : "";

  return (
    <div
      className={`job-sidebar bg-dark h-100 py-3 d-grid align-content-start ${hideSwitchToggleClass} ${mobileToggleClass}`}
    >
      <div className="d-flex title justify-content-between px-3">
        <div className="my-2">
          <p className="">{t("attributes.jobs")}</p>
        </div>
        <div
          tabIndex={0}
          role="button"
          onKeyDown={() => setSwitchJob(false)}
          onClick={() => setSwitchJob(false)}
          className=""
        >
          <CircleCloseIcon className="color-icon" />
        </div>
      </div>
      <div className="d-flex job-info justify-content-between py-4 px-3">
        <div className="d-flex current-job">
          <CompanyImage
            logos={companyLogos}
            height={lOGO_LIST_HEIGHT}
            width={lOGO_LIST_WIDTH}
          />
          <div>
            <p className="job-title">
              {candidateInfo?.job?.label ?? "Job Title"}
            </p>
            <small className="company d-flex">
              {candidateInfo?.job?.companies?.[0]?.name ?? ""}
              <span
                className={`${
                  candidateInfo?.job?.companies?.[0]?.name ? "mx-2" : ""
                } d-flex align-items-center ${getStatusText(
                  candidateInfo?.status
                )}`}
              >
                {getStatusIcon(candidateInfo?.status)}{" "}
                {candidateInfo?.status
                  ? getStatus(candidateInfo?.status, t)
                  : "Job status"}
              </span>
            </small>
          </div>
        </div>
        <div className="switch-job">
          <span className="p-2 small d-block">
            {t("jobsFilter.currentJobs")}
          </span>
        </div>
      </div>
      <div className="filter px-3">
        <div className="select">
          <SelectField
            value={activeCompany}
            options={jobOptions}
            placeholder="Company"
            isClearable
            onChange={onCompanySelect}
          />
        </div>
        <div className="position-relative my-3">
          <input
            type="search"
            placeholder="Search"
            id="job_search"
            className="form-control"
            value={searchQuery}
            onChange={searchJobs}
          />
          <button
            type="button"
            tabIndex={0}
            className="btn position-absolute search-icon p-0"
          >
            <SearchIcon height="20" width="20" className="color-icon" />
          </button>
        </div>

        <div className="mb-3 btn-grid row">
          <div className="col-sm-4 col-6">
            <button
              type="button"
              tabIndex={0}
              id="in_progress"
              className={`w-100 btn  p-2 status ${ChangeActiveFilter(
                "in_progress"
              )}`}
              onClick={filter}
              onKeyDown={filter}
            >
              {t("jobsFilter.progress")}
            </button>
          </div>
          <div className="col-sm-4 col-6">
            <button
              type="button"
              id="completed"
              className={`w-100 btn  p-2 status ${ChangeActiveFilter(
                "completed"
              )}`}
              onClick={filter}
              onKeyDown={filter}
            >
              {t("attributes.completed")}
            </button>
          </div>
          <div className="col-sm-4 col-6">
            <button
              type="button"
              tabIndex={0}
              id="expired"
              className={`w-100 btn  p-2 status ${ChangeActiveFilter(
                "expired"
              )}`}
              onClick={filter}
              onKeyDown={filter}
            >
              {t("attributes.expired")}
            </button>
          </div>
          <div className="col-sm-4 col-6">
            <button
              type="button"
              tabIndex={0}
              id="disabled"
              className={`w-100 btn  p-2 status ${ChangeActiveFilter(
                "disabled"
              )}`}
              onClick={filter}
              onKeyDown={filter}
            >
              {t("jobsFilter.disable")}
            </button>
          </div>
          <div className="col-sm-4 col-6">
            <button
              type="button"
              id="All"
              className={`w-100 btn  me-0 status p-2 ${ChangeActiveFilter(
                "All"
              )}`}
              onClick={filter}
              onKeyDown={filter}
            >
              {t("attributes.all")}
            </button>
          </div>
          <div className="col-sm-3 col-6">
            <button
              type="button"
              id="reset_filter"
              onClick={resetFilter}
              onKeyDown={resetFilter}
              className="btn w-100 p-2 status"
            >
              {t("attributes.clear")}
              <FontAwesomeIcon icon={faTimesCircle} className="ms-3" />
            </button>
          </div>
        </div>
      </div>
      {loading ? (
        <div className="custom-spinner pb-md-1 px-1 text-center p-3">
          <SpinnerIcon className="primary color-icon" />
        </div>
      ) : (
        <>
          <div className="px-3 overflow-auto">
            <CandidateJobList
              jobs={jobs}
              handleCandidateSelected={handleCandidateSelected}
              setActiveJob={setActiveJob}
              getStatus={getStatus}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Jobs;
