import React, { useContext } from "react";

import { OnBoardingContext } from "contextAPI/onBoardingContext";
import { UserContext } from "contextAPI/UserContext";

import CustomComponent from "components/CustomComponent/CustomComponent";
import EmploymentSection from "components/Sections/EmploymentSection/EmploymentSection";
import EducationForm from "components/HistoryList/EducationForm";

import NoEmploymentSection from "./EmploymentComponent/NoEmploymentSection";

import { EMPLOYMENT_DETAILS } from "constants/constant";
import { LABEL } from "constants/formType";

const SectionComponent = (props) => {
  const { data, language, dynamicSectionName, submittedData } = props;

  const { noWorkExperience } = useContext(OnBoardingContext);

  const { setFormTitle, setFormTitleEmployment } = useContext(UserContext);

  if (data.value === LABEL.SECTION_TYPE) {
    return (
      <EducationForm {...props} templateName={data?.submission?.payload_key} />
    );
  }

  if (data.value === LABEL.EMPLOYMENT_SECTION_TYPE) {
    return (
      <EmploymentSection
        {...props}
        templateName={data?.submission?.payload_key}
      />
    );
  }

  return (
    <>
      <div
        className={`row gx-2 align-items-center gy-3 ${data?.className} mb-4 mt-2`}
      >
        <div className="d-flex justify-content-between">
          <h5
            style={data?.label?.style}
            className={`align-self-center  ${data?.label?.className} mb-3`}
          >
            {data?.label?.locales?.[language]}
          </h5>
          <div>
            <NoWorkExperience data={data} language={language} props={props} />
          </div>
        </div>

        <AllComponents
          data={data}
          language={language}
          noWorkExperience={noWorkExperience}
          props={props}
          dynamicSectionName={dynamicSectionName}
          submittedData={submittedData}
        />
      </div>
    </>
  );
};

export default SectionComponent;

export const AllComponents = ({
  data,
  language,
  noWorkExperience,
  props,
  dynamicSectionName,
  submittedData,
}) => {
  if (
    data?.label?.locales?.[language] ===
      EMPLOYMENT_DETAILS.employment_history &&
    noWorkExperience === true
  ) {
    return <NoEmploymentSection />;
  }

  return data?.components?.map((item, index) => {
    if (item?.label?.locales?.[language] === EMPLOYMENT_DETAILS.no_experience) {
      return null;
    }

    return (
      <CustomComponent
        {...props}
        key={index}
        data={item}
        templateName={dynamicSectionName}
        submittedDataSection={submittedData}
      />
    );
  });
};

export const NoWorkExperience = ({ data, language, props }) => {
  return data?.components?.map((item, index) => {
    if (item?.label?.locales?.[language] === EMPLOYMENT_DETAILS.no_experience) {
      return <CustomComponent {...props} key={index} data={item} />;
    }
  });
};
