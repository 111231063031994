import React from "react";
import { Link } from "react-router-dom";

import {
  INTELLIANTEWHITE,
  TOP_LEFT_PATTERN,
  BOTTOM_RIGHT_PATTERN,
  MAIL_ICON,
} from "constants/images";

const Verify = () => {
  return (
    <div className="container-fluid sign-in sign-up verify-page">
      <div className="top-circle">
        <img src={TOP_LEFT_PATTERN.SOURCE} alt="Top Circle Pattern" />
      </div>
      <div className="bottom-circle">
        <img src={BOTTOM_RIGHT_PATTERN.SOURCE} alt="Bottom Circle Pattern" />
      </div>
      <div className="logo">
        <figure className="text-center m-0">
          <img src={INTELLIANTEWHITE.SOURCE} alt="Intelliante logo" />
        </figure>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6 col-lg-5">
          <div className="mt-4 p-4 bg-gray">
            <div className="text-center verify">
              <figure className="mb-4">
                <img src={MAIL_ICON.SOURCE} alt="mail icon" />
              </figure>
              <strong>Congratulations!</strong>
              <p className="mt-4 para">
                You have Succesfully created the Intelliante account.
              </p>
              <Link to="/" className="mt-4 send-btn btn text-uppercase">
                Go to Dashboard
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verify;
