import React, { useContext } from "react";

import AvailableCourses from "components/ApplicantDashboard/TalentLMS/Courses/AvailableCourses";
import InProgressCourses from "components/ApplicantDashboard/TalentLMS/Courses/InProgressCourses";
import CompletedCourses from "components/ApplicantDashboard/TalentLMS/Courses/CompletedCourses";

import { DashboardContext } from "contextAPI/dashboardContext";

const RenderCourse = ({
  switchTabCount,
  progressCourses,
  completedCourses,
}) => {
  const { browse } = useContext(DashboardContext);

  if (switchTabCount === 1) return <AvailableCourses browse={browse} />;

  if (switchTabCount === 2)
    return <InProgressCourses browse={progressCourses} />;

  return <CompletedCourses browse={completedCourses} />;
};

export default RenderCourse;
