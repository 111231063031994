import { useCallback } from "react";

import { WORKFLOW_PAGE_TYPES } from "constants/constant";
import { Column } from "./Column";
import { Demography } from "./Demography";
import { ImageElement } from "./Image";
import { FormInput } from "./InputFields/FormInput";
import { Row } from "./Row";
import { Section } from "./Section";
import { VideoElement } from "./Video";

export const DynamicFormElement = ({ component }) => {
  const renderComponent = useCallback(() => {
    switch (component.type) {
      case "section":
        return <Section section={component} />;
      case "row":
        return <Row row={component} />;
      case "column":
        return <Column column={component} />;
      case "field":
        return null;
      case "videoElement":
        return <VideoElement videoElement={component} />;
      case "imageElement":
        return <ImageElement imageElement={component} />;
      case WORKFLOW_PAGE_TYPES.DEMOGRAPHY:
        return <Demography component={component} />;
      case "skills":
        //TODO: skills
        return null;
      case "textElement":
        const Element = component.tag || "div";

        return (
          <Element
            className="mb-3 text-white"
            style={{ textAlign: component.textAlignment || "left" }}
          >
            {component.value}
          </Element>
        );
      default:
        return <FormInput component={component} />;
    }
  }, [component]);

  return renderComponent();
};
