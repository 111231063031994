import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import EmploymentList from "components/CustomComponent/CustomerExtraForm/EmploymentList";
import { NoWorkExperience } from "components/LayoutComponent/SectionComponent";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

import EmploymentComponents from "./EmploymentComponents";

export const SectionHeader = ({ data, language, props }) => {
  const { t } = useTranslation();

  const { showEmploymentForm, editEmploymentId, employmentData } =
    useContext(OnBoardingContext);

  const rightSideButton = () => {
    if (employmentData.length > 0) {
      return null;
    }

    if (editEmploymentId) {
      return <div className="edit-detail">{t("common.editDetail")} </div>;
    }

    return <NoWorkExperience data={data} language={language} props={props} />;
  };

  if (!showEmploymentForm) {
    return null;
  }

  return (
    <div className="d-flex justify-content-between">
      <h5
        style={data?.label?.style}
        className={`align-self-center  ${data?.label?.className} mb-3`}
      >
        {data?.label?.locales?.[language]}
      </h5>
      <div>{rightSideButton()}</div>
    </div>
  );
};

const EmploymentSection = (props) => {
  const { noWorkExperience } = useContext(OnBoardingContext);
  const {
    data,
    language,
    setStepValues,
    stepValues,
    handleClick,
    color,
    mode,
  } = props;

  return (
    <>
      <div className="box-styled mt-4">
        <EmploymentList />
      </div>
      <div
        className={`row gx-2 align-items-center gy-3 ${data?.className} mb-4 mt-2`}
      >
        <SectionHeader data={data} language={language} props={props} />
        <EmploymentComponents
          {...props}
          data={data}
          language={language}
          noWorkExperience={noWorkExperience}
          setStepValues={setStepValues}
          stepValues={stepValues}
          handleClick={handleClick}
          color={color}
          mode={mode}
        />
      </div>
    </>
  );
};

export default EmploymentSection;
