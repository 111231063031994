import React from "react";

import GameList from "components/Dashboard/Games";
import DashboardLayout from "layout/DashboardLayout/DashboardLayout";

export default function GameListPage() {
  return (
    <DashboardLayout>
      <GameList />
    </DashboardLayout>
  );
}
