import axios from "axios";

import AuthApi from "api/AuthApi/AuthApi";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

const refreshBearerToken = async (err) => {
  const originalRequest = err.config;

  if (!originalRequest.retry) {
    const refreshToken = LocalStorageManager.get(CONSTANTS.REFRESH_TOKEN);

    originalRequest.retry = true;

    const response = await AuthApi.refreshAuth(
      CONSTANTS.REFRESH_TOKEN,
      refreshToken,
      CONSTANTS.CLIENT_ID,
      CONSTANTS.CLIENT_SECRET
    );

    LocalStorageManager.set(
      CONSTANTS.REFRESH_TOKEN,
      response?.data?.refresh_token
    );

    originalRequest.headers.Authorization = `Bearer ${response.data.access_token}`;

    return axios(originalRequest);
  }
};

export const errorHandlerInterceptor = async (err, cb) => {
  if (err.response && err.response.status === 401 && cb) {
    await cb();

    return refreshBearerToken(err);
  } else {
    throw err;
  }
};
