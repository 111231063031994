export function date(value) {
  let date = new Date(value);

  let options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleString("en-EN", options);
}

export const dateFormated = (value, options) => {
  let date = new Date(value);

  return date.toLocaleDateString("default", options);
};
