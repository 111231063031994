import React from "react";
import { t } from "i18next";

import { IntIcon } from "components/Shared/Icon";

const PerformanceListWithTitle = ({ listData, layoutColor }) => {
  const emptyListData = listData.length === 0;

  if (emptyListData) {
    return (
      <div className="no-list-container d-flex flex-column align-items-center justify-content-center w-100 w-100 h-100">
        <div className="mb-4">
          <IntIcon name="noList" className="no-list-icon" />
        </div>
        <p className="no-list-description mx-auto text-center">
          {t("applicantReport.applicantDoNotHave")}
        </p>
      </div>
    );
  }

  return (
    <div className="performance-list">
      {listData.map((item, index) => (
        <ul
          key={index}
          className="m-0 d-flex gap-3 my-3 performace-list-container"
        >
          <div className={`bullet-point ${layoutColor}`}></div>
          <div className="list-container">
            <li className="title mb-2">
              {item.title} {t("applicantReport.error")}(s)
            </li>
            <li className="description">{item.description}</li>
          </div>
        </ul>
      ))}
    </div>
  );
};

export default PerformanceListWithTitle;
