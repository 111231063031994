import React from "react";

const IndividualPattern = ({ color, style, className }) => {
  return (
    <svg
      width="154"
      height="130"
      viewBox="0 0 154 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <circle
        cx="-70.5"
        cy="-94.5"
        r="223"
        stroke={color}
        strokeOpacity="0.2"
        strokeWidth="3"
      />
    </svg>
  );
};

export default IndividualPattern;
