import React, { useState, useEffect } from "react";
import { t } from "i18next";

import BusinessWritingSkills from "./BusinessWritingSkills";
import Communication from "./Communication";
import TenKey from "./TenKey";
import Typing from "./Typing";
import MccsVoiceCollecction from "./MCCSVoiceCollecction";
import MccsVoiceCustomerService from "./MCCSVoiceCustomerService";
import MccsVoiceTechnicalSupport from "./MCCSVoiceTechnicalSupport";
import MccsVoiceSales from "./MCCSVoiceSales";
import MccsChatCustomerService from "./MCCSChatCustomerService";
import MccsChatTechnicalSupport from "./MCCSChatTechnicalSupport";
import MccsChatSales from "./MCCSChatSales";
import Title from "components/Report/HelperComponents/Title";
import BasicComputerSkills from "./BasicComputerSkills";
import OverComingAdversity from "./OverComingAdversity";

import { TEST_NAME } from "constants/applicantReport";

const ReportSummary = ({ assessments }) => {
  const [availableAssemets, setAvailableAssemets] = useState([]);

  useEffect(() => {
    const assessment = assessments?.map((assessment) => assessment?.name);

    setAvailableAssemets(assessment);
  }, [assessments]);

  return (
    <div id="4">
      <div className="container max-w-1970">
        <Title>{t("applicantReport.whatYourResult")}</Title>
        {availableAssemets?.includes(TEST_NAME.BUSINESS_WRITING) ? (
          <>
            <BusinessWritingSkills assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.BASIC_COMPUTER_SKILLS) ? (
          <>
            <BasicComputerSkills assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.COMMUNICATION_SKILLS) ? (
          <>
            <Communication assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.OVERCOMING_ADVERSITY_SKILLS) ? (
          <>
            <OverComingAdversity assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.MCCS_VOICE_COLLECTION) ? (
          <>
            <MccsVoiceCollecction assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.MCCS_VOICE_CUSTOMER_SERVICE) ? (
          <>
            <MccsVoiceCustomerService assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(
          "Multi-Channel Customer Support Voice Support"
        ) ? (
          <>
            <MccsVoiceTechnicalSupport assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.MCCS_VOICE_TECHNICAL_SUPPORT) ? (
          <>
            <MccsVoiceSales assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.MCCS_CHAT_CUSTOMER_SERVICE) ? (
          <>
            <MccsChatCustomerService assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.MCCS_CHAT_TECHNICAL_SUPPORT) ? (
          <>
            <MccsChatTechnicalSupport assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.MCCS_CHAT_SALES) ? (
          <>
            <MccsChatSales assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.TEN_KEY) ? (
          <>
            <TenKey assessments={assessments} />
          </>
        ) : null}

        {availableAssemets?.includes(TEST_NAME.TYPING_TEST) ? (
          <>
            <Typing assessments={assessments} />
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ReportSummary;
