import React from "react";

const AlertIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "13"}`}
        height={`${height ? height : "12"}`}
        className={classNames.join(" ")}
        viewBox="0 0 13 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_10784_157637)">
          <path
            d="M5.57379 1.92961L0.985875 8.99962C0.891283 9.15083 0.841232 9.32226 0.840702 9.49687C0.840173 9.67149 0.889183 9.84318 0.982856 9.99488C1.07653 10.1466 1.2116 10.273 1.37463 10.3615C1.53767 10.4501 1.72297 10.4977 1.91213 10.4996H11.088C11.2771 10.4977 11.4624 10.4501 11.6255 10.3615C11.7885 10.273 11.9236 10.1466 12.0172 9.99488C12.1109 9.84318 12.1599 9.67149 12.1594 9.49687C12.1589 9.32226 12.1088 9.15083 12.0142 8.99962L7.42629 1.92961C7.32973 1.78267 7.19377 1.66117 7.03152 1.57686C6.86928 1.49254 6.68623 1.44824 6.50004 1.44824C6.31385 1.44824 6.1308 1.49254 5.96856 1.57686C5.80632 1.66117 5.67036 1.78267 5.57379 1.92961V1.92961Z"
            stroke="#778583"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.5 4.5V6.5"
            stroke="#778583"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M6.5 8.5H6.50583"
            stroke="#778583"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_10784_157637">
            <rect width="13" height="12" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default AlertIcon;
