import React from "react";
import { useTranslation } from "react-i18next";

import CompanyImage from "components/Job/CompanyImage";

import { getStatusText } from "utils/getStatusText";
import { getStatusIcon } from "utils/getStatusIcon";

import { lOGO_LIST_HEIGHT, lOGO_LIST_WIDTH } from "constants/constant";

const CandidateJobList = ({
  jobs,
  handleCandidateSelected,
  setActiveJob,
  getStatus,
}) => {
  const { t } = useTranslation();

  if (jobs.length <= 0) {
    return (
      <>
        <p className="mt-5 text-center">{t("message.jobs.noJobs")}</p>
      </>
    );
  }

  return jobs.map((job) => {
    const companyNameClass = job?.job.companies?.[0]?.name ? "ms-2" : "";
    const jobStatusName = job.status
      ? getStatus(job?.status, t)
      : t("jobsFilter.statusUnavailable");

    return (
      <div
        key={job?.job.id}
        tabIndex={0}
        role="button"
        className="d-flex result py-4"
        onKeyDown={() => {
          handleCandidateSelected(job.id);
          setActiveJob(job.job);
        }}
        onClick={() => {
          handleCandidateSelected(job.id);
          setActiveJob(job.job);
        }}
      >
        <CompanyImage
          job={job?.job}
          height={lOGO_LIST_HEIGHT}
          width={lOGO_LIST_WIDTH}
        />
        <div>
          <p className="job-title">{job.job?.label}</p>
          <small className="company">
            {job?.job.companies?.[0]?.name}
            <span
              className={` ${getStatusText(job?.status)} ${companyNameClass}`}
            >
              {getStatusIcon(job?.status)} {jobStatusName}
            </span>
          </small>
        </div>
      </div>
    );
  });
};

export default CandidateJobList;
