import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStopwatch, faBan } from "@fortawesome/free-solid-svg-icons";
import { CompletedCheckBox, SpinnerIcon } from "assets/icons/icons";

export const getStatusIcon = (status) => {
  switch (status) {
    case "disabled":
      return (
        <FontAwesomeIcon
          icon={faBan}
          height="13"
          width="13"
          className="me-1 color-icon disable fill"
        />
      );

    case "in_progress":
      return (
        <SpinnerIcon
          height="13"
          width="13"
          className="me-1 color-progress color-icon"
        />
      );

    case "completed":
      return (
        <CompletedCheckBox
          height="13"
          width="13"
          className="me-1 color-icon primary"
        />
      );

    case "expired":
      return (
        <FontAwesomeIcon
          icon={faStopwatch}
          height="13"
          width="13"
          className="me-1 color-icon danger fill"
        />
      );
    default:
      return null;
  }
};
