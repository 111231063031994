export const CONSTANTS = {
  CLIENT_ID: "8p-WdZKl-zdMfbIhlT90mu0yzE_3mYAma20PfL2EBHM",
  CLIENT_SECRET: "w0EHEeHYnHalgjD6_TiJsqV1YZU8i0c13E4nH2E69x",
  TOKEN: "token",
  ACTIVE_CANDIDATE_ID: "ACTIVE_CANDIDATE_ID",
  PASSWORD: "password",
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  EXPIRES_IN: "expires_in",
  TOKEN_TYPE: "token_type",
  SELECTED_JOB: "selectedJob",
};

class LocalStorageManager {
  static storage = window.localStorage;

  static get(key) {
    const data = this.storage.getItem(key);

    if (data) return JSON.parse(data);

    return data;
  }

  static set(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  static remove(key) {
    this.storage.removeItem(key);
  }

  static clear() {
    this.storage.clear();
  }
}

export default LocalStorageManager;
