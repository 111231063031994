import React, { useState, useEffect, useContext } from "react";

import { AuthContext } from "./AuthContext";

import JobsApi from "api/JobsApi/JobsApi";
import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

export const CandidateJobContext = React.createContext();

const CandidateJobProvider = ({ children }) => {
  const [userCandidatesData, setUserCandidatesData] = useState([]);
  const [userSelectedData, setUserSelectedData] = useState("");
  const { userInfo } = useContext(AuthContext);
  const [companyId, setCompanyId] = useState("");

  const [currentJobData, setCurrentJobData] = useState(null);

  const [showJobCard, setShowJobCard] = useState(false);

  useEffect(() => {
    setCompanyId(userInfo?.data?.id);
  }, [userInfo]);

  const jobsFetchValue = async () => {
    const res = await JobsApi.get();

    setUserCandidatesData(res?.data);
  };

  const fetchAllJobs = async () => {
    const data = await JobsApi.get();
    const currentSelectedJobId = LocalStorageManager.get(
      CONSTANTS.SELECTED_JOB
    ).candidateId;

    setCurrentJobData(
      data.data.filter((item) => item.id === currentSelectedJobId)[0]
    );
  };

  useEffect(() => {
    if (companyId) {
      jobsFetchValue();
      fetchAllJobs();
    }
  }, [companyId, userInfo]);

  return (
    <CandidateJobContext.Provider
      value={{
        userCandidatesData,
        userSelectedData,
        setUserSelectedData,
        showJobCard,
        setShowJobCard,
        jobsFetchValue,
        fetchAllJobs,
        currentJobData,
      }}
    >
      {children}
    </CandidateJobContext.Provider>
  );
};

export default CandidateJobProvider;
