import React, { useState, useEffect } from "react";
import { t } from "i18next";

import PerformanceLayout from "components/Report/HelperComponents/PerformanceLayout";

import PerformanceListWithTitle from "components/Report/HelperComponents/PerformanceListWithTitle";

import Subtitle from "components/Report/HelperComponents/Subtitle";

import TitleAlt from "components/Report/HelperComponents/TitleAlt";
import Result from "components/Report/HelperComponents/Result";
import { TEST_NAME } from "constants/applicantReport";
import LineBreaker from "components/Report/HelperComponents/LineBreaker";

const BasicComputerSkills = ({ assessments }) => {
  const [standOutList, setStandOutList] = useState([]);
  const [watchOutList, setWatchOutList] = useState([]);
  const [suggestedStatus, setSuggestedStatus] = useState("Stand Out");

  const basicComputerSkillsData = assessments.find(
    (assessment) => assessment?.name === TEST_NAME.BASIC_COMPUTER_SKILLS
  );

  const scaleData = [
    {
      title: t("applicantReport.basicComputerScale.security"),
      status:
        basicComputerSkillsData?.score?.subscales_suggested_status?.security,
      description: t("applicantReport.basicComputerScale.surveysAPersons"),
    },
    {
      title: t("applicantReport.basicComputerScale.operatingSystem"),
      status:
        basicComputerSkillsData?.score?.subscales_suggested_status
          ?.operating_system,
      description: t("applicantReport.basicComputerScale.gaugesAPersons"),
    },
    {
      title: t("applicantReport.basicComputerScale.applications"),
      status:
        basicComputerSkillsData?.score?.subscales_suggested_status?.application,
      description: t("applicantReport.basicComputerScale.evaluatesAPersons"),
    },
    {
      title: t("applicantReport.basicComputerScale.Messaging"),
      status:
        basicComputerSkillsData?.score?.subscales_suggested_status?.messaging,
      description: t("applicantReport.basicComputerScale.assessesAPersons"),
    },
    {
      title: t("applicantReport.basicComputerScale.storage"),
      status:
        basicComputerSkillsData?.score?.subscales_suggested_status?.storage,
      description: t("applicantReport.basicComputerScale.checksAPersons"),
    },
    {
      title: t("applicantReport.basicComputerScale.shortcuts"),
      status:
        basicComputerSkillsData?.score?.subscales_suggested_status?.shortcuts,
      description: t("applicantReport.basicComputerScale.measuresAPerson"),
    },
    {
      title: t("applicantReport.basicComputerScale.fileManagement"),
      status:
        basicComputerSkillsData?.score?.subscales_suggested_status
          ?.file_management,
      description: t("applicantReport.basicComputerScale.examinesAPersons"),
    },
    {
      title: t("applicantReport.basicComputerScale.internet"),
      status:
        basicComputerSkillsData?.score?.subscales_suggested_status?.internet,
      description: t("applicantReport.basicComputerScale.analyzesAPerson"),
    },
  ];

  useEffect(() => {
    const standOutData = scaleData.filter(
      (data) => data?.status === "stand_out"
    );
    const watchOutData = scaleData.filter(
      (data) => data?.status === "watch_out"
    );

    setStandOutList(standOutData);
    setWatchOutList(watchOutData);
  }, [basicComputerSkillsData]);

  useEffect(() => {
    const isStatusMostApplicant =
      basicComputerSkillsData?.score?.suggested_status === "most_applicants";
    const isStatusWatchOut =
      basicComputerSkillsData?.score?.suggested_status === "watch_out";

    if (isStatusMostApplicant) {
      setSuggestedStatus("Most Applicants");
    } else if (isStatusWatchOut) {
      setSuggestedStatus("Watch Out");
    }
  }, [basicComputerSkillsData]);

  if (basicComputerSkillsData?.status === "incomplete") {
    return null;
  }

  return (
    <>
      <LineBreaker />
      <TitleAlt>{t("applicantReport.basicComputerSkills")}</TitleAlt>
      <Subtitle>{t("applicantReport.measuresAperson")}</Subtitle>
      <Result suggestedStatus={suggestedStatus}>{suggestedStatus}</Result>
      <div className="d-flex gap-4 mt-4">
        <PerformanceLayout title={t("applicantReport.standOut")}>
          <PerformanceListWithTitle listData={standOutList} />
        </PerformanceLayout>
        <PerformanceLayout title={t("applicantReport.watchOut")}>
          <PerformanceListWithTitle listData={watchOutList} />
        </PerformanceLayout>
      </div>
    </>
  );
};

export default BasicComputerSkills;
