import React from "react";

const CloseIcon = ({ className, color, width, height }) => {
  return (
    <>
      <svg
        width={width ? width : "20"}
        height={height ? height : "15"}
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M16.5 1.5L1.5 16.5"
          stroke={color ? color : "#FAFEFD"}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1.5 1.5L16.5 16.5"
          stroke={color ? color : "#FAFEFD"}
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default CloseIcon;
