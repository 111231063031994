import React, { useContext, useRef, useState, useEffect } from "react";
import { UserContext } from "contextAPI/UserContext";
import { t } from "i18next";

import CoursesApi from "api/CoursesApi/CoursesApi";

const ProgressBar = ({
  completion_percentage,
  enrolled,
  course_percentage,
  label,
  id,
}) => {
  const { candidateInfo } = useContext(UserContext);

  const [enrollNow, setEnrollNow] = useState(false);

  const userId = candidateInfo?.id;

  const progressRef = useRef(null);

  const percentage =
    completion_percentage ?? course_percentage?.[0]?.completion_percentage;

  const level = percentage ?? "0";

  useEffect(() => {
    progressRef.current.style.width = `${level}%`;
  }, []);

  const handleEnroll = async () => {
    const response = await CoursesApi.enrollToCourse(userId, id);

    if (response) {
      setEnrollNow(true);
    }
  };

  if (enrolled && label == "browse")
    return (
      <div className="status text-center position-relative mb-2">
        <div className="progress-bar" ref={progressRef} />
        <small className="progress-text justify-content-center d-flex position-absolute w-100 top-0">
          {percentage}%
        </small>
      </div>
    );

  if (!enrollNow && !enrolled && label == "browse")
    return (
      <button
        type="button"
        className="w-100 rounded btn btn-primary btn-sm"
        onClick={handleEnroll}
        ref={progressRef}
      >
        {t("attributes.enrollNow")}
      </button>
    );

  if (enrollNow && !enrolled && label == "browse")
    return (
      <div className="status text-center position-relative mb-2">
        <div className="progress-bar enrolled-bar" ref={progressRef} />
        <small className="progress-text justify-content-center d-flex position-absolute w-100 top-0">
          0%
        </small>
      </div>
    );

  return (
    <div className="status text-center position-relative mb-2">
      <div className="progress-bar" ref={progressRef} />
      <small className="progress-text justify-content-center d-flex position-absolute w-100 top-0">
        {percentage}%
      </small>
    </div>
  );
};

export default ProgressBar;
