import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

import { NextIcon } from "assets/icons/icons";

import { UserContext } from "contextAPI/UserContext";
import JobsApi from "api/JobsApi/JobsApi";

import CompanyCard from "./Company";
import { date } from "utils/getDate";

import { WORKFLOW } from "constants/routes";

import { useTranslation } from "react-i18next";

const JobDetail = ({}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { candidateInfo } = useContext(UserContext);

  const [candidateJob, setCandidateJob] = useState({});

  const fetchJobDetail = async (jobId) => {
    const { data } = await JobsApi.getDetails(jobId).catch((err) => {
      return {};
    });

    setCandidateJob(data);
  };

  useEffect(() => {
    const jobId = candidateInfo?.job?.id;

    if (jobId) {
      fetchJobDetail(jobId);
    }
  }, [candidateInfo]);

  let locationAddress = [];

  const jobDetail = candidateJob ?? {};
  const companyDetails = candidateInfo?.company ?? {};

  const jobLocation = candidateJob?.job_locations?.length
    ? candidateJob?.job_locations
    : [];
  const lineOfBusiness = candidateJob?.line_of_business;

  for (let i = 0; i < jobLocation?.length; i++) {
    locationAddress.push({
      address1: jobLocation?.[i]?.location?.address_line_1,
      address2: jobLocation?.[i]?.location?.address_line_2,
      city: jobLocation?.[i]?.location?.city,
      state: jobLocation?.[i]?.location?.state,
    });
  }

  const acceptedDate = date(candidateJob?.created_at);

  const handleWorkflow = () => {
    navigate(WORKFLOW.INDEX);
  };

  return (
    <section
      role="contentinfo"
      aria-label="Job description section"
      className="job-detail-page container max-w-1970"
    >
      <div className="py-3 d-flex align-items-center justify-content-between">
        <div>
          <h2 className="heading">{t("message.jobDetails.welcome")}</h2>
          <small className="text-light d-block">
            {t("message.applicantDashboard.jobOverview")}
          </small>
        </div>
      </div>
      <div className="row g-2 align-items-center flex-sm-nowrap">
        <div className="col-12 col-sm-7 order-sm-first col-md-8">
          <p className="py-3 title text-secondary">
            {t("message.jobDetails.jobDetailTitle")}
          </p>
          <div className="row">
            <div className="col-lg-4 d-flex flex-column col-sm-6 col-12 py-3">
              <span className="text-light">
                {t("message.jobDetails.jobTitle")}
              </span>
              <span className="detail">
                {jobDetail?.label ?? t("message.jobDetails.na")}
              </span>
            </div>
            <div className="col-lg-4 d-flex flex-column col-sm-6 col-12 py-3">
              <span className="text-light">
                {t("message.jobDetails.appliedDate")}
              </span>
              <span className="detail">{acceptedDate}</span>
            </div>

            {lineOfBusiness && (
              <div className="col-lg-4 d-flex flex-column col-sm-6 col-12 py-3">
                <span className="text-light">
                  {t("message.jobDetails.lineOfBusiness")}
                </span>
                <span className="detail">{lineOfBusiness?.label}</span>
              </div>
            )}
          </div>
          <div className="col-lg-12 d-flex flex-column py-3">
            <span className="text-light">
              {t("message.jobDetails.location")}
            </span>
            <div className="location-container d-flex align-items-center flex-wrap">
              {locationAddress?.map((el) => (
                <>
                  <span className="detail location d-inline-block bg-dark my-1">
                    {`${el.address1},${el.city}`}
                  </span>
                </>
              ))}
            </div>
          </div>
          <div>
            <span className="text-light">
              {t("message.jobDetails.jobDesc")}
            </span>
            <div
              className="detail"
              dangerouslySetInnerHTML={{
                __html: jobDetail?.description,
              }}
            />
          </div>
        </div>
        <CompanyCard companyDetail={companyDetails} />
      </div>
      <div className="d-flex align-items-center justify-content-end mt-5 workflow-modal-footer">
        <button
          onClick={handleWorkflow}
          className="btn bg-transparent border-0 d-flex align-items-center mt-2 justify-content-end next pe-0"
        >
          <span className="me-3">{t("attributes.next")}</span>
          <NextIcon className="next-icon" />
        </button>
      </div>
    </section>
  );
};

export default JobDetail;
