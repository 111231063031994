import { OnBoardingContext } from "contextAPI/onBoardingContext";
import React, { useContext } from "react";
import { useEffect } from "react";

const ErrorAlert = ({
  children,
  className,
  show,
  resetNotification,
  isMultiple,
}) => {
  const { color } = useContext(OnBoardingContext);

  useEffect(() => {
    if (!show) return;
    setTimeout(() => {
      resetNotification();
    }, 2000);
  }, [show]);

  if (!show) return null;

  if (isMultiple) {
    return (
      <div
        className={`alert custom-alert alert-danger  end-0 ${className}`}
        style={{ backgroundColor: color.dangerColor }}
      >
        {children}
      </div>
    );
  }

  return (
    <div className="alert-container position-fixed">
      <div
        className={`alert alert-danger custom-alert position-fixed end-0 ${className}`}
        style={{ backgroundColor: color.dangerColor }}
      >
        {children}
      </div>
    </div>
  );
};

export default ErrorAlert;
