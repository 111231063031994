import React, { useContext } from "react";

import CustomCheckbox from "components/CustomComponent/Checkbox/CustomCheckbox";
import ErrorText from "components/ErrorText/ErrorText";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

import { EMPLOYMENT } from "constants/constant";

const Checkbox = ({
  label,
  id,
  checkOnChangeValidation,
  language,
  className,
  onBlur,
  templateName,
  name,
  error,
}) => {
  const { currentlyEnrolled, noWorkExperience } = useContext(OnBoardingContext);

  const updatedCheckBoxValue = () => {
    if (name === EMPLOYMENT.NO_WORK_EXPERIENCE) {
      return noWorkExperience;
    }

    if (name === EMPLOYMENT.CURRENT_EMPLOYER) {
      return currentlyEnrolled;
    }

    return null;
  };

  return (
    <div className="">
      <label className={`checkbox-label-container`}>
        {label?.locales?.[language]}
        <CustomCheckbox
          name={name}
          className={className}
          onClick={checkOnChangeValidation}
          checked={updatedCheckBoxValue()}
          custom={true}
          onBlur={onBlur}
          id={id}
          templateName={templateName}
        />
        <span className="checkmark" />
      </label>
      <ErrorText error={error} name={name} className="text-danger" />
    </div>
  );
};

export default Checkbox;
