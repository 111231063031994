import AuthenticatedApi from "api/AuthenticatedApi";

export const jsonData = {
  job_application: {
    id: 80,
    applicant_name: "Henrietta Shanahan",
    company_name: "Qualfone",
    job_title: "Legal Supervisor",
    overall_competitiveness: "40.504444444444444444444444444444444444",
    assessments: [
      {
        name: "Business Writing",
        code: "IBW",
        category: "Job Skill",
        language: "en",
        status: "completed",
        score: {
          language: "en",
          suggested_status: "watch_out",
          overall_percentile_lower: "0.6",
          overall_percentile_higher: "0.63",
          subscales_suggested_status: {
            collocation: "most_applicants",
            compounding: "stand_out",
            confused_words: "most_applicants",
            grammar: "stand_out",
            plain_english: "stand_out",
            punctuation: "most_applicants",
            redundancy: "watch_out",
            semantics: "watch_out",
            style: "stand_out",
            text_analysis: "most_applicants",
            typos: "most_applicants",
          },
        },
      },
      {
        name: "Multi-Channel Customer Support Voice Service",
        code: "MVSE",
        category: "Job Skill",
        language: "en",
        status: "incomplete",
        score: {
          language: "en",
          suggested_status: "stand_out",
          overall_percentile_lower: "0.91",
          overall_percentile_higher: "0.96",
          subscales_suggested_status: {
            accuracy: "most_applicants",
            customer_service: "stand_out",
            multi_tasking: "stand_out",
            navigation: "watch_out",
            quality: "stand_out",
          },
        },
      },
      {
        name: "Multi-Channel Customer Support Chat Support",
        code: "MCSU",
        category: "Job Skill",
        language: "en",
        status: "completed",
        score: {
          language: "en",
          suggested_status: "stand_out",
          overall_percentile_lower: "0.64",
          overall_percentile_higher: "0.69",
          subscales_suggested_status: {
            accuracy: "stand_out",
            customer_service: "most_applicants",
            multi_tasking: "stand_out",
            navigation: "most_applicants",
            quality: "most_applicants",
          },
        },
      },
      {
        name: "Ten Key",
        code: "ITK",
        category: "Job Skill",
        language: "en",
        status: "completed",
        score: {
          accuracy_percentile: "0.9",
          language: "en",
          suggested_status: "stand_out",
          net_kph: 90,
          overall_percentile: "0.668",
          raw_kph: 94,
          overall_percentile_lower: "0.79",
          overall_percentile_higher: "0.83",
          subscales_suggested_status: {
            accuracy: "stand_out",
          },
        },
      },
      {
        name: "Typing Test",
        code: "ITT",
        category: "Job Skill",
        language: "en",
        status: "completed",
        score: {
          accuracy_percentile: "0.9",
          language: "en",
          suggested_status: "stand_out",
          net_wpm: 75,
          overall_percentile: "0.781",
          raw_wpm: 40,
          overall_percentile_lower: "0.65",
          overall_percentile_higher: "0.69",
          subscales_suggested_status: {
            accuracy: "stand_out",
          },
        },
      },
      {
        name: "Overcoming Adversity Skills",
        code: "IOAS",
        category: "Social Skill",
        language: "en",
        status: "completed",
        score: {
          language: "en",
          suggested_status: "stand_out",
          overall_percentile_lower: "0.84",
          overall_percentile_higher: "0.87",
        },
      },
      {
        name: "Communication Skills",
        code: "ICS",
        category: "Social Skill",
        language: "en",
        status: "completed",
        score: {
          language: "en",
          suggested_status: "stand_out",
          overall_percentile_lower: "0.84",
          overall_percentile_higher: "0.87",
        },
      },
      {
        name: "Basic Computer Skills",
        code: "IBCS",
        category: "Job Skill",
        language: "en",
        status: "completed",
        score: {
          language: "en",
          suggested_status: "stand_out",
          overall_percentile_lower: "0.64",
          overall_percentile_higher: "0.67",
          subscales_suggested_status: {
            applications: "stand_out",
            file_management: "most_applicants",
            internet: "stand_out",
            messaging: "most_applicants",
            operating_system: "most_applicants",
            security: "most_applicants",
            shortcuts: "stand_out",
            storage: "most_applicants",
          },
        },
      },
      {
        name: "Responsibility and Dependability Skills",
        code: "IRDS",
        category: "Social Skill",
        language: "en",
        status: "completed",
        score: {
          language: "en",
          suggested_status: "stand_out",
          overall_percentile_lower: "0.66",
          overall_percentile_higher: "0.7",
          subscales_suggested_status: {
            attentiveness: "stand_out",
            compliance: "stand_out",
            perseverance: "most_applicants",
            reliability: "most_applicants",
            time_management: "most_applicants",
          },
        },
      },
    ],
  },
};

const URL = {
  DRILL_DOWN: "/job_applications/:job_application_id/reports/drill_down",
};

class ApplicantReportApi extends AuthenticatedApi {
  static getDrillDownReport(jobApplicationId) {
    let GET_DRILL_DOWN = URL.DRILL_DOWN.replace(
      ":job_application_id",
      jobApplicationId
    );

    return AuthenticatedApi.get(GET_DRILL_DOWN);
  }
}

export default ApplicantReportApi;
