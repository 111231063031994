/**
 *
 * NOTE: Please maintain by alphabetical order; it will be easier to search and look through the file.
 * Add the values according to following labels
 *
 * 1. attributes - This will include site wide attributes values eg: (Email, Name, Password, etc);
 * 2. auth - This will include all the labels for the auth with nested objects;
 *         - Include all the translations inside respective object (eg: login, signUp, passwordReset, etc);
 * 3. common - This will include common translation in app eg: (Job, Candidate, Games, etc);
 * 4. label - This will include all the labels for the buttons eg: (Login, Sign Up, etc);
 * 5. message - This will include common messages that needs to be displayed in app eg: (Email verified, etc);
 * 6. modal - This will include all the labels, description, subheadings for the modal eg: (SignUp modal, Launch setup modal, etc);
 *
 *
 **/

const translation = {
  attributes: {
    enrollNow: "Enroll Now",
    explore: "Explore Courses",
    enroll: "Enrolled Courses",
    editing: "Editing...",
    yes: "Yes",
    no: "No",
    enrolled: "enrolled",
    close: "Close",
    unknown: "Unknown",
    action: "Action",
    address1: "Address 1",
    city: "City",
    home: "Home",
    email: "Email",
    name: "Name",
    jobs: "Jobs",
    clear: "Clear",
    location: "Location {{symbol}}",
    role: "Role",
    sn: "S.N.",
    user: "User",
    note: "Note",
    details: "Details",
    invite: "Invite",
    onboarding: "Onboarding",
    games: "Games",
    status: "status",
    submit: "Submit",
    disable: "Disable",
    reminder: "Reminder",
    score: "Score",
    applied: "Applied date",
    lastActive: "Last Active on",
    back: "Back",
    next: "Next",
    invited: "Invited",
    cancel: "Cancel",
    fetch: "Fetch",
    profile: "Profile",
    update: "Update",
    reset: "Reset",
    all: "All",
    published: "Published",
    draft: "Draft",
    expired: "Expired",
    completed: "Completed",
    from: "From:",
    to: "To:",
    tests: "Tests",
    delete: "Delete",
    present: "Present",
    skip: "Skip",
    accept: "Accept",
    previewMode: "Preview Mode",
    disableValidations: "Disable Validations",
  },
  formValues: {
    addAnotherJob: "Add another job",
    viewMore: "View More",
    viewLess: "View Less",
  },
  modalValues: {
    deleteEmployment:
      "Are you sure you want to delete the employment history ?",
  },
  extraForm: {
    disableTitle: "Disability Disclosure Form",
    disableReasonTitle: "Why are you being asked to complete this form?",
    disableReasonText:
      "Because we do business with the government, we must reach out to, hire, and provide equal opportunity to qualified people with disabilities.  To help us measure how well we are doing, we are asking you to tell us if you have a disability or if you ever had a disability. Completing this form is voluntary, but we hope that you will choose to fill it out. If you are applying for a job, any answer you give will be kept private and will not be used against you in any way. If you already work for us, your answer will not be used against you in any way. ",
    disableConfirmTitle: "How do you know if you have a disability?",
    disableConfirmText:
      "You have a disability if you have a physical or mental impairment or medical condition that substantially limits a major life activity or if you have a history or record of such an impairment or medical condition",
    disableList: "Disabilities include, but are not limited to: ",
    disableOptionChoose: "Please check one of the boxes below",
    disableOption: {
      first: "Yes, I have a disability (or previously had a disability",
      second: "No, I do not have a disability",
      third: "I do not wish to disclose",
    },

    voluntaryTitle: "Voluntary Self Identification Form",
    voluntaryText1:
      "The Equal Employment Opportunity Commission (EEOC) requires private employers with 100 or more employees and federal contractors and primary subcontractors with 50 or more employees to complete an EEO-1 report each year. Covered employers must invite employees to self-identify gender and race using this report. ",
    voluntaryText2:
      "This form will be used for EEO-1 reporting and kept separate from all other personnel records. Completing this form is voluntary and will not affect your employment opportunity or the terms or conditions of your employment.",
    gender: {
      text: "Gender",
      male: "Male",
      female: "Female",
      nonBinary: "Non-Binary",
      confidential: "I do not wish to disclose.",
    },
    raceEthnicity: {
      title: "RACE/ETHNICITY",
      text: "Please check the descriptions below corresponding to the ethnic group with which you identify.",
      type: {
        hispanicTitle: "Hispanic or Latino:",
        hispanicText:
          "A person of Cuban, Mexican, Puerto Rican, South or Central American, or other Spanish culture or origin regardless of race ",
        whiteNotHispanicTitle: "White (Not Hispanic or Latino):",
        whiteNotHispanicText:
          "A person having origins in any of the original peoples of Europe, the Middle East or North Africa ",
        blackAfricanAmericanTitle:
          "Black or African American (Not Hispanic or Latino):",
        blackAfricanAmericanText:
          "A person having origins in any of the black racial groups of Africa",
        hawaiianPacificTitle:
          "Native Hawaiian or Pacific Islander (Not Hispanic or Latino):",
        hawaiianPacificText:
          "A person having origins in any of the peoples of Hawaii, Guam, Samoa or other Pacific Islands",
        asianTitle: "Asian (Not Hispanic or Latino):",
        asianText:
          "A person having origins in any of the original peoples of the Far East, Southeast Asia or the Indian Subcontinent, including, for example, Cambodia, China, India, Japan, Korea, Malaysia, Pakistan, the Philippine Islands, Thailand and Vietnam",
        nativeAmericanTitle:
          "Native American or Alaska Native (Not Hispanic or Latino):",
        nativeAmericanText:
          "A person having origins in any of the original peoples of North and South America (including Central America) and who maintains tribal affiliation or community attachment",
        twoOrMoreRaceTitle: "Two or more races (Not Hispanic or Latino):",
        twoOrMoreRaceText:
          "All persons who identify with more than one of the above five races",
        confidential: "I do not wish to disclose",
      },
    },
  },
  userMenu: {
    editProfile: "Edit Profile",
    accountSetting: "Account Setting",
    logout: "Logout",
    applicant: "applicant",
    name: "Name:",
    role: "Role:",
    candidates: "candidates",
    modeSwitch: "Mode Switch",
    myTest: "My Tests",
    myJobs: "My Jobs",
    myTraining: "My Training",
    myReport: "My Report",
    badges: "Badges",
  },

  gameType: {
    tutorials: "Tutorials",
    practice: "Practice",
    assessment: "Assessment",
  },

  jobsFilter: {
    currentJobs: "Current Job",
    progress: "In Progress",
    disable: "Disabled",
    statusUnavailable: "Status Unavailable",
  },

  auth: {
    otp: {
      resend: "Resend a verification code",
      subHeading: "Please enter the OTP code from your email",
      title: "Enter OTP code here",
    },
    signUp: {
      createAccount: "Create new Account",
      verifyEmail: "Awesome! Let's verify your email",
      verifyEmailInfo:
        "To start using intelliante platform you need to verify your email address.",
    },
  },
  number: {
    twoHundred: "200",
    twentyfiveHundred: "2500",
    fiveThousand: "5000",
    tenThousand: "10000",
  },
  common: {
    present: "Present",
    add: "Add",
    newest: "Newest",
    oldest: "Oldest",
    first: "First",
    cancel: "Cancel",
    filter: "Filter Result",
    disable: "Disable",
    clickHere: "Click here",
    save: "Save",
    anotherEducation: "Add another education level",
    confirmEducationDelete:
      "Are you sure you want to delete the education from :school_name?",
    confirmEmploymentDelete:
      "Are you sure you want to delete the employment from :employer?",
    anotherJob: "Add another job",
    saveChange: "Save change",
    withdraw: "Withdraw",
    delete: "Delete",
    learnMore: "Learn More",
    next: "Next",
    browse: "Browse",
    continue: "Continue",
    published: "Published",
    edit: "Edit",
    changePassword: "Change Password",
    details: "Details",
    sortBy: "Sort by:",
    name: "Name",
    atoZ: "A to Z",
    edit_details: "Edit Details",
    seeDetails: "See Details",
    found: "Found",
    location: "Location",
    previewWorkflow: "Preview Workflow",
    editDetail: "Edit detail",
    currentJobTitle: "Current job title",
    letsGetStarted: "Lets get started!",
    continueWorkflow: "Continue Workflow",
    addEducation: "Add Education History",
    editEducation: "Edit Education History",
    addEmployment: "Add Employment History",
    editEmployment: "Edit Employment History",
  },
  timeAttributes: {
    month: "Month",
    monthly: "Monthly",
    yearly: "Yearly",
  },
  message: {
    noCourse: {
      title: "You are not enrolled to any of the courses till now.",
      subtitle: "If you want to enrolled the course please choose the course",
    },
    availableMsg:
      "All these Course here are general courses and is not directly linked with the tests or certifications. You can go to the course details and see if it is for your or not.",
    auth: {
      emailVerified: "Email verified",
      sessionExpiryMessage: "Your session has expired",
      loginAgainMessage: "Please login again",
      emailInvalidMessage: "Email cannot be empty.",
      passwordInvalidMessage: "Password cannot be empty.",
    },

    plan: {
      mostPopular: "Most Popular",
      selectedPlan: "Selected Plan",
      fullFeature: "Expand Full Feature Comparision",
      offer: "Get 5% discount on yearly subscription",
    },
    employmemtDetails: {
      noEmploymentHistory: "There is no Employment History added.",
      pleaseCheck:
        "If you have experience, please un-check the box to enter your",
      experienceDetails: "enter your experience details.",
      addAnotherJob: "Add another Job",
      addEmployment: "Please click the button and add your Employment History",
      briefDescription: "Brief description of duties",
    },
    educationDetails: {
      noEducationHistory: "There is no Education added.",
      addEducation: "Please click the button and add your Education History",
    },

    workflowSetup: {
      yearError: "End year cannot be less than start year",
      createWorkflow: "Create your Workflow",
      workflowSetup: "Workflow Setup",
      extraSettings: "Extra settings for workflow",
      reviewJobInfo:
        " Review the job you have recently created and publish if everything looks good",
      enabled: "Enabled",
      disabled: "Disabled",
      closingMessage: {
        closingMessage: "Closing Message",
        closingMessageInfo:
          "Customize closing message which appears at the last when applicant complete test",
        closingMessageExample:
          "Eg: Thank you for taking your time to fill this form",
      },

      evaluateResult: {
        evaluateResult: "Evaluate the incomplete result?",
        evaluateResultInfo:
          "  Even if customer want to evaluate the incomplete results, candidate must have to complete at least 1 test/ game.",
      },

      scheduleTest: {
        scheduleTest: "Schedule Test",
        startTheTestDetail:
          " Start the test from your convinience time by sheduling it. You can set start date / time and end date / time.",
        fromDateTime: "From (date & time)",
        toDateTime: "To (date & time)",
      },
      timeBound: {
        timeBound: "Time Bound",
        timeBoundInfo:
          " By specifying time bound, the candidate are automatically disabled, if they do not start the test within the specified number of days after onboarding",
        noOfDays: "No. of Days",
        afterSignup: "After Sign up",
        timeBoundWarning: "Time bound must not exceed {{count}} days",
        enterTimeBound: "Please enter bound time",
      },
    },

    roles: {
      noRoles: "No Roles Added",
      noRolesDetail:
        "No roles has been added till now. Please click on the button below to add new roles and get it listed here.",
      selection: "Select all the managements",
    },

    branding: {
      label: "Branding",
    },

    users: {
      label: "Users",
      add: "Add New Users",
      bulkUpload: "Or add multiple users with bulk upload feature",
      edit: "Edit User’s Detail",
      noUsers: "No Users Added",
      noUsersDetail:
        "No users has been added till now. Please click on the button below to add new user and get it listed here",
    },

    candidates: {
      active: "Active Applicants",
      disable: "Confirmation about disable the applicant",
      disableConfirm: "Are you sure you want to disable the applicant ?",
      total: "Total Applicants",
      invite: "Invite Applicants",
      noApplicant: "No Applicants Invited",
      invite: "Invite Applicants",
      noApplicantDetails:
        " We cannot find the applicant you are searching.Try to invite the applicant",
      noApplicantDetail:
        "There is no applicants added yet. Click on Invite applicant to invite the applicants for this job",
    },

    locations: {
      label: "Locations",
      add: "Add Location",
      noLocation: "No Location Added",
      isCorporate: "This is Corporate Address",
    },

    files: {
      drop: "Drop your file here or Click to upload",
      maxSize: "You can upload upto 100MB. Download template file here",
    },

    applicants: {
      invite: {
        inviteCandidates: "Invite Candidates",
        inviteTitle: "Invite Applicants to this job",
        inviteInfo:
          " Your applicants will get an email of this invitation and they can sign up and start using intelliante plaform.",
        addUser:
          "  Add user to roles (you can add multiple emails separated with comma(,) )",
        invalidEmail: "Invalid email!!",
        multipleEmail: "to upload the multiple email at once with CSV Files",
        selectEmail: "Please select atleast one email.",
        inviteConfirm: "Are you sure you want to invite all the applicants?",
        inviteInfo: "By clicking the send button",
        receiveInvite: "Your applicants will get an email of this invitation",
        startPlatform:
          "Applicants can signup and start using intelliante plaform.",
      },
    },
    applicantDashboard: {
      applicationAdventures: "Your Application Adventures",
      background: "Background",
      enterBackgroundDetails:
        "Enter your background details and unlock the tests assigned to you.",
      jobOverview: "Job Overview",
      detailOfJob:
        "You can see detail of the job and the company that you are invited to.",
      completed: "Completed",
      tests: "Tests",
      assessment: "Assessment",
      startTests:
        "Start the tests and know your strength and weakness for the job.",
      testAssigned: "Tests assigned to you",
      completedTest: "You have completed all assigned test.",
      stepsCompleted: "Steps completed",
      noAssessments: "No assessment to attain",
    },

    confirmation: {
      paymentConfirmation: "Payment Confirmation",
    },

    thankMessage: {
      forChoosingIntelliante: "Thank you for choosing Intelliante",
    },

    jobs: {
      jobTitle: "Job Title *",
      jobDetails: "Job Details",
      jobCreationDate: "Job Creation Date",
      lastUpdated: "Last Updated",
      jobType: "Job Type *",
      jobRequistionNo: "Job Requistion No *",
      noRequisitionNO: "No Requisition Number",
      jobReview: "Review Job Information",
      updateChanges: "Update Changes",
      jobReviewInfo:
        "Review the job you have recently created and publish if everything looks good",
      editJob: "Edit Job Details",
      onetJob: "Onet Jobs",
      clearFilter: "Clear Filter",
      createdDate: "Created Date",
      onetJob: "Onet Job *",
      onetInfo:
        " ONET jobs can help you find out the closest job title from ONET.",
      onetJobInfo:
        " The Occupational Information Network (ONET) is a free online database that contains hundreds of occupational definitions to help businesses and workforce development professionals to understand today's world of work.",
      jobDescription: "Job description",
      fetchDescription: "Fetch Job description from oNet",
      selectOne: " Please select one of ONET job tag to fetch its description:",
      add: "Add new job",
      allJobs: "All Jobs",
      noJobs: "No Jobs Found",
      reqNumberTaken: "Requisition number already taken",
      resultsFound: "{{count}} results found",
      jobAnalysisSurvey: "Job Analysis Survey",
      jobAnalysisSurveyInfo:
        " Based on the ONET Job title you selected, here are two survey link for you",
      taskSurvey: "Task Survey",
      workSurvey: "Work Survey",
      noJobsDetail:
        "We cannot find the jobs you are searching. Try to add new jobs",
      withdraw: {
        label: "Withdraw Job",
        confirm: "Are you sure you want to withdraw the job?",
        message: "By clicking the withdraw button",
        pointOne: "Applicant that are already invited are disabled",
        limitActions: "You can only view details but cannot take any actions",
        withrawSuccess: "Job withdrawn sucessfully",
      },

      delete: {
        confirm: "Are you sure that you want to delete this job",
        message: "By clicking the delete button",
        deletePermanently: "Your job will be delete permanently.",
        cannotRestore: "After deleting the job you cannot restore the job.",
        deleteSuccess: "Job Deleted successfully",
      },

      clone: {
        cloneJob: "CLone this job",
      },
    },
    jobDetails: {
      welcome: "Welcome to your dashboard",
      portal: {
        start: "You are currently on the ",
        end: " portal",
      },
      jobDetailTitle: "Job Details",
      jobTitle: "Job Title",
      jobType: "Job Type",
      company: "Company",
      testAssigned: "Tests Assigned",
      startDate: "Start Date",
      endDate: "End Date",
      appliedDate: "Applied Date",
      location: "Location",
      jobDesc: "Job Description",
      companyInfo: "Company Info",
      seeMore: " See More",
      seeLess: " See Less",
      lineOfBusiness: "Line of business",
      na: "N/A",
    },
    errors: {
      missingEducationDetails: "Enter Atleast one Education History",
      missingEmploymentDetails: "Enter Atleast one Employment History",

      recoveryPassword: {
        recoveryPassword: "Recovery Password",
        hooray: "Hooray!",
        sendRecoveryLink: "We will send a recovery link to the email",
        ifAccountExists: "address if an account exists. Just click the",
        getLink: "link when you get it.",
      },
      preview: {
        assessmentMessage:
          "Applicant will be attending {{assessmentName}} in this step.",
      },
    },

    recoveryPassword: {
      recoveryPassword: "Recovery Password",
      hooray: "Hooray!",
      sendRecoveryLink: "We will send a recovery link to the email",
      ifAccountExists: "address if an account exists. Just click the",
      getLink: "link when you get it.",
    },
    jobList: {
      selectJob: "Select Job",
      chooseOneJob:
        "Please choose one of the job from the list to enter the dashboard",
    },
    preview: {
      assessmentMessage:
        "Applicant will be attending {{assessmentName}} in this step.",
    },
    modal: {
      otpSuccess: {
        createDashboard: "Let's create your dashboard",
      },
      courseTitle: "Course Information",
      thankYou: "Thank You",
      backToDashboard: "Back to dashboard",
    },
    applicantReport: {
      candidateDetail: {
        your: "Your",
        competitiveness: "Competitiveness",
        andPerfessional: "and Perfessional Growth Dashboard",
        ourGoalIsFor:
          "Our goal is for you to land the perfect job. We are investing in you by providing the skills-building tools you need to become a more competitive job applicant.",
        theseServicesAre: "These services are 100% free to you.",
        summaryReportFor: "Summary report for",
        job: "Job",
      },
      howItWorks: {
        howItWorks: "Here's How it works",
        toolTipData: {
          thatHelpYou: "That help you stand out from other applican",
          toWatchOutFor: "To Watch Out for so that they do not hold you back",
        },
        steps: {
          step: "Step",
          useThisReport: "Use this report to learn about your skills.",
          completeFreeOnline:
            "Complete free online courses to develop your skills and earn certificates.",
          addYourSkills: "Add your skill certifications to your résumé.",
        },
      },
      stickyNav: {
        yourDetail: "Your Detail",
        howItWorks: "Here's How It Works",
        testAssigned: "Test Assigned",
        resultSummary: "Result Summary",
        overallCompetitiveness: "Overall Competitiveness",
      },
      testAssigned: {
        testAssignedTo: "Test assigned to you",
        dependingOntypeOfTest:
          " Depending on the type of test, the tests are classified as either social or job skills. And the tests with a  ",
        checkedGreenBox: "checked box indicates",
        thatYouHaveCompleted: "that you have completed them.",
      },
      error: "Error",
      standOut: "Stand Out",
      watchOut: "Watch Out",
      mostApplicants: "Most Applicants",
      whatYourResult: "What Your Results Mean",
      businessesWritinSkills: "Businesses Writing Skills",
      basicComputerSkills: "Basic Computer Skills",
      communication: "Communication",
      overcomingAdversity: "Overcoming Adversity",
      tenKey: "10-key",
      typing: "Typing",
      mccsVoiceCollection: "MCCS Voice Collection",
      mccsVoiceCustomerService: "MCCS Voice Customer Service",
      mccsChatCustomerService: "MCCS Chat Customer Service",
      mccsChatSales: "MCCS Chat Sales",
      mccsVoiceSales: "MCCS Voice Sales",
      mccsVoiceTechnicalSupport: "MCCS Voice Technical Support",
      mccsChatTechnicalSupport: "MCCS Chat Technical Support",

      overallCompetitiveness: {
        yourOverall: "Your Overall",
        competitiveness: "Competitiveness",
        compared: "Compared",
        toOtherApplicants: "to Other Applicants",
      },

      talentPool: {
        howYou: "How you",
        compare: "Compare",
        toApplicant: "to Applicant",
        applyingFor: "Applying for Similiar Jobs",
        skillsThatDont: "Skills that Dont't Help or hold you back",
        standOutSkills: "Stand Out Skills that drive you forward",
      },

      applicantDoNotHave:
        "The applicant do not have any skills that hold them back in this test",
      measuresAperson:
        "Measures a person’s Business writing skills on 20 criteria, such as speaking, grammer, punctuation, readability and verb use.",
      businessesWritinScale: {
        collocation: "Collocation",
        whenAWordGoes:
          "When a word goes together with, relates to, or “naturally” selects another word to help define its meaning in a sentence, it is called collocation. A collocation error occurs when someone incorrectly pairs or arranges “naturally” co-occurring words.",
        confusedWords: "Confused words",
        theErrorOccurs:
          "The error occurs when someone uses an incorrect word when speaking or writing.",
        compounding: "Compounding",
        aCompoundingWord:
          "A compound word combines two or more other words to create a new word. Errors occur when someone misspells a compound word.",
        grammer: "Grammer",
        grammaticalErrorsRefers:
          "Grammatical errors refer to the incorrect use of the normative rules for constructing a sentence, such as word order, tense, point of view, and number.",

        typos: "Typos",
        thisTypeOf:
          "This type of error occurs when someone makes a mistake while typing or writing a word, such as a misspelling.",
        punctuation: "Punctuation",
        punctuationMarks:
          "Punctuation marks, such as periods, question marks, and apostrophes, make written text readable and understandable. An error occurs when a writer misuses punctuation marks.",
        redundancy: "Redundancy",
        thisErrorThat:
          "This error occurs when a writer uses more words than necessary to make a point, especially in the same sentence.",
        semantics: "Semantics",
        anErrorthat:
          "An error that occurs when a writer violates language rules, such as the wrong words, collocations, or lexicogrammatical (e.g., adjective in place of a noun).",
        styles: "Styles",
        stylisticErrorsOccur:
          "Stylistic errors occur when a writer, for example, is too wordy, uses incorrect words, overuses passive or active voice, relies on sentences that are too long or short, and has poor paragraph structure.",
        textAnalysis: "Text Analysis",
        readabilityDescribeHow:
          "Readability describes how easy it is to read something. Errors occur when writing is too difficult for people to read.",
        plainEnglish: "Plain English",
        clarityRefersTo:
          "Clarity refers to the ease with which someone can understand what they are reading. An error occurs when writing is ambiguous or confusing.",
      },

      overcomeAdversityScale: {
        wantToperformWell:
          "Want to perform well, especially when others are watching.",
        seekFeedbackAnd:
          "Seek feedback and advice that will help you keep improving.",
        spendTimeThinking:
          "Spend time thinking about past mistakes or missteps to avoid them in the future.",
        avoidDwellingOn:
          "Avoid dwelling on negative information and look for opportunities in bleak situations.",
        recognizeWhatCan:
          "Recognize what can and cannot be changed, and focus on things that can be altered.",
        strengthenTheSocial:
          "Strengthen the social support system, and be sure to invest time in supporting others.",
        bePositiveAnd:
          "Be positive and realistic, though they may occasionally focus on negative information in difficult times.",
        distinguishBetweenIssues:
          "Distinguish between issues they can and cannot change reasonably well, and focus energy accordingly.",
        haveARelatively:
          "Have a relatively well-developed social network that provides support when needed.",
        dwellOnNegative:
          "Dwell on negative information periodically, so it’s essential to recognize this tendency and actively search for positives.",
        imprveTheAbility:
          "Improve the ability to identify what one can and cannot change and avoid wasting time trying to influence things they have no control over.",
        expandTheirSocial:
          "Expand their social support system, and invest time in helping others.",
        haveaPositive:
          "Have a positive, realistic outlook and focus on opportunities instead of dwelling on negative information.",
        identifyIssuesThat:
          "Identify issues that they can and cannot change accurately, and focus their energy on those issues they can impact positively.",
        careForOthers:
          "Care for others and have a well-developed social support system.",
        neverStopBuilding: "Never stop building a robust social network.",
        takeCareOf:
          "Take care of themselves by getting enough sleep, having a proper diet, and exercising regularly.",
        makeEveryDay:
          "Make every day meaningful by focusing on achieving a daily goal.",
      },

      communicationScale: {
        avoidconfrontation: "Avoid confrontation and anxiety",
        minimizeOpposition: "Minimize opposition and criticism",
        changeTheirMind: "Change their mind with few (if any) consequences",
        takeTimeTo:
          "Take time to understand – listen – what someone is saying. Ask questions and then restate what you heard to confirm.",
        challengeAssumption:
          "Challenge assumptions and search for similarities when communicating with someone.",
        treatOthersWith:
          "Treat others with respect and make them feel like an equal.",
        listenWellwhen:
          "Listen well when interacting with others but may try to avoid sensitive issues to lessen the potential for confrontation or anxiety.",
        expressOpinions:
          "Express opinions and suggestions on most issues openly but may be non-commital on sensitive topics.",
        communicatePositionsClearly:
          "Communicate positions clearly unless there’s a significant risk of criticism or opposition.",
        practiseActiveListening:
          "Practice active listening by engaging the speaker through verbal and non-verbal affirmations and asking questions to verify understanding.",
        manageEmotions:
          "Manage emotions effectively while interacting with others, especially on sensitive issues. Express feelings in a professional manner to avoid escalating matters.",
        treatOthersWith:
          "Treat others with respect and admiration; make them feel valued.",
        listenWellwhen:
          "Listen well when communicating with others, and ask insightful questions that encourage ongoing dialog.",
        expressThoughts:
          "Express thoughts and concerns openly, honestly, and authentically.",
        askQuestionsThat:
          "Ask questions that encourage others to share ideas, insights, and suggestions. Take time to clarify issues and ensure proper understanding.",
        focusOnNon:
          "Focus on non-verbal communication to improve the connection with the other person.",
        manageEmotionsWhile:
          "Manage emotions while communicating with others; express feelings appropriately.",
        askCoworksAnd:
          "Ask coworkers and colleagues for feedback on communication to help you continue to improve.",
      },

      basicComputerScale: {
        security: "Security",
        surveysAPersons:
          "Surveys a person’s knowledge of basic steps to prevent unauthorized access to company assets, including computers, networks, and data.",
        operatingSystem: "Operating System",
        gaugesAPersons:
          "Gauges a person’s knowledge of and skill in using software supporting a computer’s core functions, such as executing applications and controlling peripherals. ",
        applications: "Applications",
        evaluatesAPersons:
          "Evaluates a person’s knowledge of and skill in using essential business software, such as email or a web browser. ",
        Messaging: "Messaging",
        assessesAPersons:
          "Assesses a person’s knowledge of and skill in using instant messaging software.",
        storage: "Storage",
        checksAPersons:
          "Checks a person’s knowledge of standard storage devices, such as a USB drive.",
        shortcuts: "Shortcuts",
        measuresAPerson:
          "Measures a person’s knowledge of common shortcut key combinations that improve efficiency, such as CTRL-C for copying information.",
        fileManagement: "File Management",
        examinesAPersons:
          "Examines a person’s knowledge of and skill in managing files on a computer, including creating, renaming, and deleting a file.",
        internet: "Internet",
        analyzesAPerson:
          "Analyzes a person’s knowledge of internet usage, including restoring a lost connection and clearing a cache. ",
      },

      keyTest: {
        rawKpm: "Raw KPM",
        netkpm: "Net KPM",
        rawWpm: "Raw WPM",
        netWordsPerMinute: "Net Words Per Minute",
      },

      mccsScaleData: {
        accuracy: "Accuracy",
        itIsAnIndex:
          "It is an index of data-entry quality based on accuracy and completeness.",
        customerService: "Customer Service",
        theAssistance:
          "The assistance and advice provided to people who buy or use a company’s products or services. .",
        multitasking: "Multitasking",
        aMeasureOfaPerson:
          "A measure of a person’s efficiency and effectiveness while performing more than one task simultaneously. ",
        navigation: "Navigation",
        openingAndMoving:
          "Opening and moving through computer menus efficiently and accurately. ",
        quality: "Quality",
        itIsanIndicator:
          "It is an indicator of a person’s compliance with a company’s procedures and standards. ",
        troubleshooting: "Troubleshooting",
        anIndexOfa:
          "An index of a person’s skill in solving a complex problem.",
        sales: "Sales",
        aMetricSummarizing:
          "A metric summarizing a person’s skill in navigating a customer engagement to complete a deal.",
      },
    },
  },
  welcomePage: {
    welcome: "Welcome!",
    weAreGlad: "We're glad you're here!",
    youAreTwoSteps: "You are two step closer to your dream job!",
    getStarted: "GET STARTED",
  },
  label: {
    login: "Login",
    viewAll: "View all courses",
    availableCourses: "Available Training Courses",
    inProgressCourse: "In Progress Courses",
    completedCourses: "Completed Courses",
    noCourseInProgress: "There are no courses in progress",
    noCourseInCompleted: "There are no courses in completed",
    available: "Aavailabe Course",
    inProgress: "In Progress",
    completed: "Completed",
    educationListTitle: "List of education history",
    content: "CONTENT",
    rules: "COMPLETION RULES",
    courseCategory: "Course Category",
    launchSetup: "Launch Setup",
    sendVerificationCode: "Send Verification Code",
    otpVerify: "Verify Now",
    sendRecoveryInstruction: "Send Recovery Instruction",
    sendingRecoveryInstruction: "Sending Recovery Instruction",
    roles: {
      add: "Add Role",
      addNew: "Add New Roles",
    },
    backToSignIn: "Back To Sign In",
    label: {
      signIn: "Sign in",
      workEmail: "Work Email Address",
      password: "Password",
      forgotPassword: "Forgot Password?",
      launchSetup: "Launch Setup",
      sendVerificationCode: "Send Verification Code",
      otpVerify: "Verify Now",
      companyDetail: "Company Detail",
      roles: {
        add: "Add Role",
        addNew: "Add New Roles",
      },

      users: {
        add: "Add user",
      },
    },

    applicants: {
      sendInvitation: "Send Invitation",
      howMany: "how many Applicants?",
      inviteApplicants: "Invite Applicants",
    },

    profile: {
      tellAboutYourself: "Tell us about yourself",
      fullName: "Full Name",
      whatShouldWeCall: "What should we call you when we talk?",
      jobTitle: "Current Job Title",
      phoneNumber: "Work Phone Number",
      uploadProfile: "Upload profile image",
      imageInfo: "The image must be a file of type: jpg, png, jpeg, gif, svg.",
      editProfile: "Edit Profile",
      uploadPicture: "Upload Picture",
      error: {
        enterName: "Enter your full name",
        enterJob: "Enter your current job",
        enterNickname: "Enter what should we call you when we talk",
        enterNumber: "Enter your work phone number",
      },
    },
  },
  applicantReport: {
    candidateDetail: {
      your: "Your",
      competitiveness: "Competitiveness",
      andPerfessional: "and Perfessional Growth Dashboard",
      ourGoalIsFor:
        "Our goal is for you to land the perfect job. We are investing in you by providing the skills-building tools you need to become a more competitive job applicant.",
      theseServicesAre: "These services are 100% free to you.",
      summaryReportFor: "Summary report for",
      job: "Job",
    },
    howItWorks: {
      howItWorks: "Here's How it works",
      toolTipData: {
        thatHelpYou: "That help you stand out from other applican",
        toWatchOutFor: "To Watch Out for so that they do not hold you back",
      },
      steps: {
        step: "Step",
        useThisReport: "Use this report to learn about your skills.",
        completeFreeOnline:
          "Complete free online courses to develop your skills and earn certificates.",
        addYourSkills: "Add your skill certifications to your résumé.",
      },
    },
    stickyNav: {
      yourDetail: "Your Detail",
      howItWorks: "Here's How It Works",
      testAssigned: "Test Assigned",
      resultSummary: "Result Summary",
      overallCompetitiveness: "Overall Competitiveness",
    },
    testAssigned: {
      testAssignedTo: "Test assigned to you",
      dependingOntypeOfTest:
        " Depending on the type of test, the tests are classified as either social or job skills. And the tests with a  ",
      checkedGreenBox: "checked box indicates",
      thatYouHaveCompleted: "that you have completed them.",
    },
    error: "Error",
    standOut: "Stand Out",
    watchOut: "Watch Out",
    mostApplicants: "Most Applicants",
    whatYourResult: "What Your Results Mean",
    businessesWritinSkills: "Businesses Writing Skills",
    basicComputerSkills: "Basic Computer Skills",
    communication: "Communication",
    overcomingAdversity: "Overcoming Adversity",
    tenKey: "10-key",
    typing: "Typing",
    mccsVoiceCollection: "MCCS Voice Collection",
    mccsVoiceCustomerService: "MCCS Voice Customer Service",
    mccsChatCustomerService: "MCCS Chat Customer Service",
    mccsChatSales: "MCCS Chat Sales",
    mccsVoiceSales: "MCCS Voice Sales",
    mccsVoiceTechnicalSupport: "MCCS Voice Technical Support",
    mccsChatTechnicalSupport: "MCCS Chat Technical Support",

    overallCompetitiveness: {
      yourOverall: "Your Overall",
      competitiveness: "Competitiveness",
      compared: "Compared",
      toOtherApplicants: "to Other Applicants",
    },

    talentPool: {
      howYou: "How you",
      compare: "Compare",
      toApplicant: "to Applicant",
      applyingFor: "Applying for Similiar Jobs",
      skillsThatDont: "Skills that Dont't Help or hold you back",
      standOutSkills: "Stand Out Skills that drive you forward",
    },

    applicantDoNotHave:
      "The applicant do not have any skills that hold them back in this test",
    measuresAperson:
      "Measures a person’s Business writing skills on 20 criteria, such as speaking, grammer, punctuation, readability and verb use.",
    businessesWritinScale: {
      collocation: "Collocation",
      whenAWordGoes:
        "When a word goes together with, relates to, or “naturally” selects another word to help define its meaning in a sentence, it is called collocation. A collocation error occurs when someone incorrectly pairs or arranges “naturally” co-occurring words.",
      confusedWords: "Confused words",
      theErrorOccurs:
        "The error occurs when someone uses an incorrect word when speaking or writing.",
      compounding: "Compounding",
      aCompoundingWord:
        "A compound word combines two or more other words to create a new word. Errors occur when someone misspells a compound word.",
      grammer: "Grammer",
      grammaticalErrorsRefers:
        "Grammatical errors refer to the incorrect use of the normative rules for constructing a sentence, such as word order, tense, point of view, and number.",

      typos: "Typos",
      thisTypeOf:
        "This type of error occurs when someone makes a mistake while typing or writing a word, such as a misspelling.",
      punctuation: "Punctuation",
      punctuationMarks:
        "Punctuation marks, such as periods, question marks, and apostrophes, make written text readable and understandable. An error occurs when a writer misuses punctuation marks.",
      redundancy: "Redundancy",
      thisErrorThat:
        "This error occurs when a writer uses more words than necessary to make a point, especially in the same sentence.",
      semantics: "Semantics",
      anErrorthat:
        "An error that occurs when a writer violates language rules, such as the wrong words, collocations, or lexicogrammatical (e.g., adjective in place of a noun).",
      styles: "Styles",
      stylisticErrorsOccur:
        "Stylistic errors occur when a writer, for example, is too wordy, uses incorrect words, overuses passive or active voice, relies on sentences that are too long or short, and has poor paragraph structure.",
      textAnalysis: "Text Analysis",
      readabilityDescribeHow:
        "Readability describes how easy it is to read something. Errors occur when writing is too difficult for people to read.",
      plainEnglish: "Plain English",
      clarityRefersTo:
        "Clarity refers to the ease with which someone can understand what they are reading. An error occurs when writing is ambiguous or confusing.",
    },

    overcomeAdversityScale: {
      wantToperformWell:
        "Want to perform well, especially when others are watching.",
      seekFeedbackAnd:
        "Seek feedback and advice that will help you keep improving.",
      spendTimeThinking:
        "Spend time thinking about past mistakes or missteps to avoid them in the future.",
      avoidDwellingOn:
        "Avoid dwelling on negative information and look for opportunities in bleak situations.",
      recognizeWhatCan:
        "Recognize what can and cannot be changed, and focus on things that can be altered.",
      strengthenTheSocial:
        "Strengthen the social support system, and be sure to invest time in supporting others.",
      bePositiveAnd:
        "Be positive and realistic, though they may occasionally focus on negative information in difficult times.",
      distinguishBetweenIssues:
        "Distinguish between issues they can and cannot change reasonably well, and focus energy accordingly.",
      haveARelatively:
        "Have a relatively well-developed social network that provides support when needed.",
      dwellOnNegative:
        "Dwell on negative information periodically, so it’s essential to recognize this tendency and actively search for positives.",
      imprveTheAbility:
        "Improve the ability to identify what one can and cannot change and avoid wasting time trying to influence things they have no control over.",
      expandTheirSocial:
        "Expand their social support system, and invest time in helping others.",
      haveaPositive:
        "Have a positive, realistic outlook and focus on opportunities instead of dwelling on negative information.",
      identifyIssuesThat:
        "Identify issues that they can and cannot change accurately, and focus their energy on those issues they can impact positively.",
      careForOthers:
        "Care for others and have a well-developed social support system.",
      neverStopBuilding: "Never stop building a robust social network.",
      takeCareOf:
        "Take care of themselves by getting enough sleep, having a proper diet, and exercising regularly.",
      makeEveryDay:
        "Make every day meaningful by focusing on achieving a daily goal.",
    },

    communicationScale: {
      avoidconfrontation: "Avoid confrontation and anxiety",
      minimizeOpposition: "Minimize opposition and criticism",
      changeTheirMind: "Change their mind with few (if any) consequences",
      takeTimeTo:
        "Take time to understand – listen – what someone is saying. Ask questions and then restate what you heard to confirm.",
      challengeAssumption:
        "Challenge assumptions and search for similarities when communicating with someone.",
      treatOthersWith:
        "Treat others with respect and make them feel like an equal.",
      listenWellwhen:
        "Listen well when interacting with others but may try to avoid sensitive issues to lessen the potential for confrontation or anxiety.",
      expressOpinions:
        "Express opinions and suggestions on most issues openly but may be non-commital on sensitive topics.",
      communicatePositionsClearly:
        "Communicate positions clearly unless there’s a significant risk of criticism or opposition.",
      practiseActiveListening:
        "Practice active listening by engaging the speaker through verbal and non-verbal affirmations and asking questions to verify understanding.",
      manageEmotions:
        "Manage emotions effectively while interacting with others, especially on sensitive issues. Express feelings in a professional manner to avoid escalating matters.",
      treatOthersWith:
        "Treat others with respect and admiration; make them feel valued.",
      listenWellwhen:
        "Listen well when communicating with others, and ask insightful questions that encourage ongoing dialog.",
      expressThoughts:
        "Express thoughts and concerns openly, honestly, and authentically.",
      askQuestionsThat:
        "Ask questions that encourage others to share ideas, insights, and suggestions. Take time to clarify issues and ensure proper understanding.",
      focusOnNon:
        "Focus on non-verbal communication to improve the connection with the other person.",
      manageEmotionsWhile:
        "Manage emotions while communicating with others; express feelings appropriately.",
      askCoworksAnd:
        "Ask coworkers and colleagues for feedback on communication to help you continue to improve.",
    },

    basicComputerScale: {
      security: "Security",
      surveysAPersons:
        "Surveys a person’s knowledge of basic steps to prevent unauthorized access to company assets, including computers, networks, and data.",
      operatingSystem: "Operating System",
      gaugesAPersons:
        "Gauges a person’s knowledge of and skill in using software supporting a computer’s core functions, such as executing applications and controlling peripherals. ",
      applications: "Applications",
      evaluatesAPersons:
        "Evaluates a person’s knowledge of and skill in using essential business software, such as email or a web browser. ",
      Messaging: "Messaging",
      assessesAPersons:
        "Assesses a person’s knowledge of and skill in using instant messaging software.",
      storage: "Storage",
      checksAPersons:
        "Checks a person’s knowledge of standard storage devices, such as a USB drive.",
      shortcuts: "Shortcuts",
      measuresAPerson:
        "Measures a person’s knowledge of common shortcut key combinations that improve efficiency, such as CTRL-C for copying information.",
      fileManagement: "File Management",
      examinesAPersons:
        "Examines a person’s knowledge of and skill in managing files on a computer, including creating, renaming, and deleting a file.",
      internet: "Internet",
      analyzesAPerson:
        "Analyzes a person’s knowledge of internet usage, including restoring a lost connection and clearing a cache. ",
    },

    keyTest: {
      rawKpm: "Raw KPM",
      netkpm: "Net KPM",
      rawWpm: "Raw WPM",
      netWordsPerMinute: "Net Words Per Minute",
    },

    mccsScaleData: {
      accuracy: "Accuracy",
      itIsAnIndex:
        "It is an index of data-entry quality based on accuracy and completeness.",
      customerService: "Customer Service",
      theAssistance:
        "The assistance and advice provided to people who buy or use a company’s products or services. .",
      multitasking: "Multitasking",
      aMeasureOfaPerson:
        "A measure of a person’s efficiency and effectiveness while performing more than one task simultaneously. ",
      navigation: "Navigation",
      openingAndMoving:
        "Opening and moving through computer menus efficiently and accurately. ",
      quality: "Quality",
      itIsanIndicator:
        "It is an indicator of a person’s compliance with a company’s procedures and standards. ",
      troubleshooting: "Troubleshooting",
      anIndexOfa: "An index of a person’s skill in solving a complex problem.",
      sales: "Sales",
      aMetricSummarizing:
        "A metric summarizing a person’s skill in navigating a customer engagement to complete a deal.",
    },
  },
};

export default { translation };
