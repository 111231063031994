import React, { useContext } from "react";

import EmploymentForm from "components/HistoryList/EmploymentForm";
import NoEmploymentSection from "components/LayoutComponent/EmploymentComponent/NoEmploymentSection";

import { EMPLOYMENT_DETAILS } from "constants/constant";
import { OnBoardingContext } from "contextAPI/onBoardingContext";

const EmploymentComponents = ({
  data,
  language,
  noWorkExperience,
  setStepValues,
  stepValues,
  handleClick,
  color,
  mode,
  setDetectError,
  templateName,
}) => {
  const { showEmploymentForm } = useContext(OnBoardingContext);

  if (!showEmploymentForm) {
    return null;
  }

  if (
    data.label.locales[language] === EMPLOYMENT_DETAILS.employment_history &&
    noWorkExperience === true
  ) {
    return <NoEmploymentSection />;
  }

  return (
    <EmploymentForm
      data={data}
      language={language}
      setStepValues={setStepValues}
      stepValues={stepValues}
      handleClick={handleClick}
      color={color}
      mode={mode}
      setDetectError={setDetectError}
      templateName={templateName}
    />
  );
};

export default EmploymentComponents;
