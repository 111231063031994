import GameList from "./Games";
import JobDetail from "./JobDetail";

const Container = ({ active }) => {
  switch (active) {
    case "games":
      return <GameList />;

    case "jobdetail":
      return <JobDetail />;

    default:
      return <GameList />;
  }
};

export default Container;
