import { createContext, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import ThankYouModal from "components/Modals/ThankYouModal";
import currentBrandingJson from "constants/brandingJson/index";
import { HOME } from "constants/routes";
import useWorkflowData from "hooks/useWorkflowData";
import {
  getInitialPage,
  getInitialValues,
  submitPageData,
} from "components/Onboarding/utils";
import { Wizard } from "components/Onboarding/Wizard";
import { Page } from "components/Onboarding/Page";

export const onboardingContext = createContext();

//Initial Onboarding Page for onboarding flow,
//TODO: Assessments
const Onboarding = ({ testMode, workflowId }) => {
  const navigate = useNavigate();

  const [showThankyouModal, setShowThankyouModal] = useState(false);
  const [page, setPage] = useState(0);
  const [mode, setMode] = useState("dark");
  const [color, setColor] = useState(currentBrandingJson.color);

  const { t } = useTranslation();

  const { workflow, isLoading, language, id, applicationState } =
    useWorkflowData({
      testMode,
      workflowId,
    });

  //TODO: handle submit logic
  function handleSubmit(values) {
    setShowThankyouModal(true);
  }

  function onNextPage(values, page) {
    if (testMode) {
      return submitPageData(
        values,
        workflow[page],
        applicationState,
        workflowId
      );
    }

    return submitPageData(values, workflow[page], applicationState, id);
  }

  if (isLoading)
    return (
      <div className="spinner-block">
        <div className="text-center">
          <div
            className="spinner-grow"
            style={{ width: "3rem", height: "3rem" }}
            role="status"
          ></div>
          <div className="mt-3 fs-3">Loading...</div>
        </div>
      </div>
    );

  if (!workflow) return <div>Workflow not found</div>;

  return (
    <onboardingContext.Provider
      value={{ workflow, testMode, language, mode, color }}
    >
      <ThankYouModal
        summary={t("message.applicantDashboard.completedTest")}
        link={HOME.INDEX}
        linkText={t("message.modal.backToDashboard")}
        onClick={() => navigate("/")}
        isVisible={showThankyouModal}
      />
      <Wizard
        appState={applicationState}
        onSubmit={handleSubmit}
        onNextPage={onNextPage}
        initialValues={getInitialValues(workflow)}
        initialPage={getInitialPage(applicationState)}
      >
        {workflow.map((page) => (
          <Page pageData={page} key={page.id} />
        ))}
      </Wizard>
    </onboardingContext.Provider>
  );
};

export default Onboarding;
