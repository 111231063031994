import React, { useState } from "react";

import CourseCard from "components/Dashboard/Accordion/CourseCard";
import { ChevronIcon } from "assets/icons/icons";

const AccordionItem = ({ name, id, enrolled, course, label }) => {
  const [isOpen, setIsOpen] = useState(true);

  const category = [];

  for (let i = 0; i < enrolled?.length; i++) {
    if (enrolled?.[i]?.category?.id === id) {
      category.push(enrolled?.[i]);
    }
  }

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const show = isOpen ? "show" : "";
  const isCollapsed = isOpen ? "collapsed" : "";

  const displayClassName = name ? "" : "d-none";

  return (
    <div className={`accordion-item ${displayClassName}`}>
      <h2 className="accordion-header" id={`heading-${id}`}>
        <button
          className={`accordion-button d-flex align-items-center bg-dark-0 ${isCollapsed}`}
          onClick={handleClick}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`collapse-${id}`}
          aria-expanded={!isOpen}
          aria-controls={`collapse-${id}`}
        >
          <span className="d-block me-auto"> {name} </span>
          <span className="accordion-icon">
            <ChevronIcon />
          </span>
        </button>
      </h2>
      <div
        id={`collapse-${id}`}
        className={`accordion-collapse collapse ${show}`}
        aria-labelledby={`heading-${id}`}
        data-bs-parent="#courses-accordion"
      >
        <div className="accordion-body">
          <div className="row">
            {category?.map((el) => (
              <CourseCard
                {...el}
                key={el.id}
                category_name={name}
                course={course}
                label={label}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
