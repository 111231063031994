import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { SIGN_IN } from "constants/routes.js";

import { AuthContext } from "contextAPI/AuthContext";

const ProtectedRoute = () => {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn) {
    return <Outlet />;
  }

  return <Navigate to={SIGN_IN.INDEX} />;
};

export default ProtectedRoute;
