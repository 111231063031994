import React from "react";

const LogoutIcon = ({ className, color, width, height }) => {
  return (
    <>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <g clip-path="url(#clip0_23722_214641)">
          <path
            d="M10.6654 6.66797H7.9987C7.29145 6.66797 6.61318 6.94892 6.11308 7.44902C5.61298 7.94911 5.33203 8.62739 5.33203 9.33464V25.3346C5.33203 26.0419 5.61298 26.7202 6.11308 27.2203C6.61318 27.7204 7.29145 28.0013 7.9987 28.0013H15.5947"
            stroke="#B8C1BF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24 18.668V24.0013H29.3333"
            stroke="#B8C1BF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24.0013 14.668V9.33464C24.0013 8.62739 23.7204 7.94911 23.2203 7.44902C22.7202 6.94892 22.0419 6.66797 21.3346 6.66797H18.668"
            stroke="#B8C1BF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M16.0013 4H13.3346C11.8619 4 10.668 5.19391 10.668 6.66667C10.668 8.13943 11.8619 9.33333 13.3346 9.33333H16.0013C17.4741 9.33333 18.668 8.13943 18.668 6.66667C18.668 5.19391 17.4741 4 16.0013 4Z"
            stroke="#B8C1BF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M24.0013 29.3346C26.9468 29.3346 29.3346 26.9468 29.3346 24.0013C29.3346 21.0558 26.9468 18.668 24.0013 18.668C21.0558 18.668 18.668 21.0558 18.668 24.0013C18.668 26.9468 21.0558 29.3346 24.0013 29.3346Z"
            stroke="#B8C1BF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.668 14.668H16.0013"
            stroke="#B8C1BF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.668 20H14.668"
            stroke="#B8C1BF"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_23722_214641">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default LogoutIcon;
