import { useContext } from "react";
import { Field, useField } from "react-final-form";

import { RadioGroupWrapper } from "components/Form/RadioGroupWrapper";
import { RadioInput } from "components/Form/RadioInput";
import { onboardingContext } from "pages/Onboarding/OnBoarding";

import { VALIDATION_ON } from "utils/validation";
import {
  composeValidation,
  handleBooleanString,
  isRequired,
} from "components/Onboarding/utils";

export const Radio = ({ id, label, options, name, validations }) => {
  const { language } = useContext(onboardingContext);
  const {
    meta: { error, touched },
  } = useField(name, { subscription: { error: true, touched: true } });

  return (
    <RadioGroupWrapper
      id={id}
      label={label}
      error={touched && error}
      required={isRequired(validations)}
    >
      {options?.map((el, idx) => (
        <Field
          name={name}
          key={JSON.stringify(el)}
          type="radio"
          value={"" + el.value}
          parse={(val) => handleBooleanString(val)}
          format={(val) => "" + val}
          validate={composeValidation(
            validations,
            VALIDATION_ON.CHANGE,
            language
          )}
        >
          {({ input }) => (
            <div className="d-flex align-items-center ms-3 ps-4 position-relative">
              <RadioInput
                id={id}
                idx={idx}
                inputProps={input}
                language={language}
                option={el}
              />
            </div>
          )}
        </Field>
      ))}
    </RadioGroupWrapper>
  );
};
