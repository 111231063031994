import React, { useContext } from "react";

import ButtonLoading from "pages/Partials/ButtonLoading";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

const LoadingState = ({ loadingLabel, defaultLabel, value, isLoading }) => {
  const { isNextPressed } = useContext(OnBoardingContext);

  if (isNextPressed && value === "next")
    return <ButtonLoading btnLabel={loadingLabel} />;

  if (isLoading) return <ButtonLoading btnLabel={loadingLabel} />;

  return defaultLabel;
};

export default LoadingState;
