import React from "react";

import { t } from "i18next";

import MccsLayout from "components/Report/HelperComponents/MCCSLayout";
import { TEST_NAME } from "constants/applicantReport";

const MCCSChatSales = ({ assessments }) => {
  const title = t("applicantReport.mccsChatSales");
  const subtitle = t("applicantReport.measuresAperson");
  const MCCSChatSalesData = assessments.find(
    (assessment) => assessment.name === TEST_NAME.MCCS_CHAT_SALES
  );

  return (
    <MccsLayout data={MCCSChatSalesData} title={title} subtitle={subtitle} />
  );
};

export default MCCSChatSales;
