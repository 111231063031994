import React, { useContext, useEffect } from "react";

import IndividualGame from "components/IndividualGame/IndividualGame";
import { CandidateGamesContext } from "contextAPI/CandidateGamesContext";
import { UserContext } from "contextAPI/UserContext";
import { ClockIcon } from "assets/icons/icons";

const GAME_MODES = [
  {
    name: "tutorial",
  },
  { name: "practice" },
  { name: "assessment" },
];

const getGameLink = (link, token) => `${link}?session_token=${token}`;

const GameList = () => {
  const { selectedCandidateId } = useContext(UserContext);
  const { games, fetchCandidateGames } = useContext(CandidateGamesContext);

  useEffect(() => {
    if (selectedCandidateId) fetchCandidateGames(selectedCandidateId);
  }, [selectedCandidateId]);

  return (
    <>
      <div className="py-4 top-info opacity-0">
        Total Estimated Completion Time:{" "}
        <span className="label d-inline-block bg-success ms-2 py-1 px-2 roundedtext-white small">
          10 hrs <ClockIcon className="color-icon ms-1 white" />
        </span>
      </div>
      <div className="test-holder row position-relative z-index-1">
        {games.map(
          ({
            id,
            game,
            is_practice_complete: isPracticeComplete,
            is_assessment_complete: isAssessmentComplete,
            is_tutorial_complete: isTutorialComplete,
            status,
            game_token: gameToken,
            is_locked: isLock,
          }) => (
            <IndividualGame
              key={id}
              modes={GAME_MODES}
              isloading={true}
              connected={true}
              lock={game?.locked}
              isLock={isLock}
              gameLink={getGameLink(game?.game_link, gameToken)}
              gameName={game?.game_label}
              id={id}
              gameStatus={status}
              gameIcon={game?.icon}
              practiceStatus={isPracticeComplete}
              assessmentStatus={isAssessmentComplete}
              tutorialStatus={isTutorialComplete}
            />
          )
        )}
      </div>
    </>
  );
};

export default GameList;
