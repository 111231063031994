import React from "react";

const ChevronIcon = ({ className, color, width, height }) => {
  return (
    <>
      <svg
        width={width ? width : "32"}
        height={height ? height : "32"}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
      >
        <path
          d="M21.9538 18.9801L16.0004 13.0234L10.0469 18.9801"
          stroke="#F8DC5E"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default ChevronIcon;
