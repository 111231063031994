import React from "react";

const Spinner = ({ children, loadSpinner }) => {
  return (
    <div className="centered">
      <div
        className={`spinner loading d-flex justify-content-center align-items-center ${
          loadSpinner ? "spin" : null
        }`}
      >
        <div className="loading-text">{children}</div>
      </div>
    </div>
  );
};

export default Spinner;
