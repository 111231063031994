import React, { useState, useEffect, useContext, useForm } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useStateMachine } from "little-state-machine";

import DropDown from "components/DropDown/DropDown";
import HelperText from "components/HelperText/HelperText";
import ErrorText from "components/ErrorText/ErrorText";
import EditorField from "components/EditorField/EditorField";
import updateAction from "components/InputField/updateAction";

import { OnBoardingContext } from "contextAPI/onBoardingContext";
import { UserContext } from "contextAPI/UserContext";

import {
  composeValidation,
  MONTHLY_VALUE,
  MONTH_FIELDS,
  VALIDATION_NAME,
  VALIDATION_ON,
  VALIDATION_STAGE,
} from "utils/validation";

import {
  EDUCATION,
  EMPLOYMENT,
  EMPLOYMENT_DETAILS,
  SUB_FORM_FIELDS,
} from "constants/constant";
import { LABEL } from "constants/formType";

import Enrolled from "./Enrolled";
import Checkbox from "./Checkbox";
import IconContainer from "components/IconContainer";

const InputField = ({
  data,
  type,
  onClick,
  label,
  defaultValue,
  className,
  options,
  language,
  icon,
  style,
  name,
  id,
  color,
  mode,
  helpText,
  validations,
  setStepValues,
  stepValues,
  setDetectError,
  onSubmit,
  allowMultiple,
  dynamicFieldId,
  submittedDataSection,
  templateName,
}) => {
  const { formTitle } = useContext(UserContext);
  const {
    formValues,
    setEducationFormData,
    setFormValues,
    setFormFieldData,
    setCheck,
    noWorkExperience,
    setNoWorkExperience,
    setEmploymentFormData,
    educationData,
    setEducationFormId,
    editEducationId,
    editEducationData,
    formValue,
    setFormValue,
    setCurrentlyEmployed,
    setCurrentlyEnrolledEducation,
    editEmploymentData,
    linkFields,
    setLinkFields,
  } = useContext(OnBoardingContext);

  const { state } = useStateMachine({ updateAction });
  const initialValue = [];
  const [error, setError] = useState("");
  const [updatedValue, setUpdatedValue] = useState("");

  const compareField = validations.find(
    ({ name, enabled }) =>
      name === VALIDATION_NAME.SEPARATED_DATE_LATER_THAN && enabled
  );

  useEffect(() => {
    if (!compareField) return;
    const {
      validation: {
        value: { fields },
      },
    } = compareField;

    const updatedFields = {};

    Object.values(fields).map((value) => {
      updatedFields[value] = null;
    });

    setLinkFields((prev) => ({
      ...prev,
      [`${templateName}`]: {
        ...updatedFields,
      },
    }));
  }, []);

  useEffect(() => {
    if (updatedValue && linkFields.hasOwnProperty(templateName)) {
      if (!linkFields[templateName].hasOwnProperty(name)) return;

      if (MONTH_FIELDS.includes(name)) {
        setLinkFields((previous) => ({
          ...previous,
          [`${templateName}`]: {
            ...previous[templateName],
            [name]: MONTHLY_VALUE[updatedValue],
          },
        }));
      } else {
        setLinkFields((previous) => ({
          ...previous,
          [`${templateName}`]: {
            ...previous[templateName],
            [name]: updatedValue,
          },
        }));
      }
    }
  }, [updatedValue]);

  useEffect(() => {
    if (compareField && updatedValue) {
      const error = compareField.validation.error.locales[language];
      const startYear = Number(linkFields[templateName].start_year);
      const endYear = Number(linkFields[templateName].end_year);

      if (startYear > endYear) {
        setError(error);
        updateStepValue(updatedValue, error, name);
      } else if (
        startYear === endYear &&
        linkFields[templateName].start_month ===
          linkFields[templateName].end_month
      ) {
        setError(error);
        updateStepValue(updatedValue, error, name);
      } else if (
        startYear === endYear &&
        linkFields[templateName].start_month <
          linkFields[templateName].end_month
      ) {
        setError(null);
      } else if (startYear < endYear) {
        setError(null);
      }
    }
  }, [linkFields]);

  const { section_name: sectionName } = data;

  useEffect(() => {
    const allData = {};

    submittedDataSection?.[0]?.forEach((item) => {
      const { key, value } = item;

      allData[key] = value;
    });

    setFormValue((prev) => {
      return { ...prev, ...allData };
    });
  }, [submittedDataSection]);

  useEffect(() => {
    setFormValues((prev) => {
      return { ...prev, ...editEducationData?.[0] };
    });
    setFormFieldData((prev) => {
      return { ...prev, ...editEducationData?.[0] };
    });

    // new edit
    setFormValue((prev) => {
      return { ...prev, ...editEducationData?.[0] };
    });
  }, [editEducationData, editEducationId]);

  useEffect(() => {
    if (!editEmploymentData) return;
    setFormValue((prev) => {
      return { ...prev, ...editEmploymentData?.[0] };
    });
  }, [editEmploymentData]);

  for (let i = 0; i < state?.["0"]?.length; i++) {
    if (state?.["0"]?.[i]?.dynamicFieldId == data?.dynamic_field_id) {
      initialValue.push({ value: state?.["0"]?.[i]?.value });
    }
  }

  useEffect(() => {
    if (!educationData.length) return;
    setFormValues((prev) => {
      return {
        ...prev,
        [dynamicFieldId]: null,
      };
    });
    setFormFieldData((prev) => {
      return {
        ...prev,
        [dynamicFieldId]: null,
      };
    });

    setStepValues((prev) => {
      return prev.map((item) => {
        if (item?.sectionName === LABEL.EDUCATION_COMPARE_TYPE) {
          return { ...item, value: "" };
        }

        return item;
      });
    });
  }, [educationData]);

  const updateStepValue = (value, msg, stepName = name) => {
    const copiedStepValues = [...stepValues];
    const filterElement = stepValues.find(
      (item) => item.name === stepName && item.sectionName === sectionName
    );

    const index = stepValues.findIndex(
      (item) => item.name === stepName && item.sectionName === sectionName
    );

    const copiedItem = { ...filterElement };

    copiedItem.value = value;

    copiedItem.error = msg;

    copiedStepValues[index] = copiedItem;

    setStepValues(copiedStepValues);
  };

  const onChangeDropdown = (label, value) => {
    setFormValue((prev) => {
      return { ...prev, [label]: value };
    });
  };

  useEffect(() => {
    if (!formValue[name]) return;

    const message = composeValidation(
      validations,
      VALIDATION_ON.CHANGE,
      language,
      formValue[name]
    );

    setStepValues((prev) => {
      return prev.map((i) => {
        if (i.name === name) {
          return {
            ...i,
            value: formValue[name],
            error: message,
          };
        }

        return i;
      });
    });
  }, [formValue, language, name, setStepValues, validations]);

  const checkOnChangeValidation = (e) => {
    if (e.target.name === EMPLOYMENT_DETAILS.current_employer) {
      setFormValue((prev) => {
        return { ...prev, [e.target.name]: e.target.checked };
      });
      setCurrentlyEmployed(e.target.checked);
    } else if (e.target.name === EMPLOYMENT_DETAILS.current_enrolled) {
      setFormValue((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
      setCurrentlyEnrolledEducation(e.target.value);
    } else {
      setFormValue((prev) => {
        return { ...prev, [e.target.name]: e.target.value };
      });
    }
    if (e.target.name === EMPLOYMENT.NO_WORK_EXPERIENCE) {
      setNoWorkExperience(!noWorkExperience);
    }

    if (type === "radio") {
      setCheck(e.target.value);
    }

    const message = composeValidation(
      validations,
      VALIDATION_ON.CHANGE,
      language,
      e.target.value
    );

    setError(message);
    updateStepValue(e.target.value, message);
    setUpdatedValue(e.target.value);
  };

  const onBlur = (e) => {
    setDetectError(VALIDATION_STAGE.UNVERIFIED);

    const message = composeValidation(
      validations,
      VALIDATION_ON.BLUR,
      language,
      e.target.value
    );

    setError(message);

    updateStepValue(updatedValue, message);
  };

  const classHelper = () => {
    if (error?.length) {
      return "border-danger";
    } else if (!error?.length && updatedValue) {
      return "border-primary";
    } else {
      return "";
    }
  };

  const iconStyle = (iconStyle) => {
    const customStyle = { ...iconStyle };

    if (style?.height) {
      customStyle.bottom = 0.584 + Math.abs(Number(style.height) - 45) + "px";
    }

    return customStyle;
  };

  const SetStepValues = () => {
    setStepValues((prev) => [
      ...prev,
      {
        name: name,
        type: type || name,
        value: null,
        error: composeValidation(
          validations,
          VALIDATION_ON.SUBMIT,
          language,
          null
        ),
        sectionName: data?.section_name,
      },
    ]);
  };

  useEffect(() => {
    SetStepValues();
  }, []);

  useEffect(() => {
    if (dynamicFieldId && updatedValue) {
      setFormValues((value) => {
        return {
          ...value,
          [dynamicFieldId]: updatedValue,
        };
      });
      setFormFieldData((value) => {
        return {
          ...value,
          [dynamicFieldId]: updatedValue,
        };
      });

      if (data?.section_name === LABEL.EMPLOYMENT_SECTION_TYPE) {
        setEmploymentFormData((oldValues) => ({
          ...oldValues,
          [dynamicFieldId]: updatedValue,
          [name]: updatedValue,
        }));
      }

      if (formTitle === LABEL.SECTION_TYPE) {
        setEducationFormData((value) => {
          return {
            ...value,
            [dynamicFieldId]: updatedValue,
            [name]: updatedValue,
          };
        });
      }
    }
  }, [updatedValue]);

  useEffect(() => {
    const arr = [];

    Object.keys(formValues).forEach((el) => {
      if (data?.section_name === LABEL.EDUCATION_COMPARE_TYPE) {
        setEducationFormId((prev) => [...prev, dynamicFieldId]);
      }
    });

    setEducationFormId(arr);
  }, [formValues]);

  const checkName =
    name === EDUCATION.END_YEAR_EDUCATION ||
    name === EDUCATION.START_YEAR_EDUCATION ||
    name === EMPLOYMENT_DETAILS.start_year ||
    name === EMPLOYMENT_DETAILS.end_year
      ? "number"
      : "text";

  // When form is submitted
  useEffect(() => {
    if (!onSubmit || !validations || (error && compareField)) return null;

    const message = composeValidation(
      validations,
      VALIDATION_ON.SUBMIT,
      language,
      updatedValue || formValue[name]
    );

    setError(message);

    if (!SUB_FORM_FIELDS.includes(name)) {
      if (message) {
        setDetectError(VALIDATION_STAGE.ERROR);
      }
    }

    updateStepValue(updatedValue, message);
  }, [onSubmit]);

  const dropdownChange = (value) => {
    const message = composeValidation(
      validations,
      VALIDATION_ON.CHANGE,
      language,
      value
    );

    setError(message);

    setUpdatedValue(value);
    onChangeDropdown(name, value);
  };

  const prefilled = state?.["0"]?.length;

  const iconClassName = icon?.enabled ? "icon-added" : "";
  const preFillClassName = prefilled
    ? "valid"
    : !formValues[dynamicFieldId]
    ? "non-required"
    : "required";

  const labelText = label?.enabled ? label?.locales?.[language] : " ";
  const inputFieldID = `flexCheckDefault${type}${id}`;

  return (
    <div className="form-floating">
      {type === "radio" ? (
        <div className="d-flex align-items-center">
          <label
            className="form-check-label radio-label bg-transparent"
            htmlFor={inputFieldID}
          >
            {label?.locales?.[language]}
          </label>
          <div className="d-flex align-items-center ms-4">
            {options?.map((el, idx) => (
              <Enrolled
                {...el}
                key={idx}
                data={data}
                id={idx}
                className={className}
                classHelper={classHelper}
                inputLabel={label?.locales?.[language]}
                language={language}
                checkOnChangeValidation={checkOnChangeValidation}
                onBlur={onBlur}
                name={name}
                templateName={templateName}
                error={error}
              />
            ))}
          </div>
        </div>
      ) : type === "checkbox" ? (
        <div className="d-flex align-items-center">
          <div className=" d-flex align-items-center">
            {options?.map((el, idx) => (
              <Checkbox
                {...el}
                key={idx}
                data={data}
                id={idx}
                className={className}
                classHelper={classHelper}
                language={language}
                checkOnChangeValidation={checkOnChangeValidation}
                label={label}
                templateName={templateName}
                name={name}
                error={error}
              />
            ))}
          </div>
        </div>
      ) : type === "select" && !allowMultiple ? (
        <DropDown
          options={options}
          language={language}
          onClick={onClick}
          label={label?.locales?.[language]}
          className={className}
          defaultValue={formValue[name]}
          htmlFor={inputFieldID}
          error={error}
          color={color}
          onChange={dropdownChange}
          mode={mode}
          name={name}
          textValue={formValue}
          templateName={templateName}
          onBlur={onBlur}
          setError={setError}
        />
      ) : type === "button" ? null : type === "editor" ? (
        <>
          <EditorField
            checkOnChange={(e) => {
              const value = e.target.value
                .replace("<div>", "")
                .replace("</div>", "");

              const message = composeValidation(
                validations,
                VALIDATION_ON.CHANGE,
                language,
                value
              );

              setError(message);
              setUpdatedValue(value);
              setFormValue((prev) => ({
                ...prev,
                [name]: value,
              }));

              updateStepValue(value, message);
            }}
            name={name}
            label={label?.locales?.[language]}
            textValue={formValue}
            templateName={templateName}
            onBlur={onBlur}
            setError={setError}
            data-template={templateName}
            data-field-type="multi-field"
          />

          <IconContainer
            icon={icon?.url}
            className={`${icon?.className} base-position color-icon`}
            style={iconStyle(icon?.style)}
          />
          <ErrorText error={error} name={name} className="text-danger" />
          <HelperText
            enabled={helpText?.enabled && !error.length}
            helpText={helpText}
            language={language}
            type={type}
            mode={mode}
            color={color}
          />
        </>
      ) : (
        <>
          <input
            type={checkName}
            onChange={checkOnChangeValidation}
            value={formValue[name]}
            className="form-control w-100"
            id={inputFieldID}
            name={name}
            onBlur={onBlur}
            data-template={templateName}
            data-field-type="input"
            autoComplete="off"
            placeholder={labelText}
          />
          <IconContainer
            icon={icon?.url}
            className={`${icon?.className} base-position color-icon`}
            style={iconStyle(icon?.style)}
          />

          <ErrorText error={error} name={name} className="text-danger" />
          <HelperText
            enabled={helpText?.enabled && !error.length}
            helpText={helpText}
            language={language}
            type={type}
            mode={mode}
            color={color}
          />
          <label
            className={`form-check-label position-absolute custom-paragraph-text ${preFillClassName} ${iconClassName}`}
            htmlFor={inputFieldID}
          >
            {labelText}
          </label>
        </>
      )}
    </div>
  );
};

export default InputField;
