import React from "react";

import IndividualPattern from "assets/icons/IndividualPattern";

const BackgroundPattern = ({ mobile }) => {
  return (
    <div className={`pattern-container ${mobile && " d-block d-md-none"}`}>
      <IndividualPattern className="position-absolute first-pattern" />
      <IndividualPattern className="position-absolute second-pattern" />
      <IndividualPattern className="position-absolute third-pattern" />
    </div>
  );
};

export default BackgroundPattern;
