import { t } from "i18next";
import { useContext } from "react";
import { Field } from "react-final-form";

import { onboardingContext } from "pages/Onboarding/OnBoarding";
import { RadioInput } from "components/Form/RadioInput";
import {
  getRaceOptions,
  getGenderOptions,
} from "./VoluntarySelfIdentificationOptions";

const VoluntarySelfIdentification = ({ templateName }) => {
  const { language } = useContext(onboardingContext);
  const raceOptions = getRaceOptions(t);
  const genderOptions = getGenderOptions(t);

  return (
    <div className="">
      <div className="extra-form position-relative  ">
        <header className="mb-3">
          <h2 className="custom-heading-text">
            {t("extraForm.voluntaryTitle")}
          </h2>
          <p>{t("extraForm.voluntaryText1")}</p>
          <p>{t("extraForm.voluntaryText2")}</p>
        </header>
        <div className="my-5 bg-dark py-4 rounded">
          <div className="container mt-3">
            <div className="form-group">
              <div className="mb-3">
                <strong>{t("extraForm.gender.text")}</strong>
              </div>
              <div className="radio-groups inline d-flex align-items-center">
                {genderOptions.map((option, idx) => (
                  <div
                    key={JSON.stringify(option)}
                    className="custom-control me-5 mb-4 custom-radio custom-control-inline d-flex align-items-center"
                  >
                    <Field
                      name={`${templateName}.gender`}
                      type="radio"
                      value={option.value}
                    >
                      {({ input }) => (
                        <RadioInput
                          id={"gender"}
                          idx={idx}
                          inputProps={{
                            ...input,
                            className: "custom-control-input me-2",
                          }}
                          language={language}
                          option={option}
                        />
                      )}
                    </Field>
                  </div>
                ))}
              </div>
            </div>

            <div className="form-group lg-list">
              <div className="mb-3">
                <strong className="mb-3 d-block">
                  {t("extraForm.raceEthnicity.title")}
                </strong>
                <p>{t("extraForm.raceEthnicity.text")}</p>
              </div>
              <div className="radio-groups d-flex flex-column align-items-start">
                {raceOptions.map((option, idx) => (
                  <div
                    key={JSON.stringify(option)}
                    className="custom-control me-5 mb-4 custom-radio custom-control-inline d-flex align-items-start"
                  >
                    <Field
                      name={`${templateName}.race`}
                      type="radio"
                      value={option.value}
                    >
                      {({ input }) => (
                        <RadioInput
                          id={"gender"}
                          idx={idx}
                          inputProps={{
                            ...input,
                            className: "custom-control-input me-2",
                          }}
                          language={language}
                          option={option}
                        />
                      )}
                    </Field>

                    <p>{option.helpText}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VoluntarySelfIdentification;
