import React, { useContext } from "react";
import { t } from "i18next";

import Add from "assets/images/add.svg";

import Table from "components/EducationList/Table";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

const EducationLists = (props) => {
  const { data, language } = props;

  const { setShowFormClass, showAddButton, setShowAddButton, educationData } =
    useContext(OnBoardingContext);

  const handleAdd = () => {
    setShowFormClass(true);
    setShowAddButton(false);
  };

  const showAddClass = showAddButton ? "" : "d-none";

  const showClassName = educationData?.length ? "" : "d-none";

  return (
    <div className="add-another mb-5">
      <h5
        style={data?.label?.style}
        className={`align-self-center  ${data?.label?.className} mb-3`}
      >
        {data?.label?.locales?.[language]}
      </h5>

      <span
        className={`btn w-100 add py-2 my-4 bg-dark ${showAddClass}`}
        onClick={handleAdd}
        aria-hidden
      >
        <span className="d-flex justify-content-center align-items-center">
          <img src={Add} alt="add sign" />
          {t("common.anotherEducation")}
        </span>
      </span>

      <div className={`title mb-3 ${showClassName}`}>
        <span className="list-title">{t("label.educationListTitle")}</span>
      </div>

      <Table />
    </div>
  );
};

export default EducationLists;
