import { useContext, useState } from "react";

import { DashboardContext } from "contextAPI/dashboardContext";

import Footer from "layout/Footer";
import Header from "./Header";
import Jobs from "./Jobs";
import Sidebar from "./Sidebar";

import DesktopPattern from "assets/icons/DesktopPattern";
import currentBrandingJson from "constants/brandingJson";

const DashboardLayout = ({ children, activeNav, setActiveNav }) => {
  const [switchJob, setSwitchJob] = useState(false);
  const { sidebarToggle } = useContext(DashboardContext);

  return (
    <>
      <div className={` dashboard-layout `}>
        <div className="wrapper">
          <Header
            languageList={currentBrandingJson.languages}
            company={currentBrandingJson.company}
            setSwitchJob={setSwitchJob}
          />
          <Sidebar
            company={currentBrandingJson.company}
            activeNav={activeNav}
            setActiveNav={setActiveNav}
          />

          <main class="main-content">
            <div>{children}</div>
          </main>

          <Footer
            data={currentBrandingJson.footer}
            company={currentBrandingJson.company}
          />

          <div className="position-fixed desktop-pattern overflow-hidden d-none d-md-block mx-5">
            <DesktopPattern className="color-icon" />
          </div>
        </div>
      </div>
      {switchJob ? (
        <Jobs setSwitchJob={setSwitchJob} switchJob={switchJob} />
      ) : null}
    </>
  );
};

export default DashboardLayout;
