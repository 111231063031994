import { Field } from "react-final-form";

export const ErrorText = ({ name }) => {
  return (
    <Field
      name={name}
      subscription={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span className=" text-error">{error}</span> : null
      }
    />
  );
};
