import { onboardingContext } from "pages/Onboarding/OnBoarding";
import { useContext } from "react";

function components(obj) {
  if (typeof obj === "object" && obj.hasOwnProperty("components")) {
    return obj.components.flatMap((i) => components(i));
  }

  return obj;
}

function getLabels(section, name) {
  const leaves = components(section);

  return leaves.find((i) => i.name === name)?.options;
}

export function useGetLabel(section) {
  const { language } = useContext(onboardingContext);

  return (name, value) => {
    const options = getLabels(section, name);

    return options?.find((i) => i.value === value)?.label?.locales?.[language];
  };
}
