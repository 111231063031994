import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PasswordChecklist from "react-password-checklist";

import { Spinner } from "components/Shared/Spinner";

import {
  TOP_LEFT_PATTERN,
  BOTTOM_RIGHT_PATTERN,
  MAIL_ICON,
} from "constants/images";

import AuthApi from "api/AuthApi/AuthApi";

import { LayoutContext } from "contextAPI/LayoutContext";
import { AuthContext } from "contextAPI/AuthContext";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";

import PasswordToggle from "./PasswordToggle";

import useAcceptInvitation from "hooks/useAcceptInvitation";

const SignUp = () => {
  const location = useLocation();

  const { getBranding, companyLogos, setIsLoggedIn, fetchCandidateUserInfo } =
    useContext(AuthContext);
  const { applyBrandingToApp } = useContext(LayoutContext);

  const [flag, setFlag] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const { handleAcceptInvitations } = useAcceptInvitation();

  const email = location?.state?.email;
  const invitationId = location?.state?.invitationId;

  useEffect(() => {
    if (password !== confirmPassword) {
      setFlag(true);
    } else {
      setFlag(false);
    }
  }, [password, confirmPassword]);

  const [isSigningUp, setIsSigningUp] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let body = {
      email: email,
      password: password,
      password_confirmation: confirmPassword,
      client_id: "8p-WdZKl-zdMfbIhlT90mu0yzE_3mYAma20PfL2EBHM",
    };

    const options = {
      method: "POST",
      headers: {
        "Content-type": "application/json;",
      },
      body: JSON.stringify(body),
    };

    try {
      setIsSigningUp(true);

      const response = await AuthApi.signUp({ user: body });

      if (response?.data) {
        LocalStorageManager.set(CONSTANTS.TOKEN_TYPE, "Bearer");
        LocalStorageManager.set(
          CONSTANTS.ACCESS_TOKEN,
          response?.data?.access_token?.token
        );
        LocalStorageManager.set(
          CONSTANTS.REFRESH_TOKEN,
          response?.data?.access_token?.refresh_token
        );
        setFlag(true);
        setIsLoggedIn(true);

        fetchCandidateUserInfo();
      }

      if (invitationId) {
        setIsSigningUp(true);
        await handleAcceptInvitations(invitationId);
      }

      setIsSigningUp(false);
    } catch (error) {
      setIsSigningUp(false);
      console.error(error);
    }
  };

  useEffect(async () => {
    const branding = await getBranding();

    applyBrandingToApp(branding?.data?.company?.branding_config);
  }, []);

  const showPasswordChecklist =
    password?.length > 0 ? (
      <PasswordChecklist
        rules={[
          "minLength",
          "number",
          "capital",
          "lowercase",
          "specialChar",
          "match",
        ]}
        minLength={8}
        value={password}
        valueAgain={confirmPassword}
        iconSize={10}
      />
    ) : (
      ""
    );

  const confirmPasswordChecker =
    password.length > 7 &&
    confirmPassword.length > 7 &&
    password === confirmPassword ? (
      <button
        className="btn w-100 btn-primary text-uppercase mt-4"
        onClick={handleSubmit}
      >
        Sign Up{" "}
      </button>
    ) : (
      <button
        type="button"
        className="btn w-100 btn-primary text-uppercase mt-4"
      >
        Sign Up{" "}
      </button>
    );

  if (isSigningUp)
    return (
      <div className={isSigningUp && "overflow-hidden"}>
        {isSigningUp && (
          <div
            className={`position-absolute w-100 h-100 theme-customizer bg-dark`}
          >
            <Spinner />
          </div>
        )}
      </div>
    );

  return (
    <div className="container-fluid d-flex min-vh-100 w-100 justify-content-center align-items-center sign-in sign-up">
      <div className="col-12 my-5">
        <div className="position-absolute col-5 col-sm-4 col-lg-auto top-0 start-0">
          <img
            className="mw-100"
            src={TOP_LEFT_PATTERN.SOURCE}
            alt="circle pattern"
          />
        </div>{" "}
        <div className="position-absolute col-5 col-sm-4 col-lg-auto bottom-0 end-0">
          <img
            className="mw-100"
            src={BOTTOM_RIGHT_PATTERN.SOURCE}
            alt="circle pattern"
          />
        </div>
        <h1 className="text-center mb-5">
          <img
            className="mw-100"
            src={companyLogos?.[0]?.image_url}
            alt="logo"
            title="intelliante logo"
          />
        </h1>{" "}
        <div className="row justify-content-center">
          <div className="col-lg-5 col-xl-4 col-sm-8 col-11">
            <div className="login-form mt-4 m-0">
              <div className="form-container">
                <form className="form row m-0" onSubmit={handleSubmit}>
                  <div className="col-12">
                    <h2 className="mb-4"> Sign up </h2>{" "}
                  </div>{" "}
                  <div className="col-12 email-input">
                    <div className="input-field">
                      <input
                        type="email"
                        name="email"
                        className={`form-control`}
                        value={email}
                        disabled
                      />
                      <span className="icon">
                        <img src={MAIL_ICON.SOURCE} alt="email icon" />
                      </span>{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-12 mt-4 password-input">
                    <div className="input-field form-floating">
                      <PasswordToggle
                        password={password}
                        setPassword={setPassword}
                        placeholder="Password"
                        flag={flag}
                      />{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-12 mt-4">
                    <div className="input-field form-floating">
                      <PasswordToggle
                        password={confirmPassword}
                        setPassword={setConfirmPassword}
                        placeholder="Confirm Password"
                        flag={flag}
                      />{" "}
                    </div>{" "}
                  </div>{" "}
                  <div className="col-12 mt-2">
                    {" "}
                    {showPasswordChecklist} {confirmPasswordChecker}{" "}
                  </div>{" "}
                </form>{" "}
              </div>{" "}
            </div>{" "}
          </div>{" "}
        </div>{" "}
      </div>{" "}
    </div>
  );
};

export default SignUp;
