import React from "react";
import { Routes, Route } from "react-router-dom";

import Jobs from "components/SignIn/Jobs.js";

import {
  ACHIEVEMENTS,
  DASHBOARD_ROUTE,
  EXPLORE,
  GAMELIST,
  HOME,
  JOBCARD,
  JOBDETAIL,
  ON_BOARDING,
  SETTINGS,
  TRAINING,
  WELCOME_PAGE,
  MYREPORT,
  JOBS,
  WORKFLOW,
} from "constants/routes";

import ApplicantReport from "pages/ApplicantReport/ApplicantReport";
import OnBoarding from "pages/Onboarding/OnBoarding";
import Dashboard from "pages/Dashboard";
import GameListPage from "pages/GameList/GameListPage";
import JobDetailPage from "pages/JobDetail/JobDetailPage";
import TrainingPage from "pages/TrainingPage/TrainingPage";
import SettingsPage from "pages/SettingsPage/SettingsPage";
import AchievementsPage from "pages/AchievementsPage/AchievementsPage";
import Workflow from "pages/Workflow/Workflow";
import SelectJob from "pages/Job/SelectJob";
import ApplicantDashboard from "pages/ApplicantDashboard/ApplicantDashboard";
import ExplorePage from "pages/ExplorePage/ExplorePage";

import ProtectedRoute from "./ProtectedRoute";

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path={JOBCARD.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<SelectJob />} />
      </Route>
      <Route path={JOBDETAIL.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<JobDetailPage />} />
      </Route>
      <Route path={GAMELIST.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<GameListPage />} />
      </Route>
      <Route path={TRAINING.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<TrainingPage />} />
      </Route>
      <Route path={EXPLORE.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<ExplorePage />} />
      </Route>
      <Route path={SETTINGS.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<SettingsPage />} />
      </Route>
      <Route path={ACHIEVEMENTS.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<AchievementsPage />} />
      </Route>
      <Route exact path={HOME.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<ApplicantDashboard />} />
      </Route>
      <Route path={DASHBOARD_ROUTE.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<Dashboard />} />
      </Route>
      <Route exact path={ON_BOARDING.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<OnBoarding />} />
      </Route>
      <Route exact path={MYREPORT.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<ApplicantReport />} />
      </Route>
      <Route path={HOME.LIST} element={<ProtectedRoute />}>
        <Route path="" element={<ApplicantDashboard />} />
      </Route>
      <Route path={JOBS.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<Jobs />} />
      </Route>
      <Route path={WORKFLOW.INDEX} element={<ProtectedRoute />}>
        <Route path="" element={<Workflow />} />
      </Route>
    </Routes>
  );
};

export default PrivateRoutes;
