import React from "react";

import { IntIcon } from "components/Shared/Icon";

const TestAssignedCard = ({ assessment }) => {
  const isStatusCompleted =
    assessment.status === "completed" ? "completed-tests" : "";
  const isJobSkill = assessment.category === "Job Skill" ? "jobskill" : "";
  const isTaskCompleted = assessment.status === "completed" ? "" : "d-none";

  const overcomeAdversityIconClassName =
    assessment.name === "Overcoming Adversity Skills"
      ? "overcoming-adversity-icon"
      : "";

  return (
    <div
      className={`test-cards px-4 py-3 position-relative ${isStatusCompleted}`}
    >
      <div
        className={`tick-icon position-absolute d-flex align-items-center justify-content-center ${isTaskCompleted}`}
      >
        <div className="d-flex align-items-center justify-content-center">
          <IntIcon name="tickIcon" className="report-icon" />
        </div>
      </div>
      <div className="mb-2">
        <IntIcon
          name={assessment.name}
          className={`report-icon ${overcomeAdversityIconClassName}`}
        />
      </div>
      <small className={`p-1 px-3 ${isJobSkill}`}>{assessment.category}</small>
      <p className="mt-3">{assessment.name}</p>
    </div>
  );
};

export default TestAssignedCard;
