export function reduceText(itemText, maxLength, isMore) {
  const itemTextArray = itemText.split(" ");
  let reducedText = "";

  if (itemTextArray.length < maxLength) return itemText;

  if (!isMore) {
    for (let i = 0; i < maxLength; ++i) {
      reducedText += itemTextArray[i] + " ";
    }
    reducedText += "...";
  } else {
    return itemText;
  }

  return reducedText;
}
