import { useContext } from "react";

import CustomComponent from "components/CustomComponent/CustomComponent";

import { PAYLOAD_TYPES } from "constants/constant";
import { OnBoardingContext } from "contextAPI/onBoardingContext";
import { UserContext } from "contextAPI/UserContext";

const RowComponent = (props) => {
  const { formTitle } = useContext(UserContext);
  const {
    check,
    defaultValue,
    currentlyEnrolled,
    currentlyEmployed,
    currentlyEnrolledEducation,
  } = useContext(OnBoardingContext);

  const labelPath = props?.data?.components?.[0]?.name;

  if (props.templateName === PAYLOAD_TYPES.EMPLOYMENT) {
    if (
      (currentlyEmployed === true && labelPath === "end_month") ||
      (currentlyEmployed === true && labelPath === "end_year")
    ) {
      return null;
    }
  }

  if (props.templateName === PAYLOAD_TYPES.EDUCATION) {
    if (
      (String(currentlyEnrolledEducation) == "true" &&
        labelPath == "end_month") ||
      (String(currentlyEnrolledEducation) == "true" && labelPath === "end_year")
    ) {
      return null;
    }
  }

  if (
    labelPath === "certification" &&
    defaultValue !== "Vocational or Professional Certification Program"
  )
    return null;

  return (
    <div className="row mb-4 g-6">
      {props.data.components.map((i) => (
        <div className="col me-2" key={i.id}>
          <CustomComponent {...props} data={i} />
        </div>
      ))}
    </div>
  );
};

export default RowComponent;
