import React from "react";
import Select from "react-select";

const SelectField = ({
  options,
  placeholder,
  isClearable,
  onChange,
  value,
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: "var(--bs-bg-color-1);",
      border: "0.0625em solid var(--bs-gray-600)",
      boxSizing: "border-box",
      borderRadius: "0.625rem",
      paddingLeft: "0.625rem",
      boxShadow: "none",
      minHeight: "3rem",
      height: "1.875rem",
      color: "var(--bs-gray-900) !important",
    }),

    singleValue: (provided) => ({
      ...provided,
      color: "var(--bs-gray-900)",
    }),
    input: (provided) => ({
      ...provided,
      position: "relative",
      margin: "0",
      height: "3em",
      color: "var(--bs-gray-900) !important",
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      color: "var(--bs-gray-900) !important",
    }),

    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected || state.isFocused
          ? "rgba(var(--bs-body-rgb-2), 70%) !important"
          : "rgba(var(--bs-body-rgb-2), 70%) !important",
      color:
        state.isSelected || state.isFocused
          ? "var(--bs-gray-600)"
          : "var(--bs-gray-400)",
      cursor: "pointer",
    }),

    menu: (provided) => ({
      ...provided,
      backgroundColor: "var(--bs-bg-color-1);",
      color: "var(--bs-gray-600)",
      border: "1px solid rgba(var(--text-secondary-rgb), 50%)",
      marginTop: "0px",
      width: "100%",
    }),

    valueContainer: (provided) => ({
      ...provided,
      overflow: "visible",
      height: "3em",
      padding: "0 0",
      color: "var(--bs-gray-900) !important",
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),

    indicatorsContainer: (provided) => ({
      ...provided,
      height: "3em",
    }),

    placeholder: (provided, state) => ({
      ...provided,
      position: "absolute",
      backgroundColor: "var(--bs-bg-color-1);",
      padding: "0 0.31em",
      overflow: "visible",
      color: "var(--bs-gray-600)",
      top: state.isFocused || state.hasValue ? "0" : "",
      left: state.isFocused || state.hasValue ? "0" : "",
      transform:
        state.isFocused || state.hasValue
          ? "translateY(-50%)"
          : "transformY(0)",
      transition: "transform 400ms ease",
    }),
  };

  return (
    <>
      <Select
        placeholder={placeholder}
        value={value}
        options={options}
        styles={customStyles}
        isClearable={isClearable}
        onChange={onChange}
      />
    </>
  );
};

export default SelectField;
