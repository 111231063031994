import React, { useContext } from "react";

import { OnBoardingContext } from "contextAPI/onBoardingContext";
import CustomRadio from "components/CustomComponent/CustomRadio";
import ErrorText from "components/ErrorText/ErrorText";

const Enrolled = ({
  data,
  label,
  value,
  id,
  checkOnChangeValidation,
  language,
  className,
  onBlur,
  classHelper,
  inputLabel,
  name,
  templateName,
  error,
}) => {
  const { currentlyEnrolledEducation } = useContext(OnBoardingContext);

  const compareValue = () => {
    return String(currentlyEnrolledEducation) == String(value);
  };

  return (
    <div className="d-flex align-items-center ms-3 ps-4 position-relative">
      <CustomRadio
        name={name}
        value={value}
        text={label?.locales?.[language]}
        className={`${className} ${classHelper()} checkbox form-check-input custom-control-input me-2`}
        parentClassName="label-container"
        onChange={(e) => checkOnChangeValidation(e, inputLabel)}
        checked={compareValue()}
        id={`flexCheckDefault${data.type}${id}`}
        templateName={templateName}
        custom={true}
        onBlur={onBlur}
      />
      <ErrorText error={error} name={name} className="text-danger" />
    </div>
  );
};

export default Enrolled;
