import React from "react";
import Slider from "react-slick";

import { LeftAnchor, RightAnchor } from "constants/icons/icon";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleArrow({ children, className, style, onClick }) {
  return (
    <button
      className={className}
      style={{
        ...style,
        borderRadius: "50%",
        height: "32px",
        width: "32px",
      }}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

const Carousel = ({ children, noOfSlides }) => {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: noOfSlides || 4,
    slidesToScroll: 2,
    centerMode: false,
    nextArrow: (
      <SampleArrow>
        <RightAnchor width="6" height="12" strokeColor="#121716" />
      </SampleArrow>
    ),
    prevArrow: (
      <SampleArrow>
        <LeftAnchor width="6" height="12" strokeColor="#121716" />
      </SampleArrow>
    ),
  };

  return <Slider {...settings}>{children}</Slider>;
};

export default Carousel;
