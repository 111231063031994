import React, { useContext, useEffect, useState } from "react";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

import TrixEditor from "./Editor";

const EditorField = ({
  label,
  name,
  checkOnChange,
  templateName,
  setError,
}) => {
  const { editEmploymentId, editEmploymentData, formValue } =
    useContext(OnBoardingContext);

  const [initialText, setInitialText] = useState(null);

  useEffect(() => {
    setInitialText(formValue[name]);
  }, [editEmploymentData, editEmploymentId, formValue]);

  return (
    <div className="editor-section">
      <strong className="my-4">{label}</strong>
      <TrixEditor
        name={name}
        checkOnChange={checkOnChange}
        input=""
        text={initialText}
        templateName={templateName}
        setError={setError}
      />
    </div>
  );
};

export default EditorField;
