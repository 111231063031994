export const VALIDATION_NAME = {
  REQUIRED: "required",
  MIN_LENGTH: "minlength",
  MAX_LENGTH: "maxlength",
  PATTERN: "pattern",
  MIN: "min",
  MAX: "max",
  LESS_THAN: "less_than",
  MORE_THAN: "more_than",
  SEPARATED_DATE_LATER_THAN: "separated_date_later_than",
};

export const MONTHLY_VALUE = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12,
};

export const MONTH_FIELDS = ["start_month", "end_month"];

export const VALIDATION_STAGE = {
  INITIAL: "initial",
  UNVERIFIED: "unverified",
  VERIFIED: "verified",
  ERROR: "error",
};

export const VALIDATION_ON = {
  BLUR: "blur",
  CHANGE: "change",
  SUBMIT: "submit",
};

const minLength = (errorText, validationValue, value) => {
  if (validationValue === null) return null;

  if (value === null) return errorText;

  if (value.length < Number(validationValue)) {
    return errorText;
  }

  return null;
};

const maxLength = (errorText, validationValue, value) => {
  if (validationValue === null) return null;

  if (value === null) return errorText;

  if (value.length > Number(validationValue)) {
    return errorText;
  }

  return null;
};

const patternVerification = (errorText, patternText, value) => {
  const pattern = new RegExp(patternText);

  if (!pattern.test(value)) return errorText;

  return null;
};

const min = (errorText, validationValue, value) => {
  if (validationValue === null) return null;

  if (Number(value) < Number(validationValue)) {
    return errorText;
  }

  return null;
};

const max = (errorText, validationValue, value) => {
  if (validationValue === null) return null;

  if (Number(value) > Number(validationValue)) {
    return errorText;
  }

  return null;
};

const dateValidation = (errorText, validationValue, value, allValues, meta) => {
  if (!validationValue || !allValues || !meta) return null;

  const { name } = meta;
  const [sectionName] = name.split(".");

  const { start_year, start_month, end_year, end_month } =
    allValues[sectionName];

  if (!start_year || !start_month || !end_year || !end_month) return null;

  const startDate = new Date(
    start_year,
    MONTHLY_VALUE[start_month] - 1
  ).getTime();
  const endDate = new Date(end_year, MONTHLY_VALUE[end_month] - 1).getTime();

  if (startDate >= endDate) return errorText;

  return null;
};

export const validation = (
  validationTest,
  currentEvent = VALIDATION_ON.BLUR,
  currentLocale = "en",
  value,
  allValues = null,
  meta = null
) => {
  const {
    validation: {
      error: { locales },
      validate_on: validateOn,
      value: validationValue,
      ...rest
    },
    name,
    enabled,
  } = validationTest;

  const isValidationEnabled = validateOn[currentEvent];

  if (!enabled || !isValidationEnabled) return null;

  const errorText = locales[currentLocale];

  if (name === VALIDATION_NAME.REQUIRED) {
    if (!value) return errorText;
  }

  if (!value) return null;

  switch (name) {
    case VALIDATION_NAME.MIN_LENGTH:
      return minLength(errorText, validationValue, value);

    case VALIDATION_NAME.MAX_LENGTH:
      return maxLength(errorText, validationValue, value);

    case VALIDATION_NAME.PATTERN:
      return patternVerification(errorText, validationValue, value);

    case VALIDATION_NAME.MIN:
      return min(errorText, validationValue, value);

    case VALIDATION_NAME.MAX:
      return max(errorText, validationValue, value);

    case VALIDATION_NAME.SEPARATED_DATE_LATER_THAN:
      return dateValidation(errorText, validationValue, value, allValues, meta);

    default:
      if (!value) return errorText;

      return null;
  }
};

export function validateAtleastOneArray(value, errorText) {
  return value &&
    Array.isArray(value) &&
    value.length > 1 &&
    value.filter((i) => typeof i === "object" && Object.keys(i).length > 0)
      .length > 0
    ? undefined
    : errorText;
}

export const composeValidation = (
  validationList,
  currentEvent,
  currentLocale,
  value
) => {
  let errorMessage = null;

  for (let i = 0; i < validationList.length; i++) {
    errorMessage = validation(
      validationList[i],
      currentEvent,
      currentLocale,
      value
    );

    if (errorMessage) break;
  }

  return errorMessage;
};
