import { useState, useContext, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import axios from "axios";

import ContextProviderContainer from "contextAPI/ContextProviderContainer";
import { LayoutContext } from "contextAPI/LayoutContext";
import { OnBoardingContext } from "contextAPI/onBoardingContext";

import { useQuery } from "utils/useQuery";
import "utils/icon";

import PublicRoutes from "./Routes/PublicRoutes";
import PrivateRoutes from "./Routes/PrivateRoutes";

import { errorHandlerInterceptor } from "services/requests/interceptor";
import SessionExpireModal from "components/Modals/SessionExpireModal";

const App = () => {
  const { isLayoutUpdating } = useContext(LayoutContext);
  const { setStepQuery, setStep } = useContext(OnBoardingContext);
  const query = useQuery();

  const [showSessionExpireModal, setShowSessionExpireModal] = useState(false);

  useEffect(() => {
    axios.interceptors.response.use(null, (err) =>
      errorHandlerInterceptor(err, () => setShowSessionExpireModal(true))
    );
  }, []);

  useEffect(() => {
    if (query?.step_id) {
      setStepQuery(query.step_id);
      setStep(query.step_id);
    }
  }, [query]);

  const hideSessionExpireModal = () => {
    setShowSessionExpireModal(false);
  };

  if (isLayoutUpdating) {
    return <div> Loading... </div>;
  }

  return (
    <Router>
      <PublicRoutes />
      <PrivateRoutes />
      <SessionExpireModal
        handleModalValue={hideSessionExpireModal}
        isVisible={showSessionExpireModal}
      />
    </Router>
  );
};

export default () => (
  <ContextProviderContainer>
    <App />
  </ContextProviderContainer>
);
