import React from "react";

const CSIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "81"}`}
        height={`${height ? height : "70"}`}
        className={classNames.join(" ")}
        viewBox="0 0 81 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M40 34.75C47.1797 34.75 53 28.9297 53 21.75C53 14.5703 47.1797 8.75 40 8.75C32.8203 8.75 27 14.5703 27 21.75C27 28.9297 32.8203 34.75 40 34.75Z"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.25 61.25V55.4167C20.25 52.3225 21.6723 49.355 24.2041 47.1671C26.7358 44.9792 30.1696 43.75 33.75 43.75H47.25C50.8304 43.75 54.2642 44.9792 56.7959 47.1671C59.3277 49.355 60.75 52.3225 60.75 55.4167V61.25"
          stroke="#B8C1BF"
          strokeOpacity="0.7"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.4617 23.7911L21.5094 23.9107C22.3099 23.9343 23.1005 23.755 23.7811 23.3952C24.4618 23.0355 25.0019 22.5116 25.3332 21.8898C25.6645 21.268 25.7721 20.5762 25.6424 19.9018C25.5127 19.2275 25.1515 18.6009 24.6046 18.1014C24.0576 17.6018 23.3494 17.2517 22.5695 17.0953C21.7896 16.9389 20.973 16.9832 20.2231 17.2227C19.4731 17.4622 18.8235 17.886 18.3563 18.4406C17.8891 18.9953 17.6253 19.6558 17.5983 20.3386L17.2569 28.9699C17.1845 30.8013 17.968 32.5828 19.4349 33.9226C20.9019 35.2624 22.9322 36.0507 25.0792 36.1141"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default CSIcon;
