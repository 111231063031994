import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { OnBoardingContext } from "contextAPI/onBoardingContext";
import { isEmpty } from "utils/isEmpty";

function SelectedSkills({
  item,
  language,
  selectedSkills,
  handleSelectedSkills,
}) {
  const { setFormValues, setFormFieldData } = useContext(OnBoardingContext);

  useEffect(() => {
    setFormValues((values) => ({
      ...values,
      [item.dynamic_field_id]: selectedSkills.map(
        ({ defaultValue }) => defaultValue?.locales?.en
      ),
    }));
    setFormFieldData((values) => ({
      ...values,
      [item.dynamic_field_id]: selectedSkills.map(
        ({ defaultValue }) => defaultValue?.locales?.en
      ),
    }));
  }, [selectedSkills]);

  return (
    <div className="my-2">
      <p className="pb-2">{item.label.locales?.[language]}</p>
      {selectedSkills.length ? (
        <div
          className={`${item.className} ${item.customClass} scroll`}
          style={item?.style}
        >
          {selectedSkills.map((specificItem, index) => (
            <button
              className={`btn ${item.button.className} custom-bg custom-text`}
              key={index}
              style={item.button.style}
              onClick={(e) => {
                handleSelectedSkills(
                  e,
                  specificItem?.label?.locales?.[language]
                );
              }}
            >
              {specificItem?.label?.locales[language]}

              <FontAwesomeIcon
                icon={item.button.icon.url}
                className={`${item.button.icon.className} text-danger`}
              />
            </button>
          ))}
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

const Skills = ({ data, language, color, stepValues }) => {
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [suggestedSkills, setSuggestedSkills] = useState([]);

  const handleSelectedSkills = (e, label) => {
    e?.preventDefault?.();

    const filterData = selectedSkills.filter(
      (item) => item?.label?.locales[language] !== label
    );

    setSelectedSkills(filterData);
  };

  const addSkillHandler = (e, item) => {
    e?.preventDefault?.();

    const checkItem = selectedSkills.some(
      (element) =>
        element?.label?.locales[language] == item?.label?.locales[language]
    );

    if (!checkItem) {
      setSelectedSkills((prev) => {
        return [...prev, item];
      });
    }
  };

  const addActiveStyle = (activeStyle) => {
    if (Boolean(activeStyle.backgroundColor)) {
      return activeStyle;
    } else {
      activeStyle.backgroundColor = "$primary-color";
    }
  };

  useEffect(() => {
    if (!stepValues?.[0]?.value) return;

    let filterElement;

    filterElement = suggestedSkills.find(
      (item) =>
        item.defaultValue.locales[language].toLowerCase().trim() ===
        stepValues?.[0].value.toLowerCase().trim()
    );

    if (isEmpty(filterElement)) {
      filterElement = {
        defaultValue: {
          locales: {
            en: stepValues?.[0]?.value,
            es: stepValues?.[0]?.value,
          },
        },
        label: {
          className: "",
          style: {},
          customClass: "",
          locales: {
            en: stepValues?.[0]?.value,
            es: stepValues?.[0]?.value,
          },
        },
      };
    }

    if (filterElement) {
      addSkillHandler("", filterElement);
    }
  }, [stepValues]);

  useEffect(() => {
    data.map((item) => {
      item.type == "suggested_skills" && setSuggestedSkills(item?.options);
    });
  }, []);

  return (
    <>
      {data.length && (
        <div className="skill-container">
          {data.map((item, index) =>
            item.type == "selected_skills" ? (
              <SelectedSkills
                key={index}
                item={item}
                language={language}
                selectedSkills={selectedSkills}
                handleSelectedSkills={handleSelectedSkills}
              />
            ) : (
              <div className="my-2">
                <p className="pb-2">{item.label.locales?.[language]}</p>
                <div className={`${item.className} ${item.customClass} scroll`}>
                  {item.options.map((specificItem, index) => (
                    <button
                      className={`btn ${item.button.className} ${
                        selectedSkills.some(
                          (element) =>
                            element?.label?.locales[language] ==
                            specificItem?.label?.locales[language]
                        )
                          ? "btn-primary"
                          : ""
                      }`}
                      style={
                        selectedSkills.some(
                          (element) =>
                            element?.label?.locales[language] ==
                            specificItem?.label?.locales[language]
                        )
                          ? addActiveStyle(item.button.activeStyle)
                          : item.button.style
                      }
                      key={index}
                      onClick={(e) => {
                        addSkillHandler(e, specificItem);
                      }}
                    >
                      {specificItem.label?.locales[language]}
                    </button>
                  ))}
                </div>
              </div>
            )
          )}
        </div>
      )}
    </>
  );
};

export default Skills;
