import { GENDER, RACE } from "constants/constant";

export const getGenderOptions = (t) => [
  {
    label: {
      locales: {
        en: t("extraForm.gender.male"),
      },
    },
    value: GENDER.male,
  },
  {
    label: {
      locales: {
        en: t("extraForm.gender.female"),
      },
    },
    value: GENDER.female,
  },
  {
    label: {
      locales: {
        en: t("extraForm.gender.nonBinary"),
      },
    },
    value: GENDER.non_binary,
  },
  {
    label: {
      locales: {
        en: t("extraForm.gender.confidential"),
      },
    },
    value: GENDER.not_any,
  },
];

export const getRaceOptions = (t) => [
  {
    value: RACE.hispanic,
    label: {
      locales: {
        en: t("extraForm.raceEthnicity.type.hispanicTitle"),
      },
    },
    helpText: t("extraForm.raceEthnicity.type.hispanicText"),
  },
  {
    value: RACE.white,
    label: {
      locales: {
        en: t("extraForm.raceEthnicity.type.whiteNotHispanicTitle"),
      },
    },
    helpText: t("extraForm.raceEthnicity.type.whiteNotHispanicText"),
  },
  {
    value: RACE.african_american,
    label: {
      locales: {
        en: t("extraForm.raceEthnicity.type.blackAfricanAmericanTitle"),
      },
    },
    helpText: t("extraForm.raceEthnicity.type.blackAfricanAmericanText"),
  },
  {
    value: RACE.native_hawaiian,
    label: {
      locales: {
        en: t("extraForm.raceEthnicity.type.hawaiianPacificTitle"),
      },
    },
    helpText: t("extraForm.raceEthnicity.type.hawaiianPacificText"),
  },
  {
    value: RACE.asia,
    label: {
      locales: {
        en: t("extraForm.raceEthnicity.type.asianTitle"),
      },
    },
    helpText: t("extraForm.raceEthnicity.type.asianText"),
  },
  {
    value: RACE.native_american,
    label: {
      locales: {
        en: t("extraForm.raceEthnicity.type.nativeAmericanTitle"),
      },
    },
    helpText: t("extraForm.raceEthnicity.type.nativeAmericanText"),
  },
  {
    value: RACE.two_or_more_races,
    label: {
      locales: {
        en: t("extraForm.raceEthnicity.type.twoOrMoreRaceTitle"),
      },
    },
    helpText: t("extraForm.raceEthnicity.type.twoOrMoreRaceText"),
  },
  {
    value: RACE.not_any,
    label: {
      locales: {
        en: t("extraForm.raceEthnicity.type.confidential"),
      },
    },
    helpText: "",
  },
];
