import React from "react";

import { t } from "i18next";

import Modal from "components/Shared/Modal";

const DeleteModal = ({
  setDel,
  del,
  deleteFunction,
  idx,
  deleteId,
  message,
}) => {
  if (!del || idx !== deleteId) return null;

  return (
    <Modal
      isVisible={del}
      className="custom-modal p-4 pb-2 rounded d-flex justify-content-center flex-column align-items-center bg-section"
    >
      <div className="d-flex justify-content-center flex-column align-items-center p-2 z-2">
        <div className="modal-content position-relative ">
          <div className="modal-body text-center w-100" id="del-body">
            <p className="pb-4"> {message}</p>
          </div>
          <div className=" d-flex justify-content-between border-top-0 px-3 pt-2 pb-3">
            <button
              type="button"
              className="btn btn-outline-secondary flex-fill px-2 me-3 ms-2"
              data-bs-dismiss="delModel"
              onClick={() => setDel(false)}
            >
              {t("attributes.cancel")}
            </button>
            <button
              type="button"
              className="btn btn-danger flex-fill px-4 mx-2"
              data-bs-dismiss="delModel"
              onClick={deleteFunction}
            >
              {t("attributes.delete")}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
