import DynamicFormApi from "api/DynamicForm/DynamicFormApi";

import { PAYLOAD_TYPES } from "constants/constant";

export const MultipleDataFormatter = (stepData, educationData, submittedEducationData, employmentData, submittedEmploymentData, candidateInfo) => {
  stepData.components.forEach(async ({ name, submission }) => {
    if (!submission?.url) {
      return null;
    }

    const { url, method, payload_key: payloadKey } = submission;

    const key = payloadKey.split(".")[0];
    const subKey = payloadKey.split(".")[1]

    const payload = {};

    payload[key] = {};

    let submitData;
    
    if (payloadKey == PAYLOAD_TYPES.EDUCATION) {
      submitData = filterData(educationData, submittedEducationData)
    } else if (payloadKey == PAYLOAD_TYPES.EMPLOYMENT) {
      submitData = filterData(employmentData, submittedEmploymentData)
    }

    if (submitData) {
      payload[key] = {
        [subKey]: { ...submitData }
      };

      await DynamicFormApi.submitApplicantData(
        url,
        method,
        payload,
        candidateInfo.id
      );
    }
  });

}

export const filterData = (previousData, currentData) => {
  if (previousData == currentData) {
    return;
  } else {
    let submitData = []

    const removeData = currentData.filter((item) => {
      if (!previousData.includes(item)) {
        return item
      }
    })

    const newItems = previousData.filter((item) => {
      if (!currentData.includes(item)) {
        return item
      }
    })

    newItems.map((item) => {
      delete item["id"]
      submitData.push(item)
    })

    removeData.map((item) => {
      submitData.push({
        id: item?.id,
        _destroy: true
      })
    })

    return submitData
  }
}

export const WithId = (url, id) => {
  const route = url.replace(":id", id);

  return route;
};