import React, { useContext, useEffect, useState } from "react";

import { AuthContext } from "./AuthContext";
import { UserContext } from "contextAPI/UserContext";

import CoursesApi from "api/CoursesApi/CoursesApi";

export const DashboardContext = React.createContext();

const DashBoardProvider = ({ children }) => {
  const { candidateInfo } = useContext(UserContext);
  const { isLoggedIn } = useContext(AuthContext);

  const [activeName, setActiveName] = useState("Home");

  const [sidebarToggle, setSideBarToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);

  const [browse, setBrowse] = useState([]);
  const [course, setCourse] = useState([]);

  const browseList = async () => {
    const browseResponse = await CoursesApi.getExplore(candidateInfo?.id);
    const courseResponse = await CoursesApi.getCourse(candidateInfo?.id);

    if (browseResponse || courseResponse) {
      setBrowse(browseResponse?.data);
      setCourse(courseResponse?.data);
    }
  };

  useEffect(() => {
    if (isLoggedIn) browseList();
  }, [isLoggedIn, candidateInfo?.id]);

  return (
    <DashboardContext.Provider
      value={{
        sidebarToggle,
        setSideBarToggle,
        mobileToggle,
        setMobileToggle,
        browse,
        course,
        activeName,
        setActiveName,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashBoardProvider;
