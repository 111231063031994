import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { UsersIcon } from "constants/icons/icon";
import { WORKFLOW } from "constants/routes";

import ChevronIcon from "assets/icons/ChevronIcon";

const Background = ({ totalForms, completedForms }) => {
  const { t } = useTranslation();

  return (
      <div className="box flex-grow-1 w-50 detail-card background-overview py-4 px-3 me-3 position-relative">
        <div>
          <UsersIcon className="color-icon" />
        </div>
        <span className="title my-2 d-block">
          {t("message.applicantDashboard.background")}
        </span>
        <div className="w-100 d-flex justify-content-between">
          <p className="details card-brief">
            {t("message.applicantDashboard.enterBackgroundDetails")}
          </p>
          <p className="details card-status d-flex ">
            {totalForms === completedForms
              ? t("message.applicantDashboard.completed")
              : `${completedForms} of ${totalForms} ${t(
                  "message.applicantDashboard.stepsCompleted"
                )}`}
          </p>
        </div>
        <Link to={WORKFLOW.INDEX} className="background-overview-link rounded py-4 px-3">
          <span>
            <UsersIcon className="color-icon" />
          </span>
          <span className="title my-2 d-block">
            {t("message.applicantDashboard.background")}
          </span>
          <span className="w-100 d-flex justify-content-between align-items-center">
            <span className="details card-brief">
              {t("message.applicantDashboard.enterBackgroundDetails")}
            </span>
            <span className="text-details">
                View Background  <ChevronIcon />
            </span>
          </span>
        </Link>
      </div>
    
  );
};

export default Background;
