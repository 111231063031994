import React from "react";

import { useTranslation } from "react-i18next";

const Tabs = ({
  switchTabCountHandler,
  activeTab,
  progressTab,
  completedTab,
  showTab,
}) => {
  const { t } = useTranslation();

  if (!showTab) {
    return null;
  }

  return (
    <div className="tab d-flex">
      <button
        onClick={() => switchTabCountHandler(1)}
        className={`rounded-x ${activeTab}`}
      >
        {t("label.available")}
      </button>
      <button
        onClick={() => switchTabCountHandler(2)}
        className={`border-start-0 border-end-0  ${progressTab}`}
      >
        {t("label.inProgress")}
      </button>
      <button
        onClick={() => switchTabCountHandler(3)}
        className={`rounded-y ${completedTab}`}
      >
        {t("label.completed")}
      </button>
    </div>
  );
};

export default Tabs;
