import React, { useEffect, useState } from "react";

import Loader from "components/Shared/Loader";
import { useWizardContext } from "components/Onboarding/Wizard";

const ASSESMENT_COMPLETED = "completed";

const Assessments = ({ src, id }) => {
  const [loading, setLoading] = useState(false);
  const { setIsButtonVisible } = useWizardContext();

  const handleOnLoad = () => {
    setLoading(false);
  };

  const handleAssessmentCompleted = (event) => {
    if (event.data === ASSESMENT_COMPLETED) {
      setIsButtonVisible(true);
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleAssessmentCompleted);
  }, []);

  useEffect(() => {
    const main = document.querySelector("main");

    main.style.marginTop = "0rem";

    return () => {
      main.style.marginTop = "1rem";
    };
  }, []);

  useEffect(() => {
    const preloadedIframe = document.querySelector(`#iframe${id}`);
    const iframeContainer = document.querySelector("#iframe-container");

    if (preloadedIframe) {
      iframeContainer.appendChild(preloadedIframe);
    } else {
      setLoading(true);
      const iframe = document.createElement("iframe");

      iframe.src = src;
      iframe.onload = () => handleOnLoad();

      iframeContainer.appendChild(iframe);
    }
  }, [id, src]);

  return (
    <>
      <Loader isLoading={loading} />
      <div id="iframe-container"></div>
    </>
  );
};

export default Assessments;
