import React from "react";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

import Title from "./HelperComponents/Title";

const HowItWorks = ({ id }) => {
  const { t } = useTranslation();

  const stepsData = [
    {
      name: "Step 1",
      description: t("applicantReport.howItWorks.steps.useThisReport"),
      tooltipData: [
        t("applicantReport.howItWorks.toolTipData.thatHelpYou"),
        t("applicantReport.howItWorks.toolTipData.toWatchOutFor"),
      ],
    },
    {
      name: "Step 2",
      description: t("applicantReport.howItWorks.steps.completeFreeOnline"),
    },
    {
      name: "Step 3",
      description: t("applicantReport.howItWorks.steps.addYourSkills"),
    },
  ];

  return (
    <div id={id}>
      <div className="container max-w-1970">
        <Title>{t("applicantReport.howItWorks.howItWorks")}</Title>
        <div className="mt-5">
          <ul className="d-flex justify-content-between steps-container px-0">
            <div className="text-center">
              <div className="steps step-1 position-relative">
                <div className="d-inline-block pe-3 stepsEL-wrapper position-relative">
                  <small className="me-2">•</small>
                  <li className="px-4 py-2 d-inline-block steps-name mb-3">
                    {stepsData[0].name}
                  </li>
                </div>
              </div>
              <p className="steps-description px-5 fs-4">
                {stepsData[0].description}
              </p>
            </div>
            <div className="text-center ">
              <div className="steps step-2 position-relative stepsEL-wrapper">
                <div className="d-inline-block pe-3 stepsEL-wrapper position-relative">
                  <small className="me-2">•</small>
                  <li className="px-4 py-2 d-inline-block steps-name mb-3">
                    {stepsData[1].name}
                  </li>
                </div>
              </div>
              <p className="steps-description px-5 fs-4">
                {stepsData[1].description}
              </p>
            </div>
            <div className="text-center">
              <div className="steps step-3 position-relative stepsEL-wrapper">
                <div className="d-inline-block pe-3 stepsEL-wrapper position-relative">
                  <small className="me-2">•</small>
                  <li className="px-4 py-2 d-inline-block steps-name mb-3">
                    {stepsData[2].name}
                  </li>
                </div>
              </div>
              <div className="steps-description px-5 fs-4">
                {stepsData[2].description}
              </div>
            </div>
          </ul>
          <div className="list-tooltip d-inline-block ms-5 position-relative mt-1">
            <ul className="px-4 py-3 my-0 ms-2 d-flex flex-column gap-3 ">
              <li>
                That help you <span>stand out</span> from other applicant
              </li>
              <li>
                To <span>Watch Out</span> for so that they do not hold you back.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
