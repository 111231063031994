import React from "react";

import { t } from "i18next";

import Subtitle from "./HelperComponents/Subtitle";
import TestAssignedCard from "./HelperComponents/TestAssignedCard";
import Title from "./HelperComponents/Title";

const TestAssigned = ({ assessments, id }) => {
  return (
    <div className="container max-w-1970">
      <div
        className="text-center px-4 py-5 test-assigned-container my-5"
        id={id}
      >
        <Title>{t("applicantReport.testAssigned.testAssignedTo")}</Title>
        <Subtitle>
          {t("applicantReport.testAssigned.dependingOntypeOfTest")}{" "}
          <span className="green-text">
            {t("applicantReport.testAssigned.checkedGreenBox")}
          </span>{" "}
          {t("applicantReport.testAssigned.thatYouHaveCompleted")}
        </Subtitle>

        <div className="test-card-container d-flex pt-4 flex-wrap justify-content-center gap-4">
          {assessments?.map((assessment) => (
            <TestAssignedCard assessment={assessment} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestAssigned;
