import React from "react";

const Tooltip = ({ children, title, size }) => {
  return (
    <div className="custom-tooltip position-relative">
      {children}
      <section
        className={`tooltiptext d-flex position-absolute bg-body py-3 px-3 flex-column roundedborder border-success ${
          size ? size : "small"
        }`}
      >
        {title}
      </section>
    </div>
  );
};

export default Tooltip;
