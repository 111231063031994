import { DynamicFormElement } from "./DynamicFormElement";

export const Column = ({ column }) => {
  return (
    <div className="input-block col-lg-6 ">
      {column.components.map((component) => (
        <DynamicFormElement component={component} key={component.id} />
      ))}
    </div>
  );
};
