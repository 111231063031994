import React from "react";
import { useContext } from "react";

import ApplicationAdventures from "components/ApplicantDashboard/Adventures/ApplicationAdventures";
import TrainingCourse from "components/ApplicantDashboard/TalentLMS/TrainingCourse";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

import DashboardLayout from "layout/DashboardLayout/DashboardLayout";

const ApplicantDashboard = () => {
  const { setIsModalVisible } = useContext(OnBoardingContext);

  return (
    <DashboardLayout>
      <div className="container-fluid max-w-1970">
        <ApplicationAdventures setIsModalVisible={setIsModalVisible} />
        <TrainingCourse showTab={true} progress={true} />
      </div>
    </DashboardLayout>
  );
};

export default ApplicantDashboard;
