import { useTranslation } from "react-i18next";

import { GamepadIcon } from "constants/icons/icon";

const Tests = ({
  isTestCardHovered,
  setisTestCardHovered,
  totalTests,
  completedTests,
}) => {
  const { t } = useTranslation();

  const cardHoveredClass = isTestCardHovered ? "tests-container" : null;

  const getTestText = () => {
    if (totalTests === 0) {
      return t("message.applicantDashboard.noAssessments");
    }

    return totalTests === completedTests
      ? t("message.applicantDashboard.completed")
      : `${completedTests} of ${totalTests} ${t(
          "message.applicantDashboard.stepsCompleted"
        )}`;
  };

  return (
    <div
      className={`${cardHoveredClass} flex-grow-1 w-50`}
      onMouseEnter={() => setisTestCardHovered(true)}
      onMouseLeave={() => setisTestCardHovered(false)}
    >
      <div className="detail-card col tests-card d-flex flex-column box me-0">
        <div>
          <GamepadIcon className="color-icon" />
        </div>
        <span className="title my-2">
          {t("message.applicantDashboard.assessment")}
        </span>
        <div className="w-100 d-flex justify-content-between">
          <p className="details card-brief">
            {t("message.applicantDashboard.startTests")}
          </p>
          <p className="details card-status">{getTestText()}</p>
        </div>
      </div>
    </div>
  );
};

export default Tests;
