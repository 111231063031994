import React, { useEffect, useState } from "react";

import AuthApi from "api/AuthApi/AuthApi";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";
import CompanyApi from "api/Company/CompanyApi";

import { Spinner } from "components/Shared/Spinner";
import { useLocation } from "react-router-dom";

export const AuthContext = React.createContext();

const scope = `${process.env.REACT_APP_APPLICANT_SCOPE}`;

const mapBrandingValues = {
  primaryColor: "--bs-primary-rgb",
  secondaryColor: "--bs-secondary-rgb",

  textPrimaryColor: "--bs-text-primary-rgb",
  textSecondaryColor: "--bs-heading-color",
  borderColor: "--border-color-rgb",

  bodyBackgroundColor: "--bs-body-rgb-2",
  headingBackground: "--bs-body-rgb-1",
};

const setRgb = (colorValue) => {
  if (typeof colorValue === "string") {
    return colorValue;
  }

  const { r, g, b } = colorValue;

  return `${r},${g},${b}`;
};

const applyBrandingToApp = (_brandingDetails) => {
  var brandingDetails = {
    // buttonBg: "213, 205, 229",
    // iconColor: "213, 205, 229",
    themeName: "Endoplasm",
    paletteName: "palette3",

    primaryColor: "2, 98, 137",
    secondaryColor: "254, 233, 153",

    textPrimaryColor: "238, 244, 243", //gray scale
    textSecondaryColor: "238, 244, 243",
    borderColor: "70, 108, 123",

    headingBackground: "1, 57, 80", //siebar
    // textSecondaryColor: "238, 238, 238",
    bodyBackgroundColor: "2, 74, 103", // body
  };

  brandingDetails = _brandingDetails;

  Object.entries(mapBrandingValues).forEach(([key, value]) => {
    if (brandingDetails[key])
      document.documentElement.style.setProperty(
        value,
        setRgb(brandingDetails[key])
      );
  });
};

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(
    LocalStorageManager.get(CONSTANTS.TOKEN)
  );
  const [userInfo, setCandidateUserInfo] = useState({});
  const [hasInvitations, setHasInvitations] = useState(false);

  const [authError, setError] = useState(null);
  const [isLoadingUserInfo, setIsUserInfoLoading] = useState(true);
  const [isBrandingLoading, setIsBrandingLoading] = useState(true);
  const [companyLogos, setCompanyLogos] = useState([]);

  useEffect(() => {
    const userToken = LocalStorageManager.get(CONSTANTS.ACCESS_TOKEN);

    if (userToken) {
      fetchCandidateUserInfo();
    } else {
      setIsLoggedIn(false);
      setIsUserInfoLoading(false);
    }
  }, []);

  useEffect(() => {
    if (userInfo?.data?.pending_invitations?.length > 0) {
      setHasInvitations(true);
    } else {
      setHasInvitations(false);
    }
  }, [userInfo]);

  useEffect(() => {
    const fetchBranding = async () => {
      const brandingDetails = await CompanyApi.getCompanyBranding();

      setCompanyLogos(brandingDetails?.data?.company?.logos);
      applyBrandingToApp(brandingDetails?.data?.company?.branding_config);
      setIsBrandingLoading(false);
    };

    fetchBranding();
  }, []);

  const getBranding = async () => {
    const brandingDetails = await CompanyApi.getCompanyBranding();

    return brandingDetails;
  };

  const fetchCandidateUserInfo = () => {
    setIsUserInfoLoading(true);
    const userToken = LocalStorageManager.get(CONSTANTS.ACCESS_TOKEN);

    AuthApi.getUserInfo(userToken).then((res) => {
      setIsLoggedIn(true);

      setCandidateUserInfo(res);
      setIsUserInfoLoading(false);
    });
  };

  const onLoginSuccess = (res) => {
    LocalStorageManager.set(CONSTANTS.REFRESH_TOKEN, res?.data?.refresh_token);
    LocalStorageManager.set(CONSTANTS.ACCESS_TOKEN, res?.data?.access_token);
    LocalStorageManager.set(CONSTANTS.EXPIRES_IN, res?.data?.expires_in);
    LocalStorageManager.set(CONSTANTS.TOKEN_TYPE, res?.data?.token_type);
    setIsLoggedIn(true);
    fetchCandidateUserInfo();
  };

  const handleLogin = async (email, password) => {
    setError(null);

    await AuthApi.loginAuth(
      email,
      password,
      CONSTANTS.PASSWORD,
      CONSTANTS.CLIENT_ID,
      CONSTANTS.CLIENT_SECRET,
      scope
    )
      .then((res) => {
        onLoginSuccess(res);
      })
      .catch((err) => {
        alert(err?.response?.data);
        setError(err?.response?.data);
        handleLogout();
      });
  };

  const handleLogout = () => {
    LocalStorageManager.clear();
    setError("");
    setIsLoggedIn(false);
    setIsUserInfoLoading(false);
  };

  const showSpinner = isLoggedIn && isLoadingUserInfo || isBrandingLoading;

  return (
    <div className={showSpinner && "overflow-hidden"}>
      {showSpinner && (
        <div
          className={`position-absolute w-100 h-100 theme-customizer bg-dark`}
        >
          <Spinner />
        </div>
      )}
      <AuthContext.Provider
        value={{
          isLoggedIn,
          setIsLoggedIn,
          userInfo,
          fetchCandidateUserInfo,
          handleLogin,
          authError,
          handleLogout,
          onLoginSuccess,
          isLoadingUserInfo,
          getBranding,
          hasInvitations,
          setHasInvitations,
          companyLogos,
        }}
      >
        {children}
      </AuthContext.Provider>
    </div>
  );
};

export default AuthProvider;
