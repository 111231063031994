import React from "react";
import { Field } from "react-final-form";

const CustomRadioField = ({
  name,
  value,
  className,
  onChange,
  checked,
  custom,
  onBlur,
  id,
  templateName,
}) => {
  return (
    <input
      name={name}
      component="input"
      type="radio"
      value={value}
      className={className}
      id={id}
      onChange={onChange}
      checked={checked}
      onBlur={onBlur}
      data-template={templateName}
      data-field-type="radio"
    />
  );
};

export default CustomRadioField;
