import React, { useContext, useState, useEffect } from "react";
import { t } from "i18next";

import { UserContext } from "contextAPI/UserContext";

import { OnBoardingContext } from "contextAPI/onBoardingContext";

import { LABEL } from "constants/formType";

import {
  EMPLOYMENT_DETAILS,
  PAYLOAD_TYPES,
  SECTION_TYPE,
} from "constants/constant";

import Plus from "assets/images/plus.svg";

const Button = ({ setSubmit, stepValues, onSubmit }) => {
  const {
    educationFormData,
    educationData,
    setEducationData,
    isEducationList,
    setShowFormClass,
    setShowAddButton,
    setFormValues,
    formValues,
    editEducationId,
    setEditEducationId,
    setEditEducationData,
    anotherEducationButton,
    setIsEducationEditing,
    setFormValue,
    currentlyEnrolledEducation,
  } = useContext(OnBoardingContext);

  const [isAnother, setIsAnother] = useState(false);

  const educationFields = stepValues.filter(
    ({ sectionName }) => sectionName === SECTION_TYPE.EDUCATION_LIST
  );

  const addEducationInformation = () => {
    const formData = [];

    let formFields = document.querySelectorAll("[data-template]");

    formFields.forEach((element) => {
      const {
        name,
        value,
        dataset: { template, fieldType },
        innerHTML,
      } = element;

      if (template != PAYLOAD_TYPES.EDUCATION) {
        return null;
      }

      if (fieldType == "single-dropdown") {
        formData.push({
          keyName: name,
          value: innerHTML,
          templateName: template,
        });
      } else {
        formData.push({
          keyName: name,
          value: value,
          templateName: template,
        });
      }

      element.value = "";
    });

    let newForm = {};

    formData.forEach((item) => {
      const { keyName, value } = item;

      newForm[keyName] = value;
      newForm["id"] = educationData[educationData?.length - 1]?.id
        ? educationData[educationData?.length - 1]?.id + 1
        : educationData?.length + 1;
      newForm[EMPLOYMENT_DETAILS.current_enrolled] = currentlyEnrolledEducation;
    });

    setEducationData((prev) => {
      return [...prev, newForm];
    });

    setFormValue({});
  };

  const handleSave = () => {
    setSubmit(true);
  };

  const handleAnotherCancel = () => {
    setShowFormClass(false);
    setIsAnother(false);
    setShowAddButton(true);
  };

  const handleOnCancel = () => {
    setEditEducationId(null);
    setEditEducationData(null);
    setIsEducationEditing(false);

    const itemsToRemove = Object.keys(educationFormData);
    const formValueKeys = Object.keys(formValues);

    const newValues = formValueKeys.filter((item) => {
      return itemsToRemove.indexOf(item) !== -1;
    });

    let newForm = { ...formValues };

    newValues.map((item) => {
      delete newForm[item];
    });

    setFormValues(newForm);
    setFormValue(newForm);
    setShowFormClass(false);
  };

  const handleOnUpdate = () => {
    const filteredData = educationData?.filter(
      (e) => e?.id !== editEducationId
    );

    setEducationData(filteredData);
    handleSave();
    setEditEducationData(null);
    setEditEducationId(null);
  };

  useEffect(() => {
    if (!onSubmit) return;
    const formWithoutError = educationFields.every(
      ({ error }) => error === null
    );

    if (formWithoutError) {
      addEducationInformation();
    }
    setSubmit(false);
  }, [educationFields, onSubmit]);

  const disableUpdate = educationFields.every(({ error }) => error === null);

  const formButtonClassName = "d-block";

  const showBackClassName = isEducationList ? "" : "d-none";
  const positionClassName = isEducationList ? "between" : "end";

  const showAnother = anotherEducationButton ? "d-none" : "";

  return (
    <Buttons
      handleAnotherCancel={handleAnotherCancel}
      isAnother={isAnother}
      editEducationId={editEducationId}
      handleOnCancel={handleOnCancel}
      handleOnUpdate={handleOnUpdate}
      handleSave={handleSave}
      handleAddAnother={handleSave}
      formButtonClassName={formButtonClassName}
      showBackClassName={showBackClassName}
      positionClassName={positionClassName}
      showAnother={showAnother}
      disableUpdate={disableUpdate}
    />
  );
};

export default Button;

export const Buttons = ({
  handleAnotherCancel,
  isAnother,
  editEducationId,
  handleOnCancel,
  handleOnUpdate,
  handleAddAnother,
  handleSave,
  formButtonClassName,
  positionClassName,
  showAnother,
  disableUpdate,
}) => {
  if (isAnother) {
    return (
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-outline-secondary mt-2 "
          onKeyDown={handleAnotherCancel}
          onClick={handleAnotherCancel}
        >
          {t("attributes.cancel")}
        </button>
        <button
          className="btn btn-outline-secondary mt-2"
          aria-hidden="true"
          onClick={handleSave}
        >
          {t("common.save")}
        </button>
      </div>
    );
  }

  if (editEducationId) {
    return (
      <div className="d-flex justify-content-end">
        <button
          className="btn btn-outline-secondary mt-2 "
          onKeyDown={handleOnCancel}
          onClick={handleOnCancel}
        >
          {t("attributes.cancel")}
        </button>
        <button
          className="btn btn-secondary mt-2 ms-3"
          aria-hidden="true"
          onClick={handleOnUpdate}
          disabled={!disableUpdate}
        >
          {t("attributes.update")}
        </button>
      </div>
    );
  }

  // return <button>Hi</button>;

  return (
    <div className={`button d-flex mt-2 justify-content-${positionClassName}`}>
      <div>
        <span
          className={` text-secondary py-2 mt-2 cursor-pointer ${showAnother} ${formButtonClassName}`}
          value="add"
          onClick={handleAddAnother}
        >
          <span>
            <img src={Plus} alt="icon" />
          </span>
          {t("common.anotherEducation")}
        </span>
      </div>
    </div>
  );
};
