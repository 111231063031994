import React, { useState } from "react";
import { COVER, UNCOVER } from "constants/images";

const PasswordToggle = ({
  password,
  setPassword,
  placeholder,
  passwordError,
  flag,
}) => {
  const [value, setValue] = useState({
    showPassword: false,
  });

  const [isShowing, setIsShowing] = useState(false);
  const [message, setMessage] = useState("");

  const handleShowPassword = () => {
    setValue({ ...value, showPassword: !value.showPassword });
    if (isShowing === false) {
      setIsShowing(true);
    } else {
      setIsShowing(false);
    }
  };

  const handleCaps = (e) => {
    if (e.getModifierState("CapsLock")) {
      setMessage(`Caps lock is on`);
    } else {
      setMessage("");
    }
  };

  return (
    <>
      <input
        type={value.showPassword ? "text" : "password"}
        name="password"
        className={`form-control ${flag ? "text-danger border-danger" : ""}`}
        placeholder={placeholder}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        onKeyUp={handleCaps}
        onClick={handleCaps}
      />
      <label className={`${flag ? "text-danger" : ""}`} htmlFor="floatingInput">
        {placeholder}
      </label>
      <span className="icon">
        {isShowing ? (
          <button
            className="interactive-click"
            type="button"
            onClick={handleShowPassword}
          >
            <img src={UNCOVER.SOURCE} alt="password on icon" />
          </button>
        ) : (
          <button
            className="interactive-click"
            type="button"
            onClick={handleShowPassword}
            onKeyDown={handleShowPassword}
          >
            <img src={COVER.SOURCE} alt="password off icon" />
          </button>
        )}
      </span>
      <p className="text-danger">{message}</p>
      <div className="error text-danger">{passwordError}</div>
    </>
  );
};

export default PasswordToggle;
