import { useEffect, useRef, useState } from "react";
import { FieldArray, useFieldArray } from "react-final-form-arrays";
import { useTranslation } from "react-i18next";

import Modal from "components/Shared/Modal";
import { validateAtleastOneArray } from "utils/validation";

import { ErrorToast } from "./ErrorToast";
import { Portal } from "./Portal";
import { SectionContainer } from "./Section";
import { NoEducation } from "./Education/NoEducation";
import { EducationListHeader } from "./Education/EducationListHeader";
import { EducationListItem } from "./Education/EducationListItem";
import { EducationForm } from "./Education/EducationForm";

//Education section
export const EducationSection = ({ section }) => {
  const ref = useRef();
  const [element, setElement] = useState(null);
  const { fields: input } = useFieldArray(section.name);
  const [selectedEducationIndex, setSelectedEducationIndex] = useState(
    input.value?.length - 1 || 0
  );
  const [showFormModal, setShowFormModal] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    setElement(ref.current);
  }, []);

  useEffect(() => {
    setSelectedEducationIndex(input.value.length - 1);
  }, [input.value.length]);

  function onEdit(index) {
    setSelectedEducationIndex(index);
    setShowFormModal(true);
  }

  function onDelete(deleteId) {
    input.remove(deleteId);
  }

  function onSubmit(values, index) {
    input.update(index, values[section.name]);
    if (selectedEducationIndex === input.value?.length - 1) {
      input.push(undefined);
      // this hack is because the input.value length is not immediately updated
    } else {
      setSelectedEducationIndex(input.value.length - 1);
    }
    setShowFormModal(false);
  }

  function cancelEdit() {
    setSelectedEducationIndex(input.value?.length - 1);
    setShowFormModal(false);
  }

  //The key hack is to rerender the component as FieldArray doesnot give any way to differentiate between elements which caused duplicate keys when we delete a field

  return (
    <SectionContainer
      section={section}
      left={() =>
        input.value.length > 1 && (
          <AddEducationButton onClick={() => setShowFormModal(true)} />
        )
      }
    >
      <div className="portal-container" ref={ref}>
        <Portal el={element}>
          <div className="portal-content">
            <FieldArray
              name={section.name}
              validate={(v) =>
                validateAtleastOneArray(
                  v,
                  t("message.errors.missingEducationDetails")
                )
              }
            >
              {({ fields }) =>
                fields.value.length <= 1 ? (
                  <NoEducation>
                    <AddEducationButton
                      onClick={() => setShowFormModal(true)}
                    />
                  </NoEducation>
                ) : (
                  <div>
                    {fields.value.length > 1 && <EducationListHeader />}
                    <div className="max-h-400 overflow-x-auto">
                      {fields.map((name, index) => {
                        if (index === fields.value?.length - 1) return null;

                        return (
                          <EducationListItem
                            education={fields.value[index]}
                            name={name}
                            index={index}
                            key={Date.now() + "" + index}
                            onEdit={onEdit}
                            onDelete={onDelete}
                            isEditing={selectedEducationIndex === index}
                            section={section}
                          />
                        );
                      })}
                    </div>
                  </div>
                )
              }
            </FieldArray>
            <Modal
              isVisible={showFormModal}
              onClose={cancelEdit}
              className=""
              overlayClassName="form-modal-overlay"
            >
              <EducationForm
                section={section}
                initialValue={input.value?.[selectedEducationIndex]}
                index={selectedEducationIndex}
                onSubmit={onSubmit}
                key={JSON.stringify(input.value?.[selectedEducationIndex])}
                isEditing={selectedEducationIndex !== input.value?.length - 1}
                cancelEdit={cancelEdit}
                values={input.value}
              />
            </Modal>
            <ErrorToast name={section.name} />
          </div>
        </Portal>
      </div>
    </SectionContainer>
  );
};

function AddEducationButton({ onClick }) {
  const { t } = useTranslation();

  return (
    <button
      className="btn btn-primary "
      onClick={() => onClick()}
      type="button"
    >
      {t("common.addEducation")}
    </button>
  );
}
