import React from "react";

const NewFile = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width="198"
        height="143"
        viewBox="0 0 198 143"
        fill="noxne"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M162.34 0H39.769C34.1278 0 29.5547 4.57309 29.5547 10.2143V132.786C29.5547 138.427 34.1278 143 39.769 143H162.34C167.982 143 172.555 138.427 172.555 132.786V10.2143C172.555 4.57309 167.982 0 162.34 0Z"
          className="bg-figure"
        />
        <g filter="url(#filter0_d_15821_247701)">
          <path
            d="M52.029 55.1562H189.922C191.276 55.1562 192.575 55.6943 193.533 56.6521C194.491 57.6099 195.029 58.9089 195.029 60.2634V85.7991C195.029 87.1536 194.491 88.4526 193.533 89.4104C192.575 90.3682 191.276 90.9062 189.922 90.9062H52.029C50.6745 90.9062 49.3755 90.3682 48.4177 89.4104C47.4599 88.4526 46.9219 87.1536 46.9219 85.7991V60.2634C46.9219 58.9089 47.4599 57.6099 48.4177 56.6521C49.3755 55.6943 50.6745 55.1562 52.029 55.1562V55.1562Z"
            className="bg-figure-block"
          />
        </g>
        <path
          d="M127.614 63.3242H101.056C99.3641 63.3242 97.9922 64.6961 97.9922 66.3885C97.9922 68.0809 99.3641 69.4528 101.056 69.4528H127.614C129.306 69.4528 130.678 68.0809 130.678 66.3885C130.678 64.6961 129.306 63.3242 127.614 63.3242Z"
          className="bg-figure-primary"
          fill-opacity="0.48"
        />
        <path
          d="M145.999 76.6094H101.056C99.3641 76.6094 97.9922 77.9813 97.9922 79.6737C97.9922 81.366 99.3641 82.7379 101.056 82.7379H145.999C147.692 82.7379 149.064 81.366 149.064 79.6737C149.064 77.9813 147.692 76.6094 145.999 76.6094Z"
          className="bg-figure"
        />
        <path
          d="M78.0786 82.7431C83.4377 82.7431 87.7821 78.3986 87.7821 73.0395C87.7821 67.6804 83.4377 63.3359 78.0786 63.3359C72.7194 63.3359 68.375 67.6804 68.375 73.0395C68.375 78.3986 72.7194 82.7431 78.0786 82.7431Z"
          className="bg-figure-primary"
        />
        <g filter="url(#filter1_d_15821_247701)">
          <path
            d="M8.10714 99.0859H146C147.355 99.0859 148.654 99.624 149.611 100.582C150.569 101.54 151.107 102.839 151.107 104.193V129.729C151.107 131.083 150.569 132.382 149.611 133.34C148.654 134.298 147.355 134.836 146 134.836H8.10714C6.75264 134.836 5.45362 134.298 4.49585 133.34C3.53807 132.382 3 131.083 3 129.729L3 104.193C3 102.839 3.53807 101.54 4.49585 100.582C5.45362 99.624 6.75264 99.0859 8.10714 99.0859V99.0859Z"
            className="bg-figure-block"
          />
        </g>
        <path
          d="M83.6996 107.25H57.1424C55.4501 107.25 54.0781 108.622 54.0781 110.314C54.0781 112.007 55.4501 113.379 57.1424 113.379H83.6996C85.3919 113.379 86.7638 112.007 86.7638 110.314C86.7638 108.622 85.3919 107.25 83.6996 107.25Z"
          className="bg-figure-primary"
          fill-opacity="0.48"
        />
        <path
          d="M102.085 120.535H57.1424C55.4501 120.535 54.0781 121.907 54.0781 123.599C54.0781 125.292 55.4501 126.664 57.1424 126.664H102.085C103.778 126.664 105.15 125.292 105.15 123.599C105.15 121.907 103.778 120.535 102.085 120.535Z"
          className="bg-figure"
        />
        <path
          d="M20.8754 126.657C26.2346 126.657 30.579 122.313 30.579 116.954C30.579 111.594 26.2346 107.25 20.8754 107.25C15.5163 107.25 11.1719 111.594 11.1719 116.954C11.1719 122.313 15.5163 126.657 20.8754 126.657Z"
          className="bg-figure-primary"
        />
        <g filter="url(#filter2_d_15821_247701)">
          <path
            d="M146 11.2383H8.10714C5.28655 11.2383 3 13.5248 3 16.3454V41.8811C3 44.7017 5.28655 46.9883 8.10714 46.9883H146C148.821 46.9883 151.107 44.7017 151.107 41.8811V16.3454C151.107 13.5248 148.821 11.2383 146 11.2383Z"
            className="bg-figure-block"
          />
        </g>
        <path
          d="M81.6527 19.4062H55.0955C53.4032 19.4062 52.0312 20.7782 52.0312 22.4705C52.0312 24.1629 53.4032 25.5348 55.0955 25.5348H81.6527C83.345 25.5348 84.717 24.1629 84.717 22.4705C84.717 20.7782 83.345 19.4062 81.6527 19.4062Z"
          className="bg-figure-primary"
          fill-opacity="0.48"
        />
        <path
          d="M100.038 32.6875H55.0955C53.4032 32.6875 52.0312 34.0594 52.0312 35.7518C52.0312 37.4441 53.4032 38.8161 55.0955 38.8161H100.038C101.731 38.8161 103.103 37.4441 103.103 35.7518C103.103 34.0594 101.731 32.6875 100.038 32.6875Z"
          className="bg-figure"
        />
        <path
          d="M34.1567 38.8095C39.5158 38.8095 43.8603 34.465 43.8603 29.1059C43.8603 23.7468 39.5158 19.4023 34.1567 19.4023C28.7976 19.4023 24.4531 23.7468 24.4531 29.1059C24.4531 34.465 28.7976 38.8095 34.1567 38.8095Z"
          className="bg-figure-primary"
        />
        <defs>
          <filter
            id="filter0_d_15821_247701"
            x="44.779"
            y="54.0848"
            width="152.391"
            height="40.0357"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.07143" />
            <feGaussianBlur stdDeviation="1.07143" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_15821_247701"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_15821_247701"
              result="shape"
            />
          </filter>
          <filter
            id="filter1_d_15821_247701"
            x="0.857143"
            y="98.0145"
            width="152.391"
            height="40.0357"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.07143" />
            <feGaussianBlur stdDeviation="1.07143" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_15821_247701"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_15821_247701"
              result="shape"
            />
          </filter>
          <filter
            id="filter2_d_15821_247701"
            x="0.857143"
            y="10.1669"
            width="152.391"
            height="40.0357"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="1.07143" />
            <feGaussianBlur stdDeviation="1.07143" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_15821_247701"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_15821_247701"
              result="shape"
            />
          </filter>
        </defs>
      </svg>
    </>
  );
};

export default NewFile;
