import React, { useContext, useState, useEffect } from "react";

import CandidateDetail from "./CandidateDetail";
import HowItWorks from "./HowItWorks";
import ReportSummary from "./ReportSummary/ReportSummary";
import TestAssigned from "./TestAssigned";
import StickyNav from "./StickyNav";
import TalentPoolGraph from "./TalentPoolGraph/TalentPoolGraph";
import OverallCompetitiveness from "./OverallCompetitiveness";
import ApplicantReportApi from "api/ReportApi/ReportApi";

import { AuthContext } from "contextAPI/AuthContext";

const Report = () => {
  const [jsonData, setJsonData] = useState({});
  const { userInfo } = useContext(AuthContext);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    setUserId(userInfo?.data?.applicant_personal_info?.id);
  }, [userInfo]);

  useEffect(async () => {
    const response = await ApplicantReportApi.getDrillDownReport(userId);

    setJsonData(response?.data?.job_application);
  }, [userId]);

  return (
    <div className="applicant-report-wrapper position-relative">
      <div className="position-fixed sticky-nav rounded-start border-end-0">
        <StickyNav />
      </div>

      <CandidateDetail applicantData={jsonData} id="1" />

      <HowItWorks id="2" />
      <TestAssigned assessments={jsonData?.assessments} id="3" />
      <TalentPoolGraph assessments={jsonData?.assessments} />
      <ReportSummary assessments={jsonData?.assessments} id="4" />
      <OverallCompetitiveness
        score={jsonData?.overall_competitiveness}
        id="5"
      />
    </div>
  );
};

export default Report;
