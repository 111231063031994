import React from "react";

import DashboardLayout from "layout/DashboardLayout/DashboardLayout";
import CourseLists from "components/Dashboard/CourseLists";

export default function TrainingPage() {
  return (
    <DashboardLayout>
      <CourseLists />
    </DashboardLayout>
  );
}
