export const FORM_TITLE_TYPES = {
  EDUCATION: "Education Information",
  EMPLOYMENT: "Employment History",
};

export const LABEL = {
  END_MONTH: "end_month",
  END_YEAR: "end_year",
  SECTION_TYPE: "education-list",
  EDUCATION_COMPARE_TYPE: "education_list",
  EMPLOYMENT_SECTION_TYPE: "employment-list",
};

export const VALIDATE = {
  LEVEL: "Please choose one school",
  SCHOOL: "Please enter school name",
  START_MONTH: "Please enter start month",
  START_YEAR: "Please enter start year",
  END_MONTH: "Please enter end month",
  END_YEAR: "Please enter end year",
  DEGREE: "Please enter your degree",
};

export const TOOLTIP_COLOR = {
  BACKGROUND_DARK: "#45504D",
};
