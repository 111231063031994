import { Link } from "react-router-dom";

import Modal from "components/Shared/Modal";
import RecoveryMailIcon  from "assets/icons/RecoveryMailIcon";
import { useTranslation } from "react-i18next";
import { SIGN_IN } from "constants/routes";

const PasswordRecoveryModal = ({
  hidePasswordRecoveryModal,
  isVisible,
}) => {
  const { t } = useTranslation();

  if (!isVisible) return null;

  return (
    <Modal
      className="d-flex flex-column align-items-center text-center px-5 py-4 bg-section"
      isVisible={isVisible}
    >
      <RecoveryMailIcon className="color-icon mb-3" />
      <h5>{t("message.recoveryPassword.recoveryPassword")}</h5>
      <div>
        <p>{t("message.recoveryPassword.hooray")}</p>
        <p>
          {t("message.recoveryPassword.sendRecoveryLink")}
        </p>
        <p>
          {t("message.recoveryPassword.ifAccountExists")}{" "}
        </p>{" "}
        {t("message.recoveryPassword.getLink")}
      </div>

      <div className="text-center mt-4">
        <Link
          to= {SIGN_IN.INDEX}
          className="btn btn-primary px-4 shadow-none"
          onClick={()=>hidePasswordRecoveryModal}
        >
          {t("label.backToSignIn")}
        </Link>
      </div>
    </Modal>
  );
};

export default PasswordRecoveryModal;
