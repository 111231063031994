import React from "react";

const TypingIcon = ({ width, height, className }) => {
  const classNames = [];

  classNames.push(className);

  return (
    <>
      <svg
        width={`${width ? width : "72"}`}
        height={`${height ? height : "45"}`}
        className={classNames.join(" ")}
        viewBox="0 0 72 45"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect
          x="8"
          y="11.5488"
          width="15"
          height="1.74194"
          fill="#C4C4C4"
          fillOpacity="0.6"
        />
        <rect
          x="8"
          y="34.1934"
          width="15"
          height="1.74194"
          fill="#C4C4C4"
          fillOpacity="0.6"
        />
        <rect
          x="48"
          y="34.1934"
          width="15"
          height="1.74194"
          fill="#C4C4C4"
          fillOpacity="0.6"
        />
        <rect
          x="48"
          y="25.4844"
          width="15"
          height="1.74194"
          fill="#C4C4C4"
          fillOpacity="0.6"
        />
        <rect
          x="48"
          y="10.6777"
          width="15"
          height="1.74194"
          fill="#C4C4C4"
          fillOpacity="0.6"
        />
        <rect
          x="16"
          y="5.45117"
          width="13.0645"
          height="2"
          transform="rotate(90 16 5.45117)"
          fill="#C4C4C4"
          fillOpacity="0.6"
        />
        <rect
          x="1"
          y="1"
          width="70"
          height="43"
          rx="3"
          stroke="#B8C1BF"
          strokeOpacity="0.6"
          strokeWidth="2"
        />
      </svg>
    </>
  );
};

export default TypingIcon;
