import React from "react";
import { t } from "i18next";

import Title from "./HelperComponents/Title";

const OverallCompetitiveness = ({ score, id }) => {
  return (
    <div className="overall-competitiveness" id={id}>
      <Title>
        {t("applicantReport.overallCompetitiveness.yourOverall")}{" "}
        <span>
          {t("applicantReport.overallCompetitiveness.competitiveness")}
        </span>{" "}
        {t("applicantReport.overallCompetitiveness.compared")} <br />
        {t("applicantReport.overallCompetitiveness.toOtherApplicants")}
      </Title>

      <div className="normative-container mx-auto mt-5 position-relative mb-5">
        <div
          className="normative-bar-container position-absolute px-1"
          style={{ left: score + "%" }}
        >
          <div className="normative-bar"></div>
        </div>
      </div>
    </div>
  );
};

export default OverallCompetitiveness;
