import { useState, useContext } from "react";

import TestsList from "./TestsList";

import Background from "./Background";
import JobOverview from "./JobOverview";
import Tests from "./Tests";
import Header from "./Header";

import { UserContext } from "contextAPI/UserContext";

const ApplicationAdventures = ({ setIsModalVisible }) => {
  const { candidateInfo } = useContext(UserContext);

  const [isTestCardHovered, setIsTestCardHovered] = useState(false);

  return (
    <div className={`bg-section mb-4 p-4`}>
      <Header setIsModalVisible={setIsModalVisible} />
      <div className="mt-4 d-flex">
        <JobOverview />
        <Background
          totalForms={candidateInfo?.total_forms}
          completedForms={candidateInfo?.completed_forms}
        />
        <Tests
          totalTests={candidateInfo?.total_assessments}
          completedTests={candidateInfo?.completed_assessments}
          isTestCardHovered={false}
          setisTestCardHovered={setIsTestCardHovered}
        />
      </div>
      <TestsList
        isTestCardHovered={false}
        setIsTestCardHovered={setIsTestCardHovered}
      />
    </div>
  );
};

export default ApplicationAdventures;
