import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const CourseSkeleton = () => (
  <SkeletonTheme>
    <div className="course-group">
      <Skeleton count={1} className="ribbon" />
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <Skeleton className="mb-2 skel-img" />
            <Skeleton className="skel-title" />
            <Skeleton className="skel-progress" />
          </div>
          <div className="col-md-3">
            <Skeleton className="mb-2 skel-img" />
            <Skeleton className="skel-title" />
            <Skeleton className="skel-progress" />
          </div>
          <div className="col-md-3">
            <Skeleton className="mb-2 skel-img" />
            <Skeleton className="skel-title" />
            <Skeleton className="skel-progress" />
          </div>
          <div className="col-md-3">
            <Skeleton className="mb-2 skel-img" />
            <Skeleton className="skel-title" />
            <Skeleton className="skel-progress" />
          </div>
        </div>
      </div>
    </div>
    <div className="course-group mt-4">
      <Skeleton count={1} className="ribbon" />
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <Skeleton className="mb-2 skel-img" />
            <Skeleton className="skel-title" />
            <Skeleton className="skel-progress" />
          </div>
          <div className="col-md-3">
            <Skeleton className="mb-2 skel-img" />
            <Skeleton className="skel-title" />
            <Skeleton className="skel-progress" />
          </div>
          <div className="col-md-3">
            <Skeleton className="mb-2 skel-img" />
            <Skeleton className="skel-title" />
            <Skeleton className="skel-progress" />
          </div>
          <div className="col-md-3">
            <Skeleton className="mb-2 skel-img" />
            <Skeleton className="skel-title" />
            <Skeleton className="skel-progress" />
          </div>
        </div>
      </div>
    </div>
  </SkeletonTheme>
);

export default CourseSkeleton;
