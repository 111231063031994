import { useForm } from "react-final-form";

import { WORKFLOW_PAGE_TYPES } from "constants/constant";
import Assessments from "pages/Workflow/Assessments";
import { DynamicFormElement } from "./DynamicFormElement";

export const Page = ({ pageData }) => {
  const form = useForm();

  if (pageData.subType === WORKFLOW_PAGE_TYPES.ASSESSMENT_PAGE) {
    return (
      <Assessments
        src={pageData.assessment_url}
        goNext={() => form.submit()}
        id={pageData.b_id}
      />
    );
  }

  return (
    <div>
      <div className="form-title">{pageData.title}</div>
      <div className="d-flex flex-column">
        {pageData.components.map((component) => (
          <DynamicFormElement component={component} key={component.id} />
        ))}
      </div>
    </div>
  );
};
