export const TICK_PATH =
  "M13.485 1.431a1.473 1.473 0 0 1 2.104 2.062l-7.84 9.801a1.473 1.473 0 0 1-2.12.04L.431 8.138a1.473 1.473 0 0 1 2.084-2.083l4.111 4.112l6.82-8.69a.486.486 0 0 1 .04-.045z";

export const DOT_LIST =
  "M7 4C7 4.55228 7.44772 5 8 5C8.55228 5 9 4.55228 9 4C9 3.44772 8.55228 3 8 3C7.44772 3 7 3.44772 7 4Z M15 4C15 4.55228 15.4477 5 16 5C16.5523 5 17 4.55228 17 4C17 3.44772 16.5523 3 16 3C15.4477 3 15 3.44772 15 4Z M7 12C7 12.5523 7.44772 13 8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12Z M15 12C15 12.5523 15.4477 13 16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11C15.4477 11 15 11.4477 15 12Z M7 20C7 20.5523 7.44772 21 8 21C8.55228 21 9 20.5523 9 20C9 19.4477 8.55228 19 8 19C7.44772 19 7 19.4477 7 20Z M15 20C15 20.5523 15.4477 21 16 21C16.5523 21 17 20.5523 17 20C17 19.4477 16.5523 19 16 19C15.4477 19 15 19.4477 15 20";

export const CORRECT_PATH =
  "M18.1117 8.45522C18.3443 8.25406 18.6538 8.14284 18.9749 8.14505C19.296 8.14726 19.6036 8.26271 19.8328 8.46706C20.062 8.6714 20.1949 8.94864 20.2034 9.24029C20.2119 9.53193 20.0953 9.81517 19.8783 10.0302L13.2907 17.5152C13.1774 17.6261 13.0407 17.715 12.8887 17.7768C12.7368 17.8385 12.5726 17.8718 12.4062 17.8746C12.2397 17.8774 12.0744 17.8497 11.92 17.7931C11.7656 17.7365 11.6253 17.6522 11.5076 17.5452L7.13899 13.5762C7.01733 13.4732 6.91975 13.349 6.85207 13.211C6.78439 13.073 6.748 12.9241 6.74507 12.773C6.74213 12.622 6.77272 12.4719 6.835 12.3318C6.89727 12.1917 6.98997 12.0645 7.10755 11.9577C7.22514 11.8508 7.3652 11.7666 7.51939 11.71C7.67357 11.6535 7.83873 11.6257 8.00499 11.6283C8.17125 11.631 8.33522 11.6641 8.48711 11.7256C8.63901 11.787 8.77571 11.8757 8.88908 11.9862L12.3463 15.1257L18.0803 8.48822C18.0907 8.47668 18.1017 8.46566 18.1134 8.45522H18.1117Z";

export const CANCEL_PATH =
  "M16 0c-8.837 0-16 7.163-16 16s7.163 16 16 16 16-7.163 16-16-7.163-16-16-16zM16 29c-7.18 0-13-5.82-13-13s5.82-13 13-13 13 5.82 13 13-5.82 13-13 13z M21 8l-5 5-5-5-3 3 5 5-5 5 3 3 5-5 5 5 3-3-5-5 5-5z";

export const SEARCH_PATH =
  "M10 18a7.952 7.952 0 0 0 4.897-1.688l4.396 4.396l1.414-1.414l-4.396-4.396A7.952 7.952 0 0 0 18 10c0-4.411-3.589-8-8-8s-8 3.589-8 8s3.589 8 8 8zm0-14c3.309 0 6 2.691 6 6s-2.691 6-6 6s-6-2.691-6-6s2.691-6 6-6z";

export const RIGHT_ARROW =
  "M17.414 17.414c0.781-0.781 0.781-2.047 0-2.828l-12.728-12.728c-0.781-0.781-2.047-0.781-2.828 0s-0.781 2.047 0 2.828l11.314 11.314-11.314 11.314c-0.781 0.781-0.781 2.047 0 2.828s2.047 0.781 2.828 0l12.728-12.728zM14 18h2v-4h-2v4z";

export const BACKGROUND_PATTERN =
  "M41.519-38.684c0 38.478-31.193 69.671-69.671 69.671s-69.671-31.193-69.671-69.671c0-38.478 31.193-69.671 69.671-69.671s69.671 31.193 69.671 69.671z";

export const LOCK_PATH =
  "M23.862 14.933h-14.269c-1.126 0-2.038 0.868-2.038 1.939v6.788c0 1.071 0.913 1.939 2.038 1.939h14.269c1.126 0 2.038-0.868 2.038-1.939v-6.788c0-1.071-0.913-1.939-2.038-1.939z M11.871 14.933v-3.793c0-1.257 0.512-2.463 1.422-3.352s2.146-1.389 3.434-1.389c1.288 0 2.523 0.499 3.434 1.389s1.422 2.095 1.422 3.352v3.793";

  
