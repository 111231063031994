import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Tabs from "./Tabs";

const Header = ({ switchTabCount, setSwitchTabCount, showTab }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState("");

  const switchTabCountHandler = (num) => {
    setSwitchTabCount(num);
  };

  useEffect(() => {
    if (switchTabCount === 1) {
      setTitle(t("label.availableCourses"));
    } else if (switchTabCount === 2) {
      setTitle(t("label.inProgressCourse"));
    } else {
      setTitle(t("label.completedCourses"));
    }
  }, [switchTabCount]);

  const activeTab = switchTabCount === 1 ? "active-tab" : null;
  const progressTab = switchTabCount === 2 ? "active-tab" : null;
  const completedTab = switchTabCount === 3 ? "active-tab" : null;

  const Tabstyle = showTab
    ? "d-flex justify-content-between align-items-center"
    : null;

  return (
    <div className={`header ${Tabstyle}`}>
      <h4 className="m-0">{title}</h4>
      <Tabs
        switchTabCountHandler={switchTabCountHandler}
        activeTab={activeTab}
        progressTab={progressTab}
        completedTab={completedTab}
        showTab={showTab}
      />
    </div>
  );
};

export default Header;
