import React from "react";

import GameIcon from "components/IndividualGame/GameIcon";

const TestCard = ({ testIcon, testName }) => {
  return (
    <div className="tests-list-card cursor-pointer px-4 d-flex flex-column gap-2">
      <div>
        <GameIcon
          name={testIcon}
          className={`color-icon`}
          height="40"
          width="40"
        />
      </div>

      <div className=" test-name">{testName}</div>
    </div>
  );
};

export default TestCard;
