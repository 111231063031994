import ErrorText from "components/ErrorText/ErrorText";

export const CheckboxInput = ({
  label: labelProp,
  inputProps,
  error,
  required,
}) => {
  const label = required ? `${labelProp}*` : labelProp;

  return (
    <div className="input-block">
      <div className=" d-flex align-items-center">
        <label className={`checkbox-label-container`}>
          <span className="d-block"> {label}</span>
          <input type="checkbox" {...inputProps} />
          <span className="checkmark" />
        </label>
        <ErrorText error={error} />
      </div>
    </div>
  );
};
