import React, { useState, useEffect, useContext } from "react";

import RenderEnrolled from "components/Dashboard/RenderEnrolled";

import CoursesApi from "api/CoursesApi/CoursesApi";

import { UserContext } from "contextAPI/UserContext";

const CourseLists = () => {
  const { candidateInfo } = useContext(UserContext);

  const [loading, setLoading] = useState(true);
  const [enrolled, setEnrolled] = useState([]);

  const enrolledCategory = [];

  const enrolledList = async () => {
    const response = await CoursesApi.getEnrolled(candidateInfo?.id);
    const browseResponse = await CoursesApi.getExplore(candidateInfo?.id);

    if (response) {
      setLoading(false);
      setEnrolled(response?.data);
    }
  };

  useEffect(() => {
    enrolledList();
  }, [candidateInfo?.id]);

  for (let i = 0; i <= enrolled?.length; i++) {
    enrolledCategory.push({
      id: enrolled?.[i]?.category?.id,
      name: enrolled?.[i]?.category?.name,
      parent_category_id: enrolled?.[i]?.category?.parent_category_id,
      price: enrolled?.[i]?.category?.price,
    });
  }

  const filteredCategory = enrolledCategory.filter(
    (el, idx) => enrolledCategory.findIndex((obj) => obj.id === el.id) === idx
  );

  return (
    <div className="accordion" id="accordionExample">
      <RenderEnrolled
        filteredCategory={filteredCategory}
        enrolled={enrolled}
        loading={loading}
      />
    </div>
  );
};

export default CourseLists;
