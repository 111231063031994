export const getVideoStream = async (idealWidth = 4096, idealHeight = 2160) => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const cameras = devices.filter((device) => device.kind === "videoinput");

  if (cameras && cameras.length > 0) {
    try {
      // console.log(stream.getVideoTracks()[0].getSettings().height,
      //     stream.getVideoTracks()[0].getSettings().width)
      navigator.mediaDevices.getUserMedia =
        navigator.mediaDevices.getUserMedia ||
        navigator.webkitGetUserMedia ||
        navigator.mozGetUserMedia ||
        navigator.getUserMedia ||
        navigator.msGetUserMedia;

      return await navigator.mediaDevices.getUserMedia({
        video: {
          width: { ideal: idealWidth },
          height: { ideal: idealHeight },
        },
      });
    } catch (e) {
      return e.message;
    }
  }

  return "No web camera found";
};

export const getImageCapture = (stream) => {
  return new ImageCapture(stream.getVideoTracks()[0]);
};

export const getBlobFromMediaStream = (stream) => {
  // if ('ImageCapture' in window) {
  //     return getImageCapture(stream).takePhoto()
  // } else {
  //
  // }
  const video = document.createElement("video");
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  video.srcObject = stream;

  return new Promise((resolve, reject) => {
    try {
      video.addEventListener("loadeddata", () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        video.play().then(() => {
          if (context) {
            context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
            canvas.toBlob(resolve, "image/jpeg", 1.0);
          }
        });
      });
    } catch (error) {
      reject(error);
    }
  });
};
