import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Header from "components/ApplicantDashboard/TalentLMS/Header";

import RenderCourse from "./Courses/RenderCourse";
import { LINK } from "constants/constant";

import { DashboardContext } from "contextAPI/dashboardContext";

const TrainingCourse = ({ showTab }) => {
  const { t } = useTranslation();

  const { course } = useContext(DashboardContext);

  const [switchTabCount, setSwitchTabCount] = useState(1);

  const progressCourses = [];
  const completedCourses = [];

  for (let i = 0; i < course?.length; i++) {
    if (course?.[i]?.completion_percentage !== 100) {
      progressCourses.push({
        id: course?.[i]?.id,
        name: course?.[i]?.name,
        code: course?.[i]?.code,
        category_id: course?.[i]?.category_id,
        description: course?.[i]?.description,
        price: course?.[i]?.price,
        status: course?.[i]?.status,
        creation_date: course?.[i]?.creation_date,
        last_update_on: course?.[i]?.last_update_on,
        creator_id: course?.[i]?.creator_id,
        hide_from_catalog: course?.[i]?.hide_from_catalog,
        time_limit: course?.[i]?.time_limit,
        start_datetime: course?.[i]?.start_datetime,
        expiration_datetime: course?.[i]?.expiration_datetime,
        level: course?.[i]?.level,
        shared: course?.[i]?.shared,
        shared_url: course?.[i]?.shared_url,
        avatar: course?.[i]?.avatar,
        big_avatar: course?.[i]?.big_avatar,
        certification: course?.[i]?.certification,
        certification_duration: course?.[i]?.certification_duration,
        category: {
          id: course?.[i]?.category.id,
          name: course?.[i]?.category.name,
          price: course?.[i]?.category.price,
          parent_category_id: course?.[i]?.category.parent_category_id,
        },
        course_link: course?.[i]?.course_link,
        role: course?.[i]?.role,
        enrolled_on: course?.[i]?.enrolled_on,
        enrolled_on_timestamp: course?.[i]?.enrolled_on_timestamp,
        completed_on: course?.[i]?.completed_on,
        completed_on_timestamp: course?.[i]?.completed_on_timestamp,
        completion_status: course?.[i]?.completion_status,
        completion_status_formatted: course?.[i]?.completion_status_formatted,
        completion_percentage: course?.[i]?.completion_percentage,
        expired_on: course?.[i]?.expired_on,
        expired_on_timestamp: course?.[i]?.expired_on_timestamp,
        total_time: course?.[i]?.total_time,
        total_time_seconds: course?.[i]?.total_time_seconds,
        last_accessed_unit_url: course?.[i]?.last_accessed_unit_url,
      });
    }
  }

  for (let i = 0; i < course?.length; i++) {
    if (course?.[i]?.completion_percentage === 100) {
      completedCourses.push({
        id: course?.[i]?.id,
        name: course?.[i]?.name,
        code: course?.[i]?.code,
        category_id: course?.[i]?.category_id,
        description: course?.[i]?.description,
        price: course?.[i]?.price,
        status: course?.[i]?.status,
        creation_date: course?.[i]?.creation_date,
        last_update_on: course?.[i]?.last_update_on,
        creator_id: course?.[i]?.creator_id,
        hide_from_catalog: course?.[i]?.hide_from_catalog,
        time_limit: course?.[i]?.time_limit,
        start_datetime: course?.[i]?.start_datetime,
        expiration_datetime: course?.[i]?.expiration_datetime,
        level: course?.[i]?.level,
        shared: course?.[i]?.shared,
        shared_url: course?.[i]?.shared_url,
        avatar: course?.[i]?.avatar,
        big_avatar: course?.[i]?.big_avatar,
        certification: course?.[i]?.certification,
        certification_duration: course?.[i]?.certification_duration,
        category: {
          id: course?.[i]?.category.id,
          name: course?.[i]?.category.name,
          price: course?.[i]?.category.price,
          parent_category_id: course?.[i]?.category.parent_category_id,
        },
        course_link: course?.[i]?.course_link,
        role: course?.[i]?.role,
        enrolled_on: course?.[i]?.enrolled_on,
        enrolled_on_timestamp: course?.[i]?.enrolled_on_timestamp,
        completed_on: course?.[i]?.completed_on,
        completed_on_timestamp: course?.[i]?.completed_on_timestamp,
        completion_status: course?.[i]?.completion_status,
        completion_status_formatted: course?.[i]?.completion_status_formatted,
        completion_percentage: course?.[i]?.completion_percentage,
        expired_on: course?.[i]?.expired_on,
        expired_on_timestamp: course?.[i]?.expired_on_timestamp,
        total_time: course?.[i]?.total_time,
        total_time_seconds: course?.[i]?.total_time_seconds,
        last_accessed_unit_url: course?.[i]?.last_accessed_unit_url,
      });
    }
  }

  return (
    <div className="training-course-wrapper adventure-wrapper p-0 bg-section">
      <div className="pt-4 px-4">
        <Header
          switchTabCount={switchTabCount}
          setSwitchTabCount={setSwitchTabCount}
          showTab={showTab}
        />

        {switchTabCount === 1 && (
          <p className="my-3">{t("message.availableMsg")}</p>
        )}

        <RenderCourse
          switchTabCount={switchTabCount}
          progressCourses={progressCourses}
          completedCourses={completedCourses}
        />
      </div>
      <Link to={LINK.EXPLORE} className="">
        <span className="d-block py-3 text-center cursor-pointer py-3 text-secondary bg-section-100">
          {t("label.viewAll")}
        </span>
      </Link>
    </div>
  );
};

export default TrainingCourse;
