import ReactSelect from "react-select";

import ErrorText from "components/ErrorText/ErrorText";
import { useState } from "react";

export const SelectInput = ({
  inputProps,
  label: labelProp,
  options,
  isLoading,
  error,
  required,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const label = required ? `${labelProp}*` : labelProp;

  return (
    <div className="select-floating">
      <ReactSelect
        {...inputProps}
        unstyled
        isLoading={isLoading}
        className={`dropdown ${
          !(inputProps.value || isFocused) && "no-placeholder"
        }`}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        styles={{
          menu: () => ({
            zIndex: 100,
          }),
        }}
        classNames={{
          control: () =>
            "dropdown-toggle onboarding  d-flex align-items-center w-100 text-truncate position-relative form-control",
          option: () =>
            "dropdown-item mb-0 px-0 py-3 px-3 d-flex justify-content-between",
          menu: () => "dropdown-menu d-block bg-dark w-100 py-1",
        }}
        options={options}
        key={JSON.stringify(options[0])}
        onChange={(e) => {
          inputProps.onChange(e.value);
        }}
        value={options.find(
          (option) =>
            option.value.toLowerCase() === inputProps.value.toLowerCase()
        )}
      />
      <label> {label}</label>
      <ErrorText error={error} />
    </div>
  );
};
