import React from "react";
import { useTranslation } from "react-i18next";
import { NewFileImage } from "assets/icons/icons";

const NoEmploymentSection = () => {
  const { t } = useTranslation();

  return (
    <div className="noJob-wrapper bg-dark p-5 d-flex flex-column align-items-center justify-content-center">
      <div>
        <NewFileImage />
      </div>
      <div className="title my-3">
        {t("message.employmemtDetails.noEmploymentHistory")}
      </div>
      <div className="desc text-center">
        {t("message.employmemtDetails.pleaseCheck")}
        <br />
        {t("message.employmemtDetails.experienceDetails")}
      </div>
    </div>
  );
};

export default NoEmploymentSection;
