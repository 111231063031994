import React from "react";

import { IntIcon } from "components/Shared/Icon";

const NoCourse = ({ detail }) => {
  return (
    <div className="d-flex justify-content-center flex-column gap-3 align-items-center my-4 no-course-container">
      <div>
        <IntIcon name="noList" className="no-course-icon" />
      </div>
      <p>{detail}</p>
    </div>
  );
};

export default NoCourse;
