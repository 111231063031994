import React, { useState, useEffect } from "react";

import { t } from "i18next";

import PerformanceLayout from "components/Report/HelperComponents/PerformanceLayout";
import PerformanceListWithTitle from "components/Report/HelperComponents/PerformanceListWithTitle";
import Subtitle from "components/Report/HelperComponents/Subtitle";
import TitleAlt from "components/Report/HelperComponents/TitleAlt";
import Result from "./Result";
import LineBreaker from "components/Report/HelperComponents/LineBreaker";

const MCCSLayout = ({ data, title, subtitle }) => {
  const [standOutList, setStandOutList] = useState([]);
  const [watchOutList, setWatchOutList] = useState([]);
  const [suggestedStatus, setSuggestedStatus] = useState("Stand Out");

  const scaleData = [
    {
      title: t("applicantReport.mccsScaleData.accuracy"),
      status: data?.score?.subscales_suggested_status?.accuracy,
      description: t("applicantReport.mccsScaleData.itIsAnIndex"),
    },
    {
      title: t("applicantReport.mccsScaleData.customerService"),
      status: data?.score?.subscales_suggested_status?.customer_service,
      description: t("applicantReport.mccsScaleData.theAssistance"),
    },
    {
      title: t("applicantReport.mccsScaleData.multitasking"),
      status: data?.score?.subscales_suggested_status?.multi_tasking,
      description: t("applicantReport.mccsScaleData.aMeasureOfaPerson"),
    },
    {
      title: t("applicantReport.mccsScaleData.navigation"),
      status: data?.score?.subscales_suggested_status?.navigation,
      description: t("applicantReport.mccsScaleData.itIsaIndicator"),
    },
    {
      title: t("applicantReport.mccsScaleData.quality"),
      status: data?.score?.subscales_suggested_status?.quality,
      description: t("applicantReport.mccsScaleData.itIsanIndicator"),
    },
    {
      title: t("applicantReport.mccsScaleData.troubleshooting"),
      status: data?.score?.troubleshooting_percentile,
      description: t("applicantReport.mccsScaleData.anIndexOfa"),
    },
    {
      title: t("applicantReport.mccsScaleData.sales"),
      status: data?.score?.sales_percentile,
      description: t("applicantReport.mccsScaleData.aMetricSummarizing"),
    },
  ];

  useEffect(() => {
    const standOutData = scaleData.filter(
      (data) => data?.status === "stand_out"
    );
    const watchOutData = scaleData.filter(
      (data) => data?.status === "watch_out"
    );

    setStandOutList(standOutData);
    setWatchOutList(watchOutData);
  }, [data]);

  useEffect(() => {
    if (data?.score?.suggested_status === "most_applicants") {
      setSuggestedStatus("Most Applicants");
    } else if (data?.score?.suggested_status === "watch_out") {
      setSuggestedStatus("Watch Out");
    }
  }, [data]);

  if (data?.status === "incomplete") {
    return null;
  }

  return (
    <>
      <LineBreaker />
      <TitleAlt>{title}</TitleAlt>
      <Subtitle>{subtitle}</Subtitle>
      <Result suggestedStatus={suggestedStatus}>{suggestedStatus}</Result>
      <div className="d-flex gap-4 mt-4">
        <PerformanceLayout title={t("applicantReport.standOut")}>
          <PerformanceListWithTitle listData={standOutList} />
        </PerformanceLayout>
        <PerformanceLayout title={t("applicantReport.watchOut")}>
          <PerformanceListWithTitle listData={watchOutList} />
        </PerformanceLayout>
      </div>
    </>
  );
};

export default MCCSLayout;
