import React from "react";

import DashboardLayout from "layout/DashboardLayout/DashboardLayout";

import OnBoarding from "pages/Onboarding/OnBoarding";

const Workflow = () => {
  return (
    <DashboardLayout>
      <OnBoarding />
    </DashboardLayout>
  );
};

export default Workflow;
