import React, { useEffect, useState } from "react";

import { IntIcon } from "components/Shared/Icon";

const Result = ({ children, suggestedStatus }) => {
  const [requiredIcon, setRequiredIcon] = useState("ArmIconAlt");
  const [requiredIconClassName, setRequiredIconClassName] =
    useState("arm-icon");
  const [requiredBrandingColorClassName, setRequiredBrandingColorClassName] =
    useState("stand-out-color");

  const isMostApplicant = suggestedStatus === "Most Applicants";
  const isWatchOutside = suggestedStatus === "Watch Out";

  useEffect(() => {
    if (isMostApplicant) {
      setRequiredIcon("usersIcon");
      setRequiredIconClassName("users-icon");
      setRequiredBrandingColorClassName("most-applicant-color");
    } else if (isWatchOutside) {
      setRequiredIcon("starIconAlt");
      setRequiredIconClassName("star-icon");
      setRequiredBrandingColorClassName("watch-out-color");
    }
  });

  return (
    <div className="text-center result mb-5 mt-4">
      <h2>Result</h2>
      <div
        className={`d-inline-flex gap-3 justify-content-center px-4 py-3 icon-status-container ${requiredBrandingColorClassName}`}
      >
        <div>
          <IntIcon
            name={requiredIcon}
            className={`result-icon ${requiredIconClassName}`}
          />
        </div>
        <div className="result-status">{children}</div>
      </div>
    </div>
  );
};

export default Result;
