import React from "react";

const PerformanceListWithoutTitle = ({ listData, layoutColor }) => (
  <div className="performance-list">
    {listData.map((list, index) => (
      <ul key={index} className="m-0 d-flex gap-3 my-3 p-0">
        <div className={`bullet-point ${layoutColor}`}></div>
        <li className="list">{list}</li>
      </ul>
    ))}
  </div>
);

export default PerformanceListWithoutTitle;
