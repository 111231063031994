import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import JobsApi from "api/JobsApi/JobsApi";

import CustomComponent from "components/CustomComponent/CustomComponent";

import { HOME } from "constants/routes";

import { UserContext } from "contextAPI/UserContext";
import { useTranslation } from "react-i18next";

const WelcomePage = ({ id }) => {
  const { t } = useTranslation();

  const { welcomeForm } = useContext(UserContext);

  const [messageData, setMessageData] = useState(null);

  const showData = id ? messageData : welcomeForm;

  const getData = async (id) => {
    const { data } = await JobsApi.getDetails(id);

    setMessageData(data?.welcome_page_workflow);
  };

  useEffect(() => {
    getData(id);
  }, [id]);

  return (
    <div className="welcome-page w-100 d-flex position-absolute justify-content-center align-items-center m-0 p-0 ">
      <div className="d-flex flex-column justify-content-center align-items-center">
        <div className="card mt-5 p-5 border-0">
          <h4 className="py-3 mb-4"> Welcome to applicant dasboard </h4>
          {showData?.template_json?.components[0]?.components?.map((item) => {
            return (
              <div className="mb-5">
                <CustomComponent data={item} type={item?.type} />
              </div>
            );
          })}
        </div>
        <div className="d-flex justify-content-center">
          <Link className="btn-link mt-4 text-primary" to={HOME.INDEX}>
            <button className="btn btn-primary mt-4 mb-3">
              {t("common.letsGetStarted")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
