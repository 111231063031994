import { educationHeader } from "constants/constant";

export const EducationListHeader = () => {
  return (
    <div className="bg-dark p-4 rounded mb-2">
      <div className={`row  justify-content-between align-items-center`}>
        <div className="col-4 header-text">
          <strong className="fw-semibold"> {educationHeader?.[0]}</strong>
        </div>
        <div className="col-3 header-text">
          <strong className="fw-semibold">{educationHeader?.[1]}</strong>
        </div>
        <div className="col-4 header-text">
          <strong className="fw-semibold">{educationHeader?.[2]}</strong>
        </div>
        <div className="col-1  text-center header-text">
          <strong className="fw-semibold">{educationHeader?.[3]}</strong>
        </div>
      </div>
    </div>
  );
};
