import React from "react";
import { useTranslation } from "react-i18next";

import NoCourse from "components/ApplicantDashboard/TalentLMS/NoCourse";
import Carousel from "components/ApplicantDashboard/TalentLMS/Carousel";
import CourseCard from "components/ApplicantDashboard/TalentLMS/CourseCard";

const CompletedCourses = ({ browse }) => {
  const { t } = useTranslation();

  if (browse.length == 0) {
    return <NoCourse detail={t("label.noCourseInCompleted")} />;
  }

  return (
    <div className="courses-wrapper py-3">
      <Carousel>
        {browse?.map((course, idx) => (
          <CourseCard
            key={course.id}
            courses={course}
            progress={true}
            idx={idx}
            id={course.id}
          />
        ))}
      </Carousel>
    </div>
  );
};

export default CompletedCourses;
