import { useEffect } from "react";

const Modal = ({
  isVisible,
  className,
  children,
  footer = null,
  overlayClassName = "",
  onClose = () => {},
}) => {
  useEffect(() => {
    if (!isVisible) {
      // document.querySelector("body").classList.remove("overflow-hidden");

      return null;
    }

    document.querySelector("body").classList.add("overflow-hidden");

    return () => {
      document.querySelector("body").classList.remove("overflow-hidden");
    };
  }, [isVisible]);

  return (
    isVisible && (
      <div
        className={`overlay bg-transparent vh-100 vw-100 position-fixed m-0 p-0 ${overlayClassName}`}
        onClick={() => onClose()}
      >
        <div
          className={`position-absolute ${className} top-50 start-50 translate-middle overflow-hidden card border-0`}
          onClick={(e) => e.stopPropagation()}
        >
          {children}
        </div>
      </div>
    )
  );
};

export default Modal;
