import React from "react";

const TestListIcon = ({ className, width, height }) => {
  return (
    <>
      <svg
        className={className}
        width={width ? width : "78"}
        height={height ? height : "67"}
        viewBox="0 0 78 67"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.375 15.3931L16.25 19.4969L24.375 12.6572"
          stroke="#1DB27E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.375 31.8081L16.25 35.9119L24.375 29.0723"
          stroke="#1DB27E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.375 48.2232L16.25 52.327L24.375 45.4873"
          stroke="#1DB27E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.75 16.7607H65"
          stroke="#1DB27E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.75 33.1758H65"
          stroke="#1DB27E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M35.75 49.5918H65"
          stroke="#1DB27E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default TestListIcon;
