import AuthenticatedApi from "api/AuthenticatedApi";

import BaseRequest from "../BaseApi";

const URL = {
  INDEX: `/auth`,
  TOKEN: "/oauth/token",
  PASSWORD: `/password`,
  ACCEPT_INVITATION: "/candidate_invitations/invitation_accept",
  INFO: "/me",
  USERS: "/users",
  CHECK_USER: "/check_user?email=:email",
};

class AuthApi {
  /**
   *
   * @param {string} email
   * @param {string} password
   * @param {string} grant_type
   * @param {string} client_id
   * @param {string} client_secret
   * @param {string} refresh_token
   * @param {string} access_token
   * @param {string} scope
   * @returns
   */
  static login(email, password, grant_type, client_id, client_secret) {
    return BaseRequest.post(URL.INDEX, {
      email,
      password,
      grant_type,
      client_id,
      client_secret,
    });
  }

  static loginAuth(
    email,
    password,
    grant_type,
    client_id,
    client_secret,
    scope
  ) {
    return AuthenticatedApi.post(URL.TOKEN, {
      email,
      password,
      grant_type,
      client_id,
      client_secret,
      scope,
    });
  }

  static refreshAuth(grant_type, refresh_token, client_id, client_secret) {
    return AuthenticatedApi.post(URL.TOKEN, {
      grant_type,
      refresh_token,
      client_id,
      client_secret,
    });
  }

  /**
   *
   * @param {string} token
   * @returns
   */
  static getUserInfo() {
    const INFO_URL = `${URL.INFO}`;

    return AuthenticatedApi.get(INFO_URL);
  }

  /**
   *
   * @param {obj} data
   * @param {string} data.email
   * @param {string} data.password
   * @param {string} data.password_confirmation
   * @returns
   */
  static setPassword(data) {
    return AuthenticatedApi.post(URL.PASSWORD, data);
  }

  /**
   *
   * @param {obj} data
   * @param {string} data.email
   * @param {string} data.password
   * @param {string} data.password_confirmation
   * @param {string} data.invitation_token
   * @param {string} client_id
   * @returns
   */
  static acceptInvitation(data) {
    return BaseRequest.post(URL.ACCEPT_INVITATION, data);
  }

  static signUp(postData) {
    return AuthenticatedApi.post(URL.USERS, postData);
  }

  static checkUser(email) {
    const checkUser = URL.CHECK_USER.replace(":email", email);

    return AuthenticatedApi.get(checkUser);
  }
}

export default AuthApi;
