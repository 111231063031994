import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import queryString from "query-string";
import jwtDecode from "jwt-decode";

import PasswordToggle from "components/SignUp/PasswordToggle";
import usePasswordValidator from "components/SignUp/PasswordValidator/usePasswordValidator";

import Logo from "../../assets/images/logo1.png";
import TopCircle from "../../assets/images/circle.svg";
import ModalMail from "../../assets/images/recovery-success.svg";
import AuthApi from "api/AuthApi/AuthApi";
import CandidateApi from "api/Candidate/CandidateApi";
import { AuthContext } from "contextAPI/AuthContext";
import { UserContext } from "contextAPI/UserContext";
import { ON_BOARDING, SIGN_IN } from "constants/routes";

const getQueryParams = (url) => {
  const formattedUrl = decodeURIComponent(url);

  return queryString.parse(formattedUrl);
};

const NewPassword = () => {
  const parsed = getQueryParams(window.location.search);

  const token =
    "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoxLCJzbHVnIjoidHJ1ZW1hcmsiLCJqb2JfaWQiOjEsImVtYWlsIjoiaGVsbG9AdHJ1ZW1hcmsuZGV2In0.3Hm10prIEVY9NiefdSHl5U6GVDjT_Ig7GDJ4Eh5l-TM";

  const decoded = jwtDecode(token);

  const { onLoginSuccess } = useContext(AuthContext);
  const { handleCandidateSelected } = useContext(UserContext);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [candidateInfo, setCandidateInfo] = useState({});

  const [email, setEmail] = useState("");
  const [match, setMatch] = useState(false);

  const [password, setPassword, passwordError] = usePasswordValidator({
    min: 8,
    max: 15,
  });

  useEffect(() => {
    setEmail(decoded?.email);
  }, [decoded]);

  useEffect(() => {
    if (!confirmPassword || !password) {
      setConfirmPasswordError("");
    } else {
      if (password !== confirmPassword) {
        setConfirmPasswordError("The passwords must match.");
      } else {
        setConfirmPasswordError("");
      }
    }
  }, [password, confirmPassword]);

  const handleConfirm = () => {
    if (password === confirmPassword) {
      setMatch(true);
    } else {
      setMatch(false);
    }
  };

  const navigate = useNavigate();

  const handleModalSubmit = () => {
    navigate(ON_BOARDING.INDEX);
  };

  const handleModalLink = () => {
    navigate("/sign_up");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const body = {
      invitation_token: parsed.invitation_token,
      password: password,
      password_confirmation: confirmPassword,
    };

    try {
      const res = await AuthApi.acceptInvitation(body);
      const { data } = res;

      if (data?.token) {
        onLoginSuccess(res);
        handleCandidateSelected(candidateInfo?.id, candidateInfo?.user?.email);
        handleModalSubmit();
      } else if (data?.error_message) {
        setMessage(data.error_message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCandidateData = async (uid) => {
    const { data } = await CandidateApi.fetchCandidateByUId(uid);

    const candidate = data[0];

    setCandidateInfo(candidate);
  };

  useEffect(() => {
    if (parsed.uuid) {
      fetchCandidateData(parsed.uuid);
    }
  }, []);

  return (
    <div className="container-fluid sign-in sign-up">
      <div className="top-circle">
        <img src={TopCircle} alt="Top circle pattern" />
      </div>
      <div className="bottom-circle">
        <img src={TopCircle} alt="Bottom circle pattern" />
      </div>
      <div className="logo">
        <figure className="text-center m-0">
          <img src={Logo} alt="logo" />
        </figure>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-11 col-lg-9">
          <div className="login-form mt-4 row m-0 justify-content-center">
            <div className="form-container right col-md-8 col-lg-6 col-sm-12">
              <form className="form row m-0" onSubmit={handleSubmit}>
                <div className="col-12">
                  <h5 className="mb-3">Create new password</h5>
                  <p className="mb-4">{message}</p>
                  <div className="col-12 mt-4">
                    <div className="input-field form-floating">
                      <input
                        name="email"
                        id="candidate-email"
                        className="form-control"
                        value={email}
                        readOnly
                        disabled
                      />
                      <label htmlFor="candidate-email">Email Address</label>
                    </div>
                  </div>
                  <div className="col-12 mt-4">
                    <div className="input-field form-floating">
                      <PasswordToggle
                        password={password}
                        setPassword={setPassword}
                        placeholder="New Password"
                        passwordError={passwordError}
                      />
                    </div>
                  </div>

                  <div className="col-12 mt-4">
                    <div className="input-field form-floating">
                      <PasswordToggle
                        password={confirmPassword}
                        setPassword={setConfirmPassword}
                        placeholder="Confirm Password"
                        handleConfirm={handleConfirm}
                        passwordError={confirmPasswordError}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-12 mt-4">
                  {success ? (
                    <button
                      type="submit"
                      className="btn login-btn text-uppercase"
                      onClick={handleSubmit}
                      disabled={
                        password.length > 7 &&
                        confirmPassword.length > 7 &&
                        password === confirmPassword
                          ? false
                          : true
                      }
                      data-bs-toggle="modal"
                      data-bs-target="#successModal"
                    >
                      Create New Password
                    </button>
                  ) : (
                    <button
                      type="submit"
                      className="btn login-btn text-uppercase"
                      onClick={handleSubmit}
                      disabled={
                        password.length > 7 &&
                        confirmPassword.length > 7 &&
                        password === confirmPassword
                          ? false
                          : true
                      }
                    >
                      Create New Password
                    </button>
                  )}
                </div>
                <div className="col-12 mt-3 text-center">
                  <p className="mb-0 create-account">
                    <Link to={SIGN_IN.INDEX} className="btn-link ms-1">
                      I know my password. Go to login.
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="successModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" id="modal-dialog">
          <div className="modal-content text-center" id="modal-content">
            <div className="modal-body">
              <img className="modal-title mb-4" src={ModalMail} alt="Mail" />
              <h5>Congratulations! you have new password</h5>
              <p>
                You can start using this newly created password from next time.
                Keep in mind that you should not share this with anyone else
              </p>
            </div>
            <div className="modal-footer justify-content-center">
              <button
                type="button"
                className="btn text-uppercase"
                data-bs-dismiss="modal"
                onClick={handleModalSubmit}
              >
                Let's go to sign in
              </button>
            </div>
            <div>
              <Link
                to={{
                  hash: "#",
                }}
                role="button"
                data-bs-dismiss="modal"
                onClick={handleModalLink}
                onKeyDown={handleModalLink}
              >
                Create an account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewPassword;
