import { useFormState } from "react-final-form";
import { useSectionContext } from "components/Onboarding/Section";

const axios = require("axios");
const { useState, useEffect } = require("react");

const fetchData = async (url) => {
  try {
    const response = await axios.get(url);

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

const API_URLS = {
  country: "https://truemark.platform-staging.intelliante.com/api/v1/countries",
  state:
    "https://truemark.platform-staging.intelliante.com/api/v1/states?alpha2[]=:country",
  city: "https://truemark.platform-staging.intelliante.com/api/v1/cities?alpha2[]=:country&state_codes[]=:state",
};

export const useAddressQuery = (name) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [cache, setCache] = useState({});
  const { name: sectionName } = useSectionContext();
  const { values } = useFormState();

  const url = (API_URLS[name] || "")
    .replace(":country", values[sectionName]?.country || "")
    .replace(":state", values[sectionName]?.state || "");

  useEffect(() => {
    if (!["country", "city", "state"].includes(name)) return;
    const fetch = async () => {
      setIsLoading(true);
      try {
        // Check if data is already cached
        if (cache[url]) {
          setData(cache[url]);

          return;
        }
        // Fetch data and update cache
        let data = await fetchData(url);

        if (!data) {
          setData([]);

          return;
        }

        if (name === "country") {
          data = data.map((i) => ({ value: i.alpha2, label: i.name }));
        } else if (name === "state") {
          data = data[0]?.map((i) => ({ value: i[0], label: i[1] }));
        } else if (name === "city") {
          data = data[0]?.map((i) => ({ value: i, label: i }));
        }

        setData(data);
        setCache({ ...cache, [url]: data });
      } catch (error) {
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetch();
  }, [url]);

  return { data, error, isLoading };
};
