import React from "react";

import { t } from "i18next";

import getStatus from "utils/getStatus";
import { getStatusIcon } from "utils/getStatusIcon";
import { getStatusText } from "utils/getStatusText";

import CompanyImage from "components/Job/CompanyImage";

import { lOGO_CARD_HEIGHT } from "constants/constant";

const Cards = ({ job, onJobSelected }) => {
  const jobNameClass = job?.companies?.[0]?.name ? "ms-3" : "";

  return (
    <div
      type="card"
      role="button"
      tabIndex={0}
      onClick={() => onJobSelected(job)}
      onKeyDown={() => onJobSelected(job)}
      className="h-100"
    >
      <div className="card-item h-100 d-flex align-items-center cursor-pointer">
        <div className="d-flex justify-content-around align-items-center">
          <CompanyImage logos={job.company.logos} height={lOGO_CARD_HEIGHT} />

          <div className="info-wrapper d-flex flex-column">
            <h6 className="text-white fw-normal m-0">{job?.label}</h6>
            <span className="text-gray">{job.company.name}</span>
            <span className={`d-flex ${jobNameClass} align-items-center`}>
              {getStatusIcon(job?.candidateStatus)}
              <span
                className={`${getStatusText(
                  job?.candidateStatus
                )} ms-1 align-middle`}
              >
                {getStatus(job?.candidateStatus, t)}
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
