import React from "react";

const Loader = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="spinner-block">
      <div className="text-center">
        <div className="spinner-border" role="status"></div>
        <span className="px-4">Loading...</span>
      </div>
    </div>
  );
};

export default Loader;
