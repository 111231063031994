import React, { useContext, useEffect, useState } from "react";

import LocalStorageManager, { CONSTANTS } from "utils/localStorageManager";
import { AuthContext } from "./AuthContext";
import { CandidateJobContext } from "./CandidateJobContext";

export const UserContext = React.createContext();

const getActiveCandidate = () => {
  const existingJob = LocalStorageManager.get(CONSTANTS.SELECTED_JOB);

  return existingJob?.candidateId;
};

const UserProvider = ({ children }) => {
  const { userInfo } = useContext(AuthContext);
  const { userCandidatesData } = useContext(CandidateJobContext);
  const [selectedCandidateId, setSelectedCandidate] = useState(
    getActiveCandidate()
  );
  const [candidateInfo, setCandidateInfo] = useState({});
  const [candidateName, setCandidateName] = useState({});
  const [jobsInfo, setJobsInfo] = useState({});
  const [dynamicForm, setDynamicForm] = useState(null);

  const [welcomeForm, setWelcomeForm] = useState(null);

  const [userId, setUserId] = useState(null);
  const [userRace, setUserRace] = useState(null);
  const [userGender, setUserGender] = useState(null);
  const [updatedId, setUpdatedId] = useState("");

  const [completeVoluntary, setCompleteVoluntary] = useState(false);
  const [completeDisability, setCompleteDisability] = useState(false);

  const [formTitle, setFormTitle] = useState("");

  const [formTitleEmployment, setFormTitleEmployment] = useState("");

  const getUserName = (userName) => {
    if (!userInfo?.data?.first_name) {
      return "Unnamed";
    }

    return `${userInfo?.data?.first_name} ${userInfo?.data?.last_name}`;
  };

  useEffect(() => {
    setDynamicForm(candidateInfo?.job?.applicant_workflow);
  }, [candidateInfo]);

  useEffect(() => {
    setWelcomeForm(candidateInfo?.job?.welcome_page_workflow);
  }, [candidateInfo]);

  useEffect(() => {
    if (selectedCandidateId) {
      setCandidateInfo(
        userCandidatesData.find(
          (candidate) => candidate.id === selectedCandidateId
        )
      );
    }
  }, [selectedCandidateId, userCandidatesData, userInfo]);

  useEffect(() => {
    setSelectedCandidate(getActiveCandidate(userInfo));
  }, [userInfo, userCandidatesData, selectedCandidateId]);

  const handleCandidateSelected = (candidateId, userEmail) => {
    if ((userInfo?.email || userEmail) && candidateId) {
      const saveEmail = userInfo.email || userEmail;

      const existingIds =
        LocalStorageManager.get(CONSTANTS.ACTIVE_CANDIDATE_ID) || [];

      LocalStorageManager.set(CONSTANTS.ACTIVE_CANDIDATE_ID, [
        ...existingIds.filter(({ email }) => email !== saveEmail),
        { email: saveEmail, candidateId },
      ]);
    }

    setSelectedCandidate(candidateId);
  };

  const candidateFullName = getUserName(candidateName);

  return (
    <UserContext.Provider
      value={{
        selectedCandidateId,
        handleCandidateSelected,
        candidateInfo,
        setCandidateInfo,
        setCandidateName,
        candidateName,
        candidateFullName,
        jobsInfo,
        setJobsInfo,
        completeVoluntary,
        setCompleteVoluntary,
        completeDisability,
        setCompleteDisability,
        userId,
        setUserId,
        userGender,
        setUserGender,
        userRace,
        setUserRace,
        updatedId,
        setUpdatedId,
        formTitle,
        setFormTitle,
        formTitleEmployment,
        setFormTitleEmployment,
        dynamicForm,
        setDynamicForm,
        welcomeForm,
        setWelcomeForm,
        userCandidatesData,
        setSelectedCandidate,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
