import BaseRequest from "../BaseApi";

const URL = {
  INDEX: `/companies`,
  COMPANY_BRANDING: "/companies/branding",
};

class CompanyApi {
  static get(id) {
    const GET_URL = `${URL.INDEX}/${id}`;

    return BaseRequest.get(GET_URL);
  }

  static post(data) {
    return BaseRequest.post(URL.INDEX, data);
  }

  static update(id, data) {
    const UPDATE_URL = `${URL.INDEX}/${id}`;

    return BaseRequest.put(UPDATE_URL, data);
  }

  static delete(id) {
    const DELETE_URL = `${URL.INDEX}/${id}`;

    return BaseRequest.delete(DELETE_URL);
  }

  static getCompanyBranding() {
    const GET_COMPANY_BRANDING = URL.COMPANY_BRANDING;

    return BaseRequest.get(GET_COMPANY_BRANDING);
  }
}

export default CompanyApi;
