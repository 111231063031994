import { useState } from "react";
import { t } from "i18next";

import { COMPANY } from "constants/images";
import { reduceText } from "utils/textReducer";

const CompanyCard = ({ companyDetail }) => {
  const textLength = 20;

  const [isMore, setIsMore] = useState(false);

  const handleMore = () => {
    setIsMore((prev) => !prev);
  };

  const showMore = isMore
    ? t("message.jobDetails.seeLess")
    : t("message.jobDetails.seeMore");

  const displayShowMore =
    companyDetail?.description?.split(" ").length > textLength ? showMore : "";

  const urlDisplayClassName = companyDetail?.url ? "" : "d-none";

  return (
    <div className="col-12 col-sm-5 col-md-4 order-first align-self-start">
      <p className="py-3 title text-secondary">
        {t("message.jobDetails.companyInfo")}
      </p>

      <section className="company-card">
        <header className="company-brand text-center py-2">
          <figure className="py-3 m-0">
            <img
              src={companyDetail?.logos[0].image_url}
              alt={companyDetail?.name}
              className="company-logo"
            />
          </figure>
          <div>
            <h5 className="highlight company-name m-0">
              {companyDetail?.name}
            </h5>
          </div>
        </header>
        <p className={`text-gray m-0 ${urlDisplayClassName}`}>
          <a
            href={`https://${companyDetail?.url}`}
            target="_blank"
            className="highlight company-link cursor-pointer"
          >
            {companyDetail?.url?.replace("https://", "")}
          </a>
        </p>
        <div className="info">
          <p className="text-gray company-details mt-3 text-start">
            {reduceText(companyDetail?.description, textLength, isMore)}
            <span
              onClick={handleMore}
              onKeyDown={handleMore}
              role="button"
              tabIndex="0"
              className="text-light"
            >
              {displayShowMore}
            </span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default CompanyCard;
