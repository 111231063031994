import React from "react";

import { t } from "i18next";

import { DeleteIcon, EditIcon } from "constants/icons/icon";
import DeleteModal from "components/Modals/DeleteModal";

const ActionButtons = ({
  idx,
  handleOnClickEdit,
  handleDelete,
  editId,
  deleteId,
  deleteEducation,
  setDeleteEducation,
  DeleteConfirmEducation,
}) => {
  if (idx === editId) {
    return (
      <div className="buttons-holder d-flex justify-content-end my-4">
        <div className="px-2 py-1 editing-btn">{t("attributes.editing")}</div>
      </div>
    );
  }

  return (
    <div className="d-flex my-4 justify-content-end align-items-center position-relative">
      <button
        type="button"
        className="bg-transparent border-0 btn-icon"
        onClick={handleOnClickEdit}
      >
        <EditIcon className="color-icon edit-icon me-2" />
      </button>

      <button
        type="button"
        className="bg-transparent border-0 btn-icon"
        onClick={handleDelete}
      >
        <DeleteIcon className="del-icon color-icon" />
      </button>
      <div className="delete-holder position-absolute">
        <DeleteModal
          idx={idx}
          deleteId={deleteId}
          del={deleteEducation}
          setDel={setDeleteEducation}
          deleteFunction={DeleteConfirmEducation}
        />
      </div>
    </div>
  );
};

export default ActionButtons;
