const slugName = window.location.href;

const slug = slugName.split(".")[0].split("/")[2];

class ApiUrlConfig {
  static endPoint() {
    let basePath = process.env.REACT_APP_API_BASE_URL;

    basePath = basePath.replace(":companyId", slug);

    return basePath;
  }
}

export default ApiUrlConfig;
